.svg-content {
  text-align: center;
  @include container-up {
    //padding: 0 20px;
  }

  @media (max-width: $desktop_mid_width) {
    display: none;
  }
  svg {
    #original {
      .eachlayer {
        cursor: pointer;

        &.show {

          .changebg {
            fill: #2B3174;
          }
          .iconfill {
            fill: #FFFFFF;
          }
          .text {
            fill: #FFFFFF;
          }
          .textbg {
            fill: #2B3174;
          }
        }
      }
    }

    .content {

      .popup-title {
        font-family: "futura_lt_bt", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        font-size: 22px;
      }

      .popup-inner-text {
        color: #253746;
        font-size: 12px;
        font-family: "futura_lt_bt", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        font-weight: 300;
      }

      &.show {
        display: block !important;
      }
    }
  }

}
