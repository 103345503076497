.image-with-col {
  @include container-up {
    //padding: 110px 0 0;
  }
  .full-row {
    background-color: $light-grey;
    padding: 20px 0;
    border: solid $mid-light-sky;
    color: $light-bluewhile;
    border-width: 0 1px 2px 0;
    margin: 0;
    @include desktop-down {
      padding: 30px 0;
    }
    .col-wrap {
      padding-right: 0;
      padding-left: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include phablet-down {
        padding: 0 20px;
      }
      .img-wrap {
        max-width: 300px;
        min-height: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .main-content {
        padding-left: 46px;
        padding-right: 25px;
        @include desktop-down {
          padding: 30px 14px 0 0;
        }
        @include phablet-down {
          padding: 30px 0 0;
          text-align: center;

        }
        .headline {
          padding-bottom: 27px;
          position: relative;
          h2 {
            color: $light-bluewhile;
            font-size: 22px;
            font-weight: 300;
            @include phablet-down {
              font-size: 21px;
            }
          }
          span {
            font-size: 14px;
            color: $light-bluewhile;
            font-weight: 300;
            @include phablet-down {
              font-size: 10px;
            }
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 10px;
            left: 44px;
            transform: translateX(-50%);
            width: 87px;
            height: 1px;
            background: $skyblue-light;
            border-bottom: 1px solid $bluesky-light;
            background: $sky-light;
            @include phablet-down {
              bottom: 10px;
              left: 50%;
            }
            @media (max-width: 476px) {
              bottom: 15px;
              left: 50%;
            }
          }
        }
        .content {
          padding-right: 180px;
          padding-bottom: 6px;
          @include desktop-down {
            padding-right: 0;
          }
          h3{
            font-weight: 300;
            color: $light-bluewhile;
          }
          p {
            font-size: 22px;
            color: $light-bluewhile;
            margin: 0;
            padding-bottom: 5px;
            @include phablet-down {
              font-size: 18px;
            }
          }
          ul {
            margin-left: 20px;
            margin-top: 10px;
            @include phablet-down {
              margin-left: 0;
            }
          }
        }
        .info-content {
          width: 80%;
          float: left;
          @include phablet-down {
            width: 100%;
            float: none;
          }
          h4 {
            margin: 0;
            padding-bottom: 4px;
            color: $light-bluewhile;
            font-size: 22px;
            font-weight: 300;
            letter-spacing: -0.06em;
          }
          p {
            color: $light-bluewhile;
            font-size: 22px;
            margin: 0;
          }
        }
        .right-btn {
          float: right;
          margin-top: 23px;
          padding: 0 20px 0 28px;
          @include phablet-down {
            float: none;
          }
          a {
            font-size: 16px;
            color: $white;
            font-weight: 300;

            &:hover {
              text-decoration: none;
            }
             @include phablet-down {
              font-size: 12px;
             }
          }
        }
      }
    }
  }
}

