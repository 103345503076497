.managment-charts {

  h2, p, h3, h4, h5, h, span, a, td {
    strong {
      font-weight: 700 !important;
    }
    
  }
  .bhelper {
    background-color: black;
    position: fixed;
    z-index: 2;
    bottom: 50px;
    height: 120px;
    width: 270px;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    opacity: 0.6;
    left: 50%;
    transform: translateX(-50%);
    p {
      margin: 0;
      padding: 0;
      color: white;
      font-size: 1.2em;
    }
    .harrow {
      width: 30px;
      border: 15px solid transparent;
      border-top-color: black;
      display: inline-block;
      margin-top: 17px;
    }
  }
  .countries {
    position: fixed;
    z-index: 2;
    bottom: -43px;
    height: 72px;
    background: url(/themes/thalesesecurity/templates/assets/images/compare-data.png) no-repeat center 10px #00bbdd;
    box-shadow: 0px 0px 10px 0px #777777;
    padding: 32px 10px 10px;
    border-radius: 3px 0px 0px 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    cursor: pointer;
    &.showc {
      bottom: 0;
    }
    .tt-cont {
      position: absolute;
      z-index: 102;
      height: 24px;
      width: 140px;
      display: inline-block;
      margin-top: -37px;
      .tt-arrow {
        position: absolute;
        display: inline;
        width: 7px;
        height: 7px;
        border: 7px solid transparent;
        box-sizing: border-box;
        border-top-color: #000;
        top: 100%;
        left: -25px;
      }
      .tt-text {
        position: absolute;
        background-color: #000000;
        color: #ffffff;
        display: inline;
        padding: 5px 7px 7px;
        font-size: 0.8em;
        line-height: 1em;
        font-weight: normal;
        border-radius: 3px;
        left: 0;
      }
    }
    .cbtn {
      display: inline-block;
      border-radius: 3px;
      padding: 1px 8px 5px;
      margin-bottom: 5px;
      width: 36px;
      height: 30px;
      overflow: hidden;
      text-indent: 50px;
      position: relative;
      z-index: 101;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
      &.usf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-us.jpg) center center no-repeat;
      }
      &.ukf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-uk.jpg) center center no-repeat;
      }
      &.frf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-fr.jpg) center center no-repeat;
      }
      &.def {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-de.jpg) center center no-repeat;
      }
      &.bef {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-bene.jpg) center center no-repeat;
      }
      &.inf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-in.jpg) center center no-repeat;
      }
      &.jpf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-jp.jpg) center center no-repeat;
      }
      &.auf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-au.jpg) center center no-repeat;
      }
      &.mef {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-me.jpg) center center no-repeat;
      }
      &.bzf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-bz.jpg) center center no-repeat;
      }
      &.saf {
        background: url(/themes/thalesesecurity/templates/dist/images/flag-saf.jpg) center center no-repeat;
      }

    }
  }
  #charts {
    padding: 0 10px;

    > div {
      padding-top: 13px;
      margin-bottom: 140px;
      background-color: #e1e1e1;
      @include container-down {
        margin-bottom: 40px;
      }
      @include phablet-down {
        margin-bottom: 30px;
      }
    }

   

    .bg-blue {
      background: #21296f;
      padding: 20px 0;
    }
   
    h2 {
      font-size: 54px;
      font-weight: 700;
    }
    h3 {
      color: #242a75;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 14px;
      width: calc(100% - 122px);
      @include container-up {
        padding-right: 50px;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.625;
      margin-bottom: 0;
      color: #fff;
      font-weight: 500;
    }
    .lightgrey {
      margin-top: 20px;
      @include container-down {
        margin: 0;
        margin-top: 20px;
        h3{
         font-size: 20px;
        }
      }

      @include phablet-down {
        margin: 0;
        text-align: center;
        padding: 0 20px;

        h3{
          width: 100%;
          font-size: 16px;
        }
      }
    }
    ul {
      margin: 0 0 0 -6px;
      padding: 0;
      @include phablet-down {
        margin: 0;
      }
      li {
        position: relative;
        list-style: none;
        font-size: 1em;
        font-weight: 400;
        padding: 10px 10px 10px 30px;
        margin-bottom: 2px;
        margin-right: 10px;
        width: calc(100% - 10px);
        transition: all 0.3s;
        &:before {
          content: ">";
          font-size: 1.4em;
          color: #00bbdd;
          width: 28px;
          font-weight: 900;
          position: absolute;
          left: 8px;
          top: 4px;
          transition: all 0.3s;
          margin-top: -5px;
      }
        &:hover {
          background-color: #fff;
          padding-left: 34px;
          transition: all 0.3s;
          padding-right: 5px;
            &:before {
            left: 12px;
          }
        }
        @include phablet-down {
        font-size: 14px;
        &:before{

        }
        }
      }
    }
    .lg{
    width: 100%;
    margin: 0;  
    padding-bottom: 36px;
    .col-lg-8{ 
      padding-right: 0;
      padding-left: 40px;
      @include container-down {
        padding:0 15px;
      }
      @include phablet-down {
        padding: 0;
      }
    }    
    }
    .grey-left {
      padding-top: 20px;
      padding-left: 35px;
      @include tablet-down {
        padding:10px 20px;
      }
      @include phablet-down {
        padding: 0;
      }
    }
    .gray-right {
      padding-left: 50px;
    
    }
    .darkgrey {
      background-color: #242a75;
      padding: 16px 0;
      margin-bottom: 20px;
      @include phablet-down {
        text-align: center;
      }
       .row{
         margin: 0;
       }
      p {
        line-height: 1.6;
        padding-left: 8px;
        @include container-down {
         padding-left: 12px;
        }
        @include phablet-down {
          font-size: 14px;
          padding-left: unset;
        }
      }
    }
    .bigwnum {
      color: #fff;
      font-weight: bold;
      font-size: 56px;
      float: left;
      margin: 0 10px 0 0;
      line-height: 48px;
      letter-spacing: -2px;
      @include container-down {
        font-size: 40px;
      }
      sup {
        font-size: 33px;
      }
      @include phablet-down {
        float: none;
        font-size: 40px;
        margin: 0;
        sup{
         font-size: 22px;
        }
      }
    }
    .col-8 {
      h3 {
        max-width: 654px;
        float: left;
      }
    }
    .qnum {
      float: right;
      background-color: #00bbdd;
      font-size: 3em;
      color: white;
      font-weight: bold;
      padding: 8px 20px 15px;
      line-height: 1em;
      position: absolute;
      right: 0;
      height: 63px;
      @include phablet-down {
        margin-bottom: 10px;
        float: none;
        position: relative;
        right: 0;
        left: 0;
        bottom: 0;
        font-size: 2em;
        top: 0;
        height: 50px;
        margin: auto;
      }
    }
    .compcountry {
      background-image: url("/themes/thalesesecurity/templates/assets/images/compare-global.png");
      background-repeat: no-repeat;
      float: left;
      width: 100%;
      padding: 7px 50px 7px 80px;
      font-weight: 500;
      color: #303136;
      margin-bottom: 22px;
      @include phablet-down {
        background-position:center left;
        padding: 10px 30px;
      }
    }
    table {
      width: 100%;
      border: none;
      @include phablet-down {

      }
      tr{
        background: transparent;
        &:nth-child(odd){
          background: transparent;
        }
      }
      td {
        font-size: 0.8em;
        height: 32px;
        vertical-align: middle;
        line-height: 1;
        padding: 0;
        text-align: right;
        @include container-down {
        }
        @include phablet-down {
          text-align: left;
          padding: 5px 0;
        }
      }
    }
    .leftarrow {
      background: url(/themes/thalesesecurity/templates/assets/images/multiq-l-arrow-blue.png) center center no-repeat;
      min-height: 60px;
      padding-left: 0;
      padding-right: 0;
      width: 51px;
      cursor: pointer;
      @include container-up {
      top: -6px;
      }
    }

    .rightarrow {
      background: url(/themes/thalesesecurity/templates/assets/images/multiq-r-arrow-blue.png) center center no-repeat;
      min-height: 60px;
      padding-left: 0;
      padding-right: 0;
      width: 51px;
      cursor: pointer;
      @include container-up {        
      top: -6px;
      }
    }

    .multiq-box {
      margin: 0 0 10px 0;
      padding: 10px 15px 10px;
      background-color: #cccccc;
      overflow: hidden;
      width: 98.2%;
      @include container-down {
        width: 100%;
        margin: 20px 0;
      }
      @include phablet-down {
        width: 100%;
        margin: 10px 0;
      }
      p {
        line-height: 1.6;
        padding: 0 0 1em;
        color: #3C3C3C;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }
    .chart-right {
      position: relative;
      overflow: hidden;
      @include phablet-down {
      padding: 2px 10px 0;
      }
   
      &.chart-left{
        @include phablet-down {
          padding: 0;
        }
      }
      &.chart-circle{
        @include phablet-down {
        }
      }

    }
    .chart-container {
      display: none;
      width: 93%;
      height: inherit;
      position: absolute;
      &.chart-current {
        display: block;
        z-index: 1;
        // @include phablet-down {
        //   width: 100%;
        // }
      }
    }
    .mquestion {
      position: absolute;
      display: none;
    }
    .currentq {
      display: block;
      z-index: 1;
    }
    .colourkey {
      width: 25px;
      float: right;
      height: 15px;
      margin-left: 10px;
      display: none;
    }
  }
}


