.quiz-with-image {
  background-color: #f5f5f5;

  @include media-breakpoint-up(lg) {
    padding-top: 68px;
  }
  strong {
    font-weight: 700 !important;
  }
  strong, p, h3 {
    color: $white;
  }
  .bg-dark-gray {
    background-color: #888888;
  }
  .transbtn {
    cursor: pointer;
    color: $white;
    text-decoration: none;
    padding: 7px 0;
    width: 100%;
    display: inline-block;
    font-weight: bold;
    border: 1px solid $white;
    border-radius: 5px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background-color: $white;
      color: #888888;
    }
  }

  .outer-wrapper {
    padding: 0 56px 0 26px;
    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    .inner-wrapper {
      .tabs-outer {
        min-height: 278px;
        padding: 72px 0 0;
        max-width: 710px;
        margin: 0 auto;
        text-align: center;
        @include tablet-down  {
          padding: 30px;
          min-height: 210px;

        }
        .tilte {
          width: 100%;
          h3 {
            width: 100%;
            @include media-breakpoint-up(lg) {
              margin-bottom: 12px;
            }
          }
          p {
            font-weight: 400;
            width: 100%;
            font-size: 16px;
            @include media-breakpoint-up(lg) {
              margin-bottom: 20px;
            }
          }
        }

        .result {
          display: none;
          p {
            font-size: 16px;
            font-weight: 400;
          }
          a {
            font-size: 16px;
          }
        }

      }
    }

    .image-text {
      background: #f5f5f5;
      .wrapper {
        .item {
          background: #fff;
          width: 100%;
          //width: 66.66%;
          margin: 0 auto;
          text-align: center;
          padding: 60px 20px;
          @include tablet-down  {
            padding: 40px 20px;
          }
          h2 {
            font-size: 27px;
            font-weight: 400;
            margin: 10px 0 20px;
            color: #212121;
          }
          p {
            font-size: 16px;
            color: #3C3C3C;
            line-height: 1.6;
            font-weight: 400;
            max-width: 723px;
            margin: 0 auto 27px;
          }
        }
      }
    }
  }
}
