@import 'variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

section {
  ol, ul, li {
    padding: 0;
    margin: 0;
    list-style: inherit;
    font-family: $font-families;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 20px;
    > li {
      position: relative;
      font-family: $font-families;
      font-size: 22px;
      line-height: 1.5;
      font-weight: 100;
      color: $light-bluewhile;
      margin-bottom: 0;
      @include tablet-down {
        line-height: 1.3;
        margin-bottom: 20px;
      }
      @include phablet {
        font-size: 20px;
      }
      @include phone {
        font-size: 16px;
      }
      @include phone-down {
        font-size: 14px;
      }
    }
  }

  ol {
    counter-reset: section;
    margin-top: 18px;
    text-align: left;
    > li {
      font-size: 16px;
      font-weight: 300;
      font-family: $font-families;
      line-height: 1.56;
      color: $black;
      position: relative;
      margin-bottom: 11px;
      @include tablet-down {
        font-size: 14px;
      }
    }
  }
}

a {
  //font-size: 16px;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  font-family: $font-families;
  position: relative;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
  //color: $light-bluewhile;
  @include tablet-down {
    line-height: 1.3;
  }
  @include phablet {
    font-size: 20px;
  }
  @include phone {
    font-size: 16px;
  }
  @include phone-down {
    font-size: 14px;
  }
}

span, label {
  font-family: $font-families;
}

//@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=ce3f72e4-e293-47b2-aa77-88106c8e4843");

h1, h2, h3, h4, h5, h6, p,
fieldset, form, label,
table, tbody, tr, th, td,
figure, footer, header,
menu, nav, section,
audio, video, button {
  margin: 0;
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
  border: 0;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1260px;
}

section {
  width: 100%;
  position: relative;
  float: left;
  //padding: 120px 0;
  padding: 30px 0;
  overflow: hidden;
  //@include desktop {
  //  padding: 80px 0;
  //}
  //@include tablet {
  //  padding: 60px 0;
  //}
  //@include tablet-down {
  //  padding: 40px 0;
  //}
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

header:after,
.container:after,
.ql-18:after,
.wrapper:after,
.column-wrapper:after,
.grid-wrapper:after,
.two-third:after,
.content-wrapper:after,
.v-middle-wrapper:after,
footer:after {
  content: "";
  clear: both;
  display: block;
}

footer {
  clear: both;
}

/*
 * Heading and Paragraph.
 */

h1,.h1 {
  font-size: 46px;
  line-height: 1.04;
  font-family: $font-families;
  font-weight: 600;
  color: $light-bluewhile;
  @include desktop {
    font-size: 44px;
  }
  @include tablet {
    font-size: 42px;
  }
  @include phablet {
    font-size: 40px;
  }
  @include phone {
    font-size: 35px;
  }
  @include phone-down {
    font-size: 30px;
  }
}

h2,.h2 {
  font-size: 32px;
  font-weight: 400;
  font-family: $font-families;
  line-height: 1.06;
  color: $light-bluewhile;
  @include desktop {
    //font-size: 32px;
  }
  @include tablet {
    font-size: 30px;
  }
  @include phablet {
    font-size: 28px;
  }
  @include phone {
    font-size: 26px;
  }
  @include phone-down {
    font-size: 24px;
  }
}

h3,.h3 {
  font-size: 24px;
  font-weight: 400;
  font-family: $font-families;
  line-height: 1.33;
  color: $light-bluewhile;
  margin-bottom: 20px;
  @include desktop {
    //font-size: 28px;
  }
  @include tablet {
    font-size: 22px;
  }
  @include phablet {
    font-size: 20px;
  }
  @include phablet-down {
    font-size: 18px;
  }
}

h4,.h4 {
  font-size: 20px;
  font-family: $font-families;
  font-weight: 400;
  line-height: 1.21;
  color: $light-bluewhile;
  @include phablet-down {
    font-size: 18px;
  }
}

h5,.h5 {
  font-size: 18px;
  font-family: $font-families;
  font-weight: 400;
  line-height: 1.09;
  color: $light-bluewhile;
  @include phablet-down {
    font-size: 16px;
  }
}

h6,.h6 {
  font-size: 16px;
  font-family: $font-families;
  font-weight: normal;
  line-height: 1.2;
  text-transform: uppercase;
  color: $white;
}

p {
  font-family: $font-families;
  font-size: 22px;
  line-height: 1.5;
  color: $light-bluewhile;
  margin: 0 0 1.64em;
  font-weight: 100;
  @include tablet-down {
    line-height: 1.3;
  }
  @include phablet {
    font-size: 20px;
  }
  @include phone {
    font-size: 16px;
  }
  @include phone-down {
    font-size: 14px;
  }
}

.color-white {
  color: $white;
}

/*
 * Columns.
 */

.col {
  width: 100%;
  float: left;
  position: relative;
}

.col-two {
  width: 50%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-three {
  width: 33.33%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-four {
  width: 25%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-five {
  width: 20%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-six {
  width: 16.66%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-seven {
  width: 66.66%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

.col-eight {
  width: 75%;
  float: left;
  position: relative;
  @include tablet-down {
    width: 100%;
  }
}

/*
 * Background Image.
 */
.bg-img {
  overflow: hidden;
}

/*
 * Vertical Align Middle.
 */
.v-middle-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3
}

.v-middle-inner {
  display: table;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.v-middle,
.v-top,
.v-bottom {
  display: table-cell;
  vertical-align: middle;
}

.v-top {
  vertical-align: top;
}

.v-bottom {
  vertical-align: bottom;
}

/*
 * Text Alignment.
 */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.half-padding {
  padding: 60px 0 !important;
  @include tablet-down {
    padding: 40px 0 !important;
  }
}

.half-padding-top {
  padding-top: 60px !important;
  @include tablet-down {
    padding-top: 40px !important;
  }
}

.half-padding-bottom {
  padding-bottom: 60px !important;
  @include tablet-down {
    padding-bottom: 40px !important;
  }
}

.indent {
  padding-left: 20px;
}

.blend-mode {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  mix-blend-mode: multiply;
}

input {
  font-family: $font-families;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

input::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: $font-families;
  color: $black;
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: $font-families;
  color: $black;
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: $font-families;
  color: $black;
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: $font-families;
  color: $black;
  opacity: 1;
}

.no-result {
  color: $light-bluewhile;
}

table {
  border-top: 2px solid $MidnightBlue;
  th {
    background: $dark-bluewhile1;
    color: $dark-bluewhile;
    padding: 12px 18px;
    text-align: left;
    border-color: $dark-bluewhile1;
    vertical-align: middle;
    font-weight: normal;
  }
  tr {
    &:nth-child(even) {
      background-color: $mid-light-sky1;
    }
    &:nth-child(odd) {
      background-color: $white;
    }
    td {
      padding: 8px;
      border: none;
      line-height: 155%;
      font-family: $font-families;
      vertical-align: middle;
      &:first-child {
        width: 32%;
      }
      &:last-child {
        width: 68%;
      }
    }
  }
}

.nodeid {
  display: none;
}
