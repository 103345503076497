.info-block {
  background: $white2;
  .title {
    text-align: center;
    padding-bottom: 10px;
    h2 {
      font-weight: 300;
      color: $light-bluewhile;
    }
  }

  .info-item {
    background: $white;
    padding: 36px 20px 29px 42px;
    margin: 0 0 10px;
    @include desktop-down {
      padding: 30px 20px 25px 20px;
    }
    @include phablet-down {
      padding: 25px 12px;
    }
    .content {
      padding-bottom: 20px;
      @include desktop-down {
        padding-bottom: 0;
        margin-bottom: 8px;
      }
      h3 {
        font-weight: 300;
        color: $light-bluewhile;
        padding-bottom: 6px;
        margin: 0;
        @include desktop-down {
          padding-bottom: 0;
          margin-bottom: 5px;
        }
      }
      a {
        font-weight: 100;
        color: $blue5;
        font-size: 22px;
        @include desktop-down {
          font-size: 20px;
        }
        @include tablet-down {
          font-size: 18px;
        }
        @include phablet-down {
          font-size: 16px;
        }
      }
    }
  }

  .wrapper {
    background: $white;
    display: block;
    padding: 36px 20px 0 42px;
    @include desktop-down {
      padding: 25px 20px 25px 30px;
    }
    h3 {
      padding: 0 0 29px 0;
      font-size: 24px;
      color: $light-bluewhile;
      font-weight: 500;
      @include desktop-down {
        padding: 0;
      }
    }
    .card-block {
      padding: 0 0 0 18px;
      max-width: 50%;
      min-height: 500px;
      float: left;
      @include desktop-down {
        max-width: 100%;
        float: none;
        min-height: auto;
        padding: 15px 0 0 0;
      }
      h4 {
        font-weight: 300;
        color: $light-bluewhile;
        @include tablet-down{
          margin-bottom: 5px;

        }
      }
      p {
        color: $light-bluewhile;
        font-weight: 100;
        @include desktop-down {
          margin-bottom: 15px;
        }

      }
      ul {
        padding: 20px 30px;
        @include desktop-down {
          padding: 12px 18px;
        }
        li {
          margin: 0;
          color: $light-bluewhile;
        }
      }
      &.info-card {
        text-align: center;
        //padding: 30px;
        .card {
          border: 1px solid #eaeaea;
          padding: 30px;
          @include tablet-down {
            padding: 20px;
          }
          @include phablet-down {
            padding: 12px;
          }

          h5 {
            font-size: 18px;
            color: $light-bluewhile;
            padding-bottom: 8px;
            @include desktop-down {
              padding-bottom: 15px;
            }
            @include tablet-down{
              font-size: 16px;
            }
          }
          a {
            display: block;
            img {
              max-width: 150px;
              height: auto;
              vertical-align: top;
              width: 100%;
              @include desktop-down {
                margin-bottom: 12px;
              }
            }
          }
          p {
            a {
              display: block;
              font-weight: 100;
              color: $blue5;
              font-size: 22px;
              @include desktop-down {
                font-size: 20px;
              }
              @include tablet-down{
                font-size: 18px;
              }
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

}




