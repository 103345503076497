.content-row{
  @include container-up{
    
  }
  .column-wrap{
    width:100%;
    position:relative;
    .text-with-input{
      padding: 20px;
      background-color: $white2;
      display: flex;
      align-items:center;
      width: 100%;
      margin-bottom: 40px;
      @include desktop-down{
        padding: 15px;
        margin-bottom: 25px;
      }
      @include tablet-down{
        display: block;
      }
      @include phone-down{
        padding: 10px;
      }
      .text-content{
        width: 59%;
        position: relative;
        text-align: center;
        @include desktop-down{
          width: 50%;
        }
        @include tablet-down{
          width: 100%;
          margin-bottom:20px;
        }
        h3{
          color:$MidnightBlue;
          font-size: 22px;
          font-weight: 300;
          @include desktop-down{
            font-size: 20px;
          }
          @include tablet-down{
            font-size: 18px;
          }
        }
      }
      .input-box{
        width: 41%;
        position: relative;
        display: flex;
        max-width: 100%;
        padding-left: 97px;
        @include container-down{
          padding-left: 0;
          max-width: 358px;
          margin-left: 50px;
        }
        @include desktop-down{
          width: 50%;
          margin-left: 30px;
        }
        @include tablet-down{
          padding-left:0;
          width: 100%;
          margin: 0 auto;
        }
        @include phone-down{
          max-width: 100%;
        }

        input {
          margin-right: 5px;
          font-size: 15px;
          padding: 0 12px;
          color: $light-bluewhile;
          border: 2px solid $white3;
          height: 45px;
          font-family: $font-families;
          background-color: $white;
          font-weight: 400;
          letter-spacing: 1.4px;
          outline: none;
          max-width: 245px;
          width: calc(100% );
          box-sizing: border-box;
          appearance: none;
          border-radius: 0;
          @include phone-down{
            max-width: 260px;
            margin-right: 3px;
            
          }
        }
        input::placeholder{
          font-family:$font-families;
          font-size: 15px;
          font-weight: 300;
          line-height: 1.21;
          letter-spacing: normal;
          color: $white3;
        }
        button {
          border: none;
          outline: none;
          display: inline-block;
          vertical-align: middle;
          background: -webkit-gradient(linear,left top,left bottom,from(#4eb2ca),to(#1f6ea9));
          background: -webkit-linear-gradient(top,#4eb2ca 0%,#1f6ea9 100%);
          background: linear-gradient(to bottom,#4eb2ca 0%,#1f6ea9 100%);
          width: 100%;
          text-decoration: none;
          position: relative;
          z-index: 0;
          text-align: center;
          margin: 0;
          border-radius: 0;
          height: 45px;
          padding: 0px 0;
          max-width: 107px;
          border: 0;
          cursor: pointer;
          color:$white;
          font-size: 18px;
          line-height: 1.38;
          letter-spacing: normal;
          font-family:$font-families;
          overflow: hidden;
          @include phone-down{
            max-width: 75px;
          }
          span{
            font-family: $font-families;
            position: relative;
            font-size: 16px;
            font-weight: 300;
          }

        }
      }
    }
    .items-tab{
      position:relative;
      padding: 46px 48px 28px 36px;
      margin: 0 0 31px;
      background: $light-grey;
      border-bottom: 2px solid $blue9;
      display: flex;
      @include container-down{
        padding: 30px;
      }
      @include tablet-down{
        display: block;
        padding: 30px 20px;
      }
      .heading-box{
        width: 28%;
        padding: 3px 60px 10px 0;
        @include container-down{
          padding: 3px 35px 10px 0;
        }
        @include tablet{
          padding: 3px 25px 10px 0;
        }
        @include tablet-down{
          width: 100%;
          padding: 0 0 10px 0;
        }
        h2{
          position:relative;
          font-size: 22px;
          margin: 0 0 7px;
          color:$light-bluewhile;
          font-weight: 300;
          padding: 0 0 26px;
          line-height: 1.2;
          @include tablet-down{
            padding: 0 0 15px;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width:100%;
            max-width: 87px;
            height: 2px;
            background: $blue-light;
            border-bottom: 1px solid $blue-light1;
          }
        }
      }
      .description{
        padding: 20px 20px 0 0;
        max-width: 55%;
        @include desktop-down{
          padding: 12px 15px 0 0;
        }
        @include tablet-down{
          width: 100%;
          max-width:100%;
          padding: 0 0 10px 0;
        }
        p{
          color:$light-bluewhile;
          margin: 0 0 20px;
          font-weight:100;
          line-height: 1.66;
          @include desktop-down{
            font-size:20px;
            margin: 0 0 12px;
          }
          @include tablet-down{
            font-size:18px;
          }
          @include phone-down{
            font-size:16px;
          }
        }
      }
      .link-box{
        align-self: flex-end;
        min-width: 10%;
        text-align: center;
        padding: 0 0 16px;
        margin: 0 auto;
        @include tablet-down{
          width: 100%;
          max-width:100%;
          padding: 0 0 10px 0;
          align-self: start;
          text-align: left;
        }
        .active-link{
          font-weight: 100;
          @include tablet{
            font-size: 16px;
            min-width: 12%;
          }
        }
      }
    }

    .pager{
      @include tablet-down{
        justify-content: left;
      }
      ul.js-pager__items{
        margin: 0 20px;
        @include phone-down{
          margin: 0 auto !important;
        }
        li.pager__item{
          @include desktop{
            margin: 0 20px 0 0;
          }
          @include desktop-down{
            margin: 0 15px 0 0;
          }
          @include tablet-down{
            margin: 0 10px 0 0;
          }
          a{
            &:hover{
              color: $light-bluewhile !important;
            }
          }
          &.pager__item--last, &.pager__item--first{
            @include tablet-down{
              display:none;
            }
          }

          &.pager__item--next, &.pager__item--last{
            a{
              padding-right: 27px;
              position: relative;
              @include tablet-down{
                padding-right: 0;
              }
              &:after{
                content: "\e90a";
                font-family: 'icomoon' !important;
                display: inline-block;
                position: absolute;
                width: 18px;
                top: 2px;
                right:0;
                height: 18px;
                text-align: center;
                color: $blue3;
                background:$blue15;
                border-radius: 50%;
                font-size: 18px;
                line-height: 1;
                padding: 0 0 0 1px;
                transition: all ease-out 0.3s;
                @include tablet-down{
                  display:none;
                }
              }
              &:hover{
                &:after{
                  right: -14px;
                }
              }
            }
          }
          &.pager__item--first, &.pager__item--previous{
            a{
              padding-left: 27px;
              position: relative;
              @include tablet-down{
                padding-left:0;
              }
              &:after{
                content: "\e90a";
                font-family: 'icomoon' !important;
                display: inline-block;
                position: absolute;
                width: 18px;
                top: 2px;
                left:0;
                height: 18px;
                text-align: center;
                color: $blue3;
                background:$blue15;
                border-radius: 50%;
                font-size: 18px;
                line-height: 1;
                padding: 0 0 0 1px;
                transition: all ease-out 0.3s;
                transform: rotate(180deg);
                @include tablet-down{
                  display:none;
                }
              }
              &:hover{
                &:after{
                  left: -14px;
                }
              }
            }
          }
          &.is-active{
            a{
              color: $light-bluewhile !important;
            }
          }
        }

      }
    }
  }

  .views-exposed-form {
    display: none;
  }
}