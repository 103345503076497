.cal-thanks-intro{
    padding: 60px 0 20px;
    background-color: #f5f5f5;
    @include phablet-down{
       padding: 40px 0; 
    }
    .success{
      display: none;
    }
    .downloadbtn{
      text-transform: uppercase;
      padding: 14px 30px 19px 30px;
      margin: 15px 0 3px;
      display: inline-block;
      border-radius: 3px;
      font-size: 21px;
      font-weight: bold;
      background-repeat: no-repeat;
      background-color: #00bbdd;
      color: white;
      background-position: 10px 10px;
      cursor: pointer;
      background-image: url(/themes/thalesesecurity/templates/dist/images/pdf-icon.png);
      &:hover{
        text-decoration: none;
        background-color: #41D4EE;
      }
    }
    .wrapper{
        max-width: 1000px;
        margin: 0 auto;
        .title{
            text-align: left;
            margin-bottom: 30px;
            @include phablet-down{
            margin-bottom: 20px;
            text-align: center;
            }
            h2{
            font-size: 2.3em;
            font-weight: bold;
            color: $black;
            @include desktop-down{
            font-size: 2em;
            }
            @include phablet-down{
            font-size: 1.8em;
             }
            }
        }
        .white-block{
            margin: 0;
            background-color: $white;
            border-bottom: solid 5px #00bbdd;
            padding: 30px 0 60px;
            @include phablet-down{
            padding: 20px 0;
            }
            .col-wrap{
                margin-left: 154px;
              @include  desktop-down {
                margin-left: 0;
                margin-bottom: 30px;
                }
               
                .white-content{
                    @include phablet-down {
                        text-align: center;
                      }
                    h3{
                        font-size: 24px;
                        margin-bottom: 10px;
                        font-weight: 400;
                    }
                    ul{
                        list-style-type: none;
                        padding: 0;
                        margin: 0 0 30px -3px;
                        li{
                            padding: 4px 10px 10px 32px;
                            margin-bottom: 2px;
                            margin-right: 15px;
                            transition: all 0.3s;
                            font-size: 16px;
                            font-weight: 400;
                            text-indent: -1.8em;
                            text-align: left;
                            &:before{
                                content: ">";
                                font-size: 1em;
                                color: #00bbdd;
                                width: 0.9em;
                                margin-top: -1px;
                                float: left;
                                margin-right: 10px;
                                transition: all 0.3s;
                                font-weight: 900;
                            }
                            &:hover{
                                transition: all 0.3s;
                                padding-left: 37px;
                                padding-right: 5px;
                                &:before{
                                    transition: all 0.3s;
                                    left: 10px;
                                }
                        }
                      
                        }
                    }
                    h4{
                        padding-bottom: 10px;
                        color: $black;
                        font-size: 24px;
                        font-weight: 400;
                    }
                    p{
                      font-size: 0.9em;
                      padding-bottom: 20px;
                      color: $black;
                      font-weight: 400;
                      margin: 0;
                    }
              .bluebtn{
                  a{
                    background-color: #00bbdd;
                    display: inline-block;
                    border-radius: 3px;
                    color: white;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 10px 12px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover {
                      text-decoration: none;
                      background-color: #25899B;
                    }
                    @include phablet-down {
                        padding: 8px 10px;
                        font-size: 14px;
                    }
                  }
              }

                }
                &.img-wrap{
                    margin: 0;
                    .right-img{
                        width: 100%;
                        height: auto;
                        @include phablet-down {
                            text-align: center;
                         }
                      }
                }

            }

        }
        .gray-block{
            background-color: #f5f5f5;
            padding: 20px 0 0;
            margin: 0;
            .gray-wrap{
                padding-left: 0;
                @include  desktop-down {
                padding-left: 15px;
                }
                @include phablet-down{
                    padding: 20px 0 0;
                    }
                .gray-content{
                    @include phablet-down{
                        text-align: center;
                      }
                    h3{
                        font-size: 24px;
                        margin-bottom: 10px;
                        font-weight: 400;
                      }
                      p{
                          font-size: 16px;
                          padding-bottom: 26px;
                          margin: 0;
                          font-weight: 400;
                     }
                     ul{
                        list-style: none;
                        padding: 0;
                        margin: 0 0 30px -3px;
                        li{
                            padding: 4px 10px 10px 32px;
                            margin-bottom: 2px;
                            margin-right: 15px;
                            transition: all 0.3s;
                            font-size: 16px;
                            text-indent: -1.8em;
                            font-weight: 400;
                            text-align: left;
                            &:before{
                                content: ">";
                                font-size: 1em;
                                color: #00bbdd;
                                width: 0.9em;
                                margin-right: 10px;
                                margin-top: -1px;
                                float: left;
                                transition: all 0.3s;
                                font-weight: 900;
                            }
                            &:hover{
                                background-color: $white;
                                transition: all 0.3s;
                                padding-left: 37px;
                                padding-right: 5px;
                                &:before{
                                    transition: all 0.3s;
                                    left: 10px;
                                }
                           }
                        }
                     }
                  
                }
                .demovid{
                    height: 256px;
                    width: 100%;
                    @include  desktop-down {
                      height: 340px;
                    }
                    @include phablet-down{
                        text-align: center;
                        height: auto;
                      }
                }
                .content{
                    margin-top: 20px;
                    max-width: 200px;
                    text-align: left;
                    @include phablet-down{
                        text-align: center;
                        max-width: 100%;
                      }
                    h3{
                       color: $black;
                       font-size: 24px;
                       font-weight: 400;
                       margin-bottom: 10px;
                    }
                    p{
                        font-size: 16px;
                        color: $black;
                        font-weight: 400;
                    }
                    a{
                        display: block;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}  
.calc-result{
    background-color: #f5f5f5;
    #calculator-part1{
        display: none;
    }
    #calculator-part2{
        display: none;
        overflow: hidden;
        #ctitles {
          padding-top: 30px;
          position: relative;
          overflow: hidden;
          h2{
            font-weight: bold;
            font-size: 2.3em;
            margin: 20px 0 30px 0;
            line-height: 1em;
          }
        } 
        .gethelp {
          text-align: center;
          padding-top: 34px;
          .bigbtn{
            font-size: 21px;
            padding: 5px 15px 10px;
            text-transform: uppercase;
            background-color: #00bbdd;
            color: white;
            text-decoration: none;      
            margin-bottom: 3px;
            display: inline-block;
            border-radius: 3px;
            font-weight: bold;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }
        }
        .results-block {
          padding: 30px 0 0;
          background-color: white;
        }
        h5 {
          font-size: 23px;
          margin-bottom: 30px;
          line-height: 1.4em;
          font-weight: bold;
          color: #242a75;
        }
        p{
          font-weight: 500;
        }
        .col-sm-offset-6 {
          margin-left: 50%;
        }
        .big-white-block {
          background-color: #fff;
          p {
            margin: 10px 0 15px;
            padding: 0;
            font-size: 0.9em;
          }
        }
        .lightblue {
          background-color: #00bbdd;
          @media (max-width: 768px){
            flex: 100%;
            padding-left: 35px !important;
            padding-right: 35px !important;
            max-width: 100%;
          }
        }
        .text-indent {
          padding-left: 85px;
          @media (max-width: 768px){
            padding: 10px 35px 1px;
            margin-top: 0px;
          }
        }
        .big-white-block {
          margin-bottom: 50px;
        }
        .yearsmob {
          display: none;
          @media (max-width: 768px){
            display: inline;
            width: 40%;
            float: left;
          }
        }
        .lightblue p {
          color: white;
        }
        .smain, .scomp{
          display: none;
        }
        .sentinelup-cta {
          padding: 30px 70px 10px;
          background: url(/themes/thalesesecurity/templates/dist/images/banner-v3.jpg) center center no-repeat;
          background-size: cover;
          p{
            color: #fff;
          }
        }
        .downloadbtn{
          text-transform: uppercase;
          padding: 14px 30px 19px 30px;
          margin: 15px 0 3px;
          display: inline-block;
          border-radius: 3px;
          font-size: 21px;
          font-weight: bold;
          background-repeat: no-repeat;
          background-color: #00bbdd;
          color: white;
          background-position: 10px 10px;
          background-image: url(/themes/thalesesecurity/templates/dist/images/pdf-icon.png);
          &:hover{
            text-decoration: none;
            background-color: #41D4EE;
          }
        }
        @media (max-width: 768px){
          .yearnums {
            display: none;
          }
        }
    
      }
}


