.access-management {
  padding-top: 62px;
  background-color: #f5f5f5;

  
  strong {
    font-weight: 700 !important;
  }
  .column-wrapper {
    @include media-breakpoint-up(md) {
      padding: 0 0 0 27px;
    }
    .text-wrap {
      @include media-breakpoint-up(md) {
        padding-right: 40px;
      }

      .getstarted {
        margin-top: 20px;
        font-size: 16px;
        padding: 5px 10px 5px;
      }
    }
    .img-wrap {
      @include media-breakpoint-up(md) {
        padding-left: 29px;
      }
    }
  }


  .asat-bluebtn, .asat-backbtn {
    background-color: #00bbdd;
    display: inline-block;
    border-radius: 3px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    //padding: 15px 35px 20px;
    padding: 4px 10px 9px;
    font-size: 1.3em;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #25899B !important;
      text-decoration: none;
    }

    &#results-btn {
      margin-top: 0;
      padding: 15px 35px;
    }
  }

  p {
    line-height: 1.6;
    padding: 0 0 1em;
    color: #3C3C3C;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  #asat-intro {
    ul {
      list-style-image: url(/themes/thalesesecurity/templates/dist/images/tick.png);
      padding-left: 25px;
      li {
        padding-bottom: 0;
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  #asat-questions {
    display: none;
    background-color: #f5f5f5;
    padding: 50px 0;
    @include tablet-down {
      padding:20px 0 10px ;
    }
    @include phablet-down {
      padding: 10px 0 10px ;
    }
    .row {
      @include media-breakpoint-up(md) {
        //padding: 0 58px 0 27px;
      }
    }
    .ast-wrapper {
      @include media-breakpoint-up(md) {
        padding: 0 58px 0 27px;
      }
    }
    h2 {
      font-weight: bold;
      color: black;
      @include media-breakpoint-up(md){
        font-size: 2em;
        margin-bottom: 12px;
      }
      @include media-breakpoint-up(md){
        font-size: 2em;
      }
      @include phablet-down {
        font-size: 1.6em;
        margin-bottom: 10px;
      }
    }
    .asat-box {
      padding: 51px 50px 100px;
      background-color: white;
      margin-bottom: 51px;
      position: relative;
      @include tablet-down {
       margin-bottom: 36px;
      }
      @include media-breakpoint-down(sm) {
        padding: 51px 30px 100px;
      }

      &.no-slider {
        .asat-cbtn {
          margin: 15px 0;
        }
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
    .asat-outline {
      border: solid 1px #cccccc;
      margin-bottom: 4px;
      padding-bottom: 0;
    }
    .asat-cbtn {
      //display: block;
      display: table;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 5px solid #00bbdd;
      box-shadow: 0px 0px 8px #cccccc;
      font-weight: bold;
      //padding: 20px 0;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      padding: 5px 0;
      //min-height: 70px;
      min-height: 70px;
      border-radius: 5px;
      margin: 15px 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    
      @include desktop-down {
        padding: 5px 10px;
      }
      &:hover {
        box-shadow: 0px 0px 8px #aaaaaa;
        border-bottom-color: #00ddff;
        cursor: pointer;
      }
      p {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        font-weight: bold;
        @include media-breakpoint-down(xs){
          font-size: 14px;
        }
        @include tablet-down {
          font-size: 14px;
        } 
      }
      &.asat-active {
        background-color: #00bbdd;
        background-position: bottom center;
        box-shadow: inset 0px 5px 8px 0px #0088aa !important;
        border-bottom-color: #00ddff;
        p {
          color: $white;
        }
      }
    }
    .col-md-offset-2 {
      @include media-breakpoint-up(lg) {
        margin-left: 16.66666667%;
      }
    }
    .exlogos{
      @include phablet-down {
        text-align: center;
      }
      img {
        width: 25%;
        margin: 30px 17px 0 0;
        display: inline-block;
  
        @include media-breakpoint-down(xs) {
          width: 24%;
        }
        @include phablet-down {
          width: 30%;
          margin:0 6px 10px;
        }
      }
    }
     
    .totgreybox {
      position: absolute;
      background-color: #606060;
      border-radius: 3px 0 0 3px;
      padding: 15px 50px 15px 15px;
      bottom: 30px;
      right: 0;
      p {
        margin: 0;
        padding: 0;
        font-weight: bold;
        color: white;
        font-size: 14px;
        .asatsnum {
          font-size: 2em;
          position: absolute;
          right: 10px;
          bottom: 4px;
        }
      }
    }
    .confluence {
      background: url(/themes/thalesesecurity/templates/dist/images/confluence-logo.png) no-repeat top center;
    }
    .google {
      background: url(/themes/thalesesecurity/templates/dist/images/google-cloud-logo.png) no-repeat top center;
    }
    .salesforce {
      background: url(/themes/thalesesecurity/templates/dist/images/salesforce-logo.png) no-repeat top center;
    }
    .zendesk {
      background: url(/themes/thalesesecurity/templates/dist/images/zendesk-logo.png) no-repeat top center;
    }
    .azure {
      background: url(/themes/thalesesecurity/templates/dist/images/microsoft-azure-logo.png) no-repeat top center;
    }
    .aws {
      background: url(/themes/thalesesecurity/templates/dist/images/aws-logo.png) no-repeat top center;
    }
    .office365 {
      background: url(/themes/thalesesecurity/templates/dist/images/office-365-logo.png) no-repeat top center;
    }
    //.asat-cbtn {
    //  //display: block;
    //  text-align: center;
    //  text-transform: uppercase;
    //  border-bottom: 5px solid #00bbdd;
    //  box-shadow: 0px 0px 8px #cccccc;
    //  font-weight: bold;
    //  //padding: 20px 0;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  padding: 5px 10px;
    //
    //  min-height: 70px;
    //  border-radius: 5px;
    //  margin: 15px 0 5px;
    //  -webkit-transition: all 0.3s;
    //  transition: all 0.3s;
    //  &.asat-active {
    //    background-color: #00bbdd;
    //    background-position: bottom center;
    //    box-shadow: inset 0px 5px 8px 0px #0088aa !important;
    //    border-bottom-color: #00ddff;
    //    p {
    //      color: $white;
    //    }
    //  }
    //}
    .senstivity-slider {
      height: 20px;
      opacity: 0;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      &.showslider {
        height: 120px;
        opacity: 1;
      }
      p {
        padding: 0;
        margin: 0;
      }
      div, p {
        display: none;
      }
    }
    .otherfield {
      width: 100%;
      border-radius: 3px;
      border: solid 1px #cccccc;
      margin-top: 10px;
      padding: 10px;
      display: none;
    }
    .astart, .amiddle, .aend {
      position: absolute;
      font-size: 0.8em;
    }
    .astart {
      left: 4%;
    }
    .amiddle {
      left: 44%;
    }
    .aend {
      left: 89%;
    }
    .ui-widget.ui-widget-content {
      border: 1px solid #c5c5c5;
      margin: 38px 0 20px 0;
      width: 96%;
      height: 13px;
      position: relative;
      border-radius: 3px;
      > div {
        width: 2em;
        height: 30px;
        top: 50%;
        margin-top: -.8em;
        text-align: center;
        line-height: 1.6em;
        border-radius: 3px;
        box-shadow: 0px 0px 8px #cccccc;
        background: white;
        font-weight: bold;
        color: #454545;
        position: absolute;
        border-bottom: 5px solid #00bbdd;
        // border: 1px solid #c5c5c5;
        z-index: 2;
        outline: none;
        cursor: default;
        -ms-touch-action: none;
        touch-action: none;
      }

    }
  
    .asat-bluebtn, .asat-backbtn {
      background-color: #00bbdd;
      display: inline-block;
      border-radius: 3px;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding: 15px 35px 15px;
      font-size: 1.3em;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: #25899B;
      }

      &#results-btn {
        margin-top: 0;
        @include tablet-down {
          padding: 10px 15px;
        }
      }
    }
    .col-sm-offset-2 {
      @include media-breakpoint-up(lg) {
        margin-left: 16.66666667%;
      }
    }
  }
  #asat-results {
    background-color: #f5f5f5;
    padding: 50px 0;
    display: none;
    @include tablet-down {
      padding:0;
    }
    .access-form{
      max-width: 730px;
      margin: 0 auto;
      .heading h2 {
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        padding: 10px 0 10px;
        text-transform: uppercase;
        margin: 0;
        background-color: #242a75;
        position: relative;
        font-size: 27px;

        &:after {
          content: '';
          position: absolute;
          width: 40px;
          border: 20px solid transparent;
          border-top-color: #242a75;
          border-bottom: 1px;
          display: inline-block;
          box-sizing: border-box;
          bottom: -20px;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
        }
        @media screen and (min-width: 320px)and (max-width:501px){
          font-size: 22px;
          padding: 10px;
        }
      }
      form{
        background: #fff;
        padding: 34px 21px 0;
        label {
          font-size: 16px;
          color: #253746;
          width: 30%;
          display: inline-block;
          @media screen and (min-width: 320px)and (max-width:501px){
            width: 100%;
          }
          &:before {
            content: '*';
            display: inline;
            font-size: inherit;
            color: red;
          }
        }
        input {
          width: 38%;
          margin-left: 21px;
          padding: 10px;
          height: 31px;
          margin-top: 5px;
          border: 1px solid #d6d4d4;
          display: inline-block;
          @include media-breakpoint-down(md){
            width: 64%;
          }
          @media screen and (min-width: 320px)and (max-width:501px){
            width: 100%;
            margin: 6px 0;
          }
        }
        select{
          position: relative;
          outline: none;
          height: 32px;
          padding: 0 8px;
          width: 38%;
          margin-left: 21px;
          background: url(/themes/thalesesecurity/templates/dist/images/select_dropdown.png) no-repeat right #f5f5f5;
          background-size: 7%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #d6d4d4;
          border-radius: 5px;
          display: inline-block;
          @include media-breakpoint-down(md){
            width: 64%;
          }
          @media screen and (min-width: 320px)and (max-width:501px){
            width: 100%;
            margin: 6px 0;
          }
        }

        .download-btn{
          float: left;
          width: 100%;
          input{
            padding: 7px 10px;
            margin-left: 0;
            margin-top: 9px;
            float: left;
            color: #FFF;
            background-color: #242a75;
            width: auto;
            height: auto;
            outline: none;
            border: 1px solid #00bbdd;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .privacy-policy{
          p{
            font-weight: 400;
            color: #253746;
            font-size: 12px;
            a{
              text-decoration: none;
            }
          }
        }
      }
    }
    .result-wrapper {
      //padding: 0 27px;
      padding: 0 36px 0 27px;

      @include media-breakpoint-down(sm) {
        padding: 0 0;
      }
      .asat-bluebtn {
        font-size: 16px;
        margin-top: 0;
        padding: 10px;
      }
    }
    .asat-scoring {
      order: 2;
    }
    .left-m {
      order: 1;
    }
    h2 {
      margin-bottom: 38px;
      font-weight: bold;
      color: black;
      @include media-breakpoint-up(md){

        font-size: 2em;
      }
      @include media-breakpoint-up(md){
        font-size: 2em;
      }
      @include phablet-down {
        font-size: 1.6em;
      }
    }
    .asat-results-cont {
      @include media-breakpoint-up(md) {
        padding-right: 31px;
      }

      .asat-result-header {
        background-color: #dddddd;
        //padding: 22px 30px 25px;
        padding: 20px 30px 44px;
        border-bottom: 5px solid #96BAC1;
        h3 {
          text-transform: uppercase;
          margin: 0;
          font-weight: bold;
          color: white;
        }
        &.asat-active-stage {
          background-color: white !important;
          border-bottom-color: #00bbdd;
          h3 {
            color: black !important;
          }
        }
      }
    }
    .report-cta {
      padding-left: 0;

      @include media-breakpoint-down(md) {
        display: none;
      }

      img {
        width: 74%;
        margin-bottom: 19px;
      }
      h4 {
        margin-bottom: 25px;
      }
      ul {
        list-style-image: url(/themes/thalesesecurity/templates/dist/images/tick.png);
        padding-left: 25px;
        margin: 0;
        @include media-breakpoint-up(lg) {
          padding-right: 18px;
        }
        li {
          font-size: 16px;
          padding-bottom: 0;
          margin-bottom: 7px;
          font-weight: bold;
          color: #000;
          line-height: 1.5;
        }
      }
    }
    .asat-result-info {
      padding: 0 30px;
      background-color: white;
      display: none;

      .info-content {
        margin-top: -6px;

        .asat-scoring {
          @include media-breakpoint-down(xs) {
            order: 1;
          }
        }
      }
      &:before {
        content: " ";
        width: 40px;
        height: 40px;
        display: inline-block;
        border: 20px solid #00bbdd;
        border-left-color: white;
        border-right-color: white;
        border-bottom-color: white;
      }
      .meter {
        background: url(/themes/thalesesecurity/templates/dist/images/meterm.png) top left no-repeat;
        padding: 40px 0 10px;
        min-height: 200px;
        width: 225px;
        .needle {
          background: url(/themes/thalesesecurity/templates/dist/images/needleb.png) top left no-repeat;
          width: 32px;
          height: 113px;
          display: block;
          position: absolute;
          top: 0;
          left: 112px;
          -ms-transform: rotate(-90deg);
          -ms-transform-origin: 50% 100%;
          -webkit-transform: rotate(-90deg);
          -webkit-transform-origin: 50% 100%;
          transform: rotate(-90deg);
          transform-origin: 50% 100%;
          -webkit-transition: transform 1.0s;
          transition: transform 1.0s;
        }
        .score-back {
          border: 7px solid #00bbdd;
          background-color: #dddddd;
          width: 140px;
          height: 140px;
          margin: 0 auto;
          border-radius: 70px;
          padding: 20px 0;
          position: relative;
          z-index: 3;
          p {
            text-align: center;
          }
        }
        .score-title {
          text-transform: uppercase;
          font-weight: bold;
          display: block;
          font-size: 18px;
        }
        .score-num {
          font-size: 57px;
          font-weight: bold;
          line-height: 38px;
        }
      }
    }
    .indiscores {
      margin-bottom: 20px;
      p {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 0.8em;
      }
      .indinum {
        font-size: 1.9em;
        color: #242a75;
        font-weight: bold;
        float: right;
        line-height: 17px;
      }
      .valback {
        width: 84%;
        background-color: #dddddd;
        border-radius: 3px;
        height: 20px;
        display: block;
        margin: 2px 0 5px;
        .indival {
          background-color: #00bbdd;
          width: 0%;
          height: 20px;
          border-radius: 3px;
          display: block;
        }
      }
    }
    h4 {
      color: #242a75;
      margin-bottom: 30px;
      letter-spacing: -0.02em;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
    ul {
      list-style-image: url(/themes/thalesesecurity/templates/dist/images/tick.png);
      padding-left: 25px;
      margin-bottom: 27px;
      li {
        //padding-bottom: 10px;
        font-weight: bold;
        color: #000;

        font-size: 16px;
        margin-bottom: 10px;
        padding-bottom: 0;
        line-height: 1.4;
      }
    }
    .asat-backbtn {
      font-size: 1.3em;
      background: url(/themes/thalesesecurity/templates/dist/images/arrow.png) left center no-repeat #00bbdd;
      padding: 15px 20px 15px 75px;
      margin-top: 30px;

      @include media-breakpoint-down(xs) {
        font-size: 16px;
        padding: 13px 10px 13px 62px;
      }
    }
  }
}

.banner-shrink + .access-management {
  padding-top: 20px;
}
