.set-section {
  .full-row {
    margin-left: -15px;
    width: calc(100% + 15px);
    height: 100%;
    overflow: hidden;
    @include container-down {
      width: 100%;
      margin: unset;
    }
    .col-wrap {
      width: calc(50% - 15px);
      float: left;
      margin-left: 15px;
      margin-bottom: 10px;
      position: relative;
      @include container-down {
        width: 100%;
        margin-left: unset;
      }
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
      }

      @include desktop-down {
        // padding: 0;
        // margin-bottom: 4px;
        // flex: none;
        // max-width: 100%;
        // width: 100%;
      }
      // &:nth-child(2n + 3) {
      //   padding: 0 0 0 0;
      // }
      .bg-img {
        height: 276px;
        width: 100%;
        position: relative;
        background-color: $black;
        z-index: 1;
        &:before {
          content: "";
          position: absolute;
          background-color: $black;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          opacity: 0.6;
          transition: all .3s ease-in-out;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover, object-position: right;';
        }
      }
      .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 0;
        width: 100%;
        max-width: 390px;
        text-align: center;
        z-index: 1;
        padding: 20px;

        h3 {
          color: $white;
          padding-bottom: 15px;
          font-weight: 300;
        }
        p {
          line-height: 1.2;
          color: $white;
          margin-bottom: 0;
          font-size: 16px;
          padding-bottom: 20px;
          font-weight: 100;
        }
        .link {
          text-transform: initial;
          span {
            top: 0;
            right: -8px;
          }
        }

        @include desktop-down {
          h3 {
            line-height: 1.2;
            padding: 0 20px 15px;
          }

        }
        @include phablet-down {
          padding: 10px;
          h3 {
            font-size: 16px;
          }
          p {
            font-size: 14px;

          }
        }
      }
      &:hover {
        .bg-img {
          &:before {
            opacity: 0.8;
          }
        }
        .content {

          .link {
            span {
              right: -14px;
            }
          }
        }

      }
    }
  }

}

// .full-container {
//   .col-wrap {
//     width: 50%;
//     // flex: none;
//     flex: 0 0 50%;
//     display: inline-flex;
//     margin: 0;
//     padding: 0 4px 0 0;
//     @include desktop-down {
//       margin-bottom: 4px;
//       flex: none;
//       max-width: 100%;
//       width: 100%;
//       padding: 0;
//     }
//     &:last-child {
//       padding-right: 0;
//     }
//   }

// }