
@font-face {
  font-family: 'icomoon';
  src: url(/themes/thalesesecurity/templates/assets/fonts/icomoon.eot?rmj1vv=);
  src: url(/themes/thalesesecurity/templates/assets/fonts/icomoon.eot?rmj1vv=#iefix) format("embedded-opentype"), url(/themes/thalesesecurity/templates/assets/fonts/icomoon.ttf?rmj1vv=) format("truetype"), url(/themes/thalesesecurity/templates/assets/fonts/icomoon.woff?rmj1vv=) format("woff"), url(/themes/thalesesecurity/templates/assets/fonts/icomoon.svg?rmj1vv=#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Transition */
@mixin transition($property, $time, $behaviour) {
  -webkit-transition: $property $time $behaviour;
  -moz-transition: $property $time $behaviour;
  -o-transition: $property $time $behaviour;
  -ms-transition: $property $time $behaviour;
  transition: $property $time $behaviour;
}

/* Transition-delay  */
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  -ms-transition-delay: $transition-delay;
  -moz-transition-delay: $transition-delay;
  -o-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}

@mixin transition-property($property) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}

/* Order  */
@mixin order($order) {
  -webkit-box-ordinal-group: $order;
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  order: $order;
}

/* Transform-origin */
@mixin transform-origin($origin) {
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

/* Transform */
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

/* border-radius */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

/* Backface-visibility  */
@mixin backface-visibility($arguments) {
  -webkit-backface-visibility: $arguments;
  -moz-backface-visibility: $arguments;
  -ms-backface-visibility: $arguments;
  -o-backface-visibility: $arguments;
  backface-visibility: $arguments;
}

/* Box-shadow*/
@mixin box-shadow($top, $left, $blur, $color) {
  box-shadow: $top $left $blur $color;
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  -ms-box-shadow: $top $left $blur $color;
  -o-box-shadow: $top $left $blur $color;
}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin box-orient($orientation) {
  -webkit-box-orient: $orientation;
  -moz-box-orient: $orientation;
  -ms-box-orient: $orientation;
  -o-box-orient: $orientation;
  box-orient: $orientation;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -o-flex-direction: $direction;
  flex-direction: $direction;
  -ms-flex-direction: $direction;
}

@mixin box-align($alignment) {
  -webkit-box-align: $alignment;
  -moz-box-align: $alignment;
  -ms-box-align: $alignment;
  -o-box-align: $alignment;
  box-align: $alignment;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -o-align-items: $align;
  align-items: $align;
}

@mixin line-clamp($line-clamp) {
  -webkit-line-clamp: $line-clamp;
  -moz-line-clamp: $line-clamp;
  -ms-line-clamp: $line-clamp;
  -o-line-clamp: $line-clamp;
  line-clamp: $line-clamp;
}

/* Animation */
@mixin animation($animation, $duration, $transition, $iteration) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $transition;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $transition;
  -moz-animation-iteration-count: $iteration;
  -o-animation-name: $animation;
  -o-animation-duration: $duration;
  -o-animation-timing-function: $transition;
  -o-animation-iteration-count: $iteration;
  animation-name: $animation;
  animation-duration: $duration;
  animation-timing-function: $transition;
  animation-iteration-count: $iteration;
}

/* Fonts */
$font-families: "canada-type-gibson", sans-serif !important;
$super-bold-font: "canada-type-gibson", sans-serif !important;
$font-families-md: "canada-type-gibson", sans-serif !important;
$font-familie: "canada-type-gibson", sans-serif !important;
// $font-familie-dinpro: "dinpro" !important;

/* Base colors*/
$primary: #f3f2f2;
$primary2: #ececec;
$primary3: #9e9e9e;
$white: #ffffff;
$black: #000000;
$gray: #777777;
$silver: #c6c6c6;
$whitesmoke: #f3f4f4;
$bluewhale: #0D3349;
$skyblue: #448ccb;
$Endeavour: #276296;
// #242A75

$MidnightBlue: #242a75;
$Midlightblue:#00BBDD;

$silverLight: #f0f3f4;
$light-grey: #f3f4f4;
$light-blue: #028ee3;
$light-white: #ebf2f6;
$skyblue-light: #4BAEE5;
$bluesky-light: #2894c4;
$mid-grey: #9e9e9e;
$dark-light-grey: #e8ebeb;
$light-bluewhile: #253746;
$dark-bluewhile: #1c2936;
$dark-bluewhile1: #9ebecc;
$sky-light: #69a3b9;
$dark-blue: #14213d;
$white-light: #dadbdb;
$purple: #303169;
$sky-mid: #1b5ea2;
$mid-dark-blue: #1b1b62;
$mid-light-sky: #6dc9f6;
$mid-light-sky1: #e9f5fa;
$sky-light-blue: #4EB2CA;
$sky1: #60BED3;
$sky2: #7ecbdf;
$sky-light1: #adcad6;
$blue-light: #409fd5;
$blue-light1: #2381b7;
$light-sky: #afc9e5;
$white2: #e6eff4;
// $white3: #c0c0c0;
$white1: #c0c0c0;
$white3: #dbdbdb;
$white4: #d3d3d3;
$white5: #ccc;
$white6: #ebebeb;
$white7: #e6e6e6;
$white8: #dae8f5;
$blue: #2a92ec;
$blue2: #3777bf;
$blue3: #b1cbea;
$blue15: #236cc3;
$blue4: #050735;
$blue5: #0077dd;
$blue6: #008fe1;
$blue7: #aecad6;
$blue8: #b4d1dc;
$blue9: #5dbdf4;
$blue10: #004a8b;
$blue11: #21296f;
$blue12: #00bbdc;
$blue13: #26add3;
$blue14: #1d2369;
$blue16: #5dbfd4;
$blue17: #31667c;
$blue18: #228fb0;
$blue19: #141534;
$blue20: #98bae3;
$blue21: #346D9E;
$grey2: #6f6f6f;
$grey3: #9d9d9d;
$grey4: #a9a9a9;
$grey5: #dfdfeb;
$grey6: #f8f8f8;
$grey7: #939393;
$grey8: #f5f5f5;
$grey9: #ddd;
$grey10: #999;
$grey11: #656565;
$gery12: #6f797b;
$gray13: #e8ebeb;
$grey14: #7D7D7D;
$gray15: #eaeaea;
$black1: #171a21;
$black2: #23262b;
$black3: #575c64;
$black4: #34404c;
$purple: #52557e;
$purple1: #7f80ba;
$purple2: #7c7da5;
$purple3: #2e3068;
$dark-blue2: #1A2856;

$colors: ("white", $white, "black", $black, "gray", $gray, "primary", $primary, "primary2", $primary2, "dark-blue2", $dark-blue2, "blue21", $blue21);

$gutter: 15px;
$animate: all 0.2s ease-in-out;
$rounded: 4px;
$col1: desaturate(#FF22CC, 40%);
$col2: desaturate(#EE0088, 40%);
$col3: desaturate(#DD33AA, 40%);
$col4: desaturate(#AA3399, 40%);

/*  Media query variables */
$phone_width: 481px;
$phablet_width: 596px;
$tablet_width: 768px;
$desktop_width: 992px;
$desktop_mid_width: 1024px;
$container_width: 1150px;

$orientation: landscape;

/*
 * Mobile landscape media query
 */

/* (max-width: 480px) */
@mixin phonedown_landscape {
  @media (max-width: #{$phone_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/* (max-width: 767px) */
@mixin mobiledown_landscape {
  @media (max-width: #{$tablet_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/* (max-width: 595px) */
@mixin phabletdown_landscape {
  @media (max-width: #{$phablet_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/* (min-width: 596px), (max-width: 767px) */
@mixin mobile_landscape {
  @media (min-width: #{$phablet_width}) and (max-width: #{$tablet_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/*  (min-width:481px), (max-width: 595px) */
@mixin phoneup_landscape {
  @media (min-width: #{$phone_width}) and (max-width: #{$phablet_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/* (min-width: 768px), (max-width: 991px) */
@mixin ipad_landscape {
  @media (min-width: #{$tablet_width}) and (max-width: #{$desktop_width - 1px}) and (orientation: #{$orientation}) {
    @content;
  }
}

/*  Media query mixins*/

/* (min-width:1024px), (max-height: 767px) */
@mixin laptop-only {
  @media (min-width: #{$desktop_mid_width + 1px}) and (max-height: #{$tablet_width - 1px}) {
    @content;
  }
}

/* (min-width:1250px) */
@mixin large_container-up {
  @media (min-width: #{$container_width + 100px}) {
    @content;
  }
}

/* (min-width:1200px) */
@mixin mid_container-up {
  @media (min-width: #{$container_width + 51px}) {
    @content;
  }
}

/*  (min-width:1150px) */
@mixin container-up {
  @media (min-width: #{$container_width}) {
    @content;
  }
}

/*  (min-width:1024px) */
@mixin desktop-mid-width-up {
  @media (min-width: #{$desktop_mid_width}) {
    @content;
  }
}

/*  (min-width:992px) */
@mixin desktop-up {
  @media (min-width: #{$desktop_width}) {
    @content;
  }
}

/* (min-width:992px), (max-width: 1024px) */
@mixin desktop-mid {
  @media (min-width: #{$desktop_width}) and (max-width: #{$desktop_mid_width}) {
    @content;
  }
}

/* (min-width:992px), (max-width: 1200px) */
@mixin large_desktop {
  @media (min-width: #{$desktop_width}) and (max-width: #{$container_width + 50px}) {
    @content;
  }
}

/* (min-width:992px), (max-width: 1150px) */
@mixin desktop {
  @media (min-width: #{$desktop_width}) and (max-width: #{$container_width}) {
    @content;
  }
}

/*  (min-width:768px), (max-width: 991px) */
@mixin tablet {
  @media (min-width: #{$tablet_width}) and (max-width: #{$desktop_width - 1px}) {
    @content;
  }
}

/*  (min-width:596px), (max-width: 767px) */
@mixin phablet {
  @media (min-width: #{$phablet_width}) and (max-width: #{$tablet_width - 1px}) {
    @content;
  }
}

/*  (min-width:481px), (max-width: 595px) */
@mixin phone {
  @media (min-width: #{$phone_width}) and (max-width: #{$phablet_width - 1px}) {
    @content;
  }
}

/* (max-width:1150px) */
@mixin container-down {
  @media (max-width: #{$container_width - 1px}) {
    @content;
  }
}

/* (max-width:991px) */
@mixin desktop-down {
  @media (max-width: #{$desktop_width - 1px}) {
    @content;
  }
}

/* (max-width:767px) */
@mixin tablet-down {
  @media (max-width: #{$tablet_width - 1px}) {
    @content;
  }
}

/* (max-width:595px) */
@mixin phablet-down {
  @media (max-width: #{$phablet_width - 1px}) {
    @content;
  }
}

/* (min-width:596px) */
@mixin phablet-up {
  @media (min-width: #{$phablet_width}) {
    @content;
  }
}

/* (min-width:481px) */
@mixin phone-up {
  @media (min-width: #{$phone_width}) {
    @content;
  }
}

/*  (max-width:480px) */
@mixin phone-down {
  @media (max-width: #{$phone_width - 1px}) {
    @content;
  }
}

