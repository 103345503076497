.product-slider{
  border-top: 3px solid $light-sky;
  @include container-up{
  //padding: 58px 0 70px;
  }
  .slider-wrap{
    width: 100%;

  .title-wrap{
       max-width: 800px;
       margin: 0 auto 70px;
       text-align: center;
       h2{
         color: $light-bluewhile;
         font-size: 30px;
         font-weight: 300;
       }
       @include  tablet-down {
         margin-bottom: 40px;
         h2{
           font-size: 26px;
         }
       }
     }
  .slider{
   .item{
     .content-wrap{
       display: inline-block;
       width: 100%;
       margin-left: -12px;
       margin-right: -12px;
       @include  tablet-down {
        margin-left: 0;
        margin-right: 0;
       }
      .col-wrap {
        width: calc(33.33% - 24px);
        margin: 0 12px 21px;
        @include  desktop-down {
          width: calc(50% - 24px);
          margin: 0 12px 21px;
        }
          @include  tablet-down {
          margin: 0 auto 30px;
          width: 100%;
          margin-bottom: 20px;
          max-width: 767px;
            }
        a{
          transition: all 0.5s ease;
          background: linear-gradient(to bottom,#5dbdf4 0%,#2481b8 100%);
          width: 100%;
          display: block;
          text-decoration: none;
          min-height: 480px;
          height: 100%;
          padding: 64px 20px 20px;
          @include  container-down {
            padding: 40px 10px 20px;
            min-height: 340px;
          }
          @include  tablet-down {
            min-height: 300px;

            }
          .content {
            transition: all 0.5s ease;
            text-align: center;
            @include  tablet-down {
              padding: 0;
            }
            h2 {
              transition: all 0.5s ease;
              line-height: 1.2;
              margin-bottom: 0;
              font-weight: 300;
              text-transform: uppercase;
              font-size: 24px;
              color: $white;
              position: relative;
              @include  tablet-down {
                font-size: 20px;
              }
            }
            .center-line{
              position: relative;
              max-width: 90px;
              overflow: hidden;
              width: 100%;
              margin: 30px auto 20px;
              height: 2px;
              background: $white;
              display: inline-block;
              @include  tablet-down {
                font-size: 20px;
                margin-bottom: 20px;
              }
            }
            p {
              transition: all 0.5s ease;
              font-size: 22px;
              line-height: 1.5;
              font-weight: 100;
              color: $white;
              margin-bottom: 25px;
              @include  tablet-down {
                font-size: 20px;
                line-height: 1.2;
              }
            }
            .grid-arrow{
              transition: all 0.5s ease;
               opacity: 0;
              span{
                top:0;
                right: -8px;
              }
            }
          }
          &:hover{
            transition: all 0.5s ease;
            background: linear-gradient(to bottom,#4a97c3 0%,#1c6792 100%);
          .content {
            transition: all 0.5s ease;            
            h2 {
              transition: all 0.5s ease;
            }
            p {
              margin-bottom: 20px;
            }
            .grid-arrow{
               opacity: 1;
               transition: all 0.5s ease;
              span{
                right: -14px;
              }
            }
          }
          }
        }
      }
  }
}
    .slick-arrow{
      outline: 0;
      position: absolute;
      @include  tablet-down {
        opacity: 0;
      }
    }
    .slick-prev{
      top: 46%;
      left: -44px;
      background: $white-light;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $dark-blue;
      z-index: 2;
      display: block;
      font-size: 0;
      line-height: 0;
      position: absolute;
      padding: 0;
      transform: translate(0,-50%);
      cursor: pointer;
      border: none;
      outline: none;
      &:before{
        font-family: 'icomoon' !important;
        content: "\e909";
        font-size: 23px;
        opacity: 1;
        color: $dark-blue;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
      @include  tablet-down {
        opacity: 0;
      }
    }
    .slick-next{
      z-index: 1;
      top: 46%;
      right: -44px;
      display: block;
      background: $white-light;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $dark-blue;
      font-size: 0;
      line-height: 0;
      position: absolute;
      padding: 0;
      transform: translate(0,-50%);
      cursor: pointer;
      border: none;
      outline: none;
      &:before{
        opacity: 1;
        content: "\e908";
        font-family: 'icomoon' !important;
        font-size: 23px;
        color: $dark-blue;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
      @include  tablet-down {
        opacity: 0;
      }
    }

    .slick-dots{
      text-align: center;
      list-style-type: none;
      padding-top: 40px;
      cursor: pointer;
      li{
        list-style-type: none;
        display: inline-block;
        height: 16px;
        width: 16px;
        background-color: $light-grey;
        border-radius: 50%;
        margin: 0 4px;
        button {
          font-size: 0;
        }
        &.slick-active{
          background: linear-gradient(to bottom,#5dbdf4 0%,#2b86bc 100%)
        }
      }
    }
  }
 }
}