.diagram {
  //padding: 40px 0 70px;
  padding: 0 0 70px;
  overflow: visible;
strong {
    font-weight: 700 !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: 40px;
  }

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }

  h2, h3, h4, h5, h6, span, a, td, strong, p {
    //font-family: $font-familie-dinpro;
  }
  strong {
    font-weight: 700 !important;
  }
  .btn-violet {
    display: inline-block;
    cursor: pointer;
    background-color: $MidnightBlue;
    color: $white;
    padding: 8px 15px 9px;
    font-weight: bold;
    margin: 11px 0;
    font-size: 0.8em;
    border-radius: 5px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
  .tt-cont {
    position: absolute !important;
    z-index: 102;
    height: 24px;
    width: 140px;
    display: inline-block;
    margin-top: -37px;
    .tt-arrow {
      position: absolute;
      display: inline;
      width: 7px;
      height: 7px;
      border: 7px solid transparent;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-top-color: #000;
      top: 100%;
      left: -25px;
    }
    .tt-text {
      position: absolute;
      background-color: #000000;
      color: #ffffff;
      display: inline;
      padding: 5px 7px 7px;
      font-size: 0.8em;
      line-height: 1em;
      font-weight: normal;
      border-radius: 3px;
      left: 0;
    }
  }
  .diagram-wrapper {
    padding: 0 25px;

    @include media-breakpoint-down(sm) {
      padding: 0 0;
    }

    .outer-wrapper {
      position: relative;
      .cbtnscont {
        background-color: #e1e1e1;
        padding: 7px 24px 2px;
        //padding: 10px 10px;

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: 147px;
          z-index: 2;
          right: -23px;
          top: 38%;
          transition: 0.5s;
          margin-right: -200px;
          padding: 7px 18px 7px;
        }

        .countries {
          text-align: right;

          @include media-breakpoint-down(md) {
            text-align: center;
          }

          .tt-cont {
            position: absolute;
            z-index: 102;
            height: 24px;
            width: 140px;
            display: inline-block;
            margin-top: -37px;
            .tt-arrow {
              position: absolute;
              display: inline;
              width: 7px;
              height: 7px;
              border: 7px solid transparent;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border-top-color: #000;
              top: 100%;
              left: -25px;
            }
            .tt-text {
              position: absolute;
              background-color: #000000;
              color: #ffffff;
              display: inline;
              padding: 5px 7px 7px;
              font-size: 0.8em;
              line-height: 1em;
              font-weight: normal;
              border-radius: 3px;
              left: 0;
            }
          }

        }
        .head-text {
          @include media-breakpoint-down(md) {
            text-align: center;
          }
          .new {
            font-size: 0.7em;
            font-weight: bold;
            padding: 3px 4px 3px;
            display: inline-block;
            line-height: 12px;
            margin-right: 5px;
            color: #fff;
            background-color: $Midlightblue;
          }
        }

        &.show {
          margin-right: 0;
        }
      }
      .chart-section {
        padding: 36px 0 70px;
        .chart-wrapper {
          @include media-breakpoint-up(md) {
            padding: 0 14px;
          }
          ul{
            padding-left: 40px;
            font-size: 16px;
            padding-bottom: 16px;
            li{
              position: relative;
        margin: 0;
        text-indent: -1em;
        line-height: 1.5em;
        margin-bottom: 15px;
        list-style: none;
        font-size: 16px;
        color: #000;
        font-weight: 400;
              &:before{
                content: ">";
                font-weight: bold;
                float: left;
                width: 1em;
                margin-top: -1px;
                color: #6c286b;
              }
            }
          }
          h3 {
            color: $MidnightBlue;
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: 400;
            line-height: 1.1;
          }
          .chart-left {
            @include media-breakpoint-up(md) {
              padding-right: 3px;
            }
            @include media-breakpoint-up(sm) {
              padding: 0 0;
            }
            .labels {
              border: none;
              padding-left: 37px;
              display: inline-block;
              padding-top: 22px;
              padding-bottom: 13px;
              padding-right: 11px;

              tr {
                &:nth-child(even) {
                  background-color: transparent;
                }
                &:nth-child(odd) {
                  background-color: transparent;
                }
                td {
                  width: 100%;
                  padding: 0;
                }
              }

            }
          }
          .chart-right {
            @include media-breakpoint-up(sm) {
              padding: 0 0;
            }
          }

          .compare-btn {
            display: none;
            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
        }
      }
    }
    p {
      line-height: 1.6;
      padding: 0 0 1em;
      color: #3C3C3C;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 400;
      padding-right: 3px;
      strong {
        font-weight: 700;
        margin-left: -3px;
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
    }

    td {
      font-size: 0.8em;
      height: 32px;
      vertical-align: middle;
      line-height: 1;
    }

    .colourkey {
      width: 25px;
      float: left;
      height: 15px;
      margin-right: 10px;
      display: none;
      @include media-breakpoint-down(md) {
        display: block;    
      }
    }
    .chart-container {
      display: none;
      width: 97%;
      position: absolute;
      // bottom: -21px;
      left: 18px;
      &.chart-current {
        display: block;
        z-index: 1;
        height: inherit;
      }
    }
    .multiq-box {
      width: 97%;
      margin: 25px 0;
      padding: 10px 20px 10px;
      border: 1px solid #999999;
      border-radius: 10px;
      overflow: hidden;
    }
    .multi-qs {
      min-height: 100px;
      display: inline-block;
      float: left;
      width: calc(100% - 40px);
      padding: 0 63px;

      @include media-breakpoint-down(xs){
        padding: 0 10px;
      }
    }
    h4 {
      color: $MidnightBlue;
      font-size: 1.2em;
    }
    .rightarrow {
      background: url(/themes/thalesesecurity/templates/dist/images/multiq-r-arrow-purple.png) center center no-repeat;
      min-height: 100px;
      padding-left: 0;
      padding-right: 0;
      width: 20px;
      float: right;
      cursor: pointer;
      position: relative;
      right: 32px;
      @include media-breakpoint-down(xs){
        right: 2px;
      }
    }
    .leftarrow {
      background: url(/themes/thalesesecurity/templates/dist/images/multiq-l-arrow-purple.png) center center no-repeat;
      opacity: 0;
      min-height: 100px;
      padding-left: 0;
      padding-right: 0;
      width: 20px;
      float: left;
      cursor: pointer;
      position: relative;
      left: 32px;

      @include media-breakpoint-down(xs){
        left: 2px;
      }
    }
    .mquestion {
      //position: absolute;
      position: relative;
      display: none;
      &.currentq {
        display: block;
      }
    }

    .cbtn {
      display: inline-block;
      border-radius: 7px;
      padding: 1px 8px 5px;
      width: 37px;
      height: 30px;
      overflow: hidden;
      text-indent: 50px;
      position: relative;
      z-index: 9;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }

    .cbtn.usf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-us.jpg) center center no-repeat;
    }
    .cbtn.ukf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-uk.jpg) center center no-repeat;
    }
    .cbtn.frf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-fr.jpg) center center no-repeat;
    }
    .cbtn.def {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-de.jpg) center center no-repeat;
    }
    .cbtn.bef {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-bene.jpg) center center no-repeat;
    }
    .cbtn.inf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-in.jpg) center center no-repeat;
    }
    .cbtn.jpf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-jp.jpg) center center no-repeat;
    }
    .cbtn.auf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-au.jpg) center center no-repeat;
    }
    .cbtn.mef {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-me.jpg) center center no-repeat;
    }
    .cbtn.bzf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-bz.jpg) center center no-repeat;
    }
    .cbtn.saf {
      background: url(/themes/thalesesecurity/templates/dist/images/flag-saf.jpg) center center no-repeat;
    }
  }
}

.charts {
  margin-top: 100px;
  padding-bottom: 0;

  min-height: 337px;
  display: flex;
  align-items: center;


  .heading-row {

    .wrapper {
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding: 0 0 0 40px;
      }
      .text-wrap {
        margin-top: 39px;
        //margin-top: 52px;
        h2 {
          color: $MidnightBlue;
          margin-bottom: 20px;

          @include media-breakpoint-up(md) {
            font-size: 32px;
            margin-bottom: 37px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .select-area {
        margin-top: 18px;
        @include media-breakpoint-up(lg) {
          padding-left: 37px;
          padding-right: 5px;
        }
        //@include media-breakpoint-down(md) {
        //  padding-left: 0;
        //  padding-right: 0;
        //}
        .jumpbox {
          background-color: #dddddd;
          padding: 20px 19px;
          border-radius: 5px;
          p {
            font-size: 16px;
            margin-bottom: 26px;
            font-weight: 600;
          }
          .questionscroll {
            select {
              -webkit-appearance: none;
              appearance: none;
              outline: none;
              border: 1px solid #b5b5b5;
              border-radius: 5px;
              width: 100%;
              height: 42px;
              padding: 5px 10px 0;
              letter-spacing: 0.02em;
              background: url('/themes/thalesesecurity/templates/assets/images/question-drop-down-purple.png') no-repeat right 15px #f5f5f5;
            }

            select::-ms-expand {
              display: none;
            }
          }
        }
      }
    }
  }

}
