.ast-banner {
  padding: 0;
  display: inline-grid;
  border-top: 7px solid$MidnightBlue;
  min-height: 406px;
  margin-top: 81px;

  @include media-breakpoint-down(lg) {
    min-height: 307px;
  }
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  @include media-breakpoint-down(sm) {
    min-height: 207px;
  }

  strong {
    font-weight: 700 !important;
  }
  .bg-img {
    position: absolute;
    height: 100%;
    width: 100%;

    @include media-breakpoint-down(xs) {
      position: relative;
      order: 1;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .v-middle-inner {
    .content-wrapper {
      padding: 0 0 0 41px;
      max-width: 50%;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }

      .content {
        padding-top: 12px;
        text-align: left;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
        @include phone-down {
          text-align: center;
        }
        h1 {

          font-size: 40px;
          color: #000066;
          font-weight: 500;
          padding-bottom: 66px;
          line-height: 1.1;
          @include container-down {
            font-size: 36px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 30px;
            padding: 0;
          }
          @include phablet-down {
            font-size: 27px;
            padding: 18px 0 25px;
          }
        }
        h3 {
          color: $white;
          font-weight: 300;
          font-size: 22px;
          @include phablet-down {
            font-size: 16px;
          }
        }
        p {
          color: $white;
        }
      }
      a {
        &:hover {
          text-decoration: none;
        }
        &:hover:before {
          width: 0;
        }
      }
      .btn-wrapper {
        display: inline-flex;
        float: right;
        position: absolute;
        right: 18px;
        bottom: 22px;
        @include phablet-down {
          right: 0;
          display: flex;
          float: none;
          bottom: 22px;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .btn-left {
          font-weight: 300;
          padding: 0px 17px;
          background-color: $white;
          color: $MidnightBlue;
          border-radius: 20px 0 0 20px;
          line-height: 34px;
          font-size: 15px;
          box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
          @include phablet-down {
            padding: 0 10px;
          }
        }
        .btn-right {
          background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
          padding: 0 38px;
          border-radius: 0 20px 20px 0;
          color: $white;
          font-weight: 300;
          line-height: 34px;
          font-size: 15px;
          box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
          @include phablet-down {
            padding: 0 17px;
          }
        }
        &.bottom-fix {
          position: fixed;
          bottom: 50px;
          right: 94px;
          z-index: 5;
          transition: bottom 0.6s linear;
          transform: translate(0, 30px);
          @media screen and (min-width: 1401px) {
            right: 50%;
            margin: 0 -614px 0 0;
          }
          @include tablet-down {
            right: 0;
            display: flex;
            float: none;
            position: fixed;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
  }
  &.banner-shrink {
    //height: 200px;
    min-height: 206px;

    .content-wrapper {
      @include media-breakpoint-up(md){
        padding-top: 36px;
      }
    }
  }
}





