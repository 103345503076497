.event-section {
  .views-exposed-form {
    display: none;
  }
  .nav-outer {
    min-height: 70px;
    overflow: hidden;
    background-color: $white2;
    width: 100%;


  
    ul {
      width: 100%;
      padding-left: 0;
      text-align: center;
      li {
        padding:20px 0;
        width: 50%;
        float: left;
        font-size: 22px;
        margin: 0;
        list-style: none;
        position: relative;
         &:first-child{
          cursor: default;
          &:before {
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            position: absolute;
            height: 3px;
            background: #242a75;
          }
         }
       
        a {
          font-weight: 300;
          display: block;
          color: $light-bluewhile;
          justify-content: center;
          // pointer-events: none;
          &:hover {
            text-decoration: none;
          }
        }
        &.active{
          &:first-child{
            &:before {
            opacity: 1;
            }
          }
        }
      }
    }
  }

  .full-row {
    //margin: 0;
    height: 100%;
    margin: 25px 0 10px;
    @include tablet-down {
      margin: 20px 0;
    }
  
    .col-wrap {
      flex: 0 0 21%;
      max-width: calc(21% - 21px);
      padding: 0;
      margin-right: 10px;
      @include tablet-down {
        max-width: calc(100%);
        flex: 0 0 100%;
      }
      .category-box {
        margin: 0 auto;
        max-width: 100%;
        text-align: center;
        position: relative;

        &.show {
          span {
            &:after {
              transform: rotate(-136deg);
              top: 58%;
            }
          }
          .option-category {
            border: 1px solid $white3;
          }
        }
        span {
          position: relative;
          max-width: 100%;
          background: white;
          padding: 12px 14px;
          display: block;
          color: $light-bluewhile;
          font-size: 15px;
          font-weight: 300;
          margin: 0 auto;
          text-align: left;
          border: 2px solid $white3;
          a {
            font-size: 14px;
            color: $light-bluewhile;
            font-weight: 300;
            &:hover {
              text-decoration: none;
            }
          }
          &:before {
            content: '';
            position: absolute;
            text-align: center;
            background: $white4;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 50%;
            right: 10px;
            margin: -10px 0 0;
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin: -6px 0 0 -4px;
            right: 16px;
            width: 8px;
            height: 8px;
            border: solid $black4;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }
        }
        .option-category {
          border: 1px solid $white3;
          list-style: none;
          text-align: left;
          background: white;
          position: absolute;
          z-index: 1;
          width: 100%;
          display: none;
          height: 414px;
          overflow: scroll;
          li {
            font-size: 18px;
            font-weight: 100;
            color: $grey11;
            margin: 0;
            padding: 14px 20px 0;
            cursor: pointer;
            a {
              font-size: 18px;
              color: $grey11;
            }
          }
        }
      }
      &.col-search {
        display: flex;
        max-width: calc(50%);
        align-items: center;
        width: 100%;
        flex: 0 0 50%;
        @include tablet-down {
          flex: 0 0 100%;
          max-width: 100%;
          margin: 0;
        }
        .input-box {
          width: 100%;
          position: relative;
          display: flex;
          max-width: 100%;
          @include tablet-down {
            display: block;
          }
          input {
            margin-right: 10px;
            font-size: 15px;
            padding: 0 12px;
            color: $light-bluewhile;
            border: 2px solid $white3;
            height: 51px;
            font-family: $font-families;
            background-color: $white;
            font-weight: 400;
            letter-spacing: 1.4px;
            outline: none;
            width: calc(100%);
            box-sizing: border-box;
            appearance: none;
            border-radius: 0;
            max-width: 488px;
            @include tablet-down {
              max-width: 100%;
             margin-bottom: 15px;
            }
            // @include tablet-down{
            //   max-width:calc(100% - 150px);
            // }
          }
          input::placeholder {
            font-family: $font-families;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.21;
            letter-spacing: normal;
            color: $light-bluewhile;
          }
          button {
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: middle;
            background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
            background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
            background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
            text-decoration: none;
            position: relative;
            z-index: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
            height: 51px;
            padding: 0px 0;
            min-width: 107px;
            border: 0;
            cursor: pointer;
            color: $white;
            font-size: 18px;
            line-height: 1.38;
            letter-spacing: normal;
            font-family: $font-families;
            overflow: hidden;
            span {
              font-family: $font-families;
              position: relative;
              font-size: 16px;
              font-weight: 300;
            }
            @include tablet-down {
              max-width: 100%;
             min-width: 100%;
            }
          }
        }
      }
    }
  }

  .wrapper {
    .content-wrap {
      background: $whitesmoke;
      width: 100%;
      display: flex;
      padding: 0 20px 0;
      position: relative;
      align-items: center;
      margin: 0 0 20px;
      box-shadow: 1px 2px 0 $blue9;
     @include tablet-down {
      text-align: center;
      padding: 20px 0;
      }
      .col-wrap {
        display: inline-block;
        &.img-wrap {
          height: auto;
          width: 23%;
          max-width: 250px;
          padding: 0 ;
          @include tablet-down {
            padding: 10px 0;
            margin: 0 auto;
            width: 100%;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: auto;
          }
        }
        &.content {
          padding: 10px 20px 10px 38px;
          max-width: 62%;
          width: 62%;
          @include container-down {
            padding: 20px;
            max-width: 54%;
            width: 54%;
          }
          @include desktop-down {
            max-width: 50%;
            width: 50%;
          }
          @include tablet-down {
            max-width: 100%;
            width: 100%;
            padding: 20px;
          }
          a {
            font-size: 22px;
            position: relative;
            color: $light-bluewhile;
            font-weight: 300;
            line-height: 1.22;
            text-decoration: none;
            display: inline;
            &:before {
              content: '';
              position: absolute;
              bottom: -4px;
              opacity: 0;
              height: 2px;
              width: 100%;
              background: #253746;
              transition: all linear .3s;
            }
            &:hover {
              text-decoration: none;

              &:before {
                bottom: 0;
                opacity: 1;
                transition: all linear .3s;
              }
            }
          }
          span {
            color: $light-bluewhile;
            position: relative;
            font-size: 14px;
            display: block;
            padding-bottom: 20px;
            font-weight: 300;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 87px;
              height: 2px;
              border-bottom: 1px solid #2894c4;
              background: #4baedd;
            }
            @include tablet-down {
              &:before {
                  right: 0;
                  margin: auto;
              }
            }
          }
          p {
            color: $light-bluewhile;
            margin: 0;
            padding-bottom: 6px;
            padding-top: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        &.link-cell {
          width: 180px;
          min-width: 10%;
          align-self: flex-end;
          text-align: right;
          padding: 0 0px 21px 0;
          .btn-default{
            padding: 6px 16px;
          }
          @include tablet-down {
            min-width: 100%;
            align-self: center;
            text-align: center;
          }
        }
      }
    }
  }






  .pagination {
    display: flex;
    margin-top: 50px;
    @include tablet-down {
      justify-content: center;
      margin-top: 30px;

    }
    ul {
      margin: 0;
      display: flex;
      list-style: none;
      @include tablet-down {
        margin: 0 10px;
      }
      @include phone-down {
        display: unset;
        margin: 0;
      }
      li {
        margin: 0 35px 0 0;
        color: $blue6;
        @include tablet-down {
          margin: 0 10px 0 0;
        }
        @include phone-down {
          display: inline-block;
          margin: 0;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          color: $blue6;
          font-size: 22px;
          font-weight: 300;
          &:hover {
            text-decoration: none;
          }
          &.active {
            color: $black;
          }
        }
        .gap {
          color: $grey10;
          margin-right: 4px;
        }
      }
    }
  }

}


