.user-login{
   .intro-section{
     .content-outer{
      margin: 0 auto;
      max-width: 800px;
            #block-thalesesecurity-local-tasks {
              display: inline-block;
              width: 100%;
            ul {
              position: unset;
              margin: 0;
              padding: 0;
              li {
                display: block;
                float: left;
                a {
                  background: $light-bluewhile;
                  color: $white;
                  display: inline-block;
                  color: $white;
                  margin-right: 10px;
                  padding: 10px 15px;
                  border: 2px solid transparent;
                  &:hover {
                    background: $white;
                    border: 2px solid $light-bluewhile;
                    color: $light-bluewhile;
                  }
                }
              }
            }
          }
       ul{
         list-style: none;
         li{
           a{
            color: $light-bluewhile;
            font-size: 18px;
            font-weight: 100;
            text-decoration: none;
            position: relative;
            &:after {    
               background: none repeat scroll 0 0 transparent;
               bottom: 0;
               content: "";
               display: block;
               height: 1px;
               left: 50%;
               position: absolute;
               background: $light-bluewhile;
               transition: width 0.3s ease 0s, left 0.3s ease 0s;
               width: 0;
             }
             &:hover:after { 
               width: 100%; 
               left: 0; 
             }
           }
         }
       }
       form{
         padding: 10px 0 0;
         max-width: 800px;
         margin: 0 auto;
          label{
            color: $light-bluewhile;
            font-size: 26px;
            padding: 16px 0 0;
          }
          input{
            font-size: 16px;
            background-color: $whitesmoke;
            outline: none;
            width: 100%;
            font-weight: 100;
            padding: 10px;
            box-shadow: none;
            border-radius: 0;
            -webkit-appearance: none;
            border: 1px solid $gray;
          }
          input:focus { 
            background-color: $whitesmoke;
           }
          input[type=submit]{
              margin-top: 28px;
              font-size: 18px;
              width: 120px;
              font-weight: 300;
              background-color: $light-bluewhile;
              padding: 6px 20px;
              color: $white;
              border: 2px solid transparent;
              cursor: pointer;
              &:hover{
                 background: $white;
                 border: 2px solid $light-bluewhile;
                 color: $light-bluewhile;
              }
          }
       }
     }
   }
}
