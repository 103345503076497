.newsletter{
  background: linear-gradient(to bottom,#276296 0%,#448ccb 100%);
  color: $white;
  padding: 38px 0 36px;
  @include tablet-down {
    padding: 30px 0;
  }
  .content{
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    h2,.h2{
      padding-bottom: 18px;
      font-weight: 300;
      color: $white;
       @include phone-down{
         font-size: 20px;
       }
      }
    .news-btn{
     padding: 0 19px 0 22px;
      @include  phablet-down {
        padding: 0 16px;
         }
        a{
        display: inline-block;
        line-height: 34px;
        font-size: 15px;
        font-weight: 300;
        color: $white;
        text-align: center;
        &:hover{
          text-decoration: none;
        }
        @include  phablet-down {
          font-size: 14px;
        }
        }
    }
  }
}
