.key-finding {
    background: #f5f5f5;
    padding: 60px 0 30px;
    h2,
    p,
    a {
        // font-family: dinpro;
    }
    strong {
        font-weight: 700 !important;
    }
    .title {
        margin: 0 auto;
        max-width: 800px;
        h2 {
            text-align: center;
            font-size: 27px;
            color: $white;
            font-weight: 400;
            margin: 30px 0;
            @include phablet-down {
                margin: 20px 0;
            }
        }
    }
    .bg-color {
        margin: 0;
        background-color: #555555;
    }
    p {
        line-height: 1.6;
        color: $white;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: center;
        &.bigamitxt {
            cursor: default;
            font-size: 1.2em;
            margin-bottom: 2rem;
            @include phablet-down {
                line-height: 1.2;
                margin-bottom: 1em;
            }
        }
    }
    .item {
        @include desktop-down {
            padding: 0 20px;
            margin: 0;
        }
        p {
            @include phablet-down {
                margin-bottom: 10px;
            }
        }
    }
    .item>div {
        padding: 50px 40px 40px;
        min-height: 250px;
        text-align: center;
        @include desktop-down {
            padding: 30px 20px;
        }
        @include phablet-down {
            padding: 10px 0;
        }
        &.col-12 {
            padding: 0;
            min-height: auto;
        }
        .image {
            padding-bottom: 20px;
        }
    }
    .btn {
        text-align: center;
        width: 100%;
        padding: 0 0 30px;
        margin: 0;
        @include desktop-down {
            padding: 0 20px 30px;
        }
        @include tablet-down {
            white-space: unset;
        }
        @include phablet-down {
            padding: 0 10px 30px;
        }
        a {
            color: $white;
            text-decoration: none;
            padding: 7px 15px 7px;
            text-transform: uppercase;
            display: inline-block;
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
            background-color: $Midlightblue;
            border: none;
            font-size: 1.2em;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            &:hover {
                opacity: 0.8;
            }
            @include desktop-down {
                font-size: 1em;
            }
            @include tablet-down {
                padding: 7px 10px 7px;
                margin-bottom: 10px;
            }
            @include phablet-down {
                margin-bottom: 10px;
                font-size: 0.9em;
            }
        }
    }
}

.video-section {
    background: #f5f5f5;
    text-align: center;
    padding: 30px 0;
    h2,
    p,
    a {
        // font-family: dinpro;
    }
    h2 {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0 20px;
        color: $light-bluewhile;
    }
    .video {
        width: 560px;
        display: inline-block;
        height: 315px;
        margin: 0 auto;
        @include tablet-down {
            max-width: 560px;
            width: 100%;
        }
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.demographics {
    @include media-breakpoint-up(md) {
        padding: 47px 0 30px;
    }
    background: #f5f5f5;
    .wrapper {
        padding: 60px 0;
        text-align: center;
        background-color: $white;
        @include media-breakpoint-down(sm) {
            padding: 0 28px;
        }
        .item {
            width: 100%;
            //width: 66.66%;
            margin: 0 auto;
            text-align: center;
            h2 {
                font-size: 27px;
                font-weight: 400;
                margin: 10px 0 20px;
                color: $light-bluewhile;
                @include phablet-down {
                    font-size: 22px;
                }
            }
            p {
                font-size: 1.2em;
                color: $light-bluewhile;
                line-height: 1.6;
                font-weight: 400;
                max-width: 723px;
                margin: 0 auto 27px;
                @include phablet-down {
                    font-size: 0.9em;
                }
            }
        }
    }
}

.stat-form {
    background: #f5f5f5;
    display: none;
    &.home-page-form {
        padding: 40px 0;
        .inner-wrapper {
            padding: 0;
            @include tablet-down {}
            .formFields {
                @include tablet-down {
                    padding: 34px 20px 0;
                }
                input {
                    width: 64%;
                    margin-left: 0;
                    @include tablet-down {
                        width: 60%;
                    }
                    @include phablet-down {
                        width: 100%;
                    }
                }
                select {
                    width: 64%;
                    margin-left: 0;
                    @include tablet-down {
                        width: 60%;
                    }
                    @include phablet-down {
                        width: 100%;
                    }
                }
                .submit {
                    text-align: left;
                    .gem-button-lg {
                        margin-left: 0;
                        margin-right: 14px;
                        margin-top: 9px;
                        float: unset;
                        width: 64%;
                        @include tablet-down {
                            width: 60%;
                        }
                        @include phablet-down {
                            width: 100%;
                        }
                    }
                }
                .privacy {
                    p {
                        margin-top: 10px;
                        padding-right: 12px;
                        a {
                            font-size: 12px;
                            font-weight: 400;
                            color: $MidnightBlue;
                            //color: $light-bluewhile;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(md) {
        padding: 30px 0;
    }
    h2,
    input,
    label {
        // font-family: $font-familie-dinpro;
    }
    .outer-wrapper {
        //margin-left: 16.66666667%;
        .inner-wrapper {
            margin: 0 auto;
            padding: 0 30px 0 0;
            @include phablet-down {
                padding: 0 20px;
            }
            .heading {
                h2 {
                    font-weight: 400;
                    //margin: 10px 0 20px;
                    color: $white;
                    text-align: center;
                    padding: 10px 0 10px;
                    text-transform: uppercase;
                    margin: 0;
                    background-color: $MidnightBlue;
                    //background-color: #6c286b;
                    position: relative;
                    @include container-down {
                        font-size: 24px;
                    }
                    @include phablet-down {
                        padding: 10px;
                        font-size: 20px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 27px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        width: 40px;
                        border: 20px solid transparent;
                        border-top-color: $MidnightBlue;
                        //border-top-color: #6c286b;
                        border-bottom: 1px;
                        display: inline-block;
                        box-sizing: border-box;
                        bottom: -20px;
                        z-index: 2;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .submit {
        text-align: right;
        .gem-button-lg {
            padding: 7px 10px;
            margin-left: 22px;
            margin-top: 9px;
            float: left;
            background-color: $MidnightBlue;
            //background-color: #6c286b;
            width: auto;
            height: auto;
            outline: none;
            @include media-breakpoint-down(xs) {
                margin-left: 0;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .gem-button-lg {
        position: relative;
        color: #FFF;
        overflow: hidden;
        padding: 0 10px 6px;
        vertical-align: middle;
        transition: all .5s;
        display: inline-block;
        margin: 5px;
        background-color: #00bbdd;
        border: 1px solid #00bbdd;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
    }
    .formFields {
        background: #fff;
        padding: 34px 21px 0;
        .col-12 {
            margin-bottom: 7px;
        }
    }
    label {
        font-size: 16px;
        color: $light-bluewhile;
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }
    .red {
        color: red;
    }
    input {
        //width: 65.666667%;
        //height: 36px;
        width: 38%;
        margin-left: 21px;
        padding: 10px;
        height: 31px;
        margin-top: 5px;
        border: 1px solid #d6d4d4;
        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-left: 0;
        }
    }
    select {
        //width: 65.666667%;
        position: relative;
        outline: none;
        height: 32px;
        padding: 0 8px;
        width: 38%;
        margin-left: 21px;
        background: url("/themes/thalesesecurity/templates/dist/images/select_dropdown.png") no-repeat right #f5f5f5;
        background-size: 7%;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #d6d4d4;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        @include media-breakpoint-down(xs) {
            margin: 0;
            width: 100%;
        }
    }
    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
    select::-ms-expand {
        display: none;
    }
    &.bg-purple {
        h2 {
            background-color: #b90b67;
            &:after {
                border-top-color: #b90b67;
            }
        }
        .gem-button-lg {
            background-color: #b90b67;
        }
        a {
            color: #b90b67;
        }
    }
    &.page-node-897-form {
        .middlepart {
            max-width: 1151px;
            margin: 0 auto;
            .row {
                justify-content: center;
                .nbluebg {
                    h2 {
                        color: $white;
                        text-align: center;
                    }
                }
            }
        }
    }
}

#reportcta {
    position: fixed;
    top: 190px;
    right: 10px;
    opacity: 0;
    border-radius: 5px;
    background-color: #242a75;
    box-shadow: 0px 0px 10px 1px #999999;
    padding: 10px 10px 0;
    color: white;
    display: none;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    &.showreportcta {
        display: block !important;
        opacity: 1 !important;
        @include desktop-down {
            display: none !important;
        }
    }
    p {
        line-height: 1.6;
        font-size: 16px;
        padding: 0;
        margin: 0 0 10px 0;
        color: white;
        font-weight: bold;
    }
}

.form-group {
    margin-bottom: 14px;
    .privacy {
        padding: 0 30px;
        .small-img {
            img {}
        }
        p {
            margin-top: -4px;
            font-size: 12px;
            font-weight: 400;
            color: $light-bluewhile;
            a {
                font-size: 12px;
                font-weight: 400;
                color: $MidnightBlue;
                //color: $light-bluewhile;
                text-decoration: none;
            }
        }
    }
}

&.toolbar-tray-open #reportcta {
    top: 290px;
}