.overview {

  @include container-up {
    //padding: 111px 0 20px;
  }
  img[data-align=right]{
    float: right;
    padding: 5px;
  }
  img[data-align=left]{
    float: left;
    padding: 5px;
  }
  img[data-align=center]{
    display: block;
    float: none;
    margin: 0 auto;
    padding: 5px;
  }
  .content {
    max-width: 100%;
    @include phablet-down {
      text-align: left;
    }
    h2 {
      color: $light-bluewhile;
      font-weight: 400;
      margin-bottom: 22px;
      @include container-up {
        //font-size: 46px;
      }
      @include phablet-down {
        //font-size: 20px;
        margin-bottom: 10px;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    h4 {
      margin-bottom: 22px;
      color: $light-bluewhile;
      @include phablet-down {
        margin-bottom: 10px;
      }
    }
    p {
      color: $light-bluewhile;
      margin-bottom: 34px;
      @include phablet-down {
        margin-bottom: 30px;
      }
      .align-left {
        margin-right: 20px;
        margin-bottom: 30px;
        @include phablet-down {
          float: none;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
      .align-right {
        margin-left: 20px;
        margin-bottom: 30px;
        @include phablet-down {
          float: none;
          margin-left: 0;
          margin-bottom: 0;
        }
      }
      a {
        img {
          margin-right: 20px;
          margin-bottom: 30px;
          max-width: 100%;
          height: auto;
          vertical-align: top;
        }
      }
      strong {
         a{
          color: $light-bluewhile;
         }
       }
    }

    ul {
      padding-left: 40px;
      @include phone-down {
        padding-left: 30px;
      }
    }
  }

}
