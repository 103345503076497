.content-with-video {

  @include container-up {
    //padding: 112px 0 18px;
  }
  @include desktop {
    //padding-bottom: 55px;
  }
  @include phablet {
    //padding-bottom: 55px;
  }
 

  .wrapper {
    width: 100%;

    @include phablet-down {
      text-align: center;
    }
    h2{
      color: $light-bluewhile;
      font-weight: 300;
      padding-bottom: 21px;
      @include container-up {
        font-size: 30px;
      }
      @include phone-down {
        font-size: 20px;
      }
    }
    .video-wrap{
      display: inline-block;
      padding-bottom: 30px;
       .left-video{
         position: relative;
         height: 100%;
         width: 40%;
         display: inline-block;
         vertical-align: top;
         text-align: center;
         padding: 20px 0;
         vertical-align: middle;
         @include phablet-down {
          width: 100%;
         }
         a{
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         z-index: 2;
         }
         img{
          max-width: 100%;
          height: auto;
          vertical-align: top;
         }
       }
      .content {
        width: 60%;
        float: right;
        @include phablet-down {
          width: 100%;
          text-align: center;
          float: none;
        }
       
        h3 {
          color: $light-bluewhile;
        }
        p {
          line-height: 1.5;
          color: $light-bluewhile;
          a {
            font-size: 22px;
            font-weight: 300;
            color: $light-bluewhile;
            @include phablet-down {
              font-size: 14px;
            }
            &:hover {
              color: $light-bluewhile;
            }
          }
          img {
            margin: auto;
            display: block;
          }
        }
        
      }

    }

    p {
      line-height: 1.5;
      color: $light-bluewhile;
      a {
        font-size: 22px;
        font-weight: 300;
        color: $light-bluewhile;
        @include phablet-down {
          font-size: 14px;
        }
        &:hover {
          color: $light-bluewhile;
        }
      }
      .center {
        margin: auto;
        display: block;
      }
    }

    .card-link{
      text-align: center;
      a{
        img{
        width: 100%;
        height: 100%;
        }
        h4{
          font-weight: 100;
          color: $blue5;
        }
      }
    }
   
  }

}