.calc-banner {
  height: 497px;
  overflow: hidden;
  margin-top: 130px;
  padding: 0;

  @include media-breakpoint-down(lg) {
    margin-top: 130px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
  }
  strong {
    font-weight: 700 !important;
  }
  .text-wrapper {
    // margin-bottom: 83px;
    padding: 90px 0 0;
    @include media-breakpoint-down(lg) {
      padding: 50px 0;    }
    h1 {
      font-size: 2.8em;
      margin-bottom: 38px;
      color: $black;
      font-weight: 400;
      line-height: 1.1;

      @include media-breakpoint-down(md) {
        font-size: 36px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 30px;
        margin-bottom: 18px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 25px;
      }
    }
    h3 {
      color: $light-bluewhile;
    }
  }

  h1{
    color: $white;
    font-weight: 400;

  }
  
  a {
    margin: 0;
    font-size: 16px;
    padding: 8px 16px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: solid 1px ;
    //background-color: #6c286b;
    background-color: $MidnightBlue;
    display: inline-block;
    border-radius: 5px;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
    &.btn-blue {
      background-color: $Midlightblue;
      // background-color: #00bbdd;
      border: solid 1px $Midlightblue;
      &:hover {
        opacity: 0.8;
        // border: solid 1px #53D8F0;
        // background-color: #53D8F0;
      }
    }
  }
  > div {
    position: relative;
    height: inherit;
    top: 0;
  }
  .v-middle-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    // @include media-breakpoint-down(sm) {
    //   position: relative;
    //   height: auto;
    //   background-color: #cccccc;
    //   padding: 20px 0;
    // }
  }
  .content-wrapper {
    padding: 0 40px;

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
  .bg-img {
    height: inherit;

    @include media-breakpoint-down(sm) {
      height: 380px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      @include media-breakpoint-down(xs) {
        object-position: 100%;
      }
    }
  }
  .container {
      &.inner-container {

      top: 50%;
      transform: translateY(-50%);


    }
  }

  &.voilet-banner {

    @include media-breakpoint-up(lg) {
      height: 518px;
    }
    .bg-img{
      img{
        object-position: top;
      }
    }
    .content-wrapper {
      .text-wrapper {
        @include media-breakpoint-up(lg) {
          flex: 0 0 57.666667%;
          max-width: 56.666667%;
          // margin-bottom: 129px;
        }
        h1 {
          color: $MidnightBlue;
          margin-bottom: 11px;
        }
        h3 {
          margin-bottom: 11px;
        }
      }
    }
  }
  &.home-banner{
   .full-row{
   @include phablet-down{
    text-align: center;

    }
   }
    h1 {
      color: $white;
    }

    p {
      color: $white;
      font-weight: 400;
    }
  }



}




.sticky-nav + .calc-banner {
  @include media-breakpoint-down(md) {
    margin-top: 62px;
  }
  @include media-breakpoint-down(xs) {
    margin-top: 57px;
  }
}
