.card-three-logo-slider{
  @include container-up{
    padding: 110px 0 84px;
  }


   .slider-wrap{
   .slider{
     position: relative;
     width: 100%;
     .item{
       
      .full-row{
        margin: 0 -10px;
        float: left;
        width: 100%;
        @include  container-down {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: center;
          text-align: center;
          flex-wrap: wrap;
        } 
       
        .col-wrap { 
          padding: 0 10px;
            @include  tablet-down {
            margin: 0 auto 30px;
            width: 100%;
            padding: 5px;
            max-width: 767px;
            
              }

              .wrapper{
                border: 2px solid;
                border-color: $white2 $white2 $blue9  $white2;
                border-radius: 3px;
                padding: 36px 30px 10px;
                min-height: 360px;
                @include  container-down {
                  padding: 30px 37px 10px;  
                  min-height: 400px;
                }
                @include desktop-down {
                  min-height: 340px;
                }
                @include  phablet-down {
                  padding: 36px 30px 10px;
                  min-height: 360px;
                }
                .img-icon {
                  position: relative;
                  margin: 0 auto 12px; 
                  height: 120px;
                  img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
                .content {
                  text-align: center;
                  @include  container-down {
                    padding: 0 10px;
                  }
                  @include  tablet-down {
                    padding: 0;
                  }
                

                  p {
                    margin: 0 0 6px;
                    line-height: 1.2;
                    font-size: 22px;
                    font-weight: 300;
                    color: $light-bluewhile;
                    @include  tablet-down {
                      font-size: 20px;
                }
              }
              .more{
    
              }
            }
          }
        }
       }
     }
    } 

  .slick-dots{
    text-align: center;
    list-style-type: none;
     padding-top: 30px;
     @include  phablet-down {
      padding-top: 20px;
     }
    cursor: pointer;
      li{
        list-style-type: none;
        display: inline-block;
        height: 16px;
        width: 16px;
        background-color: $light-grey;
        border-radius: 50%;
        margin: 0 4px;
        button {
          font-size: 0;
        }
        &.slick-active{
          background: linear-gradient(to bottom,#5dbdf4 0%,#2b86bc 100%)
        }
      }
  }
 
 }
}



