.careers-detail {
  .title {
    text-align: center;
    padding-bottom: 30px;
    h2 {
      font-weight: 300;
      color: $light-bluewhile;
    }
  }

  .content {
    p {
      font-weight: 100;
      color: $light-bluewhile;

      a {
        font-weight: 100;
        color: $blue5;
        font-size: 22px;
      }
    }
  }
}




