.management-info {
  padding: 130px 0 100px;
  margin-top: 100px;
  @include container-down {
    padding: 80px 0 30px;
  }
  @include desktop-down {
    padding: 30px 0;
    margin-top: 80px;
  }
  @include phablet-down {
    padding: 0;
    margin-top: 60px;
  }

  strong {
    font-weight: 700 !important;
  }
  
  .outer-wrapper {
    padding: 0 26px;
    @include desktop-down {
      padding: 0 20px;
    }
    @include phablet-down {
      padding: 20px;
      }

    .text-wrap {
      padding: 20px 40px 34px 0;
      @include desktop-down {
        padding: 0 10px 0 0;
      }
      @include phablet-down {
        text-align: center;
        padding: 20px 0;
        flex: unset;
        max-width: 100%;
      }

      h1 {
        color: #00bbdd;
        font-size: 2.8em;
        padding-right: 32px;
        line-height: 1.1;
        font-weight: 500;
        padding-bottom: 20px;
        @include desktop-down {
          font-size: 2em;
          padding-right: 0;
          padding-bottom: 10px;
        }
        @include tablet-down {
          font-size: 1.5em;
        }
        @include phablet-down {
          padding: 0;
          margin: 0;
          font-size: 1.9em;
          padding-bottom: 10px;
        }
      }

      p {
        line-height: 1.6;
        padding: 0 0 1em;
        color: #3C3C3C;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
        @include desktop-down {
          font-size: 15px;
          padding: 0;
        }
        @include phablet-down {
          font-size: 14px;
        }
      }
    }

    .image-wrap {
      padding: 0 0 0 50px;
      @include desktop-down {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 20px;
      }
      @include phablet-down {
        flex: unset;
        max-width: 100%;
        display: unset;
        justify-content: unset;
        align-items: unset;
        padding: 0;
      }
      @include desktop-down {
      img{
        vertical-align: middle;
      }
    }
    }
  }
}

.data-security {
  background-color: #e1e1e1;
  form {
    max-width: 622px;
    margin: 0 auto;
    background-color: #fff;
    @include phablet-down {
     form{

     }
    }
  }
}
