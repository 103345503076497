.col-three-image-slider {
  opacity: 0;
  @include container-up {
    //padding: 110px 0 20px;
  }

  &.gallery-center {
    @include container-up {
      //padding: 20px 0 12px;
    }

    .container {
      @include tablet-down {
        max-width: 100%;
        padding: 0;
        margin: 0;
      }
      .slider-wrap {
        .slider {
          width: 100%;
          .item {
            .full-row {
              .col-wrap {
                a {
                  display: block;
                  text-decoration: none;
                  width: 100%;
                  display: inline-block;
                  transition: all ease 0.5s;
                  cursor: pointer;
                  .bg-img {
                    position: relative;
                    min-height: 420px;
                    max-width: 385px;
                    border-bottom: 1px solid $blue;
                    &:before {
                      content: '';
                      position: absolute;
                      background: #020a7a;
                      transition: all ease 0.5s;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0.5;
                    }

                    @include container-down {
                      max-width: 100%;
                      // height: 100%;
                    }
                    @include tablet-down {
                      max-width: 100%;
                      min-height: 300px;
                    }
                    @media (max-width: 380px) {
                      // height: 280px;
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .content {
                    z-index: 1;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 0;
                    width: 100%;
                    max-width: 320px;
                    @include container-down {
                      padding: 0 20px 5px;
                    }
                    @include tablet-down {
                      padding: 0;
                    }
                    @media (max-width: 380px) {
                      padding: 20px;
                    }

                    span {
                      transition: all ease 0.5s;
                      font-size: 22px;
                      font-weight: 300;
                      color: $white;
                    }
                    h4 {
                      transition: all ease 0.5s;
                      color: $white;
                      font-size: 30px;
                      font-weight: 300;
                      padding-bottom: 10px;
                      @include tablet-down {
                        font-size: 23px;
                      }
                    }
                    .small-row {
                      padding: 0 0 40px;
                      span {
                        display: inline-block;
                        vertical-align: top;
                        font-size: 17px;
                        text-transform: capitalize;
                        position: relative;
                        &:last-child {
                          position: relative;
                          padding: 0 0 0 15px;
                          margin: 0 0 0 14px;
                          &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 2px;
                            bottom: 2px;
                            width: 2px;
                            background: #fff;
                          }
                        }

                      }
                    }
                  }
                }
                &:hover {
                  a {
                    text-decoration: none;
                    cursor: pointer;
                    .bg-img {
                      &:before {
                        opacity: 0.7;
                      }
                    }
                    .content {
                      .link {
                        span {
                          right: -17px;
                        }
                      }
                    }
                  }

                }
              }
            }
          }
          .slick-arrow {
            outline: 0;
            position: absolute;
          }
          .slick-prev {
            top: 46%;
            left: -44px;
            background: $white-light;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: $dark-blue;
            z-index: 2;
            display: block;
            font-size: 0;
            line-height: 0;
            position: absolute;
            padding: 0;
            transform: translate(0, -50%);
            cursor: pointer;
            border: none;
            outline: none;
            &:before {
              font-family: 'icomoon' !important;
              content: "\e909";
              font-size: 23px;
              opacity: 1;
              color: $dark-blue;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
            }
          }
          .slick-next {
            z-index: 1;
            top: 46%;
            right: -44px;
            display: block;
            background: $white-light;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: $dark-blue;
            font-size: 0;
            line-height: 0;
            position: absolute;
            padding: 0;
            transform: translate(0, -50%);
            cursor: pointer;
            border: none;
            outline: none;
            &:before {
              opacity: 1;
              content: "\e908";
              font-family: 'icomoon' !important;
              font-size: 23px;
              color: $dark-blue;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
            }
          }
          .slick-dots {
            text-align: center;
            list-style-type: none;
            padding-top: 40px;
            cursor: pointer;
            li {
              list-style-type: none;
              display: inline-block;
              height: 16px;
              width: 16px;
              background-color: $light-grey;
              border-radius: 50%;
              margin: 0 4px;
              button {
                font-size: 0;
              }
              &.slick-active {
                background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%)
              }
            }
          }
        }
      }

    }
  }

  .container {
    @include tablet-down {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    .slider-wrap {
      .slider {
        width: 100%;
        .item {
          .full-row {
            .col-wrap {
              transition: all ease 0.5s;
              cursor: pointer;
              a {
                display: block;
              }
              .bg-img {
                position: relative;
                height: 382px;
                max-width: 385px;
                border-bottom: 1px solid $blue;
                &:before {
                  content: '';
                  position: absolute;
                  background: $black;
                  transition: all ease 0.5s;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0.5;
                }

                @include container-down {
                  max-width: 100%;
                  height: auto;
                }
                @include tablet-down {
                  max-width: 100%;
                  height: 250px;
                }
                @media (max-width: 380px) {
                  height: 280px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .content {
                z-index: 1;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 0;
                width: 100%;
                max-width: 320px;
                @include container-down {
                  padding: 0 20px 5px;
                }
                @include tablet-down {
                  padding: 0;
                }
                @media (max-width: 380px) {
                  padding: 20px;
                }

                span {
                  transition: all ease 0.5s;
                  font-size: 22px;
                  font-weight: 300;
                  color: $white;
                }
                h4 {
                  transition: all ease 0.5s;
                  color: $white;
                  font-size: 30px;
                  font-weight: 300;
                  padding-bottom: 5px;
                  @include tablet-down {
                    font-size: 23px;
                  }
                }
              }
              &:hover {
                cursor: pointer;
                .bg-img {
                  &:before {
                    opacity: 0.7;
                  }
                }
                .content {
                  .link {
                    span {
                      right: -17px;
                    }
                  }
                }
              }
            }
          }
        }
        .slick-arrow {
          outline: 0;
          position: absolute;
        }
        .slick-prev {
          top: 46%;
          left: -44px;
          background: $white-light;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          color: $dark-blue;
          z-index: 2;
          display: block;
          font-size: 0;
          line-height: 0;
          position: absolute;
          padding: 0;
          transform: translate(0, -50%);
          cursor: pointer;
          border: none;
          outline: none;
          &:before {
            font-family: 'icomoon' !important;
            content: "\e909";
            font-size: 23px;
            opacity: 1;
            color: $dark-blue;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
        }
        .slick-next {
          z-index: 1;
          top: 46%;
          right: -44px;
          display: block;
          background: $white-light;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          color: $dark-blue;
          font-size: 0;
          line-height: 0;
          position: absolute;
          padding: 0;
          transform: translate(0, -50%);
          cursor: pointer;
          border: none;
          outline: none;
          &:before {
            opacity: 1;
            content: "\e908";
            font-family: 'icomoon' !important;
            font-size: 23px;
            color: $dark-blue;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
        }
        .slick-dots {
          text-align: center;
          list-style-type: none;
          padding-top: 40px;
          cursor: pointer;
          li {
            list-style-type: none;
            display: inline-block;
            height: 16px;
            width: 16px;
            background-color: $light-grey;
            border-radius: 50%;
            margin: 0 4px;
            button {
              font-size: 0;
            }
            &.slick-active {
              background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%)
            }
          }
        }
      }
    }

  }
}
