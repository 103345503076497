.comprehensive-calculator{
  background-color: #F1F1F1;
  strong {
    font-weight: 700 !important;
  }
  .outer-wrapper{
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    .dots-wrapper{
      text-align: center;
      padding: 30px 0;
      display: flex;
      list-style: none;
      justify-content: center;
      li{
        pointer-events: none;
        &.slick-active{
          .dots{
            background-color: #b90b67;
          }
        }
      }
      .dots{
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 10px;
        margin-left: 5px;
        transition: all ease 1.0s;
        background-color: #aaaaaa;
      }
    }
    .controls{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .btn{
        width: calc(50% - 28px);
        padding: 30px 0;
        text-align: center;
        font-weight: bold;
        font-size: 1.4em;
        margin: 10px 0;
        border-radius: 5px;
        border: none;
        background-color: #b90b67;
        color: $white;
        transition: background-color 0.3s;
        cursor: pointer;
      }
      .slick-disabled{
        color: #ffffff;
        background-color: #dddddd;
        cursor: not-allowed;
        &.arrow{
          background: transparent;
          path {
            fill: #dddddd;
          }
        }

      }
    }
    .arrow-control{
      position: absolute;
      top: 420px;
      width: 130%;
      left: -15%;
      display: flex;
      justify-content: space-between;
      .arrow{
        height: 70px;
        width: 70px;
        svg{
          height: 100%;
          width: 100%;
          path{
            fill: #b90b67;
          }
        }
      }
    }
    .slider-wrapper{
      min-height: 580px;
      margin-bottom: 80px;
      .slide{
        h3{
          color:$black;
          font-size: 30px;
          margin-bottom: 20px;
        }
        .dropdown{
          overflow: hidden;
          .dropdown-head{
            background-color: #b90b67;
            text-align: center;
            padding: 20px 10px;
            position: relative;
            h4{
              z-index: 9;
              position: relative;
            }
            &:before {
              content: "";
              position: absolute;
              height: 40px;
              width: 40px;
              background-color: #b90b67;
              left: 50%;
              bottom: -9px;
              z-index: 0;
              transform: translateX(-50%)rotate(45deg);
            }
          }
          .dropdown-wrapper{
            display: none;
            background-color: $white;
            padding: 20px 50px;
            ol{
              padding-left: 25px;
              margin-bottom: 25px;
            }
            p{
              color: #3C3C3C;
              font-size: 15px;
              font-weight: 200;
            }

          }
        }
      }
      .slick-list{
        height: auto!important;
      }
    }
  }


}
