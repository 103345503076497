.third-level-banner {
  min-height: 300px;
  margin-top: 30px;
  padding: 0;
  display: inline-grid;

  h1, h2, h3, h4, h5, h6, p {
    color: $white;
  }

  .bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-wrapper {
    padding-top: 40px;
    .content {
      padding-top: 12px;
      text-align: left;
      @include phone-down {
        text-align: center;
      }
      h1 {
        font-size: 40px;
        color: $white;
        line-height: 1.4;
        font-weight: 300;
        padding-bottom: 8px;
        @include container-down {
          font-size: 36px;
        }
        @include phablet-down {
          font-size: 30px;
          padding-bottom: 10px;
        }
      }
      h3 {
        color: $white;
        font-weight: 300;
        font-size: 22px;
        @include phablet-down {
          font-size: 16px;
        }
      }
      p {
        color: $white;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
      &:hover:before {
        width: 0;
      }
    }
    .btn-wrapper {
      display: inline-flex;
      float: right;
      position: absolute;
      right: 18px;
      bottom: 22px;
      display: none;
      @include phablet-down {
        right: 0;
        display: flex;
        float: none;
        bottom: 22px;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .btn-left {
        font-weight: 300;
        padding: 0px 17px;
        background-color: $white;
        color: $MidnightBlue;
        border-radius: 20px 0 0 20px;
        line-height: 34px;
        font-size: 15px;
        box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
        @include phablet-down {
          padding: 0 10px;
        }
      }
      .btn-right {
        background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
        padding: 0 38px;
        border-radius: 0 20px 20px 0;
        color: $white;
        font-weight: 300;
        line-height: 34px;
        font-size: 15px;
        box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
        @include phablet-down {
          padding: 0 17px;
        }
      }
      &.bottom-fix {
        position: fixed;
        bottom: 50px;
        right: 94px;
        z-index: 5;
        transition: bottom 0.6s linear;
        transform: translate(0, 30px);
        @media screen and (min-width: 1401px) {
          right: 50%;
          margin: 0 -614px 0 0;
        }
        @include tablet-down {
          right: 0;
          display: flex;
          float: none;
          position: fixed;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}


