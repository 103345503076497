.intro-with-image {

  @include container-up {
    //padding: 112px 0 18px;
  }
  @include desktop {
    //padding-bottom: 55px;
  }
  @include phablet {
    //padding-bottom: 55px;
  }
  &.content-with-image {
    @include container-up {
      //padding: 100px 0 54px;
    }
    .wrapper {

      .content {
        width: 100%;
        h2 {
          font-size: 30px;
        }
        p {

        }
      }
    }
    .center-img-wrap {
      max-width: 600px;
      img {
      }

    }
  }
  &.channel-partners {
    .wrapper {
      .content {
        width: 84%;
        @include container-down {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        @include desktop-down {
          width: 60%;
        }
        @include phablet-down {
          width: 100%;
          text-align: center;
          float: none;
        }
      }
      .right-img-wrap {
        width: 15%;
        padding: 0;
        max-width: 159px;
        height: auto;
        img {
        }
        @include desktop-down {
          width: 40%;
        }
        @include phablet-down {
          width: 100%;
          float: none;
          padding-bottom: 30px;
          margin: 0 auto 30px;
        }
      }
    }
  }

  .wrapper {
    width: 100%;

    @include phablet-down {
      //display: flex;
      //flex-direction: column-reverse;
    }

    h2 {
      color: $light-bluewhile;
      font-weight: 300;
      padding-bottom: 21px;
      @include container-up {
        //font-size: 46px;
      }
      @include phablet-down {
        text-align: center;
      }
      @include phone-down {
        //font-size: 20px;
      }
    }

    .content {
      //width: 74%;
      //float: left;
      @include container-down {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
      }
      @include desktop-down {
        //width: 60%;
      }
      @include phablet-down {
        width: 100%;
        text-align: center;
        float: none;
      }
      h3 {
        color: $light-bluewhile;
      }
      p {
        line-height: 1.5;
        color: $light-bluewhile;
        @include container-down {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        @include phablet-down {
          &:last-of-type {
            margin-bottom: 20px;
          }
        }
        a {
          font-size: 22px;
          color: $blue5;
          @include phablet-down {
            font-size: 14px;
          }
          &:hover {
            color: $blue5;
          }
        }
        .align-center {
          margin-bottom: 0;
        }
        img {
          display: block;
          margin: auto;
        }
        .align-left {
          margin-right: 30px;
          margin-bottom: 20px;
          @include phablet-down {
            margin: 0 auto;
            float: none;
          }
        }
      }
      .align-center {
        // max-width: 500px;
        // width: 100%;
        display: block;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .right-img-wrap {
      width: 100%;
      max-width: 236px;
      max-height: 236px;
      float: right;
      margin: 0 0 30px 50px;
      text-align: right;
      @include desktop-down {
        width: 40%;
        max-width: 200px;
      }
      @include phablet-down {
        width: 100%;
        float: none;
        padding-bottom: 0;
        margin: 0 auto 30px;
        text-align: center;
      }
      img {
        width: auto;
        height: auto;
        //object-fit: cover;
      }
    }
  }

  .img-center {
    max-width: 500px;
    margin: 0 auto;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ul {
    padding-left: 40px;
    @include phone-down {
      padding-left: 30px;
    }
  }
}




