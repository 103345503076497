.cost-analysis {
  padding: 0 0 68px;
  background-color: #f5f5f5;
  @include tablet-down {
    padding: 0 0 40px;
  }
 
  strong {
    font-weight: 700 !important;
  }
   .calculator-wrap{
     position: relative;
     min-height: 700px;
    @include container-down{
     min-height: 350px;
     }
   }

  .dots {
    padding-top: 15px;
    text-align: center;
    padding-bottom: 36px;
    @include phablet-down {
      padding: 15px 0;
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #cccccc;
      display: inline-block;
      margin: 0 4px;
      vertical-align: middle;
    }
    .activedot {
      background-color: $MidnightBlue !important;
    }
  }

  h2 {
    font-weight: bold;
    //font-size: 32px;
    padding: 26px 0 50px 0;
    color: #212121;
    @include phablet-down {
      padding: 30px 0 20px;
      text-align: center;
      font-size: 20px;
    }
  }
  h3 {
    text-align: center;
    margin: 0;
    color: $white;
    padding: 14px;
    font-size: 24px;
    background-color: $MidnightBlue;
    @include phablet-down {
      font-size: 18px;
    }
  }
  .arrow-center {
    text-align: center;
    margin-bottom: 0 !important;
    font-size: 0;
    .arrow {
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      border: 20px solid #ffffff;
      border-top-color: $MidnightBlue;
    }
  }

  .white-block {
    background-color: #ffffff;
    padding: 0 70px 20px;
    @include container-down{
      padding: 0 30px 20px;
    }
    @include phablet-down {
      padding: 0 10px 20px;
      text-align: center;

    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #000;
      line-height: 1.6;
      margin-bottom: 18px;
      strong {
        font-weight: 700;
      }

      .downloadbtn {
        text-transform: uppercase;
        padding: 15px 20px;
        margin: 40px 0 3px;
        display: inline-block;
        border-radius: 3px;
        font-size: 1.4em;
        font-weight: bold;
        color: $white;
        background-color: $MidnightBlue;
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
        @include phablet-down {
          padding: 12px;
          margin: 10px 0 3px; 
          font-size: 1.1em;
        }
      }

      @include phablet-down {
        font-size: 12px;
        margin-bottom: 14px;
        text-align: center;
      }
    }

    ol {
      margin-top: 0;
      padding: 0 0 18px 36px;
      @include phablet-down {
        padding: 10px 20px;
        text-align: center;
      }
      li {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 0;
        @include phablet-down {
          font-size: 14px;
          text-align: center;
        }
      }
    }

    .startbtn {
      padding: 10px;
      text-transform: uppercase;
      margin-top: 0;
      display: inline-block;
      border-radius: 3px;
      font-weight: bold;
      transition: all 0.3s;
      background-color: $MidnightBlue;
      color: #fff;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
      @include phablet-down {
        text-align: center;
        font-size: 12px;
      }
    }

    .vfields{

      input{
        border-radius: 3px;
        border: solid 1px #cccccc;
        width: 100%;
        padding: 3px 5px;
        font-weight: bold;
        text-align: right;
        margin-bottom: 5px;
        
      }

      input[disabled] {
        background-color: #dddddd;
        color: $black;
    }
    .mob-hide{
     p{
       margin-bottom: 16px;
     }
     @include phablet-down {
       display: none;
     }
    }
    }
  }

  .sentinelup-cta {
    padding: 30px 70px 10px;
    background: url(/themes/thalesesecurity/templates/assets/images/sentinel-up-cta-bg.jpg) center center no-repeat;
    background-size: cover;
    @include phablet-down {
      padding: 20px;
    }
    h5 {
      font-size: 23px;
      margin-bottom: 30px;
      padding: 0 10px;
      color: $white;
      strong {
        font-weight: 700;
        color: $white;
      }
    }
    p {
      padding: 0 0 22px;
      color: $white;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 400;
      .bigbtn {
        background-color: $MidnightBlue;
        color: white;
        border: solid 1px $MidnightBlue;
        padding: 8px 13px 8px;
        border-radius: 3px;
        font-size: 18px;
        text-transform: uppercase;
        display: inline-block;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        font-weight: 700;
        &:hover {
          text-decoration: none;
          background-color: transparent;
          color: $MidnightBlue;
        }
        @include container-down {
          font-size: 16px;
        }
        @include phablet-down {
           font-size: 16px;
        }
      }
    }
  }

  .progress-btns {
    padding-top: 60px;
    @include phablet-down {
      padding-top: 30px;
    }
    .megapbtn {
      width: 100%;
      padding: 10px;
      margin: 0;
      display: inline-block;
      border-radius: 3px;
      color: white;
      max-width: 290px;
      background-color: $MidnightBlue;
      transition: all 0.3;
      float: right;
      height: 100%;

      &.prevbtn {
        cursor: pointer;
      }

      &.nextbtn {
        cursor: pointer;
      }
      &.disabled {
        cursor: not-allowed;
      }
      @include phablet-down {
        
      }
      &:hover{
        &.disabled {
          cursor: not-allowed;
        }
        cursor: pointer;
        opacity: 0.9;
      }
    }
    .bolder-text {
      font-weight: bold;
      font-size: 20px;
      @include phablet-down {
        font-size: 15px;
      }
    }
    .prevtitle{
      font-size: 14px;
      @include phablet-down {
        font-size: 12px;
      }
    }
    .nexttitle {
      font-size: 14px;
      @include phablet-down {
        font-size: 12px;
      }
    }


    .meganbtn {
      width: 100%;
      padding: 10px;
      margin: 0;
      display: inline-block;
      border-radius: 3px;
      color: white;
      max-width: 290px;
      background-color: $MidnightBlue;
      transition: all 0.3;
      float: left;
      @include phablet-down {
      }
      &:hover{
        cursor: pointer;
        opacity: 0.9;
      }
    }
    .dbtn {
      background-color: #dddddd !important;
    }
  }

  #calculator-container {
    position: relative;
    > div {
      display: none;
    }
    .savebtn {

      display: inline-block;
      &.sprogess {
        background-image: url(/themes/thalesesecurity/templates/assets/images/6e7d66e0-74f8-11ea-9e0e-a0369f10330e.gif) !important;
        // background-image: url(/themes/thalesesecurity/templates/dist/images/loader.gif);
        background-size: 95%;
      }
    }
    .tt-cont {
      display: block !important;
      left: -6;
      top: -32px;

      .tt-arrow {
        border-top-color: #000000;
        left: -25px;
        top: 20px;
      }
    }
    #calculator-part1 {
      max-width: 810px;
      display: block;
      margin: 0 auto;
    }
    #calculator-part2{
      .white-block{
        padding-bottom: 20px;
      &:last-child{
       padding-bottom: 26px;
       p{
         padding: 0;
       }
      }
      }
    }
   
    #calculator-part5{
      .text-center{
        margin-bottom: 10px;
      }
      .row{
        .text-center{
          .expbtn {
            width: 100%;
            padding: 14px 10px;
            border: solid 1px #cccccc;
            border-radius: 3px;
            display: inline-block;
            margin-top: 10px;
            text-align: center;
            &.expbtnon{
              color: $white;
              background-color: $MidnightBlue !important;
            }
            &.singleln {
              padding: 21px 0 26px;
          }
          &:hover{
            cursor: pointer;
            background-color: $MidnightBlue !important;
            opacity: 0.8;
            color: $white;
            &.expbtnon{
              opacity: 1;
            }
           }
          }
        }
      }
    }
    #calculator-part6 {
  
      .grey-block {
        p {
          color: #3C3C3C;
          margin-bottom: 8px;
          font-size: 16px;
          padding: 0 20px 0 0;
          strong {
            color: #3C3C3C;
            font-weight: 700;
          }
          .bigbtn {
            background-color: $MidnightBlue;
            color: white;
            border: solid 1px $MidnightBlue;
            padding: 8px 13px 8px;
            border-radius: 3px;
            text-transform: uppercase;
            display: inline-block;
            transition: all 0.3s;
            font-weight: 700;
            &:hover {
              text-decoration: none;
              background-color: transparent;
              color: $MidnightBlue;
            }
            @include container-down {
              font-size: 16px;
            }

            @include phablet-down {
              font-size: 12px;
           }
          }
        }
    
        .big-text {
          font-size: 3em;
          margin-bottom: 10px;
          line-height: 1.0;
          font-weight: bold;
        }
      }
    }
  }

  .arrow-right {
    background: url(/themes/thalesesecurity/templates/assets/images/6e6f05be-74f8-11ea-bca5-a0369f10330e.png) 30px 0 no-repeat;
    width: 100%;
    height: 57px;
    display: block;
    transition: all 0.3s;
    margin-top: 415px;
    position: relative;
    right: 0;
    &.drarrow {
      background-image: url(/themes/thalesesecurity/templates/dist/images/arrow-right-disabled.png) !important;
    }
    &.nextbtn {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
    }
    &:hover {
      right: -10px;
    }
    @include container-down {
      display: none !important;
    }
  }

  .arrow-left {
    background: url(/themes/thalesesecurity/templates/assets/images/6ec96428-74f8-11ea-afbd-008cfa043684.png) 70px 0 no-repeat;
    width: 100%;
    height: 57px;
    display: block;
    transition: all 0.3s;
    margin-top: 415px;
    position: relative;
    left: 26px;
    &.dlarrow {
      background-image: url(/themes/thalesesecurity/templates/dist/images/arrow-left-disabled.png) !important;
    }
    &.prevbtn {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
    }
    &:hover {
      left: 20px;
    }

    @include container-down {
      display: none;

    }
  }

  .savebtn {
    // background: url(/themes/thalesesecurity/templates/dist/images/save-icon.png) no-repeat;
    background: url(/themes/thalesesecurity/templates/assets/images/6e76a116-74f8-11ea-a1e5-008cfa044124.png) no-repeat;
    display: inline-block;
    width: 30px;
    height: 29px;
    float: right;
    margin-right: 52px;
    &:hover {
      background-position: bottom;
      cursor: pointer;
    }
    @include phablet-down {
      margin-right: 0;
    }
  }
  label {
    // padding-right: 4px;
    // letter-spacing: -0.04em;
    font-size: 0.9em;
    color: #888888;
    margin-bottom: 16px;
    display: block;
  }
  input {
    border-radius: 3px;
    border: solid 1px #cccccc;
    width: 100%;
    padding: 8px 10px 8px;
    font-weight: bold;

  }
  #projectname {
    width: 100%;
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    font-weight: bold;
    font-size: 1.3em;
    padding: 0 0 4px 0;
    margin-bottom: 40px;
    height: 39px;
    @include phablet-down {
      text-align: center;
    }
  }
  // .col-lg-6 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   position: relative;
  // }
  // .col-lg-3 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   position: relative;
  // }
  // .col-lg-4 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   position: relative;
  // }
  // .col-md-3 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   position: relative;
  // }
  // .col-md-4 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   position: relative;
  // }

  .test-wrap{
    position: relative;
    }

  .plus, .minus {
    display: inline-block;
    position: absolute;
    right: 6px;
    font-size: 1.4em;
    line-height: 0.5em;
    padding: 0px 5px 5px;
    color: #cccccc;
  }
  .plus:hover, .minus:hover{
    cursor: pointer;
    color: $MidnightBlue;
  }
  .minus {
    top: 24px;
    // @include phablet-down {
    //   top: 5px;
    // }
  }
  .plus {
    top: 6px;
    right: 5px;
  }
  .two-line {
    // margin-top: -22px;
    padding-right: 12px;
  }
  .req-btn {
    color: $MidnightBlue;
  }
  .grey-block {

   @include container-down {
    padding: 26px;
    }
    padding: 25px 70px;
    border-top: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;
    background-color: #eeeeee;
    p {
      color: #3C3C3C;
      margin-bottom: 6px;
      font-size: 16px;
      strong {
        color: #3C3C3C;
        font-weight: 700;
      }
      .bigbtn {
        background-color: $MidnightBlue;
        color: white;
        border: solid 1px $MidnightBlue;
        padding: 8px 13px 8px;
        border-radius: 3px;
        font-size: 18px;
        text-transform: uppercase;
        display: inline-block;
        transition: all 0.3s;
        font-weight: 700;
        &:hover {
          text-decoration: none;
          background-color: transparent;
          color: $MidnightBlue;
        }
        @include container-down {
          font-size: 16px;
        }
        @include phablet-down {
          font-size: 12px;
       }
      }
    }


    .big-text {
      font-size: 3em;
      margin-bottom: 0;
      line-height: 1.1em;
      font-weight: bold;
    }

  }

  .tt-cont {
    position: absolute;
    z-index: 100;
    height: 24px;
    width: 60px;
    display: inline-block;
    right: -12px;
  }
  .tt-arrow {
    position: absolute;
    display: inline;
    width: 7px;
    height: 7px;
    border: 7px solid transparent;
    box-sizing: border-box;
  }


  .build, .buy {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.7em;
    margin: 30px 15px;
    display: inline-block;
    vertical-align: top;
    width: 80px;
    @include desktop-down {
      margin: 2px;
    }
    @include phablet-down {
      font-size: 1em;
      margin: 0;
   }
  }
  .build {
    text-align: right;
  }
  .buy {
    // color: $MidnightBlue !important;
    text-align: left;
  }
  .bvb {
    text-align: center;
    min-height: 105px;
    padding-top: 40px;
  }
  .needle {
    background: url(/themes/thalesesecurity/templates/dist/images/meter-bg.png) top center no-repeat;
    width: 180px;
    height: 124px;
    display: inline-block;
    position: relative;
    img {
      position: absolute;
      left: 41%;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transform-origin: 16px 105px;
      -webkit-transition: transform 0.5s ease-in-out;
      -moz-transition: transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
    }
  }

  .tt-text {
    position: absolute;
    background-color: #000000;
    color: #ffffff;
    display: inline;
    padding: 2px 7px 7px;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: normal;
    border-radius: 3px;
    text-align: center;
    left: 0;
  }
  .fuchsia-block {
    padding: 30px 70px 10px;
    background-color: $MidnightBlue;
  }
  p {
    font-size: 1.1em;
    color: #fff;
  }
  .stat {
    font-size: 4em;
    font-weight: bold;
    line-height: 1em;
    @include tablet-down{
      font-size: 3em;
    }
    @include phablet-down {
      font-size: 2em;

    }
  }
}

em {
  font-style: italic;
  a {
    color: $MidnightBlue;
    text-decoration: none;
    font-size: 0.9em;
    &:hover {
      color: $MidnightBlue;
      text-decoration: none;
      font-size: 0.9em;
    }
  }
}