.two-col-row {

  @include container-up {
    //padding: 50px 0 0;
  }
  .wrapper {
    cursor: pointer;
    .full-row {
      display: block;
      overflow: hidden;
      padding: 0px 0px 4px;
      &.img-right {
        .col-wrap {
          &.content {
            order: 1;
          }
          &.img {
            order: 2;
          }
        }
      }
      .col-wrap {
        width: 50%;
        min-height: 368px;
        padding: 0px 4px 0px 0px;
        cursor: default;

        @include tablet-down {
          width: 100%;
          min-height: 100%;
          padding-bottom: 4px;
        }
        a {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 5;
          transition: all 0.5s ease;
          cursor: pointer;
        }
        .bg-img {
          width: 100%;
          height: 100%;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: $black;
            transition: all 0.5s ease 0s;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

        }
        .content {
          transition: all 0.5s ease;
          .h4 {
            color: $white;
            font-size: 24px;
            font-weight: 300;
            margin-top: 7px;
            @include tablet-down {
              font-size: 22px;
            }
          }
          .white-arrow {
            transform: translateY(10px);
            transition: all 0.5s ease;
            text-transform: unset;
            padding-left: 20px;
            color: $white;
            opacity: 0;

          }
        }

        &:hover {
          .bg-img {
            &:before {
              opacity: 0.6;
            }
          }
          .content {
            .white-arrow {
              transition: all 0.5s ease;
              transform: translateY(0);
              opacity: 1;
              span {
                top: 0;
                right: -30px;
              }

            }
          }
        }

        .color-blue {
          background: linear-gradient(to bottom, #3387b5 0%, #1b5da3 100%);
          width: 100%;
          height: 100%;
          &.color-purple {
            background: linear-gradient(to bottom, #68689b 0%, #302f68 100%);
            .info-block-content {
              transition: all 0.5s ease;
              padding: 28px 16px 0 180px;
              @include container-down {
                padding: 20px 30px;
              }
              @include tablet-down {
                &:nth-of-type(2) {
                  width: 100%;
                  height: 100%;
                  border-top: 3px solid $blue17;
                  display: block;
                  text-decoration: none;
                  cursor: pointer;
                  border-color: $purple;
                }
              }
              &:before {
                background: $purple3;
              }
              .date {
              }
              .center-line {
                background: $purple1;
              }
              .white-arrow {
                padding-bottom: 5px;
                font-weight: 300;
                line-height: 1.23;
                span {
                  top: 4px;
                }
              }
              p {
                color: $white;
                margin-bottom: 34px;
                @include tablet-down {
                  margin-bottom: 6px;
                }
              }
              &:hover {
                transition: all 0.5s ease;

                &:before {
                  transition: all 0.5s ease;
                  opacity: 1;
                  z-index: 1;
                }
                .date {
                  z-index: 2;
                }
                .center-line {
                  z-index: 2;
                }
                .white-arrow {
                  z-index: 2;
                  span {
                    z-index: 2;
                    right: -16px;
                  }
                }
                p {
                  z-index: 2;
                  position: relative;
                }
              }
            }
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
          }
          .info-block-content {
            transition: all 0.5s ease;
            padding: 28px 200px 0 30px;
            min-height: 184px;
            position: relative;
            width: 100%;;
            display: inline-block;
            @include container-down {
              padding: 20px 30px;
            }

            &:before {
              content: "";
              z-index: -1;
              left: 0px;
              top: 0px;
              bottom: 0px;
              right: 0px;
              position: absolute;
              opacity: 0;
              transition: all 0.3s ease 0s;
              background: linear-gradient(rgb(62, 142, 162) 0%, rgb(42, 109, 146) 100%);
            }
            .date {
              color: $white;
              display: block;
              font-size: 22px;
              font-weight: 100;
              position: relative;
              @include tablet-down {
                font-size: 20px;
              }
            }
            .center-line {
              position: relative;
              width: 100%;
              margin: 5px 0 20px;
              width: 108px;
              left: 0;
              height: 1px;
              background: $sky2;
              opacity: .8;
              display: block;
              @include tablet-down {
                font-size: 20px;
                margin-bottom: 20px;
              }
            }
            .white-arrow {
              font-weight: 300;
              line-height: 1.23;
              span {
                top: 0;
              }
            }
            p {
              color: $white;
            }
            &:hover {
              transition: all 0.5s ease;
              &:before {
                transition: all 0.5s ease;
                opacity: 1;
                z-index: 1;
              }
              .date {
                z-index: 2;
              }
              .center-line {
                z-index: 2;
              }
              .white-arrow {
                z-index: 2;
                span {
                  z-index: 2;
                  right: -16px;
                }
              }
              p {
                z-index: 2;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}


