.related-resources {

  @include container-up {
    //padding: 56px 0 54px;
  }
  &.pt-100 {
    @include container-up {
      //padding: 100px 0 54px;
    }
  }
  &.blog {
    @include container-up {
      //padding: 70px 0 54px;
    }
  }
  h3 {
    color: $white;
  }
  .full-row {
    .col-wrap {
      cursor: pointer;
      padding: 0 4px 0 0;
      @include desktop-down {
        padding: 0 0 4px 0;
      }
      &:last-child {
        padding: 0;
        @include desktop-down {
          padding: 0;
        }
      }
      .bg-img {
        width: 100%;
        height: 443px;
        position: relative;
        @include phablet-down {
          height: auto;
        }
        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $black;
          opacity: .6;
          transition: all 0.5s ease;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .main-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 0;
        width: 100%;
        padding: 28px 20px 0;
        text-align: center;
        z-index: 1;

        .icon-img {
          margin: 0 auto 30px;
          max-width: 90px;
          max-height: 91px;
          height: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .content {
          h3 {
            color: $white;
            font-weight: 300;
            font-size: 22px;
          }
        }
        @include phablet-down {
          padding: 0 20px 0;
          .icon-img {
            margin: 0 auto 10px;
            max-width: 80px;
            max-height: 81px;
          }
          .content {
            h3 {
              font-weight: 300;
              font-size: 20px;
            }
          }
        }
      }

      &:hover {
        .bg-img {
          z-index: 1;
          transition: all 0.5s ease;
          &:before {
            opacity: .7;
            transition: all 0.5s ease;
          }
        }
      }

      &.cover-two-card {
        width: 100%;
        .two-card {
          transition: all 0.5s ease;
          cursor: pointer;
          height: 240px;
          margin-bottom: 4px;
          width: 100%;
          opacity: 0.9;
          .cover-card {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 0;
            width: 100%;
            padding: 0 20px;
            text-align: center;
            z-index: 1;
            transition: all 0.4s ease;
            .cover-icon-img {
              margin: 0 auto 28px;
              max-width: 88px;
              max-height: 88px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: all 0.4s ease;
              }
            }
            .cover-content {
              h3 {
                color: $white;
                font-size: 22px;
                font-weight: 300;
              }
            }
          }

          &.color-purple {
            background: linear-gradient(to bottom, #67699b 0%, #303169 100%);
          }
          &.color-skyblue {
            margin-top: 4px;
            height: 200px;
            background: linear-gradient(to bottom, #4a97c3 0%, #1c6792 100%);
          }
          &:hover {
            transition: all 0.5s ease;
            opacity: 1;
            .cover-card {
              .cover-content {
                .link {
                  span {
                    right: -17px;
                  }
                }
              }
            }
          }
        }
      }
      @include phablet-down {
        &.cover-two-card {
          .two-card {
            .cover-card {
              .cover-icon-img {
                margin: 0 auto 10px;
                max-width: 70px;
                max-height: 70px;
              }
              .cover-content {
                h3 {
                  font-size: 20px;
                }
              }
            }
          }
        }

        .icon-img {
          margin: 0 auto 10px;
          max-width: 80px;
          max-height: 80px;
        }
        .content {
          h3 {
            font-weight: 300;
            font-size: 20px;
          }
        }
      }
    }
  }
}




