.resource-data {

  @include container-up {
    //padding: 110px 0 88px;
  }

  .full-row {
    width: 100%;
    margin: 0;
    .col-wrap {
      background-color: #E7EFF3;
      padding: 30px 50px;
      margin: 0 auto;
      @include phablet-down {
        padding: 20px;

      }

      h3 {
        text-align: center;
        color: #253746;
        font-size: 22px;
        font-weight: 300;
        padding-bottom: 40px;
      }
      h2, .h2 {
        text-align: center;
        background-color: #242a75;
        padding: 10px;
        color: #ffffff !important;
      }

      .form-group {
        padding-bottom: 0px;
        position: relative;

        @include tablet-down {
          width: 100%;
        }
        @include phablet-down {
          padding-bottom: 0px;
          width: 100%;
          &.download-btn {
            padding-top: 10px;
          }
        }
        &:last-child {
          padding-bottom: 0;
        }
        &.download-btn {
          padding-top: 10px;
        }
        &.state-hungary {
          .wrap {
            padding-bottom: 10px;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              float: left;
              margin-top: 6px;
              background-color: $white;
            }
            label {
              padding-left: 6px;
              max-width: 280px;
              width: 100%;
            }
          }
        }

        label {
          width: 100%;
          font-size: 15px;
          sup {

            color: red;
          }
        }

        .form-control {
          //padding: 0px;
          border-radius: 0;
          max-width: 400px;
          width: 100%;
          height: 40px;
          color: #253746;
          border: 1px solid #6f797b;
          font-size: 16px;
          @include tablet-down {
            width: 100%;
            max-width: 100%;
          }
          @include phablet-down {
            width: 100%;
            max-width: 100%;
          }

        }

        .jcf-hidden {
          max-width: 400px;
          height: 40px;
          width: 100%;
          border-radius: 0;
          border: 1px solid #6f797b;
          @include tablet-down {
            max-width: 100%;
            width: 100%;
          }
          @include phablet-down {
            max-width: 100%;
            width: 100%;
          }
        }
        #download {
          font-family: $font-families;
          line-height: 1.2;
          font-style: normal;
          font-size: 16px;
          padding: 0 21px 0 22px;
          color: $white;
          background: linear-gradient(to bottom, #5fb6f1 0, #357cb1 100%);
          border: 0;
          outline: none;
          cursor: pointer;
          text-decoration: none;
          font-weight: 400;
          margin: 0 auto;
          text-transform: uppercase;
          max-width: 200px;
        }

      }
    }
    h1 {
      margin-bottom: 20px;
    }
  }

  .data-wrapper {
    // width: fit-content;
    width: 100%;
    margin: 0 auto;
    .heading {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 35px;
      margin-bottom: 74px;
      //border-bottom: 1px solid $light-sky;

      @include phablet-down {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      .img-wrapper {
        max-width: 275px;
        margin-right: 54px;
        @include phablet-down {
          margin: 0 0 20px 0;
        }
        img {
          //border: 1px solid $grey3;
        }
      }

      h2, h1 {
        color: $light-bluewhile;
        //font-size: 45px;
        width: calc(100% - 330px);
        //margin-left: 54px;
        align-self: center;
        font-weight: 300;
        /* (max-width:991px) */
        @include desktop-down {
          //font-size: 40px;
        }
        /* (max-width:767px) */
        @include tablet-down {
          //font-size: 35px;
        }
        /* (max-width:595px) */
        @include phablet-down {
          //font-size: 25px;
          width: 100%;
          margin: 20px 0 0;
        }
      }
    }
    .content {

      ol {
        padding-left: 20px;
      }

      .embed-responsive-16by9 {
        margin-bottom: 30px;
      }

      h2 {
        color: $light-bluewhile;
        //margin: 0 0 1.64em;
        margin-bottom: 20px;
      }

      p, ul {
        margin: 0 0 1.64em;
        color: $light-bluewhile;
        strong {
          //font-weight: 300;
        }
      }
      ul, a {
        font-size: 22px;
        font-weight: 100;
        @include phablet {
          font-size: 20px;
        }
        @include phone {
          font-size: 16px;
        }
        @include phone-down {
          font-size: 14px;
        }
      }
      ul {
        padding-left: 40px;
        @include phone-down {
          padding-left: 30px;
        }
      }
      a {
        color: $blue5;
        &:hover {
          color: $blue5;
        }
      }

      .video-responsive + a {
        display: block;
        margin-top: 5px;
      }
      @media screen and (max-width: $desktop_width) {
        .video-responsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;

          iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
          }
        }
      }

    }
  }
}
