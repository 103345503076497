.new-resource-slider {
  padding: 60px 0 118px;
  .heading {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    h2 {
      font-weight: 600;
    }
  }
  .wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1140px;
    margin: 0 auto;
    .col-three {
      width: calc(33.33% - 60px);
      margin: 0 30px;
      padding: 0 16px;
      min-height: 460px;
      position: relative;
      @include desktop-mid {
        width: calc(50% - 60px);
      }
      @include desktop-down {
        width: calc(50% - 60px);
      }
      @include tablet-down {
        min-height: 440px;
      }
      @include phablet-down {
        min-height: 360px;
        width: 100%;
      }
      @include phone-down {
        min-height: 380px;
      }
      .img-wrap {
        width: 100%;
        height: 180px;
        max-width: 320px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .content-wrap {
        margin-bottom: 20px;
        //text-align: center;
        h3 {
          color: $blue21;
          margin: 10px 0;
          font-weight: 600;
        }
        p {
          margin: 10px 0;
          font-weight: 300;
        }
      }
      .btn-wrap {
        position: relative;
        //text-align: center;
        a {
          &:hover {
            background: #00BBDD;
            color: #1A2856 !important;
          }
          @include tablet-down {
            font-size: 18px !important;
          }
        }
      }
    }
    .slick-arrow {
      display: block !important;
      position: absolute;
      top: 20%;
      bottom: 25%;
      margin: auto;
      background: transparent;
      font-size: 0;
      z-index: 1;
      outline: none;
      cursor: pointer;
      &.slick-next {
        width: 50px;
        height: 50px;
        right: -50px;
        background: url('/themes/thalesesecurity/templates/assets/images/new-resource-slider/slider_arrow.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
      }
      &.slick-prev {
        width: 50px;
        height: 50px;
        left: -50px;
        background: url('/themes/thalesesecurity/templates/assets/images/new-resource-slider/slider_arrow.svg');
        background-repeat: no-repeat;
        background-size: 100%;
      }
      &.slick-disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -60px;
      width: 100%;
      text-align: center;
      padding: 0;
      margin: 0;
      cursor: pointer;
      li {
        display: inline-block;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #f3f4f4;
        margin: 0 9px 0 8px;
        padding: 0;
        button {
          display: none;
        }
        &.slick-active {
          background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%);
        }
      }
    }
  }
}
