.col-three-grid {
  //border-top: 3px solid $light-sky;
  @include container-up {
    //padding: 58px 0 70px;
  }

  .title-wrap {
    max-width: 800px;
    margin: 0 auto 70px;
    text-align: center;
    h2,.h2 {
      color: $light-bluewhile;
      font-size: 30px;
      font-weight: 400;
    }
    @include tablet-down {
      margin-bottom: 40px;
      h2,.h2 {
        font-size: 26px;
      }
    }
  }
  .content-wrap {
    display: block;
    margin-left: -12px;
    margin-right: -12px;
    @include tablet-down {
      margin-left: 0;
      margin-right: 0;
    }
    .col-wrap {
      width: calc(33.33% - 24px);
      margin: 0 12px 21px;
      @include desktop-down {
        width: calc(50% - 24px);
        margin: 0 12px 21px;
      }
      @include tablet-down {
        margin: 0 auto 30px;
        width: 100%;
        margin-bottom: 20px;
        max-width: 767px;
      }
      a {
        transition: all 0.5s ease;
        background: linear-gradient(to bottom, #5dbdf4 0%, #2481b8 100%);
        width: 100%;
        display: block;
        text-decoration: none;
        min-height: 400px;
        height: 100%;
        padding: 64px 20px 20px;
        @include container-down {
          padding: 40px 10px 20px;
          min-height: 340px;
        }
        @include tablet-down {
          min-height: 300px;

        }
        .content {
          transition: all 0.5s ease;
          text-align: center;
          @include tablet-down {
            padding: 0;
          }
          h2,.h2 {
            transition: all 0.5s ease;
            line-height: 1.2;
            margin-bottom: 0;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 24px;
            color: $white;
            position: relative;
            @include tablet-down {
              font-size: 20px;
            }
          }

          .center-line {
            position: relative;
            max-width: 90px;
            overflow: hidden;
            width: 100%;
            margin: 30px auto 20px;
            height: 2px;
            background: $white;
            display: block;
            @include tablet-down {
              font-size: 20px;
              margin-bottom: 20px;
            }
          }
          p {
            transition: all 0.5s ease;
            font-size: 22px;
            line-height: 1.5;
            font-weight: 100;
            color: $white;
            margin-bottom: 25px;
            height: 132px;
            overflow: hidden;
            @include tablet-down {
              font-size: 20px;
              line-height: 1.2;
            }
          }
          .grid-arrow {
            transition: all 0.5s ease;
            opacity: 1;
            span {
              top: 0;
              right: -8px;
            }
          }
        }
        &:hover {
          transition: all 0.5s ease;
          background: linear-gradient(to bottom, #4a97c3 0%, #1c6792 100%);
          .content {
            transition: all 0.5s ease;
            h2,.h2 {
              transition: all 0.5s ease;
            }
            p {
              margin-bottom: 20px;
            }
            .grid-arrow {
              opacity: 1;
              transition: all 0.5s ease;
              span {
                right: -14px;
              }
            }
          }
        }
      }
    }
  }

  &.col-three-block {

    @include container-up {
      padding: 80px 0 70px;
    }

    .title-wrap {
      max-width: 800px;
      margin: 0 auto 68px;
      text-align: center;
      h2 {
        color: $light-bluewhile;
        font-size: 30px;
        font-weight: 300;
      }
      @include tablet-down {
        margin-bottom: 40px;
        h2 {
          font-size: 26px;
        }
      }
    }

    .content-wrap {

      .col-wrap {

        a {
          transition: all 0.5s ease;
          background: linear-gradient(to bottom, #67699b 0%, #303169 100%);
          width: 100%;
          display: block;
          text-decoration: none;
          height: 100%;
          min-height: 300px;
          padding: 64px 20px 48px;
          @include container-down {
            padding: 40px 10px;
          }
          @include tablet-down {
          }
          .content {
            transition: all 0.5s ease;
            text-align: center;
            position: relative;
            @include tablet-down {
              padding: 0;
            }
            h2 {
              transition: all 0.5s ease;
              line-height: 1.2;
              margin-bottom: 0;
              font-weight: 300;
              text-transform: uppercase;
              font-size: 24px;
              color: $white;
              position: relative;
              @include tablet-down {
                font-size: 20px;
              }
            }
            .center-line {
              position: relative;
              max-width: 90px;
              overflow: hidden;
              width: 100%;
              margin: 30px auto 20px;
              height: 2px;
              background: $white;
              display: inline-block;
              @include tablet-down {
                font-size: 20px;
                margin-bottom: 20px;
              }
            }
            p {
              transition: all 0.5s ease;
              font-size: 22px;
              line-height: 1.5;
              font-weight: 100;
              color: $white;
              margin-bottom: 20px;
              @include tablet-down {
                font-size: 20px;
                line-height: 1.2;
              }
            }
            .grid-arrow {
              transition: all 0.5s ease;
              opacity: 0;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -50px;
              span {
                top: 0;
                right: -8px;
              }
            }
          }

          &:hover {
            transition: all 0.5s ease;
            background: linear-gradient(to bottom, #3f3f69 0%, #2d2c61 100%);
            .content {
              transition: all 0.5s ease;
              h2 {
                transition: all 0.5s ease;
              }
              p {
                // margin-bottom: 20px;
              }
              .grid-arrow {
                opacity: 1;
                bottom: -45px;
                transition: all 0.5s ease;
                span {
                  right: -14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
