// Bootstrap integration
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
@import 'common/variables';
@import 'common/button';
// Plugins
@import 'plugins/animate';
@import 'plugins/slick';
// custom scss
@import "custom";

body {
  margin: 0;
  //padding-top: 35px;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100%;
  background: $white;

  @include desktop-down {
    padding-top: 91px;
  }


  @import 'common/default';
  @import 'common/button';
  @import 'section/accordian';
  @import 'section/bread-crumb';
  @import 'section/col-three-resources';
  @import 'section/resource-data';
  @import 'section/tabs-section';
  @import 'section/svg-content';
  @import 'section/leadership-grid';
  @import 'section/masonry-grid';
  @import 'section/3d-rotator-frame';
  @import 'section/info-section';
  @import 'section/resource-grid';
  @import 'section/col-four-row';
  @import 'section/col-three-counter';
  @import 'section/assessment-form';
  @import 'section/value-calculator';
  @import 'section/comprehensive-calculator';
  @import 'section/charts';
  @import 'section/hero-slider';
  @import 'section/second-level-banner';
  @import 'section/intro';
  @import 'section/col-three-icon';
  @import 'section/quote-slider';
  @import 'section/logo-block';
  @import 'section/related-resources';
  @import 'section/newsletter';
  @import 'section/footer-cta';
  @import 'section/image-with-col';
  @import 'section/third-level-banner';
  @import 'section/col-three-slider';
  @import 'section/content-detail';
  @import 'section/col-three-image-slider';
  @import 'section/intro-with-image';
  @import "section/col-four-row";
  @import "section/overview";
  @import "section/col-three-grid.scss";
  @import "section/col-three-grid.scss";
  @import "section/two-col-row.scss";
  @import "section/user-login.scss";
  @import "section/card-three-logo-slider.scss";
  @import "section/tab-with-text";
  @import "section/customers-grid.scss";
  @import "section/video-quote-slider";
  @import "section/legal-block-one.scss";
  @import "section/legal-block-two.scss";
  @import "section/legal-block-three.scss";
  @import "section/sticky-legal-block.scss";
  @import "section/page-links";
  @import "section/content-row";
  @import "section/col-three-cards";
  @import "section/product-slider";
  @import "section/col-three-block";
  // @import "section/col-two-block";
  @import "section/search";
  @import "section/blog-article";
  @import "section/accordion-ibm";
  @import "section/table-with-logo";
  @import "section/subscribe-with-icon";
  @import "section/managment-charts";
  @import "section/management-info";
  @import "section/sticky-nav";
  @import "section/calc-banner";
  @import "section/cost-analysis";
  @import "section/access-management";
  @import "section/partner-section";
  @import "section/news-release";
  @import "section/news-coverage";
  @import "section/map";
  @import "section/gdpr";
  @import "section/contact-form";
  @import "section/key-finding";
  @import "section/half-banner";
  @import "section/data-banner";
  @import "section/leap-banner";
  @import "section/video-overlay";
  @import "section/video";
  @import "section/image-grid";
  @import "section/icon-with-content";
  @import "section/col-three-card";
  @import "section/info-block";
  @import "section/careers-detail";
  @import "section/pagination-section";
  @import "section/help-block";
  @import "section/set-section";
  @import "section/event-section";
  @import "section/content-with-video";
  @import "section/tab-holder-card";
  @import "section/col-three-location";
  @import "section/content-key-detail";
  @import "section/sub-header";
  @import "section/3d-rotator-frame";
  @import "section/global-gdpr";
  @import "section/contact-us-form";
  @import "section/ast-banner";
  @import "section/search-result";
  @import "section/introduction-section";
  @import "section/quiz-with-image";
  @import "section/introduction-counter";
  @import "section/introduction-counter-iot";
  @import "section/calendar";
  @import "section/thanks-intro";
  @import "section/thanks-banner";
  @import "section/cal-thanks-banner";
  @import "section/cal-thanks-intro";
  @import "section/first-level-banner";
  @import "section/new-resource-slider";
  @import "section/col-six-grid";
  @import "section/resource-tabs";
  @import "section/feature-slider";

}


body {

  &.node-type-solution,
  &.node-type-product {
    .image-with-col {

      //padding: 15px 0px;
      .main-content {
        width: 100%;
      }
    }
  }

  .dialog-off-canvas-main-canvas {
    >.sub-header.basic-page {
      display: none;
    }
  }
}

.ua-ie-11 {
  header {
    .logo {
      height: 48px;

      a {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.menu-item {
  ol {
    width: 100%;
    display: block;

    li {
      padding-left: 20px;

      ol {
        width: 100%;
        padding-left: 0;

        li {
          padding-left: 30px !important;

          ol {
            width: 100%;
            padding-left: 20px;

            li {
              padding-left: 40px !important;

              ol {
                width: 100%;
                padding-left: 40px;

                li {
                  padding-left: 50px !important;

                  ol {
                    width: 100%;
                    padding-left: 60px;

                    li {
                      padding-left: 60px !important;

                      ol {
                        width: 100%;
                        padding-left: 80px;

                        li {
                          padding-left: 70px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#block-thalesesecurity-local-tasks {
  ul {
    position: fixed;
    top: 180px;
    z-index: 99;

    li {
      display: block;
      float: left;

      &:last-of-type {
        display: none;
      }

      a {
        display: inline-block;
        color: $blue;
        padding: 10px 15px;
        background: $white;

        &:hover {
          background: $orange;
          color: $white;
        }
      }

      &:nth-last-child(2) {
        display: none;
      }
    }
  }
}

#toolbar-bar {
  a {
    font-size: inherit;
  }
}

.ajax-progress.ajax-progress-fullscreen {
  background-image: url(/themes/thalesesecurity/templates/dist/images/ajax-loading.gif) !important;
  background-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 62px;
  background-size: contain;
}

.ua-ie-11 {
  .ast-banner {
    .bg-img {
      img {
        position: absolute;
        top: -120px;
        height: auto;
      }
    }

    .v-middle-inner {
      .content-wrapper {
        padding-top: 100px;
      }
    }
  }

  .calc-banner {
    .bg-img {
      img {
        height: auto;
      }
    }
  }

  .access-management {
    .asat-box {
      .asat-cbtn {
        box-shadow: 0 0 22px #cccccc !important;

        &:hover {
          box-shadow: 0 0 25px #cccccc !important;
        }
      }
    }
  }
}

.ua-edge {
  .introduction-counter-iot {
    .row-wrapper {
      .big-col {
        .full-rows {
          .row-outer {
            .counter {
              span {
                padding: 8px 0 0;
              }

              sup {
                top: -9px;
              }
            }
          }
        }
      }
    }
  }
}

.ua-android {
  .introduction-counter-iot {
    .row-wrapper {
      .big-col {
        .full-rows {
          .row-outer {
            .counter {
              span {
                vertical-align: -webkit-baseline-middle;
              }

              sup {
                top: -9px;
              }
            }
          }
        }
      }
    }
  }
}

.ua-ie-11 {
  .quote-slider.quote-slider-item {
    .slider-wrap {
      .slider {
        .icon-with-content {
          .logo-img {
            transform: translateX(0px);
            margin: 0;
          }
          .content {
            text-align: justify;
          }
        }
      }
    }
  }
}

img {
  display: inline-block;
}
