.introduction-counter-iot {
  padding: 72px 0 35px;
  background-color: #f5f5f5;

  @include media-breakpoint-down(xs) {
    padding: 40px 0 35px;
  }

  strong {
    font-weight: 700 !important;
  }
  p {
    font-size: 16px;
    font-weight: 400;

    &.title {
      @include media-breakpoint-up(lg) {
        font-size: 19px;
      }
    }
  }
  .btn-pink {
    margin-right: 6px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    background-color: $MidnightBlue;
    //background-color: #b90b67;
    color: white;
    border: solid 1px $MidnightBlue;
    //border: solid 1px #b90b67;
    padding: 6px 13px 6px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    @include container-down {
      font-size: 14px;
    }
    @include phablet-down {
      margin: 0;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 5px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
    &:hover {
      text-decoration: none;
      background-color: transparent;
      color: $MidnightBlue;
      //color: #b90b67;
    }
    .new {
      font-size: 0.7em;
      font-weight: bold;
      padding: 1px 4px 3px;
      display: inline-block;
      line-height: 12px;
      margin-right: 9px;
      background-color: #7dc3eb;
      color: $white;
      position: relative;
      top: -3px;
    }
    .new-green {
      font-size: 0.7em;
      font-weight: bold;
      padding: 4px 4px 3px;
      display: inline-block;
      line-height: 1;
      margin-right: 9px;
      background-color: $Midlightblue;
      color: $white;
      position: relative;
      top: -1px;
      @include tablet-down  {
        padding: 4px;
      }
    }
  }
  .title {
    max-width: 768px;
    margin: 0 auto 25px;
    text-align: center;
    color: #000;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.6;

    @include media-breakpoint-up(lg) {
      padding-right: 30px;
      font-size: 19px;
    }
    @include media-breakpoint-down(xs) {
      margin: 0 auto 25px;
    }

    strong {
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
  }

  .row-wrapper {
    @include media-breakpoint-up(lg) {
      padding-top: 29px;
    }
    .big-col {
      padding: 0 31px 0 27px;
      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
      .full-rows {
        padding: 49px 13px 34px;
        .row-outer {
          font-size: 0;
          .counter {
            width: 40%;
            display: inline-block;
            vertical-align: top;

            @include media-breakpoint-down(lg) {
              width: 100%;
              margin-bottom: 22px;
            }
            @include phablet-down {
              width: 100%;
              margin-bottom: 20px;
              text-align: center;
            }

            span {
              display: inline-block;
              font-size: 61px;
              line-height: 1;
              vertical-align: top;
              padding:0;
              min-width: 72px;
              @include phablet-down {
                font-size: 40px;
              }
              @include media-breakpoint-down(xs) {
                font-size: 50px;
                padding: 4px 0;
                //vertical-align: -webkit-baseline-middle;
              }

              &.number {
                padding: 7px;
                margin-left: 10px;
                background-color: $Midlightblue;
                color: $white;
                margin-bottom: 10px;
                font-weight: 600;
              }
            }
            sup {
              top: -23px;
              font-size: 26px;
              font-weight: 700;

              @include media-breakpoint-down(xs) {
                top: -18px;
                font-size: 20px;
              }
            }
          }
          .content {
            display: inline-block;
            width: 60%;
            padding-left: 20px;
            @include phablet-down {
              width: 100%;
              padding: 0;
              text-align: center;
            }
            @include media-breakpoint-down(lg) {
              width: 100%;
              padding: 0;
            }
            p {
              @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
              }
            }
          }
        }
        
        .detail {
          //margin-top: 16px;
          @include phablet-down {
            text-align: center;
          }
          @include media-breakpoint-up(lg) {
            margin-top: 0;
          }
          .link{
            @include phablet-down {
              padding: 0;
            }
          }
        }
      }
    }
    .small-col {

      @include media-breakpoint-up(lg) {
        flex: 0 0 46%;
        max-width: 45.4%;
      }
      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
      .report-cta {
        .report-wrapper {
          margin-top: 47px;
          .img-wrap {
            padding: 0 20px;
            @include media-breakpoint-down(md) {
              margin-bottom: 22px;
            }

            .img-container {
              max-width: 165px;
             @include phablet-down {
              margin: 0 auto;
              }
              img {

              }
            }
          }
          .text-wrap {
            padding: 0 20px;
            @include phablet-down {
              text-align: center;
            }
              h6 {
              color: $black;
            }
          }
        }
        .links {
          padding: 24px 33px;
          border-bottom: 6px solid white;
          margin-top: 26px;
          @include phablet-down {
            padding: 20px;
            text-align: center;
          }
          h3 {
            color: $MidnightBlue;
            margin-bottom: 18px;
          }

          a {
            position: relative;
            font-size: 16px;
            display: block;
            color: $MidnightBlue;
            margin-bottom: 12px;

            &::before {
              content: '';
              position: absolute;
              top: -8px;
              height: 1px;
              width: 100%;
              background-color: $white;
            }
          }
        }
      }
    }
  }

  .bg-white {
    background-color: #fff;
  }
  .bg-light-gray {
    background-color: #eeeeee;
  }
  .bg-dark-gray {
    background-color: #dddddd;
  }

  .quiz {
    padding-bottom: 15px;
    .text-wrap {
      padding-bottom: 0 !important;
    }

    .percent-wrap {
      padding: 2px 34px 20px !important;
      @include media-breakpoint-down(xs) {
        padding: 2px 29px 20px !important;
      }
      .item {
        @include media-breakpoint-down(xs) {
          padding: 0 5px;
        }
        .transbtn {
          transition: 0.2s;
          font-size: 16px;
          font-weight: 800;
          cursor: pointer;
          width: 100%;
          display: block;
          border: 1px solid;
          border-radius: 4px;
          text-align: center;
          // font-family: $font-familie-dinpro;
          color: #3C3C3C;
          border: 1px solid #888888;
          padding: 6px 4px 4px;

          &:hover {
            background-color: #888888;
            color: $white;
          }
        }
      }
    }
    .correct, .incorrect {
      display: none;
    }
    .col-wrap {
      padding: 0 34px;
      strong {
        color: #3C3C3C;
      }
      p {
        font-size: 16px;
        color: #3C3C3C;
      }

      .transbtn {
        padding: 5px 0 8px;
        width: 100%;
        display: inline-block;
        font-weight: bold;
        border: 1px solid #3C3C3C;
        border-radius: 5px;
        text-align: center;
        transition: all 0.3s;
        text-decoration: none;
        //color: #6C286B;
        color: $MidnightBlue;
        outline: 0;
        font-size: 16px;

        &:hover {
          background-color: #888888;
          border-color: #888888;
          color: $white;
        }
      }

      &.correct {
        display: none;
      }
      &.incorrect {
        display: none;
      }
    }

  }

  h2 {
    text-align: center;
    padding: 13px 0 12px;
    text-transform: uppercase;
    margin: 0 !important;
    background-color: $MidnightBlue;
    //background-color: #6c286b;
    color: $white;
    font-weight: 400;
    position: relative;

    @include media-breakpoint-up(lg) {
      font-size: 27px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -20px;
      width: 40px;
      border: 20px solid transparent;
      border-top-color: $MidnightBlue;
      border-bottom: 1px;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .outer {
    display: block;
    overflow: hidden;
    padding: 0 12px;

    @include media-breakpoint-up(md) {
      padding-top: 25px;
    }
    .col-7 {
      float: left;
      width: 60%;
      padding: 0 33px 0 15px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .image {
        display: inline-block;
        vertical-align: middle;
      }
      .col-wrapper {
        padding: 0 24px;
        margin-top: 41px;

        @include media-breakpoint-down(md) {
          padding: 0;
        }
        .counter {
          text-align: center;
          padding: 22px 14px 0 15px;
          p {
            line-height: 1.6;
            padding: 0 0 1em;
            color: #3C3C3C;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 26px;
            font-weight: 400;
            text-align: left;
          }
        }
      }
      .number {
        display: inline-block;
        vertical-align: middle;
        h4 {
          color: $MidnightBlue;
          font-weight: bold;
          font-size: 3.5em;
          line-height: 48px;
          letter-spacing: -2px;

          @include media-breakpoint-down(md) {
            font-size: 48px;
          }
        }
      }
    }
    .detail {
      padding: 0 40px 33px;

      @include media-breakpoint-down(md) {
        padding: 0 16px 33px;
      }
      p {
        line-height: 1.6;
        padding: 0 0 1em;
        color: #3C3C3C;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }
    .col-5 {
      float: left;
      width: 40%;
      padding: 0 25px 0 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .report-cta {
        padding-bottom: 36px;
        margin-bottom: 19px;

        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }

      .row {
        padding: 42px 34px 20px;
        .content {
          @include media-breakpoint-down(md) {
            padding: 0;
          }
        }
        h6 {
          line-height: 1.6;
          padding: 0 0 1em;
          color: #3C3C3C;
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: 400;
          text-transform: inherit;
        }
      }

      .link {
        width: 100%;
        text-align: center;
        padding: 0;
        a {
          text-transform: uppercase;
          @include media-breakpoint-down(md) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

}




.reportsection {
  padding: 74px 0;
  @include desktop-down {
    padding: 30px 0;
  }

  strong {
    font-weight: 700 !important;
  }
  h2, h3, h4, h5, h6, p, a {
    // font-family: dinpro;
  }
  .row{
  @include desktop-down {
    align-items: center;
   }
  .content{
   @include phablet-down {
   text-align: center;
    }
    h5 {
      font-size: 1.2em;
      line-height: 1.6;
      padding: 0 0 1em;
      color: #3C3C3C;
      font-weight: 400;
      @include desktop-down {
        font-size: 1em;

      }
    }
  }
  .align-items-center{
    @include phablet-down {
      text-align: center;
    }
  }
  .icon-img{
    @include phablet-down {
      padding: 20px 0;
    }
    img{
      @include phablet-down {
        height: 100%;
      }
    }
  }
  .inner-content{
    padding: 0 20px;
    h5 {
      font-size: 1.2em;
      line-height: 1.6;
      padding: 0 0 1em;
      color: #3C3C3C;
      font-weight: 400;
      @include phablet-down {
        font-size: 1em;
      }
    }
    .bigbtn {
      font-size: 1.2em;
      padding: 7px 15px 7px;
      text-transform: uppercase;
      display: inline-block;
      background-color: $Midlightblue;
      border-radius: 5px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      border: none;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
        // background-color: #53D8F0;
      }
      @include phablet-down {
        font-size: 1em;
      }
    }
  }
}
}
