.breadcrumbs{
  overflow: visible;

  @include tablet-down {

    display: none;
  }
  &.b-style-guide{
    ul{
      position: initial;
    }
  }
  ul{
    position: absolute;
    z-index: 9;
    top: 20px;
    display: flex;
    width: calc(100% - 20px);
    list-style: none;
    flex-wrap: wrap;
    li{
      margin: 0 18px;
      font-size: 16px;
      color: $grey2;
      position: relative;
      font-weight: 300;
      &:after{
        content: ">";
        position: absolute;
        right: -22px;
        top:0;
      }
      &:nth-last-child(1){
        &:after{
          display: none;
        }
      }
      &:nth-child(1){
          margin-left: 0;
      }
      a{
        position: relative;
        color: $grey2;
        font-weight: 300;
        font-size: 16px;
        &:hover{
          text-decoration: none;
          &:before{
            opacity: 1;
            bottom: 0;
          }
        }
        &:before{
          content: '';
          position: absolute;
          bottom: -6px;
          opacity: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: $light-bluewhile;
          -webkit-transition: all linear 0.3s;
          transition: all linear 0.3s
        }
      }
    }
  }
}
