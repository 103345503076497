
.search-result {
  .full-row {
    margin: 0;
    height: 100%;
    padding: 10px 0;
    background: $white2;
    .col-wrap {
      max-width: 23%;
      padding: 0 0 0 10px;
      @include desktop-down {
        max-width: 50%;
      }
      @include phone-down {
        max-width: 100%;
        padding-bottom: 30px;
      }
      .category-box {
        margin: 0 auto;
        max-width: 380px;
        text-align: center;
        position: relative;
        @include phone-down {
          max-width: 300px;
        }
        &.show {
          span {
            &:after {
              transform: rotate(-136deg);
              top: 58%;
            }
          }
          .option-category {
            border: 1px solid $white3;
          }
        }
        span {
          position: relative;
          max-width: 380px;
          background: white;
          padding: 12px 14px;
          display: block;
          color: $light-bluewhile;
          font-size: 15px;
          font-weight: 300;
          margin: 0 auto;
          text-align: left;
          border: 1px solid $white3;
          cursor: pointer;
          a {
            font-size: 14px;
            color: $light-bluewhile;
            &:hover {
              text-decoration: none;
            }
          }
          &:before {
            content: '';
            position: absolute;
            text-align: center;
            background: $white4;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 50%;
            right: 10px;
            margin: -10px 0 0;
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin: -6px 0 0 -4px;
            right: 16px;
            width: 8px;
            height: 8px;
            border: solid $black4;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }
          @include phone-down {
            max-width: 300px;
          }
        }
        .option-category {
          border: 1px solid $white3;
          list-style: none;
          text-align: left;
          background: white;
          position: absolute;
          z-index: 1;
          width: 100%;
          display: none;
          height: 265px;
          overflow: scroll;
          padding-left: 0;
          li {
            font-size: 18px;
            font-weight: 100;
            color: $grey11;
            margin: 0;
            padding: 7px 20px;
            cursor: pointer;
            a {
              font-size: 18px;
              color: $grey11;
            }
            &.selected {
              color: $blue11;
              background: $whitesmoke;
              font-weight: 400;
            }
          }
        }
      }
      &.col-search {
        background-color: $white2;
        display: flex;
        max-width: 53%;
        align-items: center;
        width: 100%;
        @include desktop-down {
          padding: 15px 30px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 25px;
        }
        @include tablet-down {
          display: block;
        }
        @include phone-down {
          padding: 10px;
        }
        .input-box {
          width: 100%;
          position: relative;
          display: flex;
          max-width: 100%;
          @include container-down {
            padding-left: 0;
            max-width: 358px;
            margin-left: 50px;
          }
          @include desktop-down {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
          }
          @include tablet-down {
            padding-left: 0;
            width: 100%;
            margin: 0 auto;
          }
          @include phone-down {
            max-width: 100%;
          }
          input {
            margin-right: 5px;
            font-size: 15px;
            padding: 0 12px;
            color: $light-bluewhile;
            border: 2px solid $white3;
            height: 45px;
            font-family: $font-families;
            background-color: $white;
            font-weight: 400;
            letter-spacing: 1.4px;
            outline: none;
            max-width: 100%;
            width: calc(100%);
            box-sizing: border-box;
            appearance: none;
            border-radius: 0;
            @include phone-down {
              max-width: 260px;
              margin-right: 3px;
            }
          }
          input::placeholder {
            font-family: $font-families;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.21;
            letter-spacing: normal;
            color: $white3;
          }
          button {
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: middle;
            background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
            background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
            background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
            width: 100%;
            text-decoration: none;
            position: relative;
            z-index: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
            height: 45px;
            padding: 0px 0;
            max-width: 107px;
            border: 0;
            cursor: pointer;
            color: $white;
            font-size: 18px;
            line-height: 1.38;
            letter-spacing: normal;
            font-family: $font-families;
            overflow: hidden;
            @include phone-down {
              max-width: 75px;
            }
            span {
              font-family: $font-families;
              position: relative;
              font-size: 16px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .filter-tag {
    display: none;
    padding: 0 10px;
    .single-item {
      display: inline-block;
      span {
        position: relative;
        font-size: 14px;
        padding: 3px 53px 4px 21px;
        border-radius: 16px;
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        text-transform: capitalize;
        background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
        background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
        background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
        line-height: 1.57;
        letter-spacing: -0.21px;
        &:after {
          content: "";
          position: absolute;
          top: 8px;
          right: 21px;
          width: 12px;
          height: 12px;
          background: url(/themes/thalesesecurity/templates/dist/images/close_icon_black.svg);
          background-size: cover;
        }
      }
    }
  }
  .clearAll {
    display: none;
    span {
      font-size: 14px;
      padding: 3px 10px 4px;
      cursor: pointer;
      text-transform: capitalize;
      display: inline-block;
      color: $light-bluewhile;
      line-height: 1.57;
      letter-spacing: -0.21px;
    }
  }

  .views-exposed-form {
    display: none;
  }

  .item-list {
    margin-top: 30px;
    opacity: 0;
    ol {
      margin: 0;
      padding-left: 20px;
      li {
        list-style: decimal;
      }
    }
    ul {
      list-style: none;
      padding-left: 0px;
    }
    ol,
    ul {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      li {
        float: left;
        width: 100%;
        margin: 0 0 1.64em;
        font-size: 20px;
        padding-left: 0px;
        @include tablet-down {
          font-size: 16px;
        }
        .image-with-col {
          padding-top: 0;
          .container {
            padding: 0;
          }
        }

        a {
          font-size: 24px;
          line-height: 1.33;
          margin: 0 0 0.35em;
          @include tablet-down {
            font-size: 20px;
          }
        }
        p {
          margin-bottom: 0;
          @include tablet-down {
            font-size: 16px;
          }
          @include phone-down {
            font-size: 16px;
          }
        }
      }
    }
  }

  .pager {
    margin-top: 0;
    ul {
      display: block;
      li {
        display: inline-block;
        @include tablet {
          margin: 0 20px 0 0;
        }
      }
    }
    .pager__item {
      a {
        &:hover {
          color: $dark-bluewhile;
          transition: all ease-out .3s;
          span {
            color: $dark-bluewhile;
            transition: all ease-out .3s;
          }
        }
      }
    }

    .pager__item--next,
    .pager__item--last {
      a {
        position: relative;
        padding-right: 27px;
        &:after {
          content: '';
          position: absolute;
          display: block !important;
          right: 0;
          top: 4px;
          width: 18px;
          height: 18px;
          text-align: center;
          color: $blue20;
          /*  background: $blue15 url(/themes/thalesesecurity/templates/dist/images/chevron-thin-right.svg) no-repeat center;  */
          background-size: 10px auto;
          border-radius: 50%;
          font-size: 14px;
          line-height: 17px;
          padding: 0 0 0 2px;
          transition: all ease-out .3s;
        }
        span {
          color: $sky-light-blue;
          transition: all ease-out .3s;
        }
        &:hover {
          &:after {
            right: -7px;
          }
          span {
            color: $dark-bluewhile
          }
        }
      }
    }

    .pager__item--first,
    .pager__item--previous {
      a {
        position: relative;
        padding-left: 27px;
        &:after {
          content: '';
          position: absolute;
          display: block !important;
          left: 0;
          top: 4px;
          width: 18px;
          height: 18px;
          text-align: center;
          color: $blue20;
          /*  background: $blue15 url(/themes/thalesesecurity/templates/dist/images/chevron-thin-left.svg) no-repeat center;  */
          background-size: 10px auto;
          border-radius: 50%;
          font-size: 14px;
          line-height: 17px;
          padding: 0 0 0 2px;
          transition: all ease-out .3s;
        }
        span {
          color: $sky-light-blue;
          transition: all ease-out .3s;
        }
        &:hover {
          &:after {
            left: -7px;
          }
          span {
            color: $dark-bluewhile
          }
        }
      }
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $dark-bluewhile;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $dark-bluewhile;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $dark-bluewhile;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $dark-bluewhile;
  }

}
