.leadership-grid {
    background: $white6;
    @include container-up {
        padding: 100px 0 75px;
    }
    h2 {
        //font-size: 40px;
        text-align: center;
        color: $light-bluewhile;
        font-weight: 300;
        margin-bottom: 16px;
        /* (max-width:991px) */
        @include desktop-down {
            //font-size: 36px;
        }
        /* (max-width:767px) */
        @include tablet-down {
            //font-size: 30px;
        }
        /* (max-width:595px) */
        @include phablet-down {
            //font-size: 24px;
        }
        /*  (max-width:480px) */
        //@include phone-down {
        //  font-size: 14px;
        //}
    }
    .grid-wrapper {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        &:after {
            display: none;
        }
        .card {
            width: calc(33.33% - 14px);
            margin: 0 7px 10px;
            padding: 20px 21px 20px;
            text-align: center;
            display: block;
            position: relative;
            border: 0;
            //(max-width 1024px)
            @media screen and (max-width: $desktop_mid_width) {
                width: calc(50% - 15px);
            }
            //(max-width 768px)
            @media screen and (max-width: $tablet_width) {
                width: 100%;
                margin-bottom: 12px;
            }
            &:before,
            .cover {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transition: all ease 0.2s;
                background: $white;
            }
            .cover {
                background: transparent;
                z-index: 4;
            }
            &:hover {
                z-index: 4;
                &:before {
                    border: 1px solid $white3;
                    transform: scale(1.125, 1.26);
                    @media (max-width: 1285px) {
                        transform: scale(1.075);
                    }
                    @media (max-width: 1024px) {
                        transform: scale(1.06);
                    }
                    @media screen and (max-width: $tablet_width) {
                        transform: scale(1.04);
                    }
                }
            }
            .content-wrap {
                position: relative;
                z-index: 2;
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                .img-wrap {
                    width: 120px;
                    height: 120px;
                    overflow: hidden;
                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        border: 2px solid $white7;
                        object-fit: cover;
                        font-family: 'object-fit: cover, object-position: right;';
                    }
                }
                .inner-wrap {
                    width: calc(100% - 123px);
                    padding-left: 21px;
                    padding-top: 17px;
                    text-align: initial;
                    h3 {
                        font-family: Arial;
                        font-weight: 600;
                        font-size: 22px;
                        margin-bottom: 18px;
                        color: $light-bluewhile;
                        padding-bottom: 15px;
                        border-bottom: 1px solid $grey4;
                    }
                    p {
                        font-family: Arial;
                        font-size: 16px;
                        color: $blue4;
                        font-weight: 200;
                        line-height: 19px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .overlay-slider {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -10;
        display: block;
        background: rgba(31, 37, 53, 0.65);
        visibility: hidden;
        opacity: 0;
        transition: all ease-in-out 0.5s;
        transition-delay: 0.3s;
        &.open {
            visibility: visible;
            opacity: 100;
            z-index: 999;
        }
        .custom-controls {
            position: fixed;
            right: 18px;
            z-index: 99;
            top: 13px;
            @include tablet-down {
                top: auto;
                bottom: -12px;
            }
            span {
                background: $white-light;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                float: left;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 22px;
                &.slick-disabled {
                    visibility: hidden;
                    pointer-events: none;
                }
                &:nth-child(1) {
                    margin-right: 10px;
                }
                &:after {
                    font-family: 'icomoon' !important;
                    font-weight: normal;
                    font-style: normal;
                    position: relative;
                    color: $dark-blue;
                    font-size: 23px;
                }
                &.prev {
                    &:after {
                        content: "\e909";
                    }
                }
                &.next {
                    &:after {
                        content: "\e908";
                    }
                }
            }
        }
        .cross {
            position: absolute;
            top: -61px;
            right: -61px;
            width: 27px;
            height: 27px;
            cursor: pointer;
            z-index: 99;
            @include tablet-down {
                right: -14px;
                top: -17px
            }
            &:after,
            &:before {
                content: '';
                position: absolute;
                width: 3px;
                height: 18px;
                left: 13px;
                top: 4px;
                background: $white;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                @include tablet-down {
                    background: $black;
                }
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
        .data-box {
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
            max-width: 698px;
            background: $white;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            max-height: calc(100% - 40px);
            border: 20px solid $white;
            @include tablet-down {
                max-width: calc(100% - 40px);
            }
            .slider-wrapper {
                overflow-y: scroll;
            }
            .box-wrapper {
                padding: 20px 20px 0;
                @include tablet-down {
                    padding: 0;
                }
                p {
                    font-family: Arial;
                    font-size: 16px;
                    color: $blue4;
                    font-weight: 200;
                }
                .head-content-wrap {
                    position: relative;
                    z-index: 2;
                    overflow: hidden;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 40px;
                    .img-wrap {
                        width: 120px;
                        overflow: hidden;
                        height: 120px;
                        @include tablet-down {
                            display: none;
                        }
                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                            border: 2px solid $white7;
                            object-fit: cover;
                            font-family: 'object-fit: cover, object-position: right;';
                        }
                    }
                    .inner-wrap {
                        width: calc(100% - 120px);
                        padding-left: 20px;
                        @include tablet-down {
                            padding: 0;
                            width: 100%
                        }
                        h3 {
                            font-family: Arial;
                            font-weight: 600;
                            font-size: 22px;
                            margin-bottom: 18px;
                            color: $light-bluewhile;
                            padding-bottom: 15px;
                            border-bottom: 1px solid $grey4;
                        }
                        p {
                            margin-bottom: 5px;
                        }
                        .social-icon {
                            display: flex;
                            a {
                                display: inline-block;
                                width: 23px;
                                height: 23px;
                                border-radius: 50%;
                                background: $blue10;
                                position: relative;
                                margin-right: 11px;
                                text-align: center;
                                font-size: 15px;
                                &:hover {
                                    text-decoration: none;
                                }
                                &:after {
                                    font-family: 'icomoon' !important;
                                    font-weight: normal;
                                    font-style: normal;
                                    position: relative;
                                    color: $white;
                                }
                                &.linked-in {
                                    &:after {
                                        content: "\e901";
                                    }
                                }
                                &.twitter {
                                    &:after {
                                        content: "\e903";
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}