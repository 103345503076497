.content-key-detail {

  @include container-up {
    padding: 112px 0 18px;
  }
  @include desktop {
    padding-bottom: 55px;
  }
  @include phablet {
    padding-bottom: 55px;
  }
 

  .wrapper {
    width: 100%;
    h2{
      color: $light-bluewhile;
      font-weight: 300;
      padding-bottom: 21px;
      @include container-up {
        font-size: 30px;
      }
      @include phablet-down {
        text-align: center;
        font-size: 20px;
      }
    }
    .content-with-card{
      display: inline-block;
      padding-bottom: 30px;
      .card-link{
        float: right;
        width: 30%;
        text-align: center;
        @include tablet-down {
          width: 100%;
          padding-bottom: 20px;
          text-align: center;
          a{
            h4{
              font-size: 20px;
            }
          }
        }
        @include phablet-down {
          a{
            h4{
              font-size: 16px;
            }
          }
        }
        a{
          
          img{
          width: auto;
          height: auto;
          }
          h4{
            padding-top: 6px;
            font-size: 22px;
            font-weight: 100;
            color: $blue5;
          }
        }
      }
      .content {
        width: 70%;
        float: left;
        @include tablet-down {
          width: 100%;
        }
        @include phablet-down {
          width: 100%;
          text-align: center;
          float: none;
        }
       
        h4{
          font-size: 22px;
          font-weight: 200;
          color: $light-bluewhile;
          @include phablet-down {
            font-size: 16px;
          }
        }
        p {
          color: $light-bluewhile;
         
          a {
            font-size: 22px;
            font-weight: 100;
            @include phablet-down {
              font-size: 16px;
            }
            
          }
        }
        
      }

    }
   
  }

}






