.legal-block-one{
    background-color: $white2;
    @include container-up{
        padding: 106px 0 48px;
    }

    .title{
        max-width: 400px;
        margin: 0 auto 16px;
        text-align: center;
        h2{
        color: $light-bluewhile;
        font-size: 30px;
        font-weight: 300;
        line-height: 1.2;
        @include phablet-down {
            font-size: 24px;
           }
        }
    }
    .full-row{
        max-width: 824px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 46px;
        @include desktop-down{
        padding-bottom: 20px;
        }
        @include tablet-down {
            padding: 0;
        }
        .col-wrap{
            max-width: 400px;
            width: 100%;  
            @include tablet-down {
                padding-bottom: 10px;
            }
            .main-content{
                text-align: center;
                .icon-img{
                    height: 85px;
                    max-width: 85px;
                    margin: 0 auto;
                    width: 100%;
                    img{
                     object-fit: cover;
                     width: 100%;
                     height: 100%;
                    }
                }
                .content{
                    padding: 28px 20px 20px;
                    h4{
                      color: $light-bluewhile;
                      font-size: 22px;
                      font-weight: 300;
                    }
                    .blue-arrow{
                        font-size: 20px;
                        padding: 2px 0 0 6px;
                        color: $light-bluewhile;
                        span{
                            border: 2px solid $MidnightBlue;
                              &:after {
                              border: solid $MidnightBlue;
                              border-width: 0 2px 2px 0;
                              }
                        }
                    }
                }
                
            }
            &:hover{
                cursor: pointer;
                .main-content{
                    .content{
                        .blue-arrow{
                            span{
                                right: -17px;
                            }
                        }
                    }
                    
                }
            }
        }
    }

}

