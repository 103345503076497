
.subscribe-with-icon{
  @include container-up{
    padding: 5px 0 0px;
  }
      .text-with-input{
    background: linear-gradient(to bottom,#2f3069 0,#67699a 100%);
    padding: 28px 20px 30px;
    display: flex;
    align-items:center;
    width: 100%;
    margin-bottom: 40px;
    @include desktop-down{
      padding: 15px;
      margin-bottom: 25px;
    }
    @include tablet-down{
      display: block;
    }
    @include phone-down{
      padding: 10px;
    }
    .text-content{
      width: 26%;
      position: relative;
      @include container-down{
      }
      @include desktop-down{
        width: 30%;
      }
      @include tablet-down{
        width: 100%;
        margin-bottom:20px;
      }
      h3{
        color: $white;
        font-size: 22px;
        font-weight: 300;
        padding-left: 20px;

        @include container-down {
          padding-left: 0;
          font-size: 20px;
        }
        @include tablet-down{
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .input-box{
      width: 25%;
      position: relative;
      display: flex;
      max-width: 100%;
      height: 30px;
      @include container-down{
     
      }
      @include desktop-down{
        width: 30%;
      }
      @include tablet-down{
        padding-left:0;
        width: 100%;
        margin: 0 auto;
      }
      @include phone-down{
        max-width: 100%;
      }

      input {
        border: none;
        font-size: 17px;
        border: 0;
        padding: 0 12px;
        font-family: $font-families;
        font-weight: 400;
        letter-spacing: 1.4px;
        outline: none;
        width: calc(100% );
        box-sizing: border-box;
        appearance: none;
        border-radius: 0;
        color: $white;
        background-color:$purple2;

        @include phone-down{
          max-width: 260px;
          margin-right: 3px;
          
        }
      }
      input::placeholder{
        font-family:$font-families;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.21;
        letter-spacing: normal;
        color: $white;
      }
      button {
        padding: 4px 0 0;
        outline: none;
        display: inline-block;
        vertical-align: middle;
        background: linear-gradient(to bottom,#4eb2ca 0%,#1f6ea9 100%);
        width: 100%;
        text-decoration: none;
        position: relative;
        z-index: 0;
        text-align: center;
        margin: 0;
        border-radius: 0;
        max-width: 107px;
        border: 0;
        cursor: pointer;
        color:$white;
        font-size: 18px;
        line-height: 1.38;
        letter-spacing: normal;
        font-family:$font-families;
        overflow: hidden;
        @include phone-down{
          max-width: 75px;
        }
        span{
          font-family: $font-families;
          position: relative;
          font-size: 15px;
          font-weight: 300;
        }
        &:hover{
          background: linear-gradient(to bottom,#278ea8 0,#1f6ea9 100%);
        }

      }
    }
    .social-block{
      width: 45%;
      display: flex;
      align-items: center;
      padding-left: 300px;
      @include container-down{
        width: 45%;
        padding-left: 150px;
      }
      @include desktop-down{
        width: 40%;
        padding-left: 30px;
      }
      @include tablet-down{
        padding-top: 10px;
        display: block;
        text-align: center;
        width: 100%;
        padding-left: 0;
      }
      .label{
        margin: 0 7px 0 0;
        display: block;
        font-weight: 300;
        font-size: 22px;
        color: $white;
      }
      .social-networks{
        color: $white;
        display: inline-flex;
        list-style: none;
        font-size: 13px;
        li{
          background: $white;
          width: 34px;
          border-radius: 50%;
          height: 34px;
          margin: 0 0 0 5px;
          .icon{
            display: block;
          }
        }
      }
    }
  }
}


