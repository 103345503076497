.table-with-logo {

  @include container-up{
  padding: 111px 0 0;
  }

   .title{
     text-align: center;
     padding-bottom: 16px;
     h2{
      color: $light-bluewhile;
     font-weight: 300;
     }
   }
  
  .table-wapper {
    max-width: 100%;
    @include phablet-down {
      text-align: center;
    }
    .table{

      tr{
        

         td{
          font-size: 16px;
          border: 1px solid #dad9d6;
          color: $light-bluewhile;
          padding: 10px 18px;
          font-weight: 100;
          vertical-align: middle;
          width: 25%;
          &:last-child{
          padding: 24px 18px;
           width: 50%;
          }
          h6{
            // text-transform: capitalize;
            font-size: 16px;
            color: $black;
            font-weight: 200;
          }
          p{
            color: $black;
            font-size: 16px;
          }
          a{
            font-weight: 100;
            font-size: 16px;
          }
          img{
          height: auto;
          width: 100%;
          object-fit: cover;
          }
        }
        th{
          background: #9ebecc;
          h6{
            text-transform: capitalize;
            color: $black;
            font-size: 16px;
          }
        }
      }
    }
  }
}
