.map {

  .tab-box {
    background: $white2;

    .tab-heading {
      background: $blue7;
      display: flex;
      justify-content: space-around;
      list-style: none;
      padding-left: 0;
      @include tablet-down {
        flex-wrap: wrap;
      }
      li {
        margin: 0;
        font-weight: 300;
        font-size: 22px;
        text-align: center;
        position: relative;
        cursor: pointer;
        color: $light-bluewhile;
        flex-grow: 1;
        @include tablet-down {
          width: 50%;
          border: 1px solid $blue8;
        }
        span {
          padding: 22px 15px;
          display: inline-block;
          position: relative;
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            font-size: 18px;
          }
        }
        &.active {
          color: $MidnightBlue;
          background: $white2;
          &:after {
            display: none;
          }
          span {
            &:after {
              content: '';
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;
              height: 3px;
              background: $MidnightBlue;
            }
          }
          @include tablet-down {
            border-color: $MidnightBlue;
            span {
              &:after {
                display: none;

              }
            }
          }
          &:hover {
            &:after {
              content: '';
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;
              height: 3px;
              background: $MidnightBlue;
            }
            @include tablet-down {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .tab-content {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 0;
      margin: 0 -18px 0 0;
      background-color: $white;
      visibility: hidden;
      display: none;
      @include tablet-down {
        padding: 20px 0 0;
      }
      &.active {
        position: relative;
        visibility: visible;
        display: block;
        height: 100%;
        overflow: hidden;
      }

      .tab {
        background: $whitesmoke;
        border-bottom: 2px solid $blue9;
        float: left;
        height: 100%;
        width: calc(33.33% - 20px);
        margin: 0 20px 20px 0;
        padding: 30px 15px 20px 30px;
        @include tablet-down {
          width: calc(50% - 20px);
        }
        @include phablet-down {
          width: 100%;
          margin: 0 0 20px;
          text-align: center;
          &:last-child {
            margin: 0;
          }
        }
        &:last-child {
          margin: 0 0 20px 0;
        }

        h4 {
          color: $blue4;
          line-height: 1.5;
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: 300;
          padding: 0 0 21px;
          position: relative;
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            &:before {
              left: 0;
              right: 0;
              top: 0;
              margin: auto;
            }
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 87px;
            bottom: 13px;
            border-bottom: 2px solid #5dbdf4;
          }

        }
      }
      p {
        color: $light-bluewhile;
        padding-bottom: 36px;
        margin: 0;
        font-size: 18px;
      }
      a {
        color: $light-bluewhile;
        font-size: 18px;
        font-weight: 100;
        &[href^="tel:"] {
          padding: 0;
          cursor: default;
          text-decoration: none;
          display: block;

        }
        &[href^="mailto:"] {
          display: block;
          padding: 20px 0;
        }
      }

    }

  }
}
