.intro {
  @include container-up {
    //padding: 109px 0 70px;
  }
  h1, h2, h3, h4, h5, h6, p {
    color: $light-bluewhile;
  }

  &.font-size {
    @include container-up {
      padding: 106px 0 55px;
    }
    p {
      font-size: 22px;
      line-height: 1.6;
      color: $light-bluewhile;
      @include phablet-down {
        font-size: 20px;
        margin: 0;
      }
    }
  }
  .content {
    max-width: 940px;
    margin: 0 auto;
    text-align: center;
  }
  h2 {
    color: $light-bluewhile;
    //font-size: 45px;
    font-weight: 300;
    margin-bottom: 30px;
    @include phablet-down {
      //font-size: 26px;
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 30px;
    line-height: 1.6;
    color: $light-bluewhile;
    @include phablet-down {
      font-size: 22px;
      margin: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .wrapper {
    text-align: left;
    @include phablet-down {
      text-align: center;
    }
  }
  h3 {
    color: $light-bluewhile;
    line-height: 1.33;
    font-weight: 300;
    padding-bottom: 12px;
    @include container-up {
      font-size: 46px;
    }
    @media (min-width: 1150px) {
      font-size: 28px;
      
      }

  
  }
}