.thanks-banner {
  background: url(/themes/thalesesecurity/templates/dist/images/men_discussion.jpg) center -100px no-repeat #f5f5f5;
  background-size: cover;
  transition: all 0.6s;
  padding: 120px 0 !important;
  height: 320px !important;
  background-position: 0 -100px !important;
  margin-top: 105px;
  background-size: cover !important;
  width: 100%;
  @include desktop-down {
    margin: 0;
    padding: 50px 0 !important;
    height: auto !important;
  }
  @include phablet-down {
    background-position: 100% !important;
  }
  .col-wrap {
    @include desktop-down {
      padding: 0 20px;
    }

    .content {
      background-color: unset;
      padding: 0 0 0 112px;
      border-radius: 0;
      max-width: 598px;
      @include desktop-down {
        padding: 0;
      }
      @include phablet-down {
        text-align: center;
      }
    }
    h1 {
      color: $MidnightBlue;
      font-size: 2.5em;
      margin-bottom: 20px;
      font-weight: 400;
      @include phablet-down {
        font-size: 2em;
        text-align: center;
      }
    }

  }
}

