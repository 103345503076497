.first-level-banner {
  min-height: 740px;
  margin-top: 30px;
  position: relative;
  display: inline-grid;
  @include desktop-down {
    min-height: 678px;
  }
  @include phone-down {
    min-height: 680px;
    padding: 30px 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: $white;
  }
  .bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-wrapper {
    padding: 0;
    text-align: left;
    position: relative;
    z-index: 1;
    .content {
      padding: 0;
      h1 {
        font-weight: 600;
        margin-bottom: 14px;
        @include desktop-down {
          font-size: 40px;
          margin-bottom: 9px;
        }
        @include tablet-down {
          line-height: 1.15;
        }
      }
      p {
        font-weight: 500;
        @include desktop-down {
          font-size: 24px;
          margin-bottom: 18px;
        }
        @include tablet-down {
          font-weight: 300;
          line-height: 1;
          margin-bottom: 35px;
        }
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
      &:hover:before {
        width: 0;
      }
    }
    .btn-wrapper {
      //display: inline-flex;
      float: right;
      position: absolute;
      right: 18px;
      bottom: 22px;
      display: none;
      @include phablet-down {
        right: 0;
        //display: flex;
        float: none;
        bottom: 22px;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: none;
      }
      .btn-left {
        font-weight: 300;
        padding: 0px 17px;
        background-color: $white;
        color: $MidnightBlue;
        border-radius: 20px 0 0 20px;
        line-height: 34px;
        font-size: 15px;
        box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);

        @include phablet-down {
          padding: 0 10px;
        }
      }
      .btn-right {
        background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
        padding: 0 38px;
        border-radius: 0 20px 20px 0;
        color: $white;
        font-weight: 300;
        line-height: 34px;
        font-size: 15px;
        box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
        @include phablet-down {
          padding: 0 17px;
        }
      }
      &.bottom-fix {
        position: fixed;
        bottom: 50px;
        right: 94px;
        z-index: 5;
        transition: bottom 0.6s linear;
        transform: translate(0, 30px);
        @media screen and (min-width: 1401px) {
          right: 50%;
          margin: 0 -614px 0 0;
        }
        @include tablet-down {
          right: 0;
          display: flex;
          float: none;
          position: fixed;
          justify-content: center;
          align-items: center;
          width: 100%;

        }
      }
    }
    .ban-card-wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include phone-down {
        justify-content: center;
      }
      .banner-card {
        width: 100%;
        max-width: 215px;
        min-height: 108px;
        text-align: center;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 10px 10px;
        background: rgba(0, 187, 221, 0.5);
        margin-right: 27px;
        margin-bottom: 30px;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
          @include phone-down {
            margin-bottom: 0;
          }
        }
        @include desktop-down {
          max-width: 215px;
          padding: 10px 12px;
          min-height: 104px;
        }
        @include phablet-down {
          max-width: 200px;
        }
        @include phone-down {
          margin: 0 0 10px;
          max-width: 222px;
          min-height: 108px;
        }
        h4 {
          font-size: 22px;
          font-weight: 300;
        }
      }
    }
    //.btn-wrapper {
    //  position: relative;
    //  bottom: 0;
    //  margin-bottom: 30px;
    //  display: none;
    //}
  }
}
