.tabs {
  &.p-tb-0 {
    @include container-up {
      padding: 0;
    }
  }
  img[data-align=right]{
    float: right;
    padding: 5px;
  }
  img[data-align=left]{
    float: left;
    padding: 5px;
  }
  img[data-align=center]{
    display: block;
    float: none;
    margin: 0 auto;
    padding: 5px;
  }
  .tab-box {
    background: $white2;
    .tab-heading {
      background: $blue7;
      display: flex;
      justify-content: space-around;
      list-style: none;
      padding-left: 0;
      @include tablet-down {
        flex-wrap: wrap;
      }
      li {
        font-weight: 400;
        font-size: 22px;
        text-align: center;
        position: relative;
        cursor: pointer;
        color: $light-bluewhile;
        flex-grow: 1;
        margin-bottom: 0;
        @include tablet-down {
          width: 50%;
          border: 1px solid $blue8;
        }
        span {
          padding: 22px 15px;
          display: inline-block;
          position: relative;
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            font-size: 18px;
          }
        }
        &.active {
          color: $MidnightBlue;
          background: $white2;
          &:after {
            display: none;
          }
          span {
            &:after {
              content: '';
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;
              height: 3px;
              background: $MidnightBlue;
            }
          }
          @include tablet-down {
            border-color: $MidnightBlue;
            span {
              &:after {
                display: none;

              }
            }
          }
        }
        &:hover {
          &:after {
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            height: 3px;
            background: $MidnightBlue;
          }
          @include tablet-down {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    .tab-content {

      padding: 64px 49px 70px;
      position: relative;
      @include tablet-down {
        padding: 40px;
      }
      @include phone-down {
        padding: 25px;
      }
      h2 {
        padding: 20px 0;
        &:first-child {
          padding-top: 0;
        }
      }

      .tab {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        display: none;
        &.active {
          position: relative;
          visibility: visible;
          display: block;
        }
    
          h4 {
            color: $blue4;
            line-height: 1.5;
            margin-bottom: 5px;
            font-size: 22px;
            @include phablet {
              font-size: 20px;
            }
            @include phablet-down {
              font-size: 18px;
            }
          }
          p {
            color: $light-bluewhile;
            // padding-bottom: 36px;
            padding-bottom: 22px;
            margin: 0;
            &:last-child {
              padding-bottom: 0;
            }
          }
        
       
        .side-image-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .img-wrapper {
            width: 20%;
            padding-top: 38px;
            @include desktop-down {
              width: 168px;
            }
            @include tablet-down {
              display: none;
            }
          }
          .content-wrapper {
            //width: calc(80% - 40px);
            /* max-width: 832px; */
            margin-left: 20px;
            @include desktop-down {
              //width: calc(100% - 188px);
            }
            @include tablet-down {
              width: 100%;
              margin-left: 0;
            }
          }
        }
        .col-3-wrapper {
          display: flex;
          margin: 0 -15px;

          h4 {
            color: $light-bluewhile;
          }

          @include tablet-down {
            flex-wrap: wrap;
            margin: 0;
          }
          .col-three-box {
            padding: 0 2%;
          }
        }
        .img-block-wrapper {
          text-align: center;
          margin: 30px 0;
        }
        .related-resources-box {
          // padding: 10px 0;
          padding: 0;
          .tab-row {
            padding: 44px 48px 28px 32px;
            background: $light-grey;
            border-bottom: 2px solid $blue9;
            display: flex;
            // padding: 46px 48px 28px 36px;
            position: relative;
            margin: 0 0 31px;
            @include tablet-down {
              display: block;
            }

            .img-wrapper {
              width: 28%;
              padding: 3px 60px 10px 0;
              text-align: center;
              @include tablet-down {
                width: 100%;
                padding: 3px 0 10px 0;
              }
              img {
                max-width: 125px;
              }
            }
            .content-wrapper {
              flex-grow: 1;
              padding: 0 18px 0 0;
              // padding: 0 20px 0 0;
              max-width: 55%;
              @include desktop-down {
                max-width: 100%;
              }
              @include tablet-down {
                flex-grow: 0;
                padding: 0;
              }
              h4 {
                color: $light-bluewhile;
                font-weight: 300;
                margin-bottom: 6px;
                font-size: 22px;
              }
              a {
                font-size: 22px;
                font-weight: 100;
              }
            }

          }
        }
        ol {
          padding-left: 22px;
          font-size: 22px;
        }
        ul {
          list-style-type: disc;
          padding-left: 40px;
          padding-bottom: 28px;
          @include tablet-down {
            padding-bottom: 20px;
          }
          @include phone-down {
            padding-bottom: 10px;
            padding-left: 30px;
          }
          li, a {
            font-size: 22px;
            line-height: 1.5;
            margin: 0 0 1.64em;
            font-weight: 100;
            @include tablet-down {
              line-height: 1.3;
            }
            @include phablet {
              font-size: 20px;
            }
            @include phone {
              font-size: 16px;
            }
            @include phone-down {
              font-size: 14px;
            }
          }
          li {
            color: $light-bluewhile;
            margin-bottom: 0;
            @include tablet-down {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .image-with-col{
    .container{
      @include tablet-down{
        padding: 0;
      }
    }
  }
}

.tab-hide {
  display: none;
}



