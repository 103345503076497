.col-three-location {
  .column-wrap {
    width: calc(100% + 15px);
    text-align: center;
    margin-left: -10px;
    @include tablet-down {
      margin-left: 0;
      width: 100%;
    }

    .card {
      border-radius: 0;
      width: calc(33% - 20px);
      display: inline-block;
      min-height: 369px;
      border-bottom: 2px solid $blue9;
      background: $whitesmoke;
      padding: 0 0 20px 0;
      vertical-align: top;
      margin: 0 10px 10px;
      max-width: 379px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      @include tablet {
        margin: 0 6px 10px;
        width: calc(33% - 15px);
        max-width: 100%;
      }
      @include phablet {
        width: calc(50% - 20px);
        float: left;
        min-height: 250px;
      }
      @include phablet-down {
        width: 100%;
        min-height: 250px;
        min-height: 300px;
        max-width: 100%;
      }

      .top-heading {
        background: $white2;
        min-height: 50px;
        padding: 23px;
        text-align: center;
        display: block;
        @include tablet-down {
          padding: 10px;
        }

        h4 {
          font-weight: 300;
          color: $light-bluewhile;
          @include desktop-up {
            font-size: 23px;
          }
        }
      }
      p {
        text-align: left;
        margin: 0;
        font-size: 18px;
        padding: 26px 10px 0 28px;
        color: $light-bluewhile;
        font-weight: 300;
        letter-spacing: -0.55px;
        @include desktop-down {
          padding: 20px 10px 0 28px;
        }
        @include phablet-down {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}