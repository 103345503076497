.global-gdpr{
   
    .wrapper{
        width: 100%;
        @include tablet-down {
         display: flex;
         flex-wrap: wrap-reverse;
         text-align: center;
        }
       
        .right-img{
            float: right;
            width: 30%;
           @include tablet-down {
            width: 100%;
            float: unset;
            text-align: center;
            }
            p{
                margin-bottom: 10px;
                img{
                   margin: auto;
                  display: block;
                }
            }
            .heading{
       
               padding-bottom: 20px;          
               .regulation{
                 width: 50%;
                 display: inline-block;
                 border-right: 2px solid #9b9b9b;
                 text-align: right;
                 color: $light-bluewhile;
                 padding-right: 16px;
                 font-weight: 300;
                 font-size: 18px;
               }
               .action{
                 color: $light-bluewhile;
                 text-align: left;
                 width: 50%;
                 float: right;
                 font-weight: 300;
                 font-size: 18px;
                 padding-left: 15px;
               }
             }
       
            a{
                position: relative;
                img{
                margin: auto;
                display: block;
                }
            }
            @include tablet-down{
                .regulation{
                    font-size: 16px;
                }
                .action{
                    font-size: 16px;
                }
            }

       
           }
           
           .content{
               width: 70%;
               float: left;
               padding-right: 20px;
               h2{
                color: $light-bluewhile;
               padding-bottom: 20px;
               }
               p{
                color: $light-bluewhile;
                font-weight: 300;
               }
               ul{
                   li{
                    font-weight: 200;
                    margin: 0;
                   }
               }
               @include tablet-down {
                 width: 100%;
                float: unset;
              padding: 0 0 20px 0;
               }
           }
    }
   

}