.icon-with-content {
  .title {
    text-align: center;
    padding-bottom: 110px;
    max-width: 800px;
    margin: 0 auto;
    h2 {
      color: $light-bluewhile;
      font-weight: 300;
      @include container-up {
        //font-size: 46px;
      }
      @include phone-down {
        //font-size: 20px;
      }
    }
    @include container-down {
      padding-bottom: 40px;
    }
    @include desktop-down {
      padding-bottom: 30px;
    }
    @include phone-down {
      padding-bottom: 20px;

    }
  }

  .full-row {
    .col-wrap {
      padding: 0 15px;
      .like-list {
        padding: 0 20px;
        float: left;
        list-style: none;
        li {
          color: $light-bluewhile;
          font-size: 20px;
          position: relative;
          margin: 0;
          padding: 22px 0 31px 88px;
          &:before {
            content: '';
            position: absolute;
            width: 51px;
            height: 52px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url(/themes/thalesesecurity/templates/assets/images/ico-thumbs-up.svg) no-repeat;
            background-size: 100% 100%;

          }
          @include tablet-down {
            font-size: 18px;
            padding: 20px 20px 20px 80px;
            &:before {
              width: 40px;
              height: 40px;
            }
          }
          @include phablet-down {
            padding: 20px 20px 20px 60px;
          }

        }

      }
    }
  }

}