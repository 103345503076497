.col-three-icon {

  &.col-three-content {
    padding: 70px 0 60px;
    @include desktop-down {
      padding: 58px 0 20px;
    }
    @include tablet-down {
      padding: 58px 0;
    }
    .content-wrap {
      .col-wrap {
        margin-left: 34px;
        margin-top: 10px;
        margin-bottom: 20px;
        @include container-down {
          width: calc(33.33% - 40px);
        }

        @include desktop-down {
          width: 100%;
          min-height: 100%;
          max-width: 100%;
          margin-left: 24px;
          margin-top: 0;
          margin-bottom: 34px;
        }
        @include tablet-down {
          margin-left: 7px;
          margin-right: 7px;
          margin-bottom: 37px;
        }
        &:nth-child(2) {
          .content {
            @include desktop-down {
              padding: 38px 0;
            }

            &:before {
              top: -20px;
              bottom: -20px;
              left: -8px;
              background: #ccc;

              @include desktop-down {
                top: 0;
                bottom: unset;
                right: 0;
                height: 2px;
                width: 100%;
                margin: auto;
              }
            }

            &:after {
              top: -20px;
              bottom: -20px;
              right: -12px;
              background: #ccc;

              @include desktop-down {
                top: unset;
                bottom: 0;
                right: 0;
                height: 2px;
                left: 0;
                width: 100%;
                margin: auto;
              }
            }
          }
        }

        .content {
          padding: 0 14px 0 0;
          text-align: left;

          @include container-down {
            padding: 0 10px;
          }

          @include desktop-down {
            padding: 0;
          }

          h3 {
            line-height: 1.08;
            margin-bottom: 16px;
            font-size: 24px;
            font-weight: 600;
            color: $light-bluewhile;

            @include desktop-down {
              margin-bottom: 20px;
            }
            @include tablet-down {
              margin-bottom: 15px;
            }
            a {
              color: $light-bluewhile;
            }
          }

          p {
            font-size: 22px;
            line-height: 1.5;
            font-weight: 100;
            letter-spacing: normal;
            margin-bottom: 0;
            color: #253746;
          }
        }
      }
    }
  }

  .content-wrap {
    width: calc(100% + 30px);
    margin-left: -15px;
    float: left;

    @include container-down {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
    }

    @include tablet-down {
      width: 100%;
      margin: 0;
      float: none;
    }

    .col-wrap {
      width: calc(33.33% - 40px);
      margin-left: 30px;

      @include container-down {
        width: 40%;
        min-height: 250px;
        margin: 0 20px 30px;
      }

      @include tablet-down {
        margin: 0 auto 30px;
        width: 100%;
        max-width: 767px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        @include container-down {
          .content {
            &:before {
              width: 0px;
            }

            &:after {
              width: 0px;
            }
          }
        }

        .content {
          &:before {
            content: '';
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 0;
            background: $silverLight;
            margin: 0 0 0 -15px;
          }

          &:after {
            content: '';
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            right: -16px;
            background: $silverLight;
            margin: 0 0 0 -15px;
          }
        }
      }

      .img-wrap {
        height: 100px;
        width: 100%;
        max-width: 250px;
        margin: 21px auto 45px;

        @include tablet-down {
          margin: 0 auto 20px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .content {
        padding: 0 18px 0 30px;
        text-align: center;
        position: relative;

        @include container-down {
          padding: 0 10px;
        }

        @include tablet-down {
          padding: 0;
        }

        h2 {
          line-height: 1.06;
          margin-bottom: 30px;
          font-weight: 300;
          color: $light-bluewhile;

          @include tablet-down {
            margin-bottom: 10px;
          }
        }

        h3 {
          line-height: 1.06;
          margin-bottom: 5px;
          font-weight: 300;
          color: $light-bluewhile;

          @include tablet-down {
            margin-bottom: 10px;
          }

          a {
            color: $light-bluewhile;
          }
        }

        p {
          font-size: 22px;
          line-height: 1.4;
          font-weight: 100;
          color: $mid-grey;
          letter-spacing: -0.03em;
          margin-bottom: 0;

          @include tablet-down {
            font-size: 20px;
            line-height: 1.2;
          }
        }
      }
    }
  }

  &.full-img {
    .content-wrap {
      .col-wrap {
        .img-wrap {
          height: auto;
          max-width: 390px;
          max-height: 270px;

          img {
            height: auto;
          }
        }

        .content {
          text-align: left;
          padding-left: 0;

          @include desktop {
            text-align: center;
          }

          @include desktop-down {
            text-align: center;
          }

          &:after {
            display: none;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
}
