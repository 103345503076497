.col-three-counter {
  position: relative;
  @include container-up {
    //padding: 40px 0 64px;

  }
  &.statistics {
    .bg-img {
      height: 400px;
      @include tablet-down {
        height: auto;
      }
    }
    .container {
      .title {
        h2 {
          @include container-up {
            font-size: 40px;
          }
        }
      }
      .content-wrap {
        margin-bottom: 30px;
        .col-wrap {
          .content {
            padding: 40px 30px 0;
            p {
              font-size: 77px;
              line-height: 1.23;
              &:before {
                display: none;
              }
              span {
                font-size: 77px;
                @include tablet-down {
                  font-size: 40px;
                }
              }
              @include tablet-down {
                font-size: 30px;
              }
            }
            .h2 {
              padding-top: 0;
            }
          }
          &:nth-child(2) {
            .content {
              &:before {
                display: none;
              }
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .bg-img {
    position: absolute;
    top: 0;
    bottom: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include tablet-down {
      height: auto;
    }
  }
  .container {
    .title {
      text-align: center;
      h2 {
        color: $white;
        font-weight: 300;
        @include phone-down {
          font-size: 20px;
        }

      }
    }
    .content-wrap {
      width: calc(100% + 30px);
      margin-left: -15px;
      float: left;
      text-align: center;
      @include container-down {
        width: 100%;
        margin: 0;
      }
      @include tablet-down {
        width: 100%;
        margin: 0;
      }
      .col-wrap {
        width: calc(33.33% - 40px);
        margin-left: 30px;
        @include container-down {
          margin: 0;
          width: 33%;
        }
        @include tablet-down {
          width: 100%;
          margin: 0;
        }
        .content {
          padding: 58px 30px;
          text-align: center;
          @include container-down {
            padding: 30px 15px;
          }
          @include tablet-down {
            padding: 26px 10px;
          }
          p {
            position: relative;
            display: inline-block;
            color: $white;
            font-size: 55px;
            font-weight: 300;
            margin: 0;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 87px;
              height: 2px;
              background: $white;
            }
            span {
              font-size: 55px;
              font-weight: 300;
              color: $white;
              @include tablet-down {
                font-size: 30px;
              }
            }
            @include tablet-down {
              font-size: 30px;
            }
          }
          .h2 {
            color: $white;
            font-weight: 300;
            padding-top: 30px;
            line-height: 1.2;
            animation-delay: 2.7s !important;
            @include container-down {
              padding: 22px 0 0;
            }
            @include tablet-down {
              padding-top: 20px;
            }
            @include phone-down {
              font-size: 20px;
            }
          }
        }
        &:nth-child(2) {
          .content {
            &:before {
              content: '';
              position: absolute;
              width: 1px;
              top: 40px;
              bottom: -24px;
              left: 0;
              background: $silverLight;
              margin: 0 0 0 -15px;
              height: 80%;
            }

            &:after {
              content: '';
              position: absolute;
              width: 1px;
              top: 40px;
              bottom: -24px;
              right: -16px;
              background: $silverLight;
              margin: 0 0 0 -15px;
              height: 80%;
            }
          }
          @include container-down {
            .content {
              &:before {
                bottom: -60px;
                margin: 0;
              }
              &:after {
                bottom: -60px;
                margin: 0 15px 0 0;
              }
            }
          }
          @include tablet-down {
            .content {
              &:before {
                width: 0;
              }
              &:after {
                width: 0;
              }
            }
          }
        }
      }
      .btn-default{
        margin-top: 10px;
        padding: 8px 14px;
        a{
          text-decoration: none;
          color: $white;
        }
        &:hover{

          text-decoration: none; 
        }
      }
    }
    .bottom-btn {
      text-align: center;
      .btn-cta {
        text-decoration: none;
        color: $white;
      }
    }
  }
}





