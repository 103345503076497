.assessment-form{
padding: 120px 0 60px;
background-color: #F1F1F1;
@include container-down {
  padding: 40px 0;
}

strong {
  font-weight: 700 !important;
}
.white-box{
max-width:1130px;
margin:0 auto;
background-color: #FFF;
padding: 0 15px;


  &.result-show{
    .form-wrapper{
      display: none;
    }
    .result-wrapper{
      display: block;
    }
  }

  .result-wrapper {
    display: none;
    h2{
      padding: 20px 40px;
      font-size: 30px;
      color: $MidnightBlue;
    }
    .result-box {
      overflow: hidden;

      > .row {
        padding: 20px;
      }

      &:nth-child(2) {
        .heading {
          .bars {
            &:before {
              background-color: $MidnightBlue;
            }
          }
        }
      }

      &:nth-child(3) {
        .heading {
          .bars {
            &:before, span {
              background-color: $MidnightBlue;
            }
          }
        }
      }

      &:nth-child(4) {
        .heading {
          .bars {
            &:before, span, &:after {
              background-color: $MidnightBlue;
            }
          }
        }
      }

      &.active {
        &:nth-child(2) {
          .heading {
            .bars {
              &:before {
                background-color: $white;
              }
            }
          }
        }

        &:nth-child(3) {
          .heading {
            .bars {
              &:before, span {
                background-color: $white;
              }
            }
          }
        }

        &:nth-child(4) {
          .heading {
            .bars {
              &:before, span, &:after {
                background-color: $white;
              }
            }
          }
        }

        .heading {
          background-color: $MidnightBlue;
          border: 0;
          color: $white;

          &:after {
            display: block;
          }
        }

        .data-box {
          display: flex;
        }
      }

      .heading {
        background-color: #dddddd;
        border-bottom: 8px solid $MidnightBlue;
        padding: 20px 40px;
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #aaaaaa;
        font-weight: 700;
        position: relative;

        &:after {
          display: none;
          content: "";
          position: absolute;
          height: 40px;
          width: 40px;
          background-color: $MidnightBlue;
          left: 36px;
          bottom: -9px;
          z-index: 0;
          transform: rotate(45deg);
        }

        .bars {
          display: flex;
          width: 50px;
          height: 40px;
          position: relative;
          margin-right: 20px;
          z-index: 9;
          //flex-wrap: wrap;

          &:after, &:before, span {
            content: "";
            background-color: #aaaaaa;
            //height: 100%;
            width: 33.33%;
          }

          span {
            margin: 0 7px;
          }
        }
      }

      p {
        color: $black;
        font-size: 17px;
        margin-bottom: 20px;
      }

      .data-box {
        display: none;

        .content-box {
          h4 {
            color: $black;
            margin-bottom: 20px;
          }
        }

        .link-box {
          > .row {
            margin-bottom: 20px;
          }

          .purple-link {
            display: block;
          }

          .grey-box {
            background-color: #dddddd;
            padding: 20px;
          }
        }
      }
    }
  }


  .dots-wrapper{
  text-align: center;
  padding: 30px 0;
  display: flex;
  list-style: none;
  justify-content: center;
  li{
    pointer-events: none;
    &.slick-active{
      .dots{
        background-color: $MidnightBlue;
      }
    }
  }
  .dots{
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 10px;
    margin-left: 5px;
    transition: all ease 1.0s;
    background-color: #aaaaaa;
  }
}
   h2{
  text-align: center;
  color: #212121;
}
  .controls{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .arrow,span{
      width: calc(50% - 28px);
      padding: 30px 0;
      text-align: center;
      font-weight: bold;
      font-size: 1.4em;
      margin: 10px 0;
      border-radius: 5px;
      border: none;
      background-color: $MidnightBlue;
      transition: background-color 0.3s;
      cursor: pointer;
    }
    .submit-wrapper{
      width: calc(100% - 30px);
      display: none;
      .submit{
        width: 100%;
        cursor: pointer;
      }
    }
    span{
      padding: 0;
      .next,.submit{
        padding: 30px 0;
        background: transparent;
        outline:none;
      }
    }
    .slick-disabled{
      color: #ffffff!important;
      background-color: #eeeeee !important;
      cursor: not-allowed;
    }
    .disabled{
      cursor: not-allowed;
      .next,.submit{
        color: #ffffff;
        background-color: #eeeeee;
        pointer-events: none;
      }
    }
  }
   .slider-box{
    .slide{
      margin-top: 20px;
      .head-row{
        display:flex;
        flex-wrap:wrap;
        min-height: 80px;
        .index{
          width: 80px;
          position: relative;
          background-color: $MidnightBlue;
          &.animate{
            span{
              margin-left: -200%;
            }
          }
          span{
            color:#fff;
            font-size: 3em;
            text-align: center;
            padding: 0;
            display: block;
            transition: all ease 0.4s;
          }
          &:after{
            content:"";
            position:absolute;
            background-color: $MidnightBlue;
            right: -13px;
            height: 30px;
            width: 30px;
            top: 24px;
            transform: rotate(45deg);
          }
        }
        .question{
          width: calc(100% - 80px);
          background-color: #dddddd;
          padding: 0 40px;
          display: flex;
          align-items: center;
          &.animate{
            span{
              opacity: 1;
            }
          }
          span{
            color: $MidnightBlue;
            opacity: 0;
            transition: all ease 0.4s 0.5s;
          }
        }
        .multiple-prompt{
          width: 100%;
          display:none;
          background-color: #ffebc0;
          padding: 6px 15px 10px;
          font-weight: bold;
          color: #3C3C3C;
          font-size: 16px;
        }
      }
      .option-wrapper{
        display: none;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin: 40px 0;

        .option {
          width: 31%;
          float: left;
          input{
            display: none;
          }
          label {
            width: 100%;
            cursor: pointer;
            padding: 25px 1%;
            margin-bottom: 10px;
            text-align: center;
            align-items: center;
            align-self: center;
            border-radius: 5px;
            background-color: #dddddd;
            border-bottom: 5px solid #dddddd;
            transition: all 0.3s;
          }
          input[type="checkbox"]:checked + label{
            background-color: $MidnightBlue;
            color:#fff;
          }
          input[type="radio"]:checked + label{
            background-color: $MidnightBlue;
            color:#fff;
          }
        }
      }
    }
     .slick-list{
       height: auto!important;
     }
  }
}
}



.assessment-form{
 

  .bg-white{
    position: relative;
    margin: 0 auto;
    max-width: 1136px;
    padding: 0 30px;
    width: 100%;
  .active-part {

    padding: 30px 0;
  }
  .progress-dots{
    text-align: center;
  }

  .progress-no {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #aaaaaa;
    border-radius: 10px;
    margin-left: 1;
    color: #ffffff;
    font-weight: bold;
    font-size: 0;
    -webkit-transition: all 1.0s;
    transition: all 1.0s;
    &.active-dot{
      background-color: $MidnightBlue;
    }
    @include desktop-down {
      width: 15px;
      height: 15px;
    }
    @include phablet-down {
      width: 8px;
      height: 8px;
    }
  }
  h2{
    font-size: 32px;
    padding: 46px 0 52px;
    color: #000;
    text-align: center;
    @include phablet-down {
      font-size: 20px;
      padding: 20px 0;
     }
  }

  .quiz-number {
    min-height: 80px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: visible;
    background-color: $MidnightBlue;
    p{
      margin: 0;
      font-weight: bold;
      font-size: 42px;
      text-align: center;
      padding: 10px;
      color: #fff;
    }
    @include container-down {
     p{
      padding: 20px;
     }
    }
    @include tablet-down {
      text-align: center;
      max-width: 100%;
      flex: 100%;
      min-height: 60px;
      p{
        padding: 10px;
        font-size: 32px;
      }

    }
  }
  .purple-arrow-left {
    border: solid 15px #dddddd;
    border-left-color: $MidnightBlue;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: left;
    margin: 25px 0;
    position: relative;
    left: -15px;
    @include container-down {
      margin: 38px 0;
    }

    // @include phablet-down {
      @include tablet-down {
      border-top-color: $MidnightBlue;
      border-left-color: #ffffff05;
       margin: 0;
       float: unset;
       left: unset;
    }
}
.quiz-answers {
  padding: 40px 0 40px;
  @include phablet-down {
    padding: 20px 0 20px;
  }
}

   .quiz-next {
     
     &.hover{
        background-color: #7a3c76;
        cursor: pointer; 
      }  
     &.qbtn-disabled{
      cursor: not-allowed;
     }  
    }

   
.flex-test-4, .flex-test-2, .flex-test-3, .flex-test-5 {
  display: block;
  width: 100%;
}
.hidebtnset{
  display: none;
}
.current-answers{
  display: block;
}
.sata{
  display: none;
  background-color: #ffebc0;
  p{
    padding: 8px 0;
    margin: 0;
    font-weight: bold;
    line-height: 1.6;
    color: #3C3C3C;
    font-size: 16px;
  }
  @include phablet-down{
   text-align: center;
  }
}
.btns-set div {
  // width: 31%;
  max-width: calc(33.333% - 20px);
  width: 100%;
  padding: 25px 1%;
  margin: 0 10px;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
  align-self: center;
  border-radius: 5px;
  border-bottom: 5px solid #dddddd;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #dddddd;
  float: left;
  cursor: pointer;
  &:hover{
    border-bottom: 5px solid $MidnightBlue;
  }
  &.quizbtn-on{
    background-color: $MidnightBlue;
    border-bottom: 5px solid #dddddd;
    color: #fff;
    &:hover{
      border-bottom: 5px solid $MidnightBlue;
    }
  }
  @include phablet-down {
    margin: 0;
    padding: 25px 10px;
    margin-bottom: 20px;
    &.quizbtn-on{
      padding: 20px;
    }
  }
  @include desktop-down {
    max-width: calc(50% - 20px);
  }
  @include phablet-down {
    max-width: 100%;
    margin: 10px 0;
  }
}

.flex-test-4 > div{
  width: calc(24% - 10px);
  justify-content: center;
  display: flex;

  @include container-down{
    width: calc(24% - 15px);
  }
  @include phablet-down {
    width: 100%;
  }
}
  .quiz-question {
    background-color: #dddddd;
    p{
      font-size: 22px;
      font-weight: 400;
      padding: 10px 0 14px;
      margin:0;
      color: $MidnightBlue;
      display: none;
    }
    @include tablet-down {
       max-width: 100%;
       flex: 100%;
      text-align: center;
      p{
        font-size: 16px;
        padding: 0 0 20px;
      }
    }
  }
  .checkresults,.quiz-next, .quiz-prev {
    padding: 26px 0 22px;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
    width: 100%;
    cursor: pointer;
    background-color: $MidnightBlue;
    color: #fff;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    @include phablet-down {
      font-size: 20px;
      padding: 24px 20px;
    }
}
.final-btn{
  display: none;
  .final-disabled{
    cursor: pointer;
    background-color: #eeeeee !important;
    color: #fff;
  }
}
.qbtn-disabled{

  background-color: #eeeeee !important;
  color: #fff;
}
}

  #results{
    display: none;
    @include phablet-down {
      padding: 0 0 20px;
    }
    padding: 0 0 20px;
    .row{
      background: #fff;
      @include phablet-down {
        margin: 0;
      }
    }
    h3{
      margin: 30px 20px;
      font-size: 2em;
      color: $MidnightBlue;
      @include phablet-down { 
        text-align: center;
      }
    }
    .bigbtn {
      color: white;
      background-color: $MidnightBlue;
      border: solid 1px $MidnightBlue;
      padding: 8px 13px 8px;
      border-radius: 3px;
      display: inline-block;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      @include phablet-down {
        font-size: 14px;
        font-weight: 500;
      }
  }
  
    .result-block{
      padding: 0 20px;
      > div{
        background-color: #dddddd;
        padding: 10px 15px;
        border-bottom: 8px solid $MidnightBlue;
      }
      &.result-active{
        >div{
          background-color: $MidnightBlue;
        }
        .changable-bar{
          background: #FFF !important;
        }
       
      }
      p{
        line-height: 1.6;
        color: #3C3C3C;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
      .rating-bar {
        display: inline-block;
        width: 12px;
        height: 40px;
        margin-right: 7px;
        margin-top: 5px;
        background-color: #aaaaaa;
        &.changable-bar{
          background-color: $MidnightBlue;
        }
        @include phablet-down {
            width: 8px;
            height: 30px;
            margin-top: 0;
            vertical-align: middle;
        }
      }
      .statement {
        font-weight: bold;
        font-size: 1.3em;
        vertical-align: top;
        padding-bottom: 10px;
    }

      .rating-num {
        font-size: 1.8em;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        color: #aaaaaa;
        @include phablet-down {
          font-size: 18px;
        }
      }
    }
    .result-text{
      background-color: #fff;
      padding: 0 30px;
      @include phablet-down {
        padding: 0;
        .col-sm-6{
          text-align: center;
        }
      }
      .purple-arrow-top {
        border: solid 15px #ffffff;
        border-top-color: $MidnightBlue;
        width: 15px;
        height: 15px;
        display: inline-block;
      } 
      p{
        line-height: 1.6;
        padding: 0 0 1em;
        color: #3C3C3C;
       font-size: 16px;
        margin-bottom: 10px;
       font-weight: 400;
       @include phablet-down {
        font-size: 14px;
       }
      }
      .statement{
        font-weight: bold;
  font-size: 1.3em;
  vertical-align: top;
  padding-bottom: 10px;
  @include phablet-down {
    font-size: 14px;
  }
      }
      strong{
        font-weight: 700 !important;
        @include phablet-down {
          font-weight: 500;
        }
      }
    }
    .right-assets{
      a{
        font-size: 16px;
        font-weight:700;
        text-decoration: none;
        color: $MidnightBlue;
        outline: 0;

        &.sec-default:before{
          content: " ";
          display: inline-block;
          position: relative;
          background: url(/themes/thalesesecurity/templates/dist/images/gemalto-icons.png) no-repeat;
          width: 18px;
          height: 18px;
          margin-right: 3px;
          top: 3px;
          background-position: 0 -2900px;
        }
        @include phablet-down {
          font-size: 14px;
        }
      }
    }
  }
}