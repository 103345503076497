.masonary-grid {
  border-top: 2px solid $light-sky;
  background: $white2;
  overflow: hidden;
  @include container-up {
    //padding: 71px 0 36px;
  }
  .box-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;

    @include desktop-down {
      margin: 0 auto;
    }
    .box {
      width: calc(33.33% - 23px);
      text-align: center;
      margin-bottom: 38px;

      @include desktop-down {
        width: calc(50% - 18px);
      }
      @include phablet-down {
        width: 100%;
      }

      //margin: 0 6px;
      .inner-wrapper {
        max-width: 383px;
        background: $white;
        padding: 46px 40px 40px;
        @include desktop-down {
          padding: 35px;
          max-width: 100%;
        }
        @include phablet-down {
          margin: 0 auto;
          justify-content: center;
        }

        .img-wrapper, a.img-wrapper {
          max-width: 135px;
          display: flex;
          align-items: center;
          margin: 0 auto 19px;
          justify-content: center;

          img {
            max-height: 100%;
          }
        }
        h2 {
          font-size: 24px;
          line-height: 1.05;
          color: $blue4;
          margin: 0 0 36px;
          text-transform: uppercase;
          word-break: break-word;
          // &:nth-child(3) {
          //   text-transform: none;
          //   color: $light-bluewhile;
          //   margin: 0 0 10px;
          //   line-height: 1.5;
          //   padding-top: 3px;
          // }

          @include tablet {
            font-size: 22px;
          }
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            font-size: 18px;
            margin-bottom: 21px;
          }
        }
        h4{
          text-transform: none;
          color: $light-bluewhile;
          line-height: 1.5;
          padding-top: 3px;
          font-size: 24px;
          padding-bottom: 8px;

          @include tablet {
            font-size: 22px;
          }
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            font-size: 18px;
            margin-bottom: 21px;
          }
        }
        ul {
          list-style: none;
          text-align: left;
          padding-bottom: 19px;
          li {
            margin: 0 0 19px;
            font-weight: 100;
            line-height: 1;
            @include phablet-down {
              margin-bottom: 10px;
            }
            a {
              color: $black;
              text-decoration: none;
              //position: relative;
              font-weight: 200;
              font-size: 18px;
              &:before {
                content: "\e90a";
                font-family: 'icomoon' !important;
                display: inline;
                position: absolute;
                vertical-align: top;
                width: 18px;
                height: 18px;
                text-align: center;
                color: $blue3;
                background:$blue15;
                border-radius: 50%;
                font-size: 18px;
                line-height: 18px;
                padding: 0 0 0 1px;
                margin: 2px 0 0 -24px;
                -webkit-transition: all ease 0.3s;
                transition: all ease 0.3s;
                pointer-events: none;
              }

              &:hover {
                &:after {
                  margin-left: -20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
