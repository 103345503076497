.legal-block-two{
    margin-top: 20px;
    background-color: $white2;
    @include container-up{
        padding: 106px 0;
       }
    .content{
        max-width: 840px;
        margin: 0 auto 30px;
        text-align: center;
        h2{
            color: $light-bluewhile;
            padding-bottom: 14px;
            font-weight: 300;
            font-size: 30px;
            @include phablet-down {
                font-size: 24px;
               }
        }
        p{
            color: $light-bluewhile;
            font-size: 22px;
            a{
            color:$blue5 ;
            font-size: 22px;
            }
            @include tablet-down {
                font-size: 20px;
                a{
                    font-size: 20px;
                }
            }
        }
    }
}
