
.overlay-search {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  padding: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  transition: all 0.6s;
  .search-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(10,10,10,0.55);
    .content-outer {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 20px;
      @include desktop-up {
        top: 32%;
      }
    }
    .content-wrap  {
      max-width: 820px;
      margin: 0 auto;
      position: relative;
      width: 100%;
      background: $blue18;
      padding: 26px;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      .search-link {
        position: relative;
        padding-top: 50px;
        a {
          color: #FFF;
          margin-right: 10px;
        }
      }
      .search-box {
        position: relative;
        img {
          width: 25px;
          height: 25px;
          margin-right: 20px;
          display: inline-block;
          vertical-align: top;
          @include tablet-down {
            display: none;
          }
        }
        .search-input {
          width: calc(100% - 130px);
          display: inline-block;
          vertical-align: top;
          height: 43px;
          border: none;
          padding: 4px 80px 4px 6px;
          font-weight: 400;
          font-family: $font-families;
          color: $blue18;
          background: $white;
          outline: none;
          border-radius: 0;
          font-size: 26px;
          @include tablet-down {
            font-size: 14px;
            padding: 4px 0;
            width: 100%;
            text-align: center;
          }
        }
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $white inset;
        }
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: 20px;
          font-weight: 400;
          font-family: $font-families;
          color: $white;
          @include tablet-down {
            font-size: 14px;
            text-align: center;
          }
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          font-size: 20px;
          font-weight: 400;
          font-family: $font-families;
          color: $white;
          @include tablet-down {
            font-size: 14px;
            text-align: center;
          }
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          font-size: 20px;
          font-weight: 400;
          font-family: $font-families;
          color: $white;
          @include tablet-down {
            font-size: 14px;
            text-align: center;
          }
        }

        :-moz-placeholder {
          /* Firefox 18- */
          font-size: 20px;
          font-weight: 400;
          font-family: $font-families;
          color: $white;
          @include tablet-down {
            font-size: 14px;
            text-align: center;
          }
        }
        .search-btn {
          background: transparent;
          border: 0;
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          color: $white;
          font-weight: 400;
          cursor: pointer;
          outline: none;
          padding: 0 !important;
          min-width: auto;
          box-shadow: none;
          font-size: 20px;
          font-family: $font-families;
          @include tablet-down {
            font-size: 16px;
            position: relative;
            margin: 20px auto 0;
            display: block;
            right: 0;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: -30px;
      right: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 1;
      @include container-up {
        top: -30px;
        right: -30px;
      }
      &:hover {
        opacity: 1;
        &:after,
        &:before {
          background: $blue18;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        background: $white;
        left: 0;
        top: 8px;
        transform: rotate(45deg);
        transition: all 0.4s ease-in-out;
      }
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        background: $white;
        left: 0;
        top: 8px;
        transform: rotate(-45deg);
        transition: all 0.4s ease-in-out;
      }
    }
  }
  &.overlay-open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    top: 0;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
  }
}


