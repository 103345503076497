.full_calendar_wrap {
  overflow: inherit;
  .views-exposed-form {
    display: none;
  }

  padding: 150px 0;
  @include desktop-down {
    padding: 60px 0;
  }
  @include phablet-down {
    padding: 40px 0;
  }
  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    h3 {
      color: #212121;
      font-size: 24px;
      margin-bottom: 0;
    }

    .region_wrapper {
      border: 0;
      tr {
        background-color: transparent;
        td {
          width: auto;
        }
      }
      .chkbx {
        cursor: pointer;
        input[type="checkbox"] {
          margin-right: 5px;
        }
      }
      .chkbx.noram, .cal-noram {
        color: #003399;
        font-weight: bold;
      }
      .chkbx.apac, .cal-apac {
        color: green;
        font-weight: bold;
      }
      .chkbx.emea, .cal-emea {
        color: purple;
        font-weight: bold;
      }
    }
  }
  .fc-toolbar.fc-header-toolbar {
    color: #FFF;
    padding: 10px 0px;
    background-color: #6C286B;
    background-image: none;
    margin-bottom: 0;
    button {
      background-image: none;
      text-transform: capitalize;
      color: #fff;
      background-color: transparent;
      box-shadow: none;
      border: 0;
      opacity: 1;
    }
    h2 {
      color: #FFF;
    }
    button .fc-icon {
      height: auto;
    }
  }
  .full-calendar {
    position: relative;

   @include desktop-down {
    overflow-x: auto;
    width: 100%;

     }
    .js-drupal-fullcalendar {
      @include desktop-down {
        width: 1000px;
        }
    }
    table {
      border-top: 0;
      th {
        border-color: #a7bac5;
        background-color: #6C286B;
        background-image: none;
        color: #FFF;
      }
      tr:nth-child(odd) {
        background-color: transparent;
      }
      tr {

        td {
          width: auto;
          .fc-day-number {
            float: left;
            color: #6C286B;
            padding: 1px 4px 3px;
            text-decoration: none;
            font: normal 13px/14px Arial, sans-serif;
          }
          .fc-day-grid-event {
            //pointer-events: none;
          }
        }
        .fc-title {
          font: normal 12px/14px Arial, sans-serif;
          line-height: 14px;
          padding: 0 3px;
        }
        .fc-time {
          display: none;
        }
      }
    }
    .fc-day-grid-container{
      overflow: inherit !important;
    }
    .fc-row.fc-rigid{
      overflow: inherit;
    }
    .fc-day-grid-event .fc-content{
      overflow: inherit;
    }
    .fc-event-container{
        position: relative;
    }
  }
  .fc-time-grid .fc-bgevent, .fc-time-grid .fc-event{
    position: relative;
  }
}

.qtip {
  display: none !important;
}

.fc-event-container {
  position: relative;
  a {
    margin: 3px 0;
  }
}
.current_tooltip {
  display: none;
  position: absolute;
  width: 600px;       
  z-index: 2;
  padding-bottom: 10px;
  &:after{
    content: '';
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    border-width: 10px;
    border-style: solid;
    border-color: grey transparent transparent transparent;
  }
  @include tablet-down{
    width: 420px;
  }
  .qtip-tip {
    display: none;
  }
  
  .item{
    position: relative;
    height: 350px;
    overflow-y: scroll;
    background-color: #FFF;
    border: 1px solid #E8E8E8;
  }
  
  .qtip-titlebar {
    border-bottom: 1px solid grey;
    padding: 8px;
    .qtip-title {
      font: normal 18px "Segoe UI", Arial, Sans-serif;
      float: left;
      line-height: 22px;
      color: #6C286B !important;
    }
  }
  .qtip-content {
    p {
      padding: 0px;
      font: normal 12px "Segoe UI", Arial, Sans-serif;
      color: #666;
      line-height: 1.4;
      margin: 0;
    }
    a {
      padding: 0px;
      font: normal 12px "Segoe UI", Arial, Sans-serif;
      color: #6C286B;
      line-height: 1.4;
    }
    .url-link {
      padding-top: 5px;
      padding-bottom: 10px;
      float: left;
      font-weight: bold;
    }
    .cat-link {
      padding-top: 5px;
      padding-bottom: 10px;
      float: right;
      font-weight: bold;
    }
    br {
      content: "";
      margin: 3px 0;
      display: block;
      font-size: 24%;
    }
  }
  &.active {
    display: block;
  }
}
.pointer {
  //opacity: 0;
  //background-color: grey;
  //position: absolute;
  //bottom: 31px;
  //width: 20px;
  //z-index: 2;
  //height: 0px;
  //left: 50%;
  //transform: translateX(-50%);
  //transition: opacity .1s ease-in-out;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 100%;
  //  left: 0;
  //  right: 0;
  //  margin: 0 auto;
  //  width: 0;
  //  height: 0;
  //  border-top: solid 10px #E0E0E0;
  //  border-left: solid 10px transparent;
  //  border-right: solid 10px transparent;
  //}
}
.cal-intro {
  padding: 30px 0 0 ;
  border-top: 7px solid #6C286B;
  margin-top: 76px;
  @include desktop-down {
    margin-top: 0;
  }
  .content{
    @include phablet-down {
      text-align: center;
    }
     h1{
       font-weight: 400;
      color: #6c286b;
      font-size: 32px;
      @include phablet-down {
        font-size: 28px;
      }
     }
     h2{
      color: #6D6E70;
      font-size: 27px;
      padding-top: 26px;
      @include phablet-down {
        font-size: 22px;
        padding-top: 15px;
      }
     }
     p{
      padding-top: 20px;
      line-height: 1.6;
      color: #212121;
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      @include phablet-down {
        font-size: 14px;
        padding-top: 10px;
      }
     }
    .question {
      padding-top: 30px;
     @include phablet-down {
      padding-top: 20px;
     }
      span{
        font-size: 16px;
        a { 
          padding-left: 2px;
          text-decoration: none;
          color: #6C286B;
          outline: 0;
          font-size: 16px;
          &:hover{
            text-decoration: none;
          }
          @include phablet-down {
            font-size: 14px;
            a{
              font-size: 14px;
            }
          }
        }

      }
    }
  }

}