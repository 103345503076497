.tab-holder-card{
  text-align: center;
  background-color: #e6eff4;
  border-top: 4px solid #3679c0;
  padding: 50px 0 0;
  
  .title-wrap{
    max-width: 800px;
    margin: 0 auto 50px;
    text-align: center;
    h2{
      color: $light-bluewhile;
      font-size: 30px;
      font-weight: 400;
    }
    @include  tablet-down {
      margin-bottom: 40px;
      h2{
        font-size: 26px;
      }
    }
  }
  
  .content-wrap{
    display: block;
    margin-left: -12px;
    margin-right: -12px;
    @include  tablet-down {
     margin-left: 0;
     margin-right: 0;
    }
   .col-wrap {
     width: calc(33.33% - 24px);
     margin: 0 12px 21px;
     background: $white;
     @include  desktop-down {
       width: calc(50% - 24px);
       margin: 0 12px 21px;
     }
       @include  tablet-down {
       margin: 0 auto 30px;
       width: 100%;
       margin-bottom: 20px;
       max-width: 767px;
         }

       .content {
         transition: all 0.5s ease;
         text-align: center;
         min-height: 500px;
         @include  tablet-down {
           padding: 0;
         }
         h3 {
          padding: 25px 0 50px;
           transition: all 0.5s ease;
           line-height: 1.2;
           margin-bottom: 0;
           font-weight: 300;
           font-size: 24px;
           color: $light-bluewhile;
           position: relative;
           @include  tablet-down {
             font-size: 20px;
           }
         }
         
         .icon-img{
          padding-bottom: 12px;
          border-bottom: 2px solid #9b9b9b;
          max-width: 180px;
          margin: 0 auto;
           @include  tablet-down {
            
            
           }
           img{
           }
         }
        .heading{

          padding-top: 25px;          
          .regulation{
            width: 50%;
            display: inline-block;
            border-right: 2px solid #9b9b9b;
            text-align: right;
            color: $light-bluewhile;
            padding-right: 16px;
            font-weight: 400;
            font-size: 18px;
          }
          .action{
            color: $light-bluewhile;
            text-align: left;
            width: 50%;
            float: right;
            font-weight: 400;
            font-size: 18px;
            padding-left: 15px;
          }
        }

         p {
           padding: 30px 20px;
           transition: all 0.5s ease;
           font-size: 22px;
           line-height: 1.5;
           font-weight: 100;
           color: $light-bluewhile;
           @include  tablet-down {
             font-size: 20px;
             line-height: 1.2;
           }
         }
         .btn-default{
           margin-bottom: 20px;
           a{
             font-size: 16px;
             font-weight: 200;
             color: $white;
             text-decoration: none;
           }
          
         }
       }
       
   }
}

    
}