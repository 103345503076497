.quote-slider {
  background: $whitesmoke;
  // opacity: 0;
  &.quote-slider-item {
    padding: 55px 0 58px;
    .slider-wrap {
      .slider {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 83px;
          width: 70px;
          height: 53px;
          font-size: 210px;
          background: url("/themes/thalesesecurity/templates/assets/images/quotemark.png") no-repeat;
          background-size: 100%;
          @include tablet-down {
            display: none;
          }
        }
        .icon-with-content {
          justify-content: left;
          padding: 0 90px;
          @include phablet-down {
            padding: 0 10px;
          }
          .logo-img {
            width: 300px;
            margin: 0 auto;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 216px;
              height: auto;
              width: 100%;
            }
          }

          .content {
            text-align: unset;
            position: relative;
            max-width: 1220px;
            padding-left: 7px;
            margin-top: 30px;
            width: 100%;
            @include desktop-down {
              padding: 32px 0 0 0;
            }

            p {
              margin: -9px 0 26px 0;
              position: relative;
              font-size: 32px;
              font-style: italic;
              line-height: 1.5;
              font-weight: 300;
              letter-spacing: normal;

              @include desktop-down {
                font-size: 24px;
                line-height: 1.58;
              }

            }

            h3 {
              font-size: 28px;
              font-weight: 600;
              line-height: 1.21;
              padding-top: 6px;
              margin-bottom: 0;
              letter-spacing: normal;
              color: #253746;
              width: 51%;
              margin-left: auto;
              text-align: left;

              @include desktop-down {
                font-size: 24px;
                padding-top: 13px;
              }
              @include tablet-down {
                width: 62%;
              }
              @include phablet-down {
                width: 100%;
                padding-top: 17px;
                margin-left: 10px;
              }

              &::before {
                display: none;
              }
            }

            span {
              font-size: 24px;
              line-height: 1.04;
              display: block;
              color: #253746;
              width: 51%;
              font-weight: 300;
              margin-top: 6px;
              margin-left: auto;
              text-align: left;

              @include desktop-down {
                font-size: 22px;
                margin-top: 10px;

              }
              @include tablet-down {
                width: 62%;
              }
              @include phablet-down {
                width: 100%;
                margin-top: 9px;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .slick-prev {
        background: #f3f4f4;
        float: left;
        width: 55px;
        height: 55px;

        &:before {
          content: '';
          position: absolute;
          background: url(/themes/thalesesecurity/templates/assets/images/bg-arrow.png) no-repeat;
          background-size: 100%;
          width: 55px;
          height: 55px;
          top: 45px;
          left: -8px;
          transform: rotate(180deg);
        }

        @include desktop-down {
          &:before {
            left: 0;
            content: '';
            left: 0;
          }
        }
      }

      .slick-next {
        background: #f3f4f4;
        float: right;
        width: 55px;
        height: 55px;

        &:before {
          content: '';
          position: absolute;
          background: url(/themes/thalesesecurity/templates/assets/images/bg-arrow.png) no-repeat;
          background-size: 100%;
          width: 55px;
          height: 55px;
          top: 45px;
          right: -12px;
          transform: rotate(0deg);
        }

        @include desktop-down {
          &:before {
            right: 0;
            content: '';
            right: 0;
          }
        }
      }
      .slick-dots {
        padding: 6px 4px;
        //background: white;
        min-width: 70px;
        height: 30px;
        margin: 26px auto 0;
        display: inline-block;
        position: relative;
        transform: translateX(-50%);
        left: 50%;

        li {
          height: 12px;
          width: 12px;
          background: #ddd;
        }
      }
    }
  }

  .slider-wrap {
    width: 100%;

    .slider {
      .icon-with-content {
        padding: 0 100px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .logo-img {
          a {
            img {
              height: 100%;
              width: 200px;
            }

            &:hover:before {
              left: 0;
              right: 0;
              width: 0;
            }
          }
        }

        .content {
          position: relative;
          padding: 32px 0 0 0;
          text-align: center;
          max-width: 930px;

          p {
            display: inline;
            font-size: 24px;
            margin: -9px 0 26px 0;
            letter-spacing: -0.007em;
            color: $light-bluewhile;
            position: relative;
            line-height: 1.4;

          }

          h3 {
            position: relative;
            padding-top: 56px;
            color: $dark-bluewhile;
            font-weight: 300;

            &:before {
              content: '';
              position: absolute;
              top: 26px;
              left: 50%;
              transform: translateX(-50%);
              width: 124px;
              height: 3px;
              background: $sky-light;
            }
          }

          span {
            color: $light-bluewhile;
            font-size: 22px;
            font-weight: 100;
          }
        }
      }

      .btn-wrap {
        margin: 30px 0 0;
        text-align: center;
        @include desktop-down {
          margin: 42px 0 0;
        }
        @include phablet-down {
          margin: 33px 0 0;

        }
        a {
          min-width: 175px;
          font-size: 20px;
          font-weight: normal;
          font-style: normal;
          line-height: 1.3;
          background-color: #346d9e;
          letter-spacing: normal;
          padding: 8px 20px;
          opacity: 1;
          display: inline-block;
          border-radius: 5px;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: #00bbdd;
            color: #1A2856;
          }
          @include desktop-down {
            padding: 7px 20px;
            min-width: 215px;

          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .slick-arrow {
      outline: 0;
      position: absolute;
    }

    .slick-prev {
      top: 51%;
      left: 10px;
      background: $white-light;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $dark-blue;
      z-index: 2;
      display: block;
      font-size: 0;
      line-height: 0;
      position: absolute;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      border: none;
      outline: none;

      &:before {
        font-family: 'icomoon' !important;
        content: "\e909";
        font-size: 23px;
        opacity: 1;
        color: $dark-blue;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
    }

    .slick-next {
      z-index: 1;
      top: 51%;
      right: 10px;
      display: block;
      background: $white-light;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $dark-blue;
      font-size: 0;
      line-height: 0;
      position: absolute;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      border: none;
      outline: none;

      &:before {
        opacity: 1;
        content: "\e908";
        font-family: 'icomoon' !important;
        font-size: 23px;
        color: $dark-blue;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }

    }

    .slick-dots {
      opacity: 0;
      text-align: center;
      list-style-type: none;
      padding-top: 40px;
      cursor: pointer;

      li {
        list-style-type: none;
        display: inline-block;
        height: 16px;
        width: 16px;
        background-color: $blue7;
        border-radius: 50%;
        margin: 0 4px;

        button {
          font-size: 0;
        }

        &.slick-active {
          background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%)
        }
      }
    }

    @include phablet-down {
      .slider {
        .icon-with-content {
          padding: 0 10px;

          .content {
            padding: 0;

            p {
              font-size: 20px;
            }

            h3 {
              padding-top: 40px;

              &:before {
                top: 40%;
                bottom: 60px;
                left: 50%;
                transform: translateX(-50%);
                width: 124px;
                height: 3px;
              }
            }

            span {
              font-size: 18px;
            }
          }
        }
      }

      .slick-arrow {
        display: none !important;

      }

      .slick-prev {
        display: none !important;

        &:before {
          display: none !important;
        }
      }

      .slick-next {
        display: none !important;

        &:before {
          display: none !important;
        }
      }

      .slick-dots {
        opacity: 1;
      }

    }
  }
}
