.video {
  @include container-up {
  }
  padding: 60px 0;
  h2, h3 {
    font-size: 32px;
    font-weight: 600;
    font-family: $font-families;
    line-height: 1.06;
    color: $light-bluewhile;
    text-align: center;
    margin-bottom: 30px;
  }
  .wrapper {
    .video-img-wrap {
      margin: 0 auto;
      max-width: 800px;
      height: 450px;
      text-align: center;
      position: relative;
      @include phone-down {
        height: 100%;
      }
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
        display: block;
      }
      .cicle-btn-play {
        display: block;
        cursor: pointer;
        width: 70px;
        height: 70px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -35px 0 0 -35px;
        z-index: 2;
        box-shadow: 0 0 0 4px white;
        border-radius: 50%;
        @include tablet-down {
          width: 50px;
          height: 50px;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
        }
        &:before {
          height: 0;
          width: 0;
          border-bottom: 12px solid transparent;
          border-left: 20px solid #fff;
          border-top: 12px solid transparent;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          margin: 0 0 0 5px;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
        &:after {
          pointer-events: none;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          content: '';
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          top: -4px;
          left: -4px;
          padding: 0;
          z-index: 10;
          border: 4px dashed #fff;
        }

      }
      &:hover {
        transition: box-shadow 0.2s;
        transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        &:before {

        }
        &:after {
          -webkit-animation: spinAround 9s linear infinite;
          animation: spinAround 9s linear infinite;
        }

      }
      iframe {
        display: none;
      }
      &.no-modal {
        .watch-video {
          display: none;
        }
        img {
          //display: none;
          position: relative;
          z-index: 1;
        }
        .cicle-btn-play {
          //display: none;
        }
        iframe {
          width: 100%;
          height: 450px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          border: 0;
          @include desktop-down {
            height: 100%;
          }
        }
      }
    }
  }

}
