.contact-form {
  overflow: visible;
  @include container-up {
    padding: 100px 0;
  }

  .full-row {
    max-width: 1020px;
    padding: 0 10px;
    margin: 0 auto;
    @include phone-down {
    padding: 0;
    }

    .form-outer {
      background-color: $white2;
      position: relative;
      z-index: 9;
      height: 100%;
      max-width: 1020px;
      padding: 0 10px;
      margin: 0 auto;
      @include tablet-down {

      }
      @include phone-down {
      padding: 0;
      }
      .form-wrap {
       
        form {
          @include tablet-down {

          }
          @include phone-down {

          }
          text-align: center;
          padding: 20px 0;
          .field-wrap {
            width: 50%;
            float: left;
            padding: 0 30px;
            @include tablet-down {
              width: 100%;
              padding: 0 20px;
            }
            @include phone-down {
              padding: 0 10px;
            }
            label {
              float: left;
              font-size: 18px;
              @include phone-down {
                font-size: 14px;
              }
            }
            input {
              background-color: #ffffff;
              width: 100%;
              margin: 10px auto;
              border: 0;
              color: $light-bluewhile;
              padding: 14px 20px;
              border: 1px solid $white3;
              margin-bottom: 25px;
              outline: none;
           
              &::placeholder {
                font-size: 18px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                color: $light-bluewhile;
                letter-spacing: normal;
              }
              @include tablet-down {

              }
              @include phone-down {
                padding: 8px 10px;
              }
            }

            .dropdown-cuntry {
              margin-bottom: 25px;
              position: relative;
              @include tablet-down {

              }
              @include phone-down {

              }
              &.show {
                span {
                  &:after {
                    transform: rotate(-136deg);
                    top: 58%;
                  }
                }
                .drop-down {
                  opacity: 1;
                  border: 1px solid $white3;
                  visibility: visible;

                }
                @include tablet-down {

                }
                @include phone-down {

                }
              }

              span {
                position: relative;
                background: white;
                padding: 14px 20px;
                display: block;
                color: $light-bluewhile;
                font-size: 15px;
                font-weight: 300;
                text-align: left;
                border: 1px solid $white3;
                &:before {
                  content: '';
                  position: absolute;
                  text-align: center;
                  background: $white4;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  top: 50%;
                  right: 10px;
                  margin: -10px 0 0;
                }
                &:after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  margin: -6px 0 0 -4px;
                  right: 16px;
                  width: 8px;
                  height: 8px;
                  border: solid $black4;
                  border-width: 0 1px 1px 0;
                  transform: rotate(45deg);
                }
                @include tablet-down {

                }
                @include phone-down {

                }
              }
              .drop-down {
                border: 1px solid $white3;
                list-style: none;
                text-align: left;
                background: $white;
                position: absolute;
                z-index: 1;
                width: 100%;
                opacity: 0;
                min-height: 174px;
                max-height: 340px;
                overflow: scroll;
                visibility: hidden;
                padding-left: 0;
                li {
                  font-size: 18px;
                  font-weight: 100;
                  color: #656565;
                  padding: 7px 20px;
                  margin: 0;

                  &:hover {
                    background: $white7;
                    color: $light-bluewhile;

                  }
                }
                @include tablet-down {

                }
                @include phone-down {

                }
              }

            }
          }
    
          .text-area {
            padding: 0 30px;

            textarea {
              display: block;
              min-height: 300px;
              width: 100%;
            }
            @include tablet-down {

            }
            @include phone-down {
              padding: 0px 10px;
              textarea{
                min-height: 200px;
              }
            }
          }

          a {
            margin: 30px 0;
          }
        }
      }
    }










    .content-wrapper {
      padding: 0;
      .content {
        margin-top: 25px;
        p {
          font-size: 22px;
          color: $light-bluewhile;
          margin-bottom: 24px;
        }
        ul {
          padding: 0 16px;
          li {
            color: $light-bluewhile;
            a {
              font-size: 22px;
              color: $light-bluewhile;
              &[href^="tel:"] {
                font-size: 22px;
                color: $light-bluewhile;
              }
              &[href^="mailto:"] {

                font-size: 22px;
                color: $light-bluewhile;
              }
            }
          }
        }
      }
    }
  }
}


