.cal-thanks-banner{
        background: url(/themes/thalesesecurity/templates/dist/images/banner-v31.jpg) no-repeat center center #ffffff;
        background-size: cover;
        padding: 30px 0 10px;
        background-size: cover;
        transition: all 0.6s;
        padding: 50px 0 !important;
        height: 198px !important;
        background-position: 0 -100px !important;
        margin-top: 76px;
        background-size: cover !important;
        width: 100%;
        border-top: solid 7px #00bbdd;
        @include desktop-down {
            margin: 0;
            padding: 50px 0!important;
            height: auto !important;
        }
        @include phablet-down {
            background-position: 100% !important;
        }
        .col-wrap{
            
            @include desktop-down {
            padding: 0 20px;
            }
           
            .content{
                background-color:unset;
                padding: 0 0 0 112px;
                border-radius: 0;
                max-width: 598px;
                @include desktop-down {
                    padding: 0;
                }
                @include phablet-down {
                    text-align: center;
                }
            }
            h1{
                color: $white;
                font-size: 32px;
                margin-bottom: 20px;
                font-weight: 400;
                @include phablet-down {
                    font-size: 2em;
                    text-align: center;
                }
            }
            p{
                font-size: 16px;
                color: $white;
                font-weight: 400;
            }
    
        }
    }  

