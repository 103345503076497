.sub-header {
  padding: 0;
  position: relative;
  overflow: visible;
  z-index: 9;
  //opacity: 0;
  // @include desktop-up{
  //   min-height: 84px;
  // }
  &.hide {
    //display: none;
  }
  &.fixed {
    .inner-header {
      @include desktop-up {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        padding: 0;
      }
    }
  }
  &.show-header{
    .inner-header{
      @include desktop-up{
        top: 61px;
      }
    }
  }
  .inner-header {
    width: 100%;
    padding: 20px 0;
    background: $white;
    @include desktop-up{
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
      //transition: padding 0.4s ease-in-out, box-shadow 0.4s ease-in-out, top 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
    @include desktop-down {
      position: relative !important;
      top: 0 !important;
    }
  }
  .secondary-nav {
    display: none;
    float: right;
    @include desktop-down {
      display: block;
    }
    .search {
      @include desktop-down {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
    .hamburger {
      position: relative;
      width: 30px;
      height: 25px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      z-index: 2;
      span {
        position: absolute;
        left: 0;
        width: 30px;
        height: 4px;
        background: $black1;
        display: block;
        border-radius: 5px;
        transition: all 0.25s ease-in-out;
        transform: rotate(0deg);
        &:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 10px;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: 20px;
          transform-origin: left center;
        }
      }
      &.active {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            left: 7px;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 21px;
            left: 7px;
          }
        }
      }
    }
  }
  .nav-wrap {
    @include desktop-down {
      width: 100%;
      padding: 0 20px 20px;
      position: absolute;
      top: 41px;
      left: 0;
      background: $white;
      display: none;
      //height: calc(100vh - 91px);
      height: auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    .main-nav {
      h2 {
        color: $blue11;
      }
      > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 0;
        @include desktop-down {
          width: 100%;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        .has-menu {
          > a,
          > span {
            position: relative;
            cursor: pointer;
            &:after {
              content: '';
              position: absolute;
              right: -10px;
              top: 5px;
              bottom: 0;
              width: 12px;
              height: 12px;
              background-image: url("/themes/thalesesecurity/templates/dist/images/downarrow.svg");
              background-size: 100%;
              background-repeat: no-repeat;
              @include desktop-down {
                right: 0;
                top: 0;
                margin: auto;
                transition: 0.4s ease;
              }
            }
          }
          &.inner-link {
            > span,
            > a {
              &:after {
                right: 20px;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }
        > li {
          position: relative;
          list-style: none;
          display: inline-block;
          vertical-align: top;
          margin: 0 15px 0 0;
          padding-top: 6px;
          padding-bottom: 5px;
          @include desktop-down {
            border-bottom: 1px solid $blue11;
          }
          @include desktop-up {
            &:hover {
              > a,
              > span {
                &:before {
                  left: 0;
                  right: 0;
                  visibility: visible;
                }
              }
              .sub-menu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: inherit;
                transition: all 0.4s ease-in-out;
              }
            }
          }
          @include desktop-down {
            display: block;
            margin: 0;
            padding: 0;
            position: relative;
            float: left;
            width: 100%;
          }
          &.big {
            @include desktop-up {
              .sub-menu {
                li {
                  .sub-menu-inner {
                    right: auto;
                    left: -249px;
                  }
                }
              }
            }
          }
          &.inner-nav {
            @include desktop-up {
              margin-right: 32px;
            }
            @include desktop-down {
              &.menu-active {
                > span,
                > a {
                  &:after {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
          > span {
            cursor: default;
          }
          > a,
          > span {
            &:not(.btn-default) {
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              position: relative;
              font-family: $font-families;
              color: $blue11;
              padding: 0 10px 0 0;
              display: inline-block;
              transition: all 0.4s ease-in-out;
              @include large_desktop {
                padding: 0 10px 0 0;
              }
              @include desktop-down {
                display: block;
                padding: 10px 0;
              }
            }
            &:hover {
              text-decoration: none;
              &:not(.btn-default) {
              }
              &:before {

              }
            }
          }
          >ul{
            @include desktop-up{
              opacity: 0;
              visibility: hidden;
              position: absolute;
            }
          }
          .sub-menu {
            @include desktop-up {
              opacity: 0;
              visibility: hidden;
              position: absolute;
              left: 0;
              top: 44px;
              background: $white;
              margin: 0;
              transform: translateY(7px);
              border-top: 1px solid $grey6;
              width: 250px;
              padding: 10px 0;
              z-index: 8;
              box-shadow: 0 3px 13px 1px rgba(0, 0, 0, .15);
              pointer-events: none;
            }
            @include desktop-down {
              //float: left;
              width: 100%;
              display: none;
              padding-left: 0;
              padding-bottom: 10px;
            }
            > li {
              @include desktop-down {
                &.inner-link-active {
                  > span,
                  > a {
                    &:after {
                      transform: rotate(180deg) !important;
                    }
                  }
                }
              }
            }

            li {
              list-style: none;
              transition: all 0.4s ease;
              margin: 0;
              @include desktop-down {
                //float: left;
                width: 100%;
              }
              &.link-active {
                > a {
                  span {
                    &:after {
                      transform: rotate(180deg) !important;
                    }
                  }
                }
              }
              &.overview {
                display: none;
                padding: 0;
              }
              &:hover {
                @include desktop-up {
                  background: $blue13;
                  > span,
                  > a {
                    color: $white;
                  }
                }
              }
              span,
              a {
                position: relative;
                font-size: 16px;
                color: $blue14;
                text-decoration: none;
                transition: all 0.4s ease;
                font-weight: 400;
                display: block;
                padding: 10px 30px 10px 20px;
                @include desktop-down {
                  display: block;
                  padding: 5px 15px;
                }
              }
              .sub-menu-inner {
                @include desktop-up {
                  opacity: 0;
                  visibility: hidden;
                  position: absolute;
                  top: 0;
                  right: -249px;
                  transition: all 0.4s ease;
                  background: $white;
                  margin: 0;
                  padding: 0;
                  width: 250px;
                  z-index: -1;
                  box-shadow: 0 3px 13px 1px rgba(0, 0, 0, .15);
                  max-height: 300px;
                  overflow: auto;
                }
                @include desktop-down {
                  display: none;
                  width: 100%;
                  padding-bottom: 10px;
                }
                .detail-link {
                  position: initial;
                }
                li {
                  &:hover {
                    @include desktop-up {
                      .detail-menu {
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                      }
                    }
                  }
                }
                .detail-menu {
                  @include desktop-up {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    top: 0;
                    transition: all 0.4s ease;
                    background: $white;
                    margin: 0;
                    padding: 30px 20px 30px 50px;
                    min-height: 373px;
                    max-height: 390px;
                    &:before {
                      content: '';
                      position: absolute;
                      left: -23px;
                      top: 0;
                      width: 30px;
                      height: 100%;
                      background: transparent;
                      @include desktop-down {
                        display: none;
                      }
                    }
                  }
                  @include desktop-down {
                    display: none;
                    padding: 0 5px;
                    min-height: 100%;
                  }
                  ul {
                    margin: 0;
                    padding: 0;
                    @include desktop-up {
                      max-height: 340px;
                      overflow-y: auto;
                    }
                  }
                }
              }
              &:hover {
                @include desktop-up {
                  .sub-menu-inner {
                    opacity: 1;
                    visibility: visible;
                    z-index: 3;
                  }
                }
              }
            }
            @include desktop-down {
              position: relative;
              z-index: 2;
              top: -1px;
              display: none;
            }
          }
        }
      }
    }
  }
}