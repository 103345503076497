.resource-tabs {
  background: #FFFFFF;
  padding: 60px 0;

  &.resource-reverse {

    .col-wrap {
      flex-direction: column-reverse;
      order: 1;
    }

    .img-wrapper {
      order: 2;
    }

    .content {
      padding: 0 40px 0 0;

      @include desktop-down {
        padding: 0;
      }
    }
  }

  .title-wrap {
    text-align: center;

    h2 {
      font-size: 32px;
      margin-bottom: 40px;
      font-weight: 600;
      color: #253746;

      @include desktop-down {
        margin-bottom: 20px;
      }
    }
  }

  .columm-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include desktop-down {
      margin: 0;
      display: block;
      justify-content: center;
      text-align: center;
    }

    @include tablet-down {
      width: 100%;
      margin: 0;
      float: none;
    }
  }

  .col-wrap {
    display: inline-block;

    @include desktop-down {
      padding: 0 0 20px;
    }

    &:last-child {
      @include desktop-down {
        padding: 0;
      }
    }

    .img-wrap {
      height: 180px;
      max-width: 320px;

      @include desktop-down {
        margin: 0 auto;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .img-wrapper {
    width: 320px;

    @include desktop-down {
      width: 100%;
      // padding: 20px;
    }
  }

  .content-wrapper {
    width: calc(100% - 320px);

    @include desktop-down {
      width: 100%;
      // padding: 20px;
    }
  }

  .content {
    padding: 0 0 0 40px;
    text-align: left;

    @include desktop-down {
      padding: 0;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 28px;
      color: #346D9E;
      font-weight: 600;
    }

    p {
      font-weight: 100;
      color: #253746;
      font-size: 22px;
      letter-spacing: -0.03em;
      margin-bottom: 10px;
    }

    img {
      display: inline-block;
      max-height: 180px;
      max-width: 150px;
      object-fit: contain;
      margin-left: 22px;

      @include desktop-down {
        max-width: 100%;
        margin-left: 0;
        padding: 20px 0;
      }
    }

    .btn-wrap {
      margin-top: 20px;
      //a.btn-dark-blue {
      //  &:hover {
      //    color: #1A2856 !important;
      //  }
      //}
    }
  }
}

.bg-white-text {

  h2,
  h3,
  p {
    color: $white !important;
  }
}
