body {
  &.overlay-open {
    overflow: hidden;
  }
  // &.node-17616,
  // &.node-17946,
  // &.node-19441,
  // &.node-23936,
  // &.node-588,
  // &.node-590,
  // &.node-607,
  // &.node-732,
  // &.node-788,
  // &.node-type-blog,
  // &.node-type-faqs,
  // &.node-type-news,
  // &.node-type-partners {
  //   header .language-changer {
  //     display: none !important;
  //   }
  // }
}

.ua-ie .masonary-grid .box-wrapper .box .inner-wrapper a.img-wrapper {
  display: block;

  img {
    object-fit: contain;
    font-family: 'object-fit: contain, object-position: left;';
  }
}

.ua-edge {
  body .col-three-slider .slider-wrap .slider .item .full-row .col-wrap {
    height: 100% !important;
  }
}

.ua-edge {
  .resource-grid .filter-wrap .bottom-row ul {
    list-style: none;
  }
}

.ua-ie {
  body
    .card-three-logo-slider
    .slider-wrap
    .slider
    .item
    .full-row
    .col-wrap
    .wrapper
    .img-icon
    img {
    height: auto;
  }
}

.ua-ie {
  body .two-col-row .wrapper .full-row .col-wrap .content .white-arrow {
    text-transform: none !important;
  }

  header .logo img {
    max-width: 174px;
    height: 21px;
  }
}

.ua-mobile {
  body .sub-header .nav-wrap .main-nav > ul > li .sub-menu li.overview {
    display: block;
  }
}

.msg-error {
  display: none;
  color: red;
}

.style-guide {
  padding: 50px 20px;
  background-color: antiquewhite;
  position: relative;
  float: left;
  width: 100%;
  font-weight: bold;
}

.ua-mobile {
  header nav .nav-wrap .main-nav > ul > li .sub-menu ul li.overview {
    display: block;
  }

  .sub-header .nav-wrap .main-nav nav > ul > li .overview {
    display: block !important;
  }
}

.ua-ie {
  body .col-four-row .content-wrap .col-wrap .img-wrap {
    max-width: 100%;
    width: 100px;
    margin: 10px auto 48px;
  }
}

body {
  .olark-launch-button {
    @include phablet-down {
      bottom: 65px !important;
    }
  }
}

.ua-ie header .logo a img {
  width: 200px;
  max-width: 100%;
  height: 30px;
}

#block-thalesesecurity-content > .outer-header {
  display: none;
}

.ua-ie {
  body .assessment-form .bg-white .quiz-question .current-question {
    display: block !important;
  }
}

.ua-ie {
  body .cost-analysis .minus {
    right: 6px !important;
    top: 16px;
  }
}

.ua-ie {
  body .cost-analysis .plus {
    right: 3px !important;
    top: 4px;
  }
}

.ua-ie {
  body .assessment-form .bg-white .quiz-number p {
    padding: 10px;
  }
}

.ua-ie {
  body .cost-analysis .white-block .vfields input {
    padding: 4px 4px 6px;
    margin-bottom: 10px;
  }
}

.ua-edge {
  body .assessment-form .bg-white .quiz-number p {
    padding: 10px;
  }
}

.ua-edg {
  body .introduction-section .full-row .bigbtn .btn-default {
    padding: 8px 12px;
  }
}

.ua-edg {
  body
    .introduction-counter-iot
    .row-wrapper
    .big-col
    .full-rows
    .row-outer
    .counter
    span.number {
    padding: 8px 8px 0;
  }
}

.ua-edg {
  body .introduction-counter-iot .btn-pink .new-green {
    padding: 4px;
  }
}

.ua-ie {
  body
    .introduction-counter-iot
    .row-wrapper
    .big-col
    .full-rows
    .row-outer
    .counter
    span.number {
    padding: 8px 8px 0;
  }
}

.ua-ie {
  body .managment-charts #charts .qnum {
    padding: 10px 20px;
  }
}

.ua-ie {
  body .access-management .column-wrapper .text-wrap .getstarted {
    padding: 5px 10px 2px;
  }

  body .access-management #asat-questions .asat-backbtn#results-btn {
    padding: 15px 35px 10px;
  }

  body .access-management #asat-results .asat-backbtn {
    padding: 15px 20px 10px 75px;
  }
}

.ua-firefox {
  body .access-management #asat-questions .asat-cbtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.node-594 {
  .col-three-icon {
    .content-wrap {
      .col-wrap {
        .img-wrap {
          height: auto;
          max-width: 390px;
          max-height: 270px;

          img {
            height: auto;
          }
        }

        .content {
          text-align: left;
          padding-left: 0;
          @include desktop {
            text-align: center;
          }
          @include desktop-down {
            text-align: center;
          }

          &:after {
            display: none;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.node-15,
.node-830,
.node-831,
.node-832,
.node-833,
.node-879,
.node-883 {
  header .top-nav {
    margin-bottom: 0;
  }
}

.node-879 {
  body .data-banner {
    margin-top: 72px;
  }
}

.ua-firefox {
  body .value-calc #form .form-group input {
    padding: 8px 6px 6px;
    height: 34px;
  }

  body .value-calc #form .form-group select {
    height: 34px;
    padding: 4px 6px 6px;
  }
}

.msg-error {
  display: none;
  color: red;
}
/* Glenn Added Hidden breakpoints */
@media (max-width: 480px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}

html[lang='pl'],
html[lang='nl'] {
  .main-nav > ul > li > a:before,
  .main-nav > ul > li > span:before
  // .main-nav > ul > li > a:after,
  // .main-nav > ul > li > span:after,
  {
    display: none !important;
  }
}
.language-changer ul.lite-language {
  visibility: hidden;
}

body[data-page='lite_page'] {
  .dropdown-group {
    background: $white;
    width: 240px;
  }
  .language-changer ul.lite-language {
    visibility: visible;
  }
}

/* 01-13-2025 GH */
.mktoForm {
	max-width: 600px;
    margin: auto;
	padding: 30px 30px 0px 30px;
	background-color: #ffffff;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.mktoHtmlText, .mktoHtmlText p, .mktoHtmlText a {
	font-size: 1em;
	font-family: canada-type-gibson, sans-serif;
    width: auto !important;
	font-weight: 400 !important;
}
.form input, .form select, .form input[type="text"], .form input[type="tel"], .form input[type="email"], .form select.mktoField,
.mktoForm input, .mktoForm select, .mktoForm input[type="text"], .mktoForm input[type="tel"], .mktoForm input[type="email"], .mktoForm select.mktoField, .mktoForm textarea.mktoField {
    width: 100% !important;
    border: 1px solid #acacac !important;
    height: 45px !important;
    text-indent: 15px;
    padding: 0px !important;
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 16px !important;
    font-family: "canada-type-gibson", sans-serif !important;
    font-weight: 300 !important;
}

.mktoButtonRow {
	margin: 0px 0px 10px 0px !important;
    text-align: center !important;
}
.mktoButtonWrap {
	margin: auto !important;
}
.mktoFieldDescriptor.mktoFormCol {
    width: 100% !important;
    margin-bottom: 0px !important;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(https://cpl.thalesgroup.com/sites/default/files/content/marketo/form-arrow-down.png) 100% / 31px no-repeat #fff !important;
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton,
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
	background-color: #00bbdd !important;
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
	background-color: #00bbdd !important;
    padding: 12px 29px !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    border: none !important;
    width: -moz-fit-content;
    min-width: 200px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    font-weight: 600;
    font-size: 16px !important;
	background: none;
    background-image: none !important;
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
	background-color: #00CEE6 !important;
}
form.mktoForm input[type=email]:focus, form.mktoForm input[type=tel]:focus, form.mktoForm input[type=text]:focus, form.mktoForm input[type=url]:focus, form.mktoForm select:focus, form.mktoForm textarea:focus {
    border: 2px solid #285ae6 !important;
    background-color: #f2f6fb !important;
}
.mktoForm textarea.mktoField {
    padding: 10px 15px !important;
    text-indent: 0 !important;
    height: 60px !important;
}
.mktoForm textarea[rows="2"] {
    height: 6.4em !important;
}
.mktoForm .mktoCheckboxList input {
	margin-right: 10px;
}
.mktoForm .mktoFieldWrap {
    float: unset !important;
    width: 100% !important;
}
.mktoForm .mktoLabel {
    float: unset !important;
}
.mktoForm .mktoHtmlText, .mktoForm .mktoHtmlText span, .mktoForm .mktoHtmlText p, .mktoForm .mktoHtmlText p.span {
    font-size: 10px !important;
    line-height: normal !important;
    margin-bottom: 5px !important;
    display: inline-block;
}
.mktoForm input[type=checkbox].mktoField {
    width: 20px !important;
	margin: 0px 10px;
}
.mktoForm .mktoCheckboxList > label {
    margin: 10px !important;
}
body .resource-data .full-row .col-wrap {
    background-color: #ffffff !important;
    padding: 20px !important;
}

/*************** responsive ****************/
@media only screen and (max-width: 480px) {
  .mktoForm .mktoRadioList, .mktoForm .mktoCheckboxList {
    width: auto;
		margin-right: 10px;
	}
	.mktoForm * {
    padding: 0px !important;
  }
  .mktoForm input[type=checkbox].mktoField {
	  margin: 0px 10px 30px !important;
  }
	.mktoButtonRow {
		margin: 0px 0px 20px 0px !important;
	}
	body .resource-data .full-row .col-wrap h2 {
    padding: 10px !important;
	}
}
iframe#formframe {
	max-width: 600px;
    margin: auto;
    padding: 30px 30px 0px 30px;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
iframe.coh-iframe {
	max-width: 600px;
    margin: auto;
    padding: 30px 30px 0px 30px;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
