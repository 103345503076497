.customers-grid {
  border-top: 2px solid $light-sky;
  background: $white2;
  overflow: visible;
  @include container-up {
    padding: 111px 0 36px;
  }
  .wrapper {

    .title-wrap {
      max-width: 800px;
      margin: 0 auto 30px;
      text-align: center;
      h2 {
        color: $light-bluewhile;
        font-size: 24px;
        font-weight: 300;
      }
      @include tablet-down {
        margin-bottom: 40px;
        h2 {
          font-size: 22px;
        }
      }
    }

    .stories-box {
      margin: 0 auto 58px;
      max-width: 380px;
      text-align: center;
      position: relative;
      @include phone-down {
        max-width: 300px;
      }
      &.show {
        span {
          &:after {
            transform: rotate(-136deg);
            top: 58%;
          }
        }
        .success-stories {
          opacity: 1;
          border: 1px solid $white3;
          visibility: visible;
        }
      }
      span {
        position: relative;
        max-width: 380px;
        background: white;
        padding: 14px 20px;
        display: block;
        color: $light-bluewhile;
        font-size: 15px;
        font-weight: 300;
        margin: 0 auto;
        text-align: left;
        border: 1px solid $white3;
        &:before {
          content: '';
          position: absolute;
          text-align: center;
          background: $white4;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 50%;
          right: 10px;
          margin: -10px 0 0;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin: -6px 0 0 -4px;
          right: 16px;
          width: 8px;
          height: 8px;
          border: solid $black4;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }

        @include phone-down {
          max-width: 300px;

        }

      }
      .success-stories {
        border: 1px solid $white3;
        list-style: none;
        text-align: left;
        background: white;
        position: absolute;
        z-index: 1;
        width: 100%;
        opacity: 0;
        max-height: 414px;
        overflow: auto;
        visibility: hidden;
        padding-left: 0;
        li {
          font-size: 18px;
          font-weight: 100;
          color: #656565;
          padding: 7px 20px;
          margin: 0;
          &:hover {
            background: $white7;
            color: $light-bluewhile;
          }
        }

      }
    }

    .content-wrap {
      display: inline-block;
      width: 100%;
      @include phone-down {
        margin: 0 -4px;

      }
      @include tablet-down {
      }
      > div > div {
        > div {

          width: 20%;
          padding: 20px 25px;
          margin: 0 0 12px;
          position: relative;
          z-index: 0;
          float: left;
          display: table;
          min-height: 190px;

          &:before {
            content: '';
            background: $white;
            left: 5px;
            right: 5px;
            top: 0;
            bottom: 0;
            position: absolute;
            z-index: -1;
            border-bottom: 2px solid $blue9;
          }
          @include desktop-down {
            width: 33.333%;
          }
          @include tablet-down {
            width: 50%;
          }
          @include phone-down {
            width: 100%;
          }
          //&:after {
          //  content: '';
          //  clear: both;
          //  display: block;
          //}
        }
      }
      .col-wrap {
        width: 20%;
        padding: 20px 25px;
        margin: 0 0 12px;
        position: relative;
        z-index: 0;
        display: table;
        min-height: 190px;

        &:before {
          content: '';
          background: $white;
          left: 5px;
          right: 5px;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: -1;
          border-bottom: 2px solid $blue9;
        }
        @include desktop-down {
          width: 33.333%;
        }
        @include tablet-down {
          width: 50%;
        }
        @include phone-down {
          width: 100%;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .icon-wrap {
          height: auto;
          width: 100%;
          display: table-cell;
          vertical-align: middle;
          img {
            object-fit: cover;
            width: 100%;
            height: auto;
          }
        }

        &:hover {
          transform: scale(1.05, 1.05);
        }

      }
    }

    .pagination {
      display: flex;
      margin-top: 50px;
      @include tablet-down {
        justify-content: center;
        margin-top: 30px;

      }
      ul {
        display: flex;
        list-style: none;
        @include tablet-down {
          margin: 0 10px;
        }
        li {
          margin: 0 35px 0 0;
          color: $sky-light-blue;
          @include tablet-down {
            margin: 0 10px 0 0;
          }
          &:nth-last-child(1) {
            margin-right: 0;
          }
          a {
            color: $sky-light-blue;
            font-size: 18px;
            font-weight: 300;
            &:hover {
              text-decoration: none;
            }
            &.active {
              color: $black;
            }
          }
        }
      }
    }
  }

  .pager {
    width: 100%;
  }
  .views-exposed-form {
    display: none;
  }

  &.style-guide {
    .wrapper {
      .content-wrap {
        .col-wrap {
          width: 20%;
          padding: 20px 25px;
          margin: 0 0 12px;
          position: relative;
          z-index: 0;
          display: table;
          min-height: 190px;

          &:before {
            content: '';
            background: $white;
            left: 5px;
            right: 5px;
            top: 0;
            bottom: 0;
            position: absolute;
            z-index: -1;
            border-bottom: 2px solid $blue9;
          }
          @include desktop-down {
            width: 33.333%;
          }
          @include tablet-down {
            width: 50%;
          }
          @include phone-down {
            width: 100%;
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          .icon-wrap {
            height: auto;
            width: 100%;
            display: table-cell;
            vertical-align: middle;
            img {
              object-fit: cover;
              width: 100%;
              height: auto;
            }
          }

          &:hover {
            transform: scale(1.05, 1.05);
          }

        }
      }

    }

    .pager {
      width: 100%;
    }
    .views-exposed-form {
      display: none;
    }
  }
  &.sta {
    .wrapper {
      .sta-box {
        margin: 0 auto 58px;
        max-width: 380px;
        text-align: center;
        position: relative;
        @include phone-down {
          max-width: 300px;
        }
        &.show {
          span {
            &:after {
              transform: rotate(-136deg);
              top: 58%;
            }
          }
          .success-stories {
            opacity: 1;
            border: 1px solid $white3;
            visibility: visible;
          }
        }
        span {
          position: relative;
          max-width: 380px;
          background: white;
          padding: 14px 20px;
          display: block;
          color: $light-bluewhile;
          font-size: 15px;
          font-weight: 300;
          margin: 0 auto;
          text-align: left;
          border: 1px solid $white3;
          &:before {
            content: '';
            position: absolute;
            text-align: center;
            background: $white4;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 50%;
            right: 10px;
            margin: -10px 0 0;
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin: -6px 0 0 -4px;
            right: 16px;
            width: 8px;
            height: 8px;
            border: solid $black4;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }

          @include phone-down {
            max-width: 300px;

          }

        }
        .success-stories {
          border: 1px solid $white3;
          list-style: none;
          text-align: left;
          background: white;
          position: absolute;
          z-index: 1;
          width: 100%;
          opacity: 0;
          max-height: 414px;
          overflow: auto;
          visibility: hidden;
          padding-left: 0;
          li {
            font-size: 18px;
            font-weight: 100;
            color: #656565;
            padding: 7px 20px;
            margin: 0;
            &:hover {
              background: $white7;
              color: $light-bluewhile;
            }
          }

        }
      }
    }
    .content-wrap .col-wrap .icon-wrap {
      text-align: center;
      img {
        width: auto;
        margin: 0px auto;
      }
    }
  }

  &.sta {
    .sta-box {
      display: inline-block;
      width: 24%;
      @include tablet-down {
        width: 100%;
        display: block;
        margin-bottom: 20px !important;
      }
    }
    .col-search {
      display: inline-block;
      width: 50%;
      padding-left: 5px;
      @include tablet-down {
        width: 100%;
        display: block;
      }
    }
    .filter-wrapper {
      background-color: $white2;
      width: 100%;
      text-align: center;
      @include desktop-down {
        padding: 15px 30px;
        max-width: 100%;
        width: 100%;
        margin-bottom: 25px;
      }
      @include tablet {
        font-size: 0;
        padding: 0;
      }
      @include tablet-down {
        display: block;
      }
      @include phone-down {
        padding: 10px;
      }
      .input-box {
        width: 100%;
        position: relative;
        display: flex;
        max-width: 100%;
        @include container-down {
          padding-left: 0;
          //max-width: 358px;
          //margin-left: 50px;
        }
        @include desktop-down {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
        @include tablet-down {
          padding-left: 0;
          width: 100%;
          margin: 0 auto;
        }
        @include phone-down {
          max-width: 100%;
        }
        input {
          margin-right: 5px;
          font-size: 15px;
          padding: 0 12px;
          color: $light-bluewhile;
          border: 2px solid $white3;
          height: 52px;
          font-family: $font-families;
          background-color: $white;
          font-weight: 400;
          letter-spacing: 1.4px;
          outline: none;
          max-width: 100%;
          width: calc(100%);
          box-sizing: border-box;
          appearance: none;
          border-radius: 0;
          @include phone-down {
            max-width: 260px;
            margin-right: 3px;
          }
        }
        input::placeholder {
          font-family: $font-families;
          font-size: 15px;
          font-weight: 300;
          line-height: 1.21;
          letter-spacing: normal;
          color: $white3;
        }
        button {
          border: none;
          outline: none;
          display: inline-block;
          vertical-align: middle;
          background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
          background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
          background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
          width: 100%;
          text-decoration: none;
          position: relative;
          z-index: 0;
          text-align: center;
          margin: 0;
          border-radius: 0;
          height: 52px;
          padding: 0px 0;
          max-width: 107px;
          border: 0;
          cursor: pointer;
          color: $white;
          font-size: 18px;
          line-height: 1.38;
          letter-spacing: normal;
          font-family: $font-families;
          overflow: hidden;
          @include phone-down {
            max-width: 75px;
          }
          span {
            font-family: $font-families;
            position: relative;
            font-size: 16px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
