.accordian {
  .accordian-box {
    background: $white2;
    margin-bottom: 10px;
    .heading-wrapper {
      border-top: 2px solid $blue2;
      text-align: center;
      padding: 35px 10px;
      cursor: pointer;
      &.open {
        h3 {
          &:before {
            transform: rotate(-135deg) translate(-4px, -4px);
          }
        }
      }
      h3 {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: $light-bluewhile;
        font-weight: 300;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: top;
          width: 12px;
          height: 12px;
          border: solid $light-bluewhile;
          border-width: 0 2px 2px 0;
          position: relative;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          margin: 3px 9px 0 0;
          top: 50%;
          -webkit-transition: all ease 0.2s;
          transition: all ease 0.2s;
        }
      }
    }
    .box-wrapper {
      display: none;
      flex-wrap: wrap;
      padding: 0 15px 20px;
      justify-content: space-between;
      .box {
        width: calc(33.33% - 16px);
        text-align: center;
        margin-bottom: 38px;

        @include desktop-down {
          width: calc(50% - 21px);
        }
        @include phablet-down {
          width: calc(100% - 32px);
        }

        //margin: 0 6px;
        .inner-wrapper {
          margin: 0 auto;
          max-width: 315px;
          background: $white;
          padding: 45px 40px 40px;
          @include desktop-down {
            padding: 30px;
          }
          .img-wrapper, a.img-wrapper {
            max-width: 135px;
            display: block;
            margin: 0 auto 19px;
            height: 135px;
            img {
              height: 100%;
            }
          }
          h2 {
            //font-size: 24px;
            font-weight: 300;
            line-height: 26px;
            color: #050735;
            margin: 0 0 37px;
            text-transform: uppercase;
            word-break: break-word;
            @include desktop {
              //font-size: 28px;
            }
            //@include tablet {
            //  font-size: 22px;
            //}
            //@include phablet {
            //  font-size: 20px;
            //}
            @include phablet-down {
              //font-size: 18px;
              margin-bottom: 17px;
            }
          }
          ul {
            list-style: none;
            text-align: left;
            padding-bottom: 19px;
            padding-left: 0;
            li {
              margin: 0 0 19px;
              font-weight: 100;
              @include phablet-down {
                margin-bottom: 10px;
              }
              a {
                color: $black;
                text-decoration: none;
                position: relative;
                font-weight: 200;
                font-size: 18px;
                word-wrap: break-word;
                &:before {
                  // position: absolute;
                  // top: 0;
                  // bottom: 0;
                  // vertical-align: top;
                  content: "\e90a";
                  font-family: 'icomoon' !important;
                  display: inline-block;
                  position: relative;
                  vertical-align: top;
                  width: 18px;
                  top: 8px;
                  height: 18px;
                  text-align: center;
                  color: $blue3;
                  background: $blue15;
                  border-radius: 50%;
                  font-size: 18px;
                  line-height: 1;
                  padding: 0 0 0 1px;
                  margin: -1px 6px 0 0;
                  transition: all ease-out 0.3s;
                }

                &:hover {
                  &:after {
                    margin-left: 14px;
                  }
                }
              }
            }
          }
          .btn-default{
          font-size: 14px !important;
          }
        }
      }
    }
  }

}
