.half-banner{
    background: #f5f5f5;
   
    strong {
        font-weight: 700 !important;
      }

    .description{
        max-width: 1024px;
        margin: 0 auto;
        background: #fff;
        padding: 20px 40px;
        margin-top: -40px;
        position: relative;
        h1{
            padding: 14px 0 18px 30px;
            font-size: 40px;
            font-weight: 100;
            text-transform: uppercase;
            color: $Midlightblue;
        }
        p{
            padding-left: 30px;
            font-size: 16px;
            line-height: 22px;
            color: $light-bluewhile;    
        }
    }
}



.research-block{
    text-decoration: none;
    background: #f5f5f5;
    padding: 20px 0;
     
    .row{
        max-width: 1170px;
        margin: 0 auto;
    }

    .featured-block{
        // border-top: 5px solid $MidnightBlue;
        border-top: 5px solid $Midlightblue;
        background: #fff;
        padding: 20px;
    }

    a {
        color: $gray;
        display: block;
        font-size: 20px;
        text-align: center;
     }
    h2{
        font-size: 20px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 10px;
        color: #5E5F5F;
    }
    p{
        font-size: 16px;
        text-align: left;
        font-weight: normal;
        margin-bottom: 10px;
        color: #000;
    }
    .featured-img {

        img{
            width: 100%;
        }

    }
    ul{
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        padding: 0;
        li{
            background: url('/themes/thalesesecurity/templates/dist/images/home-next.png') no-repeat left;
            background-size: 12px;
            background-position: 0px 0px;
            padding-left: 14px;
            margin-bottom: 5px;
            list-style: none;
            font-size: 16px;
            color: #000;
        }
    }
    .featured-resources {
        background-color: #F6F6F6;
        border: 1px solid #DDD;
        border-radius: 8px;
        margin-top: 15px;
        padding-top: 7px;
        h4{
            color: #212121;
            font-size: 20px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        li{
            display: inline-block;
            background: none;
            vertical-align: bottom;
        }

        span,a{
            height: 38px;
            width: 38px;
            display: block;
        }
        .resource-link {
            &:hover:before {
                border: solid;
                border-color: #DDD transparent;
                border-width: 6px 6px 0 6px;
                bottom: 36px;
                content: "";
                left: 29%;
                position: absolute;
                z-index: 1;
            }
            &:hover:after {
                background: #DDD;
                border-radius: 5px;
                bottom: 42px;
                color: #5E5F5F;
                content: "Asset";
                left: -10%;
                padding: 5px 15px;
                position: absolute;
                z-index: 98;
                width: 40px;
            }
        }
        .resource-Whitepaper {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) -152px -38px;
            &:hover{
                background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) -152px -38px;               
                &:after {
                content: "Whitepaper";
                width: 62px;
                }
            }
        }

        .resource-Website {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) 0px 0px;
            &:hover{
                background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) 0px 0px;
                &:after{
                        content: "Website";
                        width: 45px;
                    }
            }
        }
        .resource-Infographic {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) -114px -38px;

            &:hover{
                background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) -114px -38px;
                &:after{
                    content: "Infographic";
                    width: 60px;
                }
            }
        }
        // .resource-Website {
        //     background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) 146px 39px;
        //     &:hover{
        //         background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) 146px 39px;
        //         &:after{
        //             content: "Website";
        //             width: 45px;
        //         }
        //     }
        // }

        // .resource-Blogpost{
        //     background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) 0px 40px;
        //     &.hover{
        //         background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) 0px 40px;
        //     }
        // }
        // .resource-Productbrief{
        //     background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) 0px -37px;
        //     &.hover{
        //         background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) 0px -37px;
        //     }
        // }
        // .resource-Brochure{
        //     background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-orange.png) 0px 0px;
        //     &.hover{
        //         background: url(/themes/thalesesecurity/templates/assets/images/research-asset-icons-purple.png) 0px 0px;
        //     }
        // }

         .resource-link-disabled {
            display: inline-block;
            position: relative;
            width: 38px;
            height: 38px;
            margin-right: 12px;
            vertical-align: bottom;

         }
        .resource-Casestudy-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -38px -76px;
        }
        .resource-Blogpost-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -76px -38px;
        }
        .resource-Productbrief-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) 0px -76px;
        }
        .resource-Brochure-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) 0px -38px;
        }
        .resource-Website-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) 0px 0px;
        }
        .resource-Executivesummary-disabled { 
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -76px -76px;
        }
        .resource-Video-disabled{ 
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -38px -38px;
        }
        .resource-Whitepaper-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -152px -38px;
        }
        .resource-Infographic-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -114px -38px;
        }
        .resource-Guide-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -152px 0px;
        }
        .resource-Pressrelease-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -114px 0px;
        }
        .resource-Solutionbrief-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -38px 0px;
        }
        .resource-Webinar-disabled {
            background: url(/themes/thalesesecurity/templates/dist/images/research-asset-icons-grey.png) -76px 0px;

        }
    }
}




.research-three-block {
    background: #f5f5f5;
    .title{
        padding: 20px 0;
        h2{
            font-size: 28px;
            text-align: left;
            color: $light-bluewhile;
        }
    }
    .item{
        max-width: 920px;
        margin: 0 auto;
        padding: 0 20px;
        background-color: #fff;
        border-top: 5px solid $Midlightblue;
        padding: 10px 30px 30px;
       
        .image{
            margin: 20px 0;
            display: block;
            position: relative;
            height: 150px;
            img{

                max-height: 176px;
                max-width: 257px;
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        a {  
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: normal;
            font-size: 17px;
            color: $Midlightblue;
        }
        p{
            line-height: 1.6;
            padding: 0 0 1em;
            color: #212121;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 400;
        }
    }
}