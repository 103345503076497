.logo-block {
  background: $whitesmoke;
  padding: 28px 0 18px;
  @include tablet-down {
    padding: 30px 0;
  }
  &.home-logo-block {
    background: $white;
    border-bottom: 2px solid $white8;
    @include container-up {
      padding: 22px 0 15px;

    }
  }
  .logo-wrap {
    display: inline-flex;
    @include tablet-down {
      width: 100%;
      display: inline-block;
    }
    ul {
      padding: 0 30px;
      display: flex;
      margin: 0;
      @include container-down {
        padding: 0;
      }
      @include tablet-down {
        padding: 0;
        width: 100%;
        display: block;
      }
      .logo-wrap {
        max-width: 200px;
        max-height: 100px;
        list-style: none;
        margin-left: 86px;
        margin-bottom: 0;
        @include desktop-down {
          margin-left: 0;
        }
        @include tablet-down {
          margin: 0 auto;
          width: 100%;
          padding: 0 0 30px;
          display: block;
        }
        &:first-child {
          margin-left: 0;
          @include tablet-down {
            margin: 0 auto;
            width: 100%;
            padding: 0 0 30px;
            display: block;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .color-link {
      margin-left: 20px;
      padding-right: 0;
      padding-top: 2px;
      float: right;
      font-weight: 300;
      color: $MidnightBlue;
      display: flex;
      font-size: 20px;
      &:hover {
        text-decoration: none;
      }
      .arrow-color {
        border: 2px solid $MidnightBlue;
        top: 4px;
        &:after {
          border: solid $MidnightBlue;
          border-width: 0 2px 2px 0;
        }
      }
      @include container-down {
        padding-right: 50px;
        .arrow-color {
          right: -8px;
          &:hover {
            right: -10px;
          }
        }
      }
      @include tablet-down {
        margin-left: 0;
        padding: 0;
        float: none;
        justify-content: center;
        width: 100%;
        display: flex;
        .arrow-color {
          width: 18px;
          height: 18px;
          top: 5px;
          bottom: 0;
          left: 18px;
          right: 0;
          margin: 0;
          &:after {
            right: 2px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}




