// style the pagination globally //
.pagination,
.pager {
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    @include tablet-down {
        justify-content: center;
        margin-top: 40px;
    }
    a {
        color: $blue6;
        font-size: 18px;
        font-weight: 300;
        &:hover {
            text-decoration: none;
        }
    }
    ul {
        display: flex;
        list-style: none;
        margin: 0 20px;
        @include tablet-down {
            margin: 0 10px;
        }
        li {
            margin: 0 35px 0 0;
            color: $sky-light-blue;
            @include tablet-down {
                margin: 0 10px 0 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
            a {
                color: $sky-light-blue;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-weight: 400;
                span {
                    font-weight: 300;
                }
                &:hover {
                    font-size: 18px;
                }
            }
            &.is-active {
                a {
                    color: $black;
                }
            }
        }
    }
}