&.toolbar-tray-open .sticky-nav {
  top: 200px;
}

.sticky-nav {
  width: 100%;
  padding: 10px 0 10px 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-transition: top 0.5s;
  //transition: top 0.5s;
  background-color: $MidnightBlue;
  position: fixed;
  z-index: 10;
  top: 106px;
  border-top: 7px solid $Midlightblue;
  transition: 0.5s;
  transform: translateY(0);
   
  @include media-breakpoint-down(sm) {
    top: 91px;
  }
  strong {
    font-weight: 700 !important;
  }
  &.bg-pink {
    background-color: $MidnightBlue;
    border-color: $Midlightblue;

    .right-nav {
      a {
        @include media-breakpoint-down(md) {
          padding: 7px 4px 10px;
          font-size: 13px;
        }

        &:first-child {
          @include media-breakpoint-down(md) {
            margin-top: 12px;
          }
        }
      }
    }
  }
  a {
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    // font-family: $font-familie-dinpro;
    font-weight: 500;
  }
  .left-nav {
    float: left;
    margin-left: 26px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 0.01em;
      padding-right: 16px;

      @include media-breakpoint-down(md) {
        padding-right: 7px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 166px;
        vertical-align: middle;
      }
      .tool-tip {
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
      }
      .tt-cont {
        position: absolute;
        z-index: 102;
        height: 24px;
        width: 140px;
        display: inline-block;
        margin-top: 5px;
        left: 34px;
        .tt-arrow {
          position: absolute;
          display: inline;
          width: 9px;
          height: 9px;
          border: 9px solid transparent;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top-color: #000;
          top: 10%;
          left: -15px;
          transform: rotate(90deg);
        }
        .tt-text {
          position: absolute;
          background-color: #000000;
          color: #ffffff;
          display: inline;

          line-height: 1em;
          font-weight: normal;
          border-radius: 3px;
          left: 3px;
          padding: 7px 9px 7px 8px;
          font-size: 12px;
          top: -1px;
        }
      }

      span {
        display: block;
        font-size: 0.8em;
        font-weight: normal;
        letter-spacing: 8px;
        text-transform: uppercase;
        line-height: 1;
      }

      &:hover {
        text-decoration: none;
      }
    }
    .icon {
      vertical-align: top;
      @include tablet-down {
        vertical-align: middle;

      }

    }
  }
  .right-nav {
    float: right;
    margin-right: 53px;
    font-size: 0;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
    @include media-breakpoint-down(md) {
      float: left;
      width: 100%;
      margin: 10px 0;

    }
    a {
      display: inline-block;
      border-radius: 4px;
      background-color: transparent;
      transition: 0.3s ease;
      letter-spacing: 0.02em;
      padding: 7px 10px 7px;

      @include media-breakpoint-only(lg) {
        font-size: 13px;
      }
      @include media-breakpoint-down(md) {
        display: block;
      }
      &:hover {
        border-radius: 4px;
        background-color: $Midlightblue;
      }
      &.active {
        border-radius: 4px;
        background-color: $Midlightblue;
        &:hover {
          border-radius: 4px;
          opacity: 0.9;
          }
      }
    }
  }

  .burger-menu {
    display: none;
    position: relative;
    float: right;
    cursor: pointer;
    width: 25px;
    height: 14px;
    @include desktop-down {
      margin: 8px 0;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }

    span {
      position: absolute;
      height: 3px;
      width: 100%;
      background: $white;

      &:first-child {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:last-child {
        top: 100%;
      }
    }
  }


  &.nav-up {
    //transform: translateY(-200%);
    transform: translateY(-173px);
    //opacity: 0;
    //visibility: hidden;
    z-index: 1;
  }
}
