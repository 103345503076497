.thanks-intro {
  padding-top: 62px;
  background-color: #f5f5f5;

  .column-wrapper {
    padding: 0 0 0 20px;
    max-width: 1070px;
    margin: 0 auto;
    @include desktop-down {
      padding: 0;
    }
    .text-wrap {
      padding: 0 40px 0 20px;
      @include desktop-down {
        padding-left: 0;
      }
      @include phablet-down {
        text-align: center;
        padding: 0;
      }
      h2 {
        font-size: 2em;
        font-weight: bold;
        color: $black;
      }
      p {
        padding-top: 20px;
        font-size: 16px;
        color: $black;
        font-weight: 400;
      }
      img {
        width: auto;
        object-fit: cover;
      }
      .pdf-btn {
        margin-top: 30px;
        @include phablet-down {
          margin-bottom: 20px;
        }
      }
      .asat-bluebtn {
        background-color: #00bbdd;
        display: inline-block;
        border-radius: 3px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 12px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          text-decoration: none;
          background-color: #25899B;
        }
      }
      .failure {
        display: none;
        text-align: center;
        width: 300px;
        margin-top: 20px;
        .errormsg {
          display: none;
        }
        .backbtn {
          &:hover {
            text-decoration: none;
          }
        }
      }
      &.img-wrap {
        padding: 0 20px;
        @include desktop-down {
          padding: 0;
        }
        .bg-img {
          position: relative;
          img {
            border: solid 1px #bbbbbb;
            height: 100%;
          }

        }
        .content {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          padding: 25px 56px 250px 50px;
          @include desktop-down {
            padding: 20px;
          }
          @include phablet-down {
            max-width: 400px;
            margin: auto;
            text-align: center;
            padding: 50px;
          }
          h4 {
            font-weight: bold;
            font-size: 1.3em;
            line-height: 1.4em;
            color: $black;
          }
          .visit-btn {
            margin-top: 20px;
            background-color: #00bbdd;
            display: inline-block;
            border-radius: 3px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            transition: all 0.3s;
            font-size: 16px;
            padding: 3px 10px;
            &:hover {
              text-decoration: none;
              background-color: #25899B;
            }
          }
        }
      }
    }

  }
}


