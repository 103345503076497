.col-three-card {
  .full-row {
    .col-wrap {
      padding: 0 15px;
      padding-bottom: 30px;

      .inner-col {
        position: relative;
        text-align: center;
        border: 2px solid #2c99b5;
        padding: 30px;
        height: 100%;
        a {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
        .icon-img {
          display: inline-block;
          max-width: 87px;
          margin-bottom: 40px;
          img {
            width: 100%;
            height: 100%;

          }
        }
        h4 {
          color: $light-bluewhile;
        }

      }

    }

  }

}