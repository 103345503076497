.col-three-slider {
  opacity: 0;
  @include container-up {
    padding: 0;
  }
  .slider-wrap {
    .title-wrap {
      padding-bottom: 66px;
      h2 {
        //font-size: 30px;
        color: $light-bluewhile;
        font-weight: 300;
        text-align: center;
      }
    }

    .slider {
      width: 100%;
      .item {

        .full-row {
          .col-wrap {
            transition: all ease 0.3s;
            background-color: $dark-light-grey;
            border: solid $mid-light-sky;
            color: $light-bluewhile;
            border-width: 0 0 2px 0;
            margin: 0 10px 0;
            cursor: pointer;
            min-height: 261px;
            @include desktop {
              min-height: 313px;
            }
            @include tablet {
              min-height: 313px;
            }
            @include tablet-down {
              min-height: 300px;
            }
            a {

              .content {
                padding: 32px 20px 32px 22px;
                text-align: center;
                backface-visibility: hidden;
                perspective: unset;
                span {
                  transition: all ease 0.3s;
                  font-size: 22px;
                  font-weight: 100;
                  color: $light-bluewhile;
                }
                h4 {
                  position: relative;
                  transition: all ease 0.5s;
                  color: $light-bluewhile;
                  font-size: 22px;
                  font-weight: 300;
                  padding-top: 27px;
                  &:before {
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 50.4%;
                    transform: translateX(-50%);
                    width: 87px;
                    height: 2px;
                    background: $blue-light;
                  }
                }
              }
            }
            &:hover {
              a {
                text-decoration: none;
                .content {
                  span {
                    color: $sky-light-blue;
                  }
                  h4 {
                    color: $sky-light-blue;
                  }
                }
              }
            }
          }
        }
      }
      .slick-arrow {
        outline: 0;
        position: absolute;
      }
      .slick-prev {
        top: 45%;
        left: -44px;
        background: $white-light;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: $dark-blue;
        z-index: 2;
        display: block;
        font-size: 0;
        line-height: 0;
        position: absolute;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        &:before {
          font-family: 'icomoon' !important;
          content: "\e909";
          font-size: 23px;
          opacity: 1;
          color: $dark-blue;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
        }
      }
      .slick-next {
        z-index: 1;
        top: 45%;
        right: -44px;
        display: block;
        background: $white-light;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: $dark-blue;
        font-size: 0;
        line-height: 0;
        position: absolute;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        &:before {
          opacity: 1;
          content: "\e908";
          font-family: 'icomoon' !important;
          font-size: 23px;
          color: $dark-blue;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
        }
      }
      .slick-dots {
        text-align: center;
        list-style-type: none;
        padding-top: 40px;
        cursor: pointer;
        li {
          list-style-type: none;
          display: inline-block;
          height: 16px;
          width: 16px;
          background-color: $light-grey;
          border-radius: 50%;
          margin: 0 4px;
          button {
            font-size: 0;
          }
          &.slick-active {
            background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%)
          }
        }
      }
    }
  }
}


