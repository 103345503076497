
 .overlay-active {
  overflow: hidden;
  .video-overlay {
    visibility: visible;
    opacity: 1;
    z-index: 99999;
    .video-container {
      opacity: 1;
      animation: fade-Up 1.5s ease-in-out both;
    }
  }
}
.video-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.5s ease;
  opacity: 0;
  height: 100vh;
  z-index: -1;
  visibility: hidden;
  iframe {
    border: none;
    width: 100%;
  }
  .v-middle-inner {
    display: table;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    .v-middle {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .video-container {
    position: relative;
    max-width: 840px;
    padding: 0 20px;
    margin: 0 auto;
  }
}
.close-video {
  height: 30px;
  width: 30px;
  //border: 1px solid $primary3;
  padding: 1px 1px 0 0;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  right: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 13px;
    left: 4px;
    width: 20px;
    height: 2px;
    background: $white;
    transition: all 0.3s ease-in-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(130deg);
  }
  &:hover {
    border-color: $white;
    &:after,
    &:before {
      background: $white;
    }
  }
}
















