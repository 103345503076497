.legal-block-three{
    margin-top: 20px;
    background-color: $white2;
    @include container-up{
        padding: 106px 0 82px;
    }

    .content{
        max-width: 856px;
        margin: 0 auto 30px;
        text-align: center;
        h2{
            color: $light-bluewhile;
            padding-bottom: 12px;
            font-weight: 300;
            font-size: 30px;
            @include phablet-down {
                font-size: 24px;
               }
        }
        p{
            color: $light-bluewhile;
            font-size: 22px;
            a{
            color:$blue5 ;
            font-size: 22px;
            }
            @include tablet-down {
                font-size: 20px;
                a{
                    font-size: 20px;
                }
            }
        }
    }
    .wrapper{
        text-align: center;
        .icon-img{
            height: 80px;
            max-width: 80px;
            margin: 0 auto;
            width: 100%;
            img{
             object-fit: cover;
             width: 100%;
             height: 100%;
            }
        }
        .content{
            padding-top: 20px;
            h4{
              color: $light-bluewhile;
              font-size: 22px;
              font-weight: 300;
            }
            .blue-arrow{
                font-size: 20px;
                color: $light-bluewhile;
                span{
                    border: 2px solid $MidnightBlue;
                      &:after {
                      border: solid $MidnightBlue;
                      border-width: 0 2px 2px 0;
                      }
                }
            }
        }       
    }
}

