.footer-cta {

    .full-container {
        width: 100%;
        .col-wrap {
            width: 33.333%;
            float: left;
            padding: 0 4px 0 0;
            position: relative;
            >a {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 3;
                cursor: pointer;
            }
            @include desktop-down {
                padding: 0;
                margin-bottom: 4px;
                flex: none;
                max-width: 100%;
                width: 100%;
            }
            &:nth-child(2n + 3) {
                padding: 0 0 0 0;
            }
            .bg-img {
                // height: 276px;
                width: 100%;
                position: relative;
                background-color: $black;
                z-index: 1;
                height:  380px;
                &:before {
                    content: "";
                    position: absolute;
                    background-color: $black;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0.5;
                    transition: all .3s ease-in-out;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    // 20220421 font-family: 'object-fit: cover, object-position: right;';
                    object-position: left;
                }
            }
            .content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 0;
                width: 100%;
                padding: 0 20px;
                text-align: center;
                z-index: 1;
                h3 {
                    padding-bottom: 15px;
                    font-weight: 300;
                    color: $white;
                    padding: 5px 0px;
                    font-size: 20px;

                }
                p {
                    color: $white;
                    margin-bottom: 0;
                    padding-bottom: 20px;
                    font-weight: 200;
                    font-size:  16px;
                }
                .btn-default {
                    // padding: 0 24px 0 22px;
                    a {
                        line-height: 34px;
                        font-size: 16px;
                        color: $white;
                        font-weight: 300;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                @include desktop-down {
                    h3 {
                        line-height: 1.2;
                        padding: 5px 0 0 0;
                        font-size: 26px;
                    }
                    p {
                        font-size: 16px !important;
                    }
                }
            }
            &:hover {
                .bg-img {
                    &:before {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    &.two-col {
        .full-container {
            .col-wrap {
                width: 50%;
                // flex: none;
                flex: 0 0 50%;
                display: inline-flex;
                margin: 0;
                padding: 0 4px 0 0;
                @include desktop-down {
                    margin-bottom: 4px;
                    flex: none;
                    max-width: 100%;
                    width: 100%;
                    padding: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    &.single-col {
        .full-container {
            .col-wrap {
                padding: 0;
                max-width: 100%;
                width: 100%;
                display: inline-flex;
                .bg-img {
                    //height: 100%;
                }
                .content {
                    max-width: 1260px;
                    padding: 0 20px;
                    h3 {
                        margin-bottom: 0;
                        @include container-up {
                            font-size: 30px;
                        }
                        line-height: 1.4;
                        padding-bottom: 4px;
                    }
                    p {
                        font-size: 22px;
                        margin-bottom: 0;
                        padding-bottom: 20px;
                        font-weight: 200;
                    }
                }
            }
        }
    }
    &.col-two-block {
        padding: 86px 0 76px;
        background-color: $white2;
        @include phablet-down {
            padding: 40px 0;
        }
        .full-container {
            position: relative;
            width: 100%;
            margin: 0 auto;
            padding: 0 20px;
            max-width: 1260px;
            .full-row {
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .col-wrap {
                    width: 50%;
                    float: left;
                    padding: 0 15px;
                    @include tablet-down {
                        margin-bottom: 30px;
                        padding: 0;
                        width: 100%;
                    }
                    &:last-child {
                        @include tablet-down {
                            margin-bottom: 0;
                        }
                    }
                    .content {
                        position: relative;
                        max-width: 510px;
                        margin: 0 auto;
                        text-align: center;
                        left: 0;
                        top: 0;
                        transform: none;
                        z-index: 0;
                        h3 {
                            color: $light-bluewhile;
                            font-weight: 300;
                            margin-bottom: 0;
                            @include container-up {
                                font-size: 30px;
                            }
                        }
                        p {
                            line-height: 1.6;
                            margin-bottom: 0;
                            color: $light-bluewhile;
                            font-size: 22px;
                            font-weight: 100;
                            @include tablet {
                                font-size: 18px;
                            }
                            @include tablet-down {
                                font-size: 16px;
                            }
                        }
                        .btn-default {
                            padding: 0 24px 0 22px;
                            a {
                                line-height: 34px;
                                font-size: 16px;
                                color: $white;
                                font-weight: 300;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.single-col {
            .full-container {
                .full-row {
                    @include tablet-down {
                        margin: 0;
                    }
                    .col-wrap {
                        width: 100% !important;
                    }
                }
            }
        }
        &.three-col {
            .full-container {
                .full-row {
                    @include tablet-down {
                        margin: 0;
                    }
                    .col-wrap {
                        width: 33.33% !important;
                        @include tablet-down {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
    .in-container {
        .full-container {
            max-width: 1260px;
            margin: auto;
        }
    }
    .bg-purple {}
}
