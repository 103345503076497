.data-banner{
    background: url(/themes/thalesesecurity/templates/dist/images/bannernew.jpg) no-repeat top center #2b0370;
    padding: 30px 0;
    margin-top: 105px;
    background-size: cover !important;
    width: 100%;
    h1,p,a{
        //    font-family: $font-familie-dinpro;
        // font-family: dinpro;
    
    }
    .col-wrap{

        .white-box{
            background-color: white;
            padding: 25px 35px 20px;
            border-radius: 0px 25px 25px 25px;
        }
        h1{
            color:$light-bluewhile ;
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 400;
        }
        p{
            font-size: 16px;
            line-height: 1.6;
        padding: 0 0 1em;
        color: #212121;
        }
        a{
        text-decoration: none;
        padding: 5px 15px 10px;
        margin-bottom: 3px;
        display: inline-block;
        border-radius: 3px;
        font-weight: bold;
        background-color: #b90b67;
        color: $white !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        }

    }


    

    &.software-update-banner{
    padding: 40px 0 78px;
    background-size: 100%;
    border-top: 7px solid $MidnightBlue;
    background: url(/themes/thalesesecurity/templates/assets/images/banner-v11.jpg) no-repeat center center #ffffff;
     @include desktop-down {
        margin-top: 0;
        padding: 40px 0;
      }
      
     .col-wrap{
        padding-left: 55px;
        padding-right: 14px;
        @include desktop-down {
        padding:0 30px;
        }
     
        @include phablet-down {
          padding: 0 20px;
        }
        .white-box{
            background-color: $white;
            padding: 25px 36px 22px;
            border-radius: 0px 25px 25px 25px;
            @include desktop-down {
                padding: 20px;
            }
            @include phablet-down {
              padding: 20px;
              text-align: center;
            }
        }
       
        h1{
            color: $light-bluewhile;
            font-size: 32px;
            font-weight: 400;
            line-height: 1.1;
            margin-bottom: 20px;
            @include desktop-down {
                font-size: 28px;
            }
            @include phablet-down {
                font-size: 22px;
            }
        }
        p{
            font-size: 16px;
            line-height: 1.6;
            font-weight: 400;
            padding: 0;
           color: $light-bluewhile;
           @include phablet-down {
               font-size: 12px;
           }
        }
     }
    }
  
    
    &.gdpr-banner{
     padding: 60px 0 104px;
     border-top: 7px solid $MidnightBlue;
     background: url(/themes/thalesesecurity/templates/assets/images/eu-bg.jpg) no-repeat center center #ffffff;
     @include desktop-down {
        margin-top: 0;
        padding: 40px 0;
      }
     .col-wrap{
         padding-left: 58px;
         padding-right: 0;
         @include container-down {
            padding:0 20px;
          }
        .white-box{
            background-color: white;
            padding: 25px 35px 20px;
            @include desktop-down {

                padding: 30px 30px 20px;
             }
             @include phablet-down {
                 text-align: center;
                 padding: 20px 15px 10px;
             }
        }
        h1{
            color:$light-bluewhile ;
            font-size: 32px;
            margin-bottom: 10px;
            font-weight: 400;
            @include desktop-down {
                font-size: 28px;
                line-height: 1.2;
            }
            @include phablet-down {
                font-size: 22px;
                line-height: 1.2;
            }
        }
        p{
            font-size: 27px;
            margin-bottom: 0;
            font-weight: 300;
            color:$light-bluewhile ;
            @include desktop-down {
                font-size: 24px;
            }
            @include phablet-down {
                font-size: 18px;
                line-height: 1.5;

            }
        }
     }

    }
   
    
  
}




.featured{
    background: #f5f5f5;
    h2{
        font-weight: bold;
    font-size: 1.3em;
    margin: 0 0 5px 0;
    color: #212121;
    text-align: center;
    }
    h3{
        font-weight: bold;
        font-size: 1.3em;
        margin: 0 0 5px 0;
        color: #212121;
    }
    p{
        font-size: 16px;
        line-height: 1.6;
        color: #212121;
        margin: 0;
        padding: 0 0 10px 0;
    }
    img {
        margin: 30px 0 10px 0;
    }
    .hidef {
        display: none;
    }
    .expand {
        background: url(/themes/thalesesecurity/templates/dist/images/plus1.png) center center no-repeat;
        width: 100%;
        height: 18px;
        cursor: pointer;
    }
    .minus {
        background-image: url(/themes/thalesesecurity/templates/dist/images/minus.png);
    }
}
.resource-type{
    h2,a,p{
        // font-family: dinpro;
    }
    h2{
        font-size: 2em;
        color: #b90b67;
        font-weight: 400;
        margin-bottom: 10px;
    }
    p{
        padding: 0 0 1em;
        color: #212121;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
    }
    a{
        padding: 5px 15px 10px;
        margin-bottom: 3px;
        display: inline-block;
        border-radius: 3px;
        font-weight: bold;
        color: white !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background-color: #b90b67;
        border: solid 1px #b90b67;
        text-decoration: none;
    }
    .col-sm-3{
        text-align: center;
    }
    .col-sm-7{
        margin-left: 8.33333333%;
    }
}
.testinomial{
    background-color: #b90b67;
    padding: 30px 0 50px;
    img{
        max-width: 200px;
        max-height: auto;
        border-radius: 100px;
        overflow: hidden;
    }
    .image{
        text-align: end;
    }
    .item{
        padding: 0 40px;
    }
    blockquote {
        font-size: 1.3em;
        line-height: 1.2em;
        margin-top: 20px;
        color: white;
        &:before {
            content: '"';
            left: 0;
            font-size: 1.8em;
            line-height: 0.6em;
            position: absolute;
        }
        &:after {
            content: '"';
            padding-left: 10px;
            font-size: 1.8em;
            line-height: 0.6em;
            position: absolute;
        }
    }
}