.blog-article {
  @include container-up {
    //padding: 0;
  }
  .full-row {
    margin: 0;
    height: 100%;
    padding: 10px 0;
    background: $white2;
    .col-wrap {
      max-width: 23%;
      padding: 0 0 0 10px;
      @include desktop-down {
        max-width: 50%;
      }
      @include phone-down {
        max-width: 100%;
        padding-bottom: 30px;
      }
      .category-box {
        margin: 0 auto;
        max-width: 380px;
        text-align: center;
        position: relative;
        @include phone-down {
          max-width: 300px;
        }
        &.show {
          span {
            &:after {
              transform: rotate(-136deg);
              top: 58%;
            }
          }
          .option-category {
            border: 1px solid $white3;
          }
        }
        span {
          position: relative;
          max-width: 380px;
          background: white;
          padding: 12px 14px;
          display: block;
          color: $light-bluewhile;
          font-size: 15px;
          font-weight: 300;
          margin: 0 auto;
          text-align: left;
          border: 1px solid $white3;
          cursor: pointer;
          a {
            font-size: 14px;
            color: $light-bluewhile;
            &:hover {
              text-decoration: none;
            }
          }
          &:before {
            content: '';
            position: absolute;
            text-align: center;
            background: $white4;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 50%;
            right: 10px;
            margin: -10px 0 0;
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin: -6px 0 0 -4px;
            right: 16px;
            width: 8px;
            height: 8px;
            border: solid $black4;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }
          @include phone-down {
            max-width: 300px;
          }
        }
        .option-category {
          border: 1px solid $white3;
          list-style: none;
          text-align: left;
          background: white;
          position: absolute;
          z-index: 1;
          width: 100%;
          display: none;
          height: 265px;
          overflow: scroll;
          padding-left: 0;
          li {
            font-size: 18px;
            font-weight: 100;
            color: $grey11;
            margin: 0;
            padding: 7px 20px;
            cursor: pointer;
            a {
              font-size: 18px;
              color: $grey11;
            }
            &.selected {
              color: $blue11;
              background: $whitesmoke;
              font-weight: 400;
            }
          }
        }
      }
      &.col-search {
        background-color: $white2;
        display: flex;
        max-width: 53%;
        align-items: center;
        width: 100%;
        @include desktop-down {
          padding: 15px 30px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 25px;
        }
        @include tablet-down {
          display: block;
        }
        @include phone-down {
          padding: 10px;
        }
        .input-box {
          width: 100%;
          position: relative;
          display: flex;
          max-width: 100%;
          @include container-down {
            padding-left: 0;
            max-width: 358px;
            margin-left: 50px;
          }
          @include desktop-down {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
          }
          @include tablet-down {
            padding-left: 0;
            width: 100%;
            margin: 0 auto;
          }
          @include phone-down {
            max-width: 100%;
          }
          input {
            margin-right: 5px;
            font-size: 15px;
            padding: 0 12px;
            color: $light-bluewhile;
            border: 2px solid $white3;
            height: 45px;
            font-family: $font-families;
            background-color: $white;
            font-weight: 400;
            letter-spacing: 1.4px;
            outline: none;
            max-width: 100%;
            width: calc(100%);
            box-sizing: border-box;
            appearance: none;
            border-radius: 0;
            @include phone-down {
              max-width: 260px;
              margin-right: 3px;
            }
          }
          input::placeholder {
            font-family: $font-families;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.21;
            letter-spacing: normal;
            color: $white3;
          }
          button {
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: middle;
            background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
            background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
            background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
            width: 100%;
            text-decoration: none;
            position: relative;
            z-index: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
            height: 45px;
            padding: 0px 0;
            max-width: 107px;
            border: 0;
            cursor: pointer;
            color: $white;
            font-size: 18px;
            line-height: 1.38;
            letter-spacing: normal;
            font-family: $font-families;
            overflow: hidden;
            @include phone-down {
              max-width: 75px;
            }
            span {
              font-family: $font-families;
              position: relative;
              font-size: 16px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .columm-wrap {
    border-bottom: 2px solid $blue9;
    margin: 20px 0 0;
    width: 100%;
    .item-box {
      display: flex;
      border-radius: 0;
      width: 100%;
      min-height: 226px;
      @include tablet-down {
        display: block;
      }
      .col-two {
        float: none;
        &.img-wrap {
          width: inherit;
          max-width: 226px;
          display: flex;
          align-items: center;
          .bg-img {
            height: 100%;
            align-self: center;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          @include desktop-down {
          }
          @include tablet-down {
            display: block;
            margin: 0 auto;
          }
        }
        &.text-wrap {
          background-color: $whitesmoke;
          width: calc(100% - 226px);
          padding: 8px 20px 10px 38px;
          .item-article {
            .author-with-date {
              padding-bottom: 7px;
              span {
                font-weight: 300;
                font-size: 14px;
                color: $light-bluewhile;
              }
              .date {
                color: $light-bluewhile;
                padding: 0 0 0 13px;
                margin: 0 0 0 9px;
                position: relative;
                &:before {
                  content: '';
                  position: absolute;
                  top: 1px;
                  bottom: 3px;
                  left: 0;
                  width: 2px;
                  height: 14px;
                  background: $black;
                }
              }
            }
            a {
              font-size: 22px;
              position: relative;
              color: $light-bluewhile;
              font-weight: 300;
              line-height: 1.22;
              text-decoration: none;
              &:before {
                content: '';
                position: absolute;
                bottom: -4px;
                opacity: 0;
                height: 2px;
                width: 100%;
                background: #253746;
                transition: all linear .3s;
              }
              &:hover {
                text-decoration: none;
                &:before {
                  bottom: 0;
                  opacity: 1;
                  transition: all linear .3s;
                }
              }
            }
            p {
              color: $light-bluewhile;
              margin: 0;
              padding-bottom: 6px;
              padding-top: 2px;
            }
            .active-link {
              color: $black;
              font-size: 18px;
              font-weight: 100;
              text-decoration: none;
              &:after {
                top: 4px;
              }
              &:hover {
                text-decoration: none;
                &:after {
                  margin: 0 0 0 27px;
                }
                &::before {
                  display: none;
                }
              }
            }
          }
          @include tablet-down {
            // display: block;
            width: 100%;
            padding: 30px 20px 20px 50px;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    margin-top: 50px;
    @include tablet-down {
      justify-content: center;
      margin-top: 30px;
    }
    ul {
      margin: 0;
      display: flex;
      list-style: none;
      @include tablet-down {
        margin: 0 10px;
      }
      @include phone-down {
        display: unset;
        margin: 0;
      }
      li {
        margin: 0 35px 0 0;
        color: $blue6;
        @include tablet-down {
          margin: 0 10px 0 0;
        }
        @include phone-down {
          display: inline-block;
          margin: 0;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          color: $blue6;
          font-size: 22px;
          font-weight: 300;
          &:hover {
            text-decoration: none;
          }
          &.active {
            color: $black;
          }
        }
        .gap {
          color: $grey10;
          margin-right: 4px;
        }
      }
    }
  }
  .views-exposed-form {
    display: none;
  }
  &.horizon {
    .columm-wrap {
      .item-box {
        .col-two.text-wrap {
          .item-article {
            .active-link {
              &:after {
                top: 10px;
                @include phablet {
                  top: 5px;
                }
                @include phablet-down {
                  top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  .content .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 0 0 57px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    img {
      border: 2px solid #e6e6e6;
      border-radius: 50%;
      overflow: hidden;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0 8px 10px 0;
    }

    .name {
      display: block;
      border-bottom: 1px solid #a9a9a9;
      font-weight: 500;
      padding: 0 0 5px;
      margin: -14px 0 3px;
      font-size: 22px;
      line-height: 25px;
      color: #050735;
    }
  }
}