.introduction {
  padding: 72px 0 35px;
  background-color: #f5f5f5;

  @include media-breakpoint-down(xs){
    padding: 40px 0 35px;
  }

  .btn-pink {
    margin-right: 6px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    background-color: $MidnightBlue;
    color: white;
    border: solid 1px $MidnightBlue;
    padding: 7px 13px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    @include container-down {
      font-size: 14px;
    }
    @include tablet-down {
     font-size: 12px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 5px;
    }
    &:hover {
      text-decoration: none;
      background-color: transparent;
      color: $MidnightBlue;
    }
    .new {
      font-size: 0.7em;
      font-weight: bold;
      padding: 3px 4px;
      display: inline-block;
      line-height: 12px;
      margin-right: 9px;
      background-color: #7dc3eb;
      color: $white;
      position: relative;
      top: -3px;
    }
    .new-green {
      font-size: 0.7em;
      font-weight: bold;
      padding: 1px 4px 3px;
      display: inline-block;
      line-height: 12px;
      margin-right: 9px;
      background-color: #50beb4;
      color: $white;
      position: relative;
      top: -3px;
    }
  }
  .title {
    max-width: 768px;
    margin: 0 auto 25px;
    text-align: center;
    color: $light-bluewhile;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.6;

    @include media-breakpoint-up(lg) {
      padding-right: 30px;
      font-size: 19px;
    }
    @include media-breakpoint-down(xs) {
      margin: 0 auto 25px;
    }

    strong{
      @include media-breakpoint-down(xs){
        font-size: 16px;
      }
    }
  }
  .bg-white {
    background-color: #fff;

  }
  .bg-light-gray {
    background-color: #eeeeee;

  }

  .bg-dark-gray {
    background-color: #dddddd;
  }
  .quiz {
    padding-bottom: 30px;
    @include tablet-down {
      padding-bottom: 20px;
    }
    .text-wrap {
      padding-bottom: 0 !important;
    }

    .percent-wrap {
      padding: 2px 34px 20px !important;
      @include media-breakpoint-down(xs) {
        padding: 2px 29px 20px !important;
      }
      .item {
        @include media-breakpoint-down(xs) {
          padding: 0 5px;
        }
        .transbtn {
          transition: 0.2s;
          font-size: 16px;
          font-weight: 800;
          cursor: pointer;
          width: 100%;
          display: block;
          border: 1px solid;
          border-radius: 4px;
          text-align: center;
          color: $light-bluewhile;
          border: 1px solid #888888;
          padding: 6px 4px 4px;

          &:hover {
            background-color: #888888;
            color: $white;
          }
        }
      }
    }
    .correct, .incorrect {
      display: none;
    }
    .col-wrap {
      padding: 0 34px;
      strong {
        color: $light-bluewhile;
      }
      p {
        font-size: 16px;
        color: $light-bluewhile;
      }

      .transbtn {
        padding: 7px 0;
        width: 100%;
        display: inline-block;
        font-weight: bold;
        border: 1px solid #3C3C3C;
        border-radius: 5px;
        text-align: center;
        transition: all 0.3s;
        text-decoration: none;
        color: $MidnightBlue;
        outline: 0;
        font-size: 16px;

        &:hover {
          background-color: #888888;
          border-color: #888888;
          color: $white;
        }
      }

      &.correct {
        display: none;
      }
      &.incorrect {
        display: none;
      }
    }

  }

  h2 {
    text-align: center;
    padding: 10px 0 15px;
    text-transform: uppercase;
    margin: 0 !important;
    background-color: $MidnightBlue;
    color: $white;
    font-weight: 400;
    position: relative;

    @include media-breakpoint-up(lg){
      font-size: 27px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -20px;
      width: 40px;
      border: 20px solid transparent;
      border-top-color: $MidnightBlue;
      border-bottom: 1px;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .outer {
    display: block;
    overflow: hidden;
    padding: 0 12px;

    @include media-breakpoint-up(md){
      padding-top: 25px;
    }
    .col-7 {
      float: left;
      width: 60%;
      padding: 0 33px 0 15px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .image {
        display: inline-block;
        vertical-align: middle;
      }
      .col-wrapper {
        padding: 0 24px;
        margin-top: 41px;

        @include media-breakpoint-down(md) {
          padding: 0;
        }
        .counter {
          text-align: center;
          padding: 22px 14px 0 15px;
          p {
            line-height: 1.6;
            padding: 0 0 1em;
            color: $light-bluewhile;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 26px;
            font-weight: 400;
            text-align: left;
          }
        }
      }
      .number {
        display: inline-block;
        vertical-align: middle;
        h4 {
          color: $MidnightBlue;
          font-weight: bold;
          font-size: 3.5em;
          line-height: 48px;
          letter-spacing: -2px;

          @include media-breakpoint-down(md) {
            font-size: 48px;
          }
        }
      }
    }
    .detail {
      padding: 0 40px 33px;

      @include media-breakpoint-down(md) {
        padding: 0 16px 33px;
      }
      p {
        line-height: 1.6;
        padding: 0 0 1em;
        color: $light-bluewhile;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }
    .col-5 {
      float: left;
      width: 40%;
      padding: 0 25px 0 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .report-cta {
        padding-bottom: 36px;
        margin-bottom: 19px;

        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }

      .row {
        padding: 42px 34px 20px;
        .content {
          @include media-breakpoint-down(md) {
            padding: 0;
          }
        }
        h6 {
          line-height: 1.6;
          padding: 0 0 1em;
          color: $light-bluewhile;
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: 400;
          text-transform: inherit;
        }
      }

      .link {
        width: 100%;
        text-align: center;
        padding: 0;
        a {
          text-transform: uppercase;
          @include media-breakpoint-down(md) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.reportsection {
 
  strong {
    font-weight: 700 !important;
  }
  h5 {
    font-size: 1.2em;
    line-height: 1.6;
    padding: 0 0 1em;
    color: $light-bluewhile;
    font-weight: 400;
  }
  a {
    padding: 5px 15px 10px;
    text-transform: uppercase;
    display: inline-block;
    background-color: #00bbdd;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background-color: #53D8F0;
    }

  }
}
