.col-three-cards {
  width: 100%;
  .container {
    width: 100%;
    .wrapper {
      margin-left: -12px;
      margin-right: -12px;
      display: inline-block;
      position: relative;
      @include tablet-down {

      }
      @include phablet-down {
        margin: 0;
      }
      .cards {
        text-align: center;
        float: left;
        width: calc(33.33% - 24px);
        text-align: center;
        border: 2px solid #e8ebeb;
        cursor: pointer;
        margin: 0 12px 24px;
        a {
          text-decoration: none;
          padding: 35px 30px 32px;
          display: block;
          @media screen and (min-width: 596px) and (max-width: 767px) {
            padding: 25px 20px 20px;
          }
          @media screen and (min-width: 480px) and (max-width: 595px) {
            padding: 25px 20px 20px;
          }
          @media screen and (max-width: 479px) {
            padding: 25px 20px 20px;
          }

          .date {
            position: relative;
            display: block;
            padding: 0 0 30px;
            font-size: 22px;
            line-height: 1.2;
            font-family: "futura_lt_bt", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
            color: black;
            margin: 0px;
            @media screen and (min-width: 596px) and (max-width: 767px) {
              font-size: 18px;
            }
            @media screen and (min-width: 480px) and (max-width: 595px) {
              font-size: 18px;
            }
            @media screen and (max-width: 479px) {
              font-size: 18px;
            }
          }
          h4 {
            font-size: 22px;
            line-height: 1.5;
            color: black;
            font-family: "futura_md_bt", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
            @media screen and (min-width: 596px) and (max-width: 767px) {
              font-size: 18px;
            }
            @media screen and (min-width: 480px) and (max-width: 595px) {
              font-size: 18px;
            }
            @media screen and (max-width: 479px) {
              font-size: 18px;
            }
          }

        }
        @include tablet-down {
          width: calc(50% - 22px);
          margin: 0px 11px 15px;
        }
        @include phablet-down {
          width: 100%;
          margin: 0px 0px 15px;
        }

      }

      .cards :hover {
        .date {
          color: #5dbdf4;
        }
        h4 {
          color: #5dbdf4;
        }
      }

    }

    .date::after {
      content: '';
      position: absolute;
      bottom: 13px;
      width: 87px;
      left: 50%;
      margin-left: -43px;
      border-bottom: 2px solid #409fd5;
    }

  }
}
					