@import 'variables';

/*
 * Links.
 */
%btn-default {
  display: inline-block;
  font-size: 20px !important;
  font-family: $font-families;
  line-height: 1.2;
  font-style: normal;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 10px 21px 10px 22px;
  color: $white !important;
  background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
  border: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  min-width: 108px;
  text-align: center;
  @include transition(all, 0.3s, ease-out);
  //@include phone {
  //  padding: 11px 17px;
  //}
  a {
    font-size: 20px !important;
    font-weight: 500 !important;
    @include phone-down {
      font-size: 14px !important;
    }
  }
  @include phone-down {
    font-size: 14px !important;
  }
}

.btn-default {
  @extend %btn-default;
}

.btn-dark-blue {
  display: inline-block;
  font-size: 20px !important;
  font-family: $font-families;
  line-height: 1.2;
  font-style: normal;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 10px 21px 10px 22px;
  color: $white;
  background: $dark-blue2;
  border: 0;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  min-width: 175px;
  text-align: center;
  @include transition(all, 0.3s, ease-out);
  &:hover {
    background: $Midlightblue;
    color: #1A2856;
  }
}

.btn-blue-hover {
  display: inline-block;
  font-size: 20px !important;
  font-family: $font-families;
  line-height: 1.2;
  font-style: normal;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 10px 21px 10px 22px;
  color: $white !important;
  background: $dark-blue2;
  border: 0;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  min-width: 175px;
  text-align: center;
  @include transition(all, 0.3s, ease-out);
  &:hover {
    background: $Midlightblue;
  }
}

.btn-blue-dark {
  display: inline-block;
  font-size: 20px !important;
  font-family: $font-families;
  line-height: 1.2;
  font-style: normal;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 10px 21px 10px 22px;
  color: $white;
  background: $dark-blue2;
  border: 0;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  min-width: 175px;
  text-align: center;
  @include transition(all, 0.3s, ease-out);
  &:hover {
    background: $Midlightblue;
    color: #1A2856;
  }
}

.btn-lightblue {
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 8px 20px;
  color: $dark-blue2;
  background: #00BBDD;
  border-radius: 5px;
  border: 2px solid #00BBDD;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  min-width: 175px;
  text-align: center;
  transition: all 0.3s ease;
  &:hover {
    color: $white;
    background: $dark-blue2;
    border: 2px solid white;
  }
}

.btn-blue-light {
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 8px 20px;
  color: $dark-blue2;
  background: #00BBDD;
  border-radius: 5px;
  border: 2px solid #00BBDD;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  min-width: 175px;
  text-align: center;
  transition: all 0.3s ease;
  &:hover {
    color: $white;
    background: $dark-blue2;
    border: 2px solid white;
  }
}

.btn-blue-ghost {
  color: #ffffff;
  background: #1A2856;
  border: 2px solid white;
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 8px 20px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  min-width: 175px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  &:hover {
    color: #1A2856;
    background: #00BBDD;
    border: 2px solid #00BBDD;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  bottom: 0;
    //  right: 0;
    //  left: 0;
    //  margin: auto;
    //  width: 90%;
    //  height: 60%;
    //  border: 2px solid $white;
    //  border-radius: 5px;
    //}
  }
}

.btn-blue {
  min-width: 175px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  background-color: #346d9e;
  letter-spacing: normal;
  padding: 8px 20px;
  opacity: 1;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #00bbdd;
    text-decoration: none;
    color: #1A2856;
  }
}

.link {
  position: relative;
  font-size: 22px;
  color: $white;
  font-weight: 100;
  padding-right: 28px;
  transition: right linear 0.25s;
  vertical-align: middle;
  display: inline-block;
  span {
    position: relative;
    top: 4px;
    width: 24px;
    height: 24px;
    border: 2px solid $white;
    border-radius: 50%;
    transition: right linear 0.25s;
    vertical-align: top;
    right: -8px;
    display: inline-block;
    &:after {
      content: '';
      width: 9px;
      height: 9px;
      position: absolute;
      border: solid $white;
      border-width: 0 2px 2px 0;
      right: 3px;
      margin: -1px 0 0;
      top: 50%;
      transform: rotate(-45deg) translateY(-50%);
      transition: right ease 0.25s;
    }
    //&:before {
    //  content: '';
    //  width: 2px;
    //  height: 9px;
    //  position: absolute;
    //  border: 1px solid $white;
    //  right: 3px;
    //  margin: -1px 0 0;
    //  top: 7px;
    //  transform: rotate(50deg) translateY(50%);
    //  transition: right ease 0.25s;
    //}
  }
  &:hover {
    text-decoration: none;
    span {
      right: -17px;
    }
  }
  @include phablet-down {
    font-size: 20px;
  }
}

.fb {
  float: left;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #004a8b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: block;
    height: 100%;
    color: #fff;
    line-height: 24px;
    text-decoration: none !important;
    li {
      position: relative;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      &:before {
        content: "\e904";
        position: absolute;
        left: -3px;
        top: -13px;
      }
    }
  }
}

.linkedin {
  float: left;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #004a8b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: block;
    height: 100%;
    color: $white;
    line-height: 24px;
    text-decoration: none !important;

    li {
      position: relative;
      speak: none;
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      font-family: icomoon !important;
      font-variant: normal;
      &:before {
        content: "";
        position: absolute;
        left: -3px;
        top: -14px;
      }
    }
  }
}

.twitter {
  float: left;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #004a8b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: block;
    height: 100%;
    color: $white;
    line-height: 24px;
    text-decoration: none !important;
    li {
      position: relative;
      speak: none;
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      font-family: icomoon !important;
      font-variant: normal;
      &:before {
        content: "";
        position: absolute;
        left: -3px;
        top: -13px;
      }
    }
  }
}

.you-tube {
  float: left;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #004a8b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: block;
    height: 100%;
    color: $white;
    line-height: 24px;
    text-decoration: none !important;
    li {
      position: relative;
      speak: none;
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      font-family: icomoon !important;
      font-variant: normal;
      &:before {
        content: "";
        position: absolute;
        left: -3px;
        top: -13px;
      }
    }
  }
}

.active-link {
  color: $black;
  text-decoration: none;
  position: relative;
  font-size: 17px;
  line-height: 1.3;
  letter-spacing: 0.03em;
  word-wrap: break-word;
  &:after {
    content: "\e90a";
    font-family: 'icomoon' !important;
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 18px;
    top: 2px;
    height: 18px;
    text-align: center;
    color: $blue3;
    background: $blue15;
    border-radius: 50%;
    font-size: 18px;
    line-height: 1;
    padding: 0 0 0 1px;
    margin: 0 0 0 10px;
    transition: all ease-out 0.3s;
  }
  &:hover {
    &:after {
      margin-left: 14px;
    }
  }

}

.purple-fb-color {
  position: relative;
  color: #4b4c82;
  font-size: 18px;
  line-height: 34px;
  font-family: icomoon;
  &:before {
    content: "\e904";
    position: absolute;
    left: 8px;
    top: 0;
  }
}

.purple-yt-color {
  position: relative;
  color: #4b4c82;
  font-size: 18px;
  line-height: 34px;
  font-family: icomoon;
  &:before {
    content: "\e907";
    position: absolute;
    left: 8px;
    top: 0;
  }
}

.purple-tw-color {
  position: relative;
  color: #4b4c82;
  font-size: 18px;
  line-height: 34px;
  font-family: icomoon;
  &:before {
    content: "\e903";
    position: absolute;
    left: 8px;
    top: 0;
  }
}

.purple-rss-color {
  position: relative;
  color: #4b4c82;
  font-size: 18px;
  line-height: 34px;
  font-family: icomoon;
  &:before {
    content: "\ea9b";
    position: absolute;
    left: 8px;
    top: 0;
  }
}


