.gdpr {
  background: #f5f5f5;
  h2, h3, h4, h5, h6, span, a, td {
    // font-family: $font-familie-dinpro;
  }
  strong {
    font-weight: 700 !important;
  }
  .row {
    margin: 0;
  }
  #quiz {
    background-color: #FFF;
    .progress-dots {
      text-align: center;
      padding: 30px 0;
    }
    .quiz-title {
      text-align: center;
      padding: 20px 0 30px;
      h2 {
        font-size: 2.5em;
        font-weight: 400;
        color: $light-bluewhile;
      }
    }
    .progress-no {
      width: 20px;
      height: 20px;
      background-color: #aaaaaa;
      display: inline-block;
      border-radius: 10px;
      margin-left: 5px;
      margin-left: 5px;
      color: #ffffff;
      font-weight: bold;
      font-size: 1.4em;
      -webkit-transition: all 1.0s;
      transition: all 1.0s;
      &.active-dot {
        background-color: $MidnightBlue;
      }
      h2 {
        font-size: 2.5em;
        margin: 20px 0 30px;
        font-weight: 400;
        color: $light-bluewhile;
        @include phablet-down {
          font-size: 2.0em;
        }
      }
    }
    .quiz-number {
      min-height: 80px;
      padding-left: 0px;
      padding-right: 0px;
      overflow: hidden;
      background-color: $MidnightBlue;
      p {
        margin: 0;
        font-weight: bold;
        font-size: 3em;
        text-align: center;
        padding: 0;
        line-height: 1.4em;
        color: #ffffff;
      }
    }
  
    .quiz-question {
      min-height: 80px;
      padding-left: 0;
      padding-right: 50px;
      background-color: #dddddd;
      @include phablet-down {
        padding: 20px 20px;
      }
      .purple-arrow-left {
        border: solid 15px #dddddd;
        border-left-color: $MidnightBlue;
        width: 15px;
        height: 15px;
        display: inline-block;
        float: left;
        margin: 25px 0;
        @include phablet-down {
          position: absolute;
          top: 0;
          margin: 0;
          transform: rotate(90deg);
          left: calc(50% - 15px);
        }
      }
      p {
        font-size: 1.4em;
        padding: 0;
        margin: 13px 0;
        color: $MidnightBlue;
        font-weight: 400;
        line-height: 1.1em;
        display: none;
        margin-left: 30px;
        @include phablet-down {
          margin-left: 0;
        }
      }
    }

    .sata {
      padding: 4px 0;
      background-color: #ffebc0;
      display: none;
      p {
        padding: 0;
        margin: 6px 0 10px;
        font-weight: bold;
        color: #3C3C3C;
        font-size: 16px;
        margin: 0;
      }
    }
    
    .quiz-answers {
      padding: 40px 0 40px;
      @include phablet-down {
        padding: 20px 0 20px;
      }
    }
    .quizbtn-on {
      color: $white;
      background-color: $MidnightBlue !important;
      border-bottom: 5px solid $MidnightBlue;
    }
    .quizbtn:hover {
      border-bottom: 5px solid $MidnightBlue;
    }

    .flex-test-4, .flex-test-5, .flex-test-3, .flex-test-2 {
      width: 100%;
      text-align: center;
      div {
        padding: 25px 1%;
        margin-bottom: 10px;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #dddddd;
        -ms-flex-item-align: center;
        align-self: center;
        border-radius: 5px;
        border-bottom: 5px solid #dddddd;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        display: inline-block;
        width: 24%;
        vertical-align: top;
        cursor: pointer;
        @include phablet-down {
          width: 49%;
          padding: 15px 1%;
        }
      }
    }
    .flex-test-5 div {
      width: 19.5%;
      @include phablet-down {
        width: 49%;
        padding: 15px 1%;
      }
    }
    .flex-test-3 div {
      width: 33%;
      @include phablet-down {
        width: 49%;
        padding: 15px 1%;
      }
    }
    .flex-test-2 div {
      width: 49.5%;
      @include phablet-down {
        width: 49%;
        padding: 15px 1%;
      }
    }
    .hidebtnset {
      display: none;
    }
    .checkresults, .quiz-next, .quiz-prev {
      padding: 30px 0;
      text-align: center;
      font-weight: bold;
      font-size: 1.4em;
      margin: 10px 0;
      border-radius: 5px;
      border: none;
      width: 100%;
      cursor: pointer;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      @include phablet-down {
        padding: 20px 0;
      }
    }
    .quiz-prev {
      background-color: $MidnightBlue;
      color: #ffffff;
      &.qbtn-disabled {
        background-color: #eeeeee !important;
      }
    }
    .quiz-next {
      background-color: $MidnightBlue;
      color: #ffffff;
      &.qbtn-disabled {
        background-color: #eeeeee !important;
      }
    }
    .checkresults {
      background-color: $MidnightBlue;
      color: #ffffff;
      &.qbtn-disabled, &.final-disabled {
        background-color: #eeeeee !important;
      }
    }
    .final-btn {
      display: none;
    }
  }
  #results {
    display: none;
    h3 {
      margin: 30px 20px;
      font-size: 2em;
      color: $light-bluewhile;
    }
    .bigbtn {
      background-color: $Midlightblue;
      color: white;
      border: solid 1px $Midlightblue;
      padding: 4px 13px 8px;
      border-radius: 3px;
      display: inline-block;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        text-decoration: none;
        background-color: transparent;
        color: $Midlightblue;
      }
    }
    p {
      line-height: 1.6;
      padding: 0 0 1em;
      color: #3C3C3C;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 400;
    }
    .result-text{
      .purple-arrow-top{
        border: solid 15px #ffffff;
        border-top-color: #242a75;
        width: 15px;
        height: 15px;
        display: inline-block;
      }
      .right-assets{
        text-align: center;
        padding-top: 20px;
      }
    }
    .statement {
      font-weight: bold;
      font-size: 1.3em;
      vertical-align: top;
      padding-bottom: 10px;
      line-height: 1.6;
      padding:0 0 1em;
      color: #3C3C3C;
    }
    .result-block {
      .col-lg-12 {
        padding: 10px 15px;
        border-bottom: 8px solid #242a75;  
        background: #dddddd;
        p {
          padding: 0;
          margin: 0;
        }
      }
      .changable-bar {
        background-color: #242a75;
      }
    }
    .result-active {
      border-bottom: none;
      background-color: $MidnightBlue;
      .rating-num {
        color: #242a75;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
    .rating-num {
      font-size: 1.8em;
      font-weight: bold;
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
      color: #aaaaaa;
      @include phablet-down{
        font-size: 1.3em;
        vertical-align: middle;
      }
    }
    .rating-bar {
      display: inline-block;
      width: 12px;
      height: 40px;
      margin-right: 7px;
      margin-top: 5px;
      height: 40px;
      vertical-align: middle;
      background-color: #aaaaaa;
      @include phablet-down{
        width: 10px;
        height: 40px;
        margin-right: 5px;
        margin-top: 5px;
        height: 38px;
        vertical-align: middle;
      }
    }
  }
}