.year-tabs{
    background: #f5f5f5;
    h2,h3,h4,h5,h6,span,p,li{
        // font-family: dinpro;
    }
    strong {
        font-weight: 700 !important;
      }
    ul{
        li{
            color: #555;
                font-weight: 400;
                margin-right: 2px;
                line-height: 1.42857143;
                border-radius: 4px 4px 0 0;
                display: inline-block;
                padding: 10px;
                cursor: pointer;
                background: url(/themes/thalesesecurity/templates/dist/images/gemalto-tabs-divider.png) no-repeat #eee;
            &.active{
                background:none;
                background-color: #fff;
                border: 1px solid #ddd;
            }
        }
    }
    .tab-content{
        background-color: #fff;
        padding: 20px;
        h3{
            color: #F29220;
            font-size: 24px;
            font-weight: normal;
        }
        span{
            color: #333;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 4px;
        }
        p{
            line-height: 1.6;
            padding: 0 0 1em;
            color: #212121;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .tab{
        margin-bottom: 20px;
    padding: 20px 20px 20px 20px;
    border: 1px solid #CCC;
    background-color: #EEE;
    display: none;
    &.active{
        display: block;
    }
    }
}