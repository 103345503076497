.image-grid {
  .flex-row {
    @include large_desktop {
      padding: 0 14px;
    }
    @include container-down {
      padding: 0 14px;
    }
    .col-wrap {
      padding: 0 2px;
      .bg-img {
        margin-bottom: 3px;
        img {
          width: 100%;
          height: 100%;

        }
      }

    }
  }

}

