.news-coverage {
  @include container-up {

  }
  .wrapper {
    display: inline-block;
    width: 100%;

    .title {
      max-width: 800px;
      margin: 0 auto 4px;
      text-align: center;
      h2 {
        color: $light-bluewhile;
        //font-size: 45px;
        font-weight: 300;
      }
      @include tablet-down {
        margin-bottom: 40px;
        h2 {
          //font-size: 22px;
        }
      }
    }

    .full-row {
      max-width: 700px;
      margin: 0 auto;
      height: 100%;
      // padding: 10px 0;
      padding: 40px;
      .col-search {
        display: flex;
        align-items: center;
        width: 100%;
        @include desktop-down {
          padding: 15px 30px;
          max-width: 100%;
          width: 100%;
          margin-bottom: 25px;
        }
        @include tablet-down {
          display: block;
        }
        @include phone-down {
          padding: 10px;
        }
        .input-box {
          width: 100%;
          position: relative;
          display: flex;
          max-width: 100%;
          @include container-down {
            padding-left: 0;
            max-width: 358px;
            margin-left: 50px;
          }
          @include desktop-down {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
          }
          @include tablet-down {
            padding-left: 0;
            width: 100%;
            margin: 0 auto;
          }
          @include phone-down {
            max-width: 100%;
          }

          input {
            font-size: 22px;
            padding: 0 12px;
            color: $light-bluewhile;
            border: 1px solid $gery12;
            height: 55px;
            font-family: $font-families;
            background-color: $white;
            font-weight: 100;
            letter-spacing: 1.4px;
            outline: none;
            max-width: 100%;
            width: calc(100%);
            box-sizing: border-box;
            appearance: none;
            border-radius: 0;
            @include phone-down {
              max-width: 260px;
              margin-right: 3px;

            }
          }
          input::placeholder {
            font-family: $font-families;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.21;
            letter-spacing: normal;
            color: $white3;
          }
          button {
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: middle;
            background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
            width: 100%;
            text-decoration: none;
            position: relative;
            z-index: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
            height: 55px;
            max-width: 120px;
            border: 0;
            cursor: pointer;
            color: $white;
            font-size: 18px;
            line-height: 1.38;
            letter-spacing: normal;
            font-family: $font-families;
            overflow: hidden;
            @include phone-down {
              max-width: 75px;
            }
            span {
              font-family: $font-families;
              position: relative;
              font-size: 16px;
              font-weight: 300;
            }

          }
        }
      }
    }

    .columm-wrap {
      background: $white;
      margin: 0 -10px 0;
      .col-wrap {
        transition: all ease 0.3s;
        color: $light-bluewhile;
        margin: 0;
        padding: 0 10px 20px;

        cursor: pointer;
        min-height: 261px;
        @include desktop {
          min-height: 313px;
        }
        @include tablet {
          min-height: 313px;
        }
        @include tablet-down {
          min-height: 300px;
        }
        a {
          transition: all 0.5s ease;
          width: 100%;
          display: block;
          background: $gray13;
          text-decoration: none;
          min-height: 280px;
          height: 100%;
          border-bottom: 2px solid $mid-light-sky;
          .img-icon {
            display: block;
            height: 226px;
            border-top: 2px solid $gray13;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            img {
              display: inline-block;
              max-width: 99%;
              height: auto;
              vertical-align: middle;
            }
          }
          .content {

            padding: 40px;
            text-align: center;
            backface-visibility: hidden;
            perspective: unset;
            span {
              transition: all ease 0.3s;
              font-size: 22px;
              font-weight: 100;
              color: $light-bluewhile;
              position: relative;
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 87px;
                left: 0;
                padding-top: 50px;
                top: 0;
                margin: auto;
                right: 0;
                border-bottom: 2px solid $blue-light;
              }
            }
            h4 {

              transition: all ease 0.5s;
              color: $light-bluewhile;
              font-size: 22px;
              font-weight: 300;
              padding-top: 27px;
            }
          }
          &:hover {
            transition: all 0.5s ease;
            .content {
              span {
                color: $sky-light-blue;
              }
              h4 {
                color: $sky-light-blue;
              }
            }
          }
        }

      }
    }

    .pagination {
      display: flex;
      margin-top: 50px;
      @include tablet-down {
        justify-content: center;
        margin-top: 30px;

      }
      ul {
        margin: 0;
        display: flex;
        list-style: none;
        @include tablet-down {
          margin: 0 10px;
        }
        @include phone-down {
          display: unset;
          margin: 0;
        }
        li {
          margin: 0 35px 0 0;
          color: $blue6;
          @include tablet-down {
            margin: 0 10px 0 0;
          }
          @include phone-down {
            display: inline-block;
            margin: 0;
          }
          &:nth-last-child(1) {
            margin-right: 0;
          }
          a {
            color: $blue6;
            font-size: 22px;
            font-weight: 300;
            &:hover {
              text-decoration: none;
            }
            &.active {
              color: $black;
            }
          }
          .gap {
            color: $grey10;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .views-exposed-form {
    display: none;
  }

}

