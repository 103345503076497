.hero-slider {
  padding: 0;

  h1, h2, h3, h4, h5, h6, p {
    color: $white;
  }
  
  .slider {
    .items {
      outline: none;
    }
  }
 

  .slider-wrapper {
    position: relative;
    float: left;
    width: calc(100%);
    .bg-img {
      position: relative;
      height: 588px;
      @include phone-down {
        height: 440px;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .cover-text-image {
      display: inline-block;
      position: relative;
      .content {
        width: 100%;
        max-width: 770px;
        text-align: left;
        padding-left: 25px;
        @include phone-down {
          padding: 0;
        }
        h1 {
          font-family: futura_bt;
          color: $white;
          font-weight: bold;
          margin-bottom: 8px;
          span {
            font-family: futura_bt;
            color: $white;
            font-size: 46px;
            max-width: 700px;
            font-weight: bold;
            margin-bottom: 8px;
            display: block;
          }
          @include phone-down {
            font-size: 22px;
          }
        }
        p {
          color: $white;
          font-weight: 300;
          font-size: 24px;
          display: inline-block;
          margin-bottom: 20px;
          @include phone-down {
            font-size: 18px;
          }
          span {
            color: $sky1;
            font-size: 24px;
            font-family: futura_md_bt;
            font-weight: 900;
            @include phone-down {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
    &:hover:before {
      width: 0;
    }
  }
  .btn-wrapper {
    display: inline-flex;
    float: right;
    position: absolute;
    right: 90px;
    bottom: 22px;
    @include container-down {
      right: 20px;
    }
    @include tablet-down {
      right: 0;
      display: flex;
      float: none;
      bottom: 22px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .btn-left {
      font-weight: 300;
      padding: 0px 17px;
      background-color: $white;
      color: $MidnightBlue;
      border-radius: 20px 0 0 20px;
      line-height: 34px;
      font-size: 15px;
      box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);

      @include phablet-down {
        padding: 0 10px;
      }
    }
    .btn-right {
      background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
      padding: 0 38px;
      border-radius: 0 20px 20px 0;
      color: $white;
      font-weight: 300;
      line-height: 34px;
      font-size: 15px;
      box-shadow: 0 2px 24px -12px rgba(0, 0, 0, 0.4);
      @include phablet-down {
        padding: 0 17px;
      }
    }
    &.bottom-fix {
      position: fixed;
      bottom: 50px;
      right: 94px;
      z-index: 5;
      transition: bottom 0.6s linear;
      transform: translate(0, 30px);
      @media screen and (min-width: 1401px) {
        right: 50%;
        margin: 0 -614px 0 0;
      }
      @include container-down {
        right: 24px;
      }
      @include tablet-down {
        right: 0;
        display: flex;
        float: none;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
  h4 {
    margin: 20px 0;
  }
  .slick-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
    @include desktop-down {
      bottom: 3px;
    }
    li {
      display: inline-block;
      position: relative;
      width: 15px;
      height: 15px;
      margin: 0 4px;
      padding: 0;
      &:before {
        display: none;
      }
      button {
        outline: none;
        width: 15px;
        height: 15px;
        text-indent: 999999px;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.8;
        background: $white;
      }
      &.slick-active {
        button {
          background: $blue-light;
          opacity: 1;
        }
      }
    }
  }
}

