.info-section {

  @include container-up {
    //padding: 20px 0;
  }

  .info-box {
    background: $white2;
    margin-bottom: 10px;
    border-top: 2px solid $light-sky;
    text-align: center;
    padding: 38px 15px;

    h3 {
      text-transform: uppercase;
      color: $light-bluewhile;
      font-weight: 300;
      padding-bottom: 28px;
    }
    .content-box {
      width: 100%;
      text-align: center;
      .inner-wrapper {
        background: $white;
        padding: 44px 40px 40px;
        @include desktop-down {
          padding: 30px;
        }
        .img-wrapper, a.img-wrapper {
          max-width: 95px;
          display: block;
          margin: 0 auto 24px;
          height: 95px;
          img {
            height: 100%;
          }
        }
        h2 {
          //font-size: 24px;
          font-weight: 300;
          line-height: 26px;
          color: $light-bluewhile;
          margin: 0 0 12px;
          word-break: break-word;
          @include desktop {
            //font-size: 28px;
          }
          @include tablet {
            //font-size: 22px;
          }
          @include phablet {
            //font-size: 20px;
          }
          @include phablet-down {
            //font-size: 18px;
            margin-bottom: 17px;
          }
        }
        ul {
          list-style: none;
          text-align: left;
          padding-bottom: 19px;
          @include phablet-down {
            text-align: center;
          }
          li {
            margin: 0 0 14px;
            font-weight: 100;
            @include phablet-down {
              margin-bottom: 10px;
            }
            a {
              color: $black;
              text-decoration: none;
              position: relative;
              font-size: 17px;
              line-height: 1.4;
              letter-spacing: 0.03em;
              padding-right: 40px;
              top: -2px;
              &:after {
                content: "\e90a";
                font-family: 'icomoon' !important;
                display: inline-block;
                position: absolute;
                vertical-align: top;
                width: 18px;
                top: 1px;
                height: 18px;
                text-align: center;
                color: $blue3;
                background: $blue10;
                border-radius: 50%;
                font-size: 18px;
                line-height: 18px;
                padding: 0 0 0 1px;
                margin: 0 0 0 7px;
                -webkit-transition: all ease-out 0.3s;
                transition: all ease-out 0.3s;
              }

              &:hover {
                &:after {
                  margin-left: 14px;
                }
              }
            }
          }
        }
      }
    }

  }
}
