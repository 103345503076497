.help-block {

  .full-row {
    text-align: center;
    @include desktop-down {
      max-width: 100%;
      margin: 0 auto;
    }
    .col-wrap {
      display: inline-block;
      vertical-align: middle;
      @include desktop-down {
        width: 100%;
      }
      .card-block {
        position: relative;
        width: 280px;
        border: 2px solid #253746;
        text-align: center;
        padding: 20px;
        margin: 30px;

        @include container-down {
          margin: 10px;
        }
        @include desktop-down {
          width: 100%;
          max-width: 100%;
        }

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

        }
        .icon-img {
          display: block;
          text-decoration: none;
          text-transform: uppercase;
          padding-bottom: 30px;
          img {
            max-width: 100%;
            height: auto;
            vertical-align: top;
          }
        }
        h4 {
          font-weight: 300;
          color: $blue5;
        }
      }
    }

  }

}

