.image-rotator-frame {
  padding: 0;
  .outer-wrap {
    background: url("/themes/thalesesecurity/templates/dist/images/background.svg")
      no-repeat;
    background-size: cover;
    padding: 90px 60px;
    @include media-breakpoint-down(lg) {
      padding: 80px 40;
      overflow: scroll;
    }
    @include media-breakpoint-down(md) {
      padding: 60px 40px;
    }
    @include media-breakpoint-down(sm) {
      padding: 40px 40px;
    }
    .inner-wrap {
      height: 418px;
      @include media-breakpoint-down(lg) {
        width: 1200px;
      }
      .intro-wrap {
        margin-bottom: 35px;
        h3 {
          color: #fff;
          text-align: center;
          font-weight: 600;
          font-size: 30px;
          span {
            position: relative;
            padding-left: 20px;
            margin-left: 10px;
            font-weight: 100;
            &::before {
              position: absolute;
              content: "";
              top: 8px;
              left: 0;
              height: 21px;
              width: 1px;
              background: #fff;
            }
          }
        }
      }
      .benefit-wrap {
        max-width: 200px;
        h6 {
          font-size: 18px;
          color: #fff;
          position: relative;
          margin-bottom: 20px;
          &::before {
            position: absolute;
            content: "";
            bottom: -8px;
            left: 0;
            width: 30px;
            height: 1px;
            background: #00bbdd;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            font-size: 14px;
            margin-bottom: 15px;
            color: #fff;
            .icon-wrap {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
      .rotator-wrap {
        text-align: center;
        position: relative;
        .tab-content {
          margin-top: 20px;
          position: relative;
          max-width: 733px;
          .img-wrap {
            height: 320px;
          }
        }
        .rotator-view-box {
          width: 100%;
          margin-top: 20px;
          .img-wrap {
            overflow: hidden;
            cursor: pointer;
            width: 100%;
            height: 320px;
            img {
              transition: all 0.3s ease;
              position: relative;
            }
            &.zoom {
              img {
                transform: scale(1.58);
              }
            }
          }
          .controls {
            position: absolute;
            width: calc(100% - 55px);
            bottom: 84px;
            left: 50%;
            transform: translateX(-50%);
            .arrow {
              max-width: 321px;
              cursor: pointer;
            }
          }
        }
        .feature-wrap {
          display: none;
          .front-view {
            position: relative;
            button {
              position: absolute;
              outline: none;
              cursor: pointer;
              background: none;
              top: 105px;
              &.active {
                opacity: 0.4;
              }
              &#disk {
                left: 221px;
              }
              &#slot {
                left: 383px;
              }
              &#caution {
                left: 523px;
              }
              &#service {
                left: 558px;
              }
            }
          }
          .back-view {
            display: none;
            position: relative;
            button {
              position: absolute;
              outline: none;
              cursor: pointer;
              background: none;
              top: 148px;
              &.active {
                opacity: 0.4;
              }
              &#port {
                left: 204px;
              }
              &#aux {
                left: 240px;
              }
              &#led {
                left: 301px;
                top: 141px;
              }
              &#power {
                left: 533px;
                top: 124px;
              }
            }
          }
          .side-toggle {
            display: inline-block;
            position: absolute;
            bottom: 70px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            .icon {
              height: 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            span {
              color: #00bbdd;
              padding-top: 5px;
              display: block;
            }
          }
        }
        .toggle-wrap {
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: -35px;
          width: 745px;
          span {
            font-size: 56px;
            font-weight: 100;
            color: #fff;
            opacity: 0.5;
            padding-right: 22px;
            position: relative;
            margin-right: 12px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              opacity: 1;
            }
            &.active {
              color: #00bbdd;
              cursor: default;
              opacity: 1;
            }
            &:before {
              position: absolute;
              content: "";
              top: 14px;
              right: 0;
              width: 1px;
              height: 55px;
              background: #fff;
            }
            &:last-child {
              &:before {
                opacity: 0;
              }
            }
          }
        }
        .info-popup {
          width: 185px;
          position: absolute;
          top: 0;
          right: 0;
          display: none;
          @include media-breakpoint-down(lg) {
            right: 70px;
          }
          .img-wrap {
            height: 117px;
          }
          .content-block {
            background: #171a21;
            padding: 12px 12px 16px;
            border-bottom-left-radius: 11px;
            border-bottom-right-radius: 11px;
            span {
              color: #fff;
              display: block;
              text-align: left;
              font-weight: 100;
              &.title {
                font-size: 19px;
                position: relative;
                &::before {
                  content: "";
                  width: 30px;
                  height: 1px;
                  border-bottom: 1px solid #00bbdd;
                  bottom: -5px;
                  left: 0;
                  position: absolute;
                }
              }
              &.desc {
                font-size: 14px;
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
