.col-four-row {
  //border-top: 2px solid $light-sky;
  //padding: 48px 0 56px;
  @include tablet-down {
    //padding: 30px 0;
  }
  .content-wrap {
    width: calc(100% + 40px);
    margin-left: -21px;
    display: flex;
    flex-wrap: wrap;
    @include container-down {
      width: 100%;
      margin: 0;
      justify-content: center;
      text-align: center;
    }
    @include tablet-down {
      width: 100%;
      margin: 0;
      float: none;
    }
    .col-wrap {
      width: calc(25% - 40px);
      margin-left: 30px;
      @include container-down {
        width: calc(33.33% - 40px);
        margin: 0 20px 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include tablet-down {
        margin: 0 auto 30px;
        width: 100%;
        max-width: 767px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .img-wrap {
        height: 96px;
        max-width: 250px;
        margin: 21px 0 48px 18px;
        @include container-down {
          margin-bottom: 24px;
        }
        @include tablet-down {
          margin: 0 auto 20px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .content {
        padding: 0 0 0 12px;
        text-align: center;
        @include tablet-down {
          padding: 0;
        }

        h3 {
          margin-bottom: 29px;
          line-height: 1.06;
          font-weight: 300;
          color: $light-bluewhile;
          @include container-down {
            margin-bottom: 16px;
          }
          @include tablet-down {
            margin-bottom: 10px;
          }
        }
        p {
          line-height: 1.44;
          font-weight: 100;
          color: $mid-grey;
          letter-spacing: -0.03em;
          margin-bottom: 14px;
          @include container-up {
            font-size: 23px;
          }
          @include tablet-down {
            line-height: 1.2;
          }
        }
        .btn-default{
          padding: 10px 17px 10px 22px;
                }
      }
    }
  }
}





