.col-six-grid {
  background: $white2;
  padding: 60px 0;
  .heading {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    h2 {
      font-weight: 600;
    }
  }
  .column-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include phablet-down {
      max-width: 400px;
      margin: 0 auto;
    }
    @include phone-down {
      max-width: 100%;
    }
  }
  .flip-card {
    background-color: transparent;
    width: calc(25% - 40px);
    margin: 0 20px 30px;
    min-height: 300px;
    perspective: 1000px;
    position: relative;
    @include large_desktop {
      width: calc(33.33% - 40px);
      margin: 0 20px 30px;
    }
    @include desktop-down {
      width: calc(50% - 40px);
    }
    @include phablet-down {
      width: 100%;
      margin: 0 0 30px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      width: 22px;
      height: 16px;
      background: url("/themes/thalesesecurity/templates/assets/images/col-six-grid/flip_arrow.png") no-repeat;
      background-size: 100%;
      z-index: 1;
    }
    &.flip {
      outline: 0;
      &:before {
        display: none;
      }
      .flip-card-inner {
        transform: rotateY(180deg);
      }
    }
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .flip-card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: $white;
    color: black;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 5px;
    @include tablet-down {
      padding: 40px;
    }
    img {
      width: 120px;
      height: 120px;
    }
    h2, h3 {
      color: $blue21;
      margin: 0;
      font-weight: 600;
      @include phablet-down {
        width: 100%;
        font-size: 22px;
      }
    }
  }
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: $dark-blue2;
    color: white;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    border-radius: 5px;
    @include tablet-down {
      padding: 10px 20px;
    }

    h3 {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 10px;
      color: $white;
      line-height: 1.2;
      @include tablet-down {
        font-size: 22px;
        line-height: 1.25;
      }
    }
    p {
      font-size: 18px;
      text-align: left;
      margin-bottom: 10px;
      color: $white;
      padding-right: 0;
      line-height: 1.3;
      font-weight: 400;
      @include large_desktop {
        padding-right: 0;
      }
      @include desktop-down {
        padding-right: 0;
      }
    }
    .btn-wrap {
      width: 100%;
      text-align: right;
      margin-right: 10px;
      display: inline-flex;
      align-items: flex-end;
      justify-content: flex-end;
      a {
        font-size: 16px;
        text-decoration: none;
        color: $white;
        position: relative;
        right: -5px;
        transition: right 0.3s linear;

        &:before {
          content: '';
          position: absolute;
          top: 1px;
          bottom: 0;
          margin: auto;
          right: -10px;
          opacity: 0;
          width: 14px;
          height: 14px;
          background: url("/themes/thalesesecurity/templates/assets/images/col-six-grid/left_icon.svg") no-repeat;
          background-size: 100%;
          transform: rotate(180deg);
          transition: all 0.3s linear;
        }
        &:hover {
          text-decoration: none;
          right: 5px;
          &:before {
            opacity: 1;
            right: -20px;
          }
        }
      }
    }
  }
  &.three-col-grid {
    .column-wrapper {
      max-width: 920px;
      margin: 0 auto;
    }
    .flip-card {
      width: calc(33.33% - 40px);
      @include desktop-down {
        width: calc(50% - 40px);
      }

      @include phablet-down {
        width: 100%;
        margin: 0 0 30px;
      }
    }
    .flip-card-front {
      h2, h3 {
        color: $blue21;
        margin: 0;
        font-weight: 600;
        width: 100%;
        @include phablet-down {
          width: 100%;
          font-size: 22px;
        }
      }
    }
  }
}
