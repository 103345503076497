.sticky-legal-block {
  padding: 90px 0 36px;
  min-height: 226px;
 
  @include tablet-down {
    display: none;
  }
  .full-container {
    width: 100%;
    .tab-heading {
      max-width: 920px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      list-style: none;
      @include tablet-down {
        flex-wrap: wrap;
      }
      li {
        padding: 20px;
        font-weight: 300;
        font-size: 22px;
        text-align: center;
        position: relative;
        cursor: pointer;
        color: $light-bluewhile;
        flex-grow: 1;
        a {
          text-decoration: none;
          color: $black;
          display: inline-block;
          position: relative;
          font-weight: 900;
          @include phablet {
            font-size: 20px;
          }
          @include phablet-down {
            font-size: 18px;
          }

        }
        &.active {
          position: relative;
          color: $MidnightBlue;
          &:after {
            content: '';
            left: 0;
            right: 0;
            bottom: -18px;
            position: absolute;
            height: 3px;
            background: $MidnightBlue;
          }
          

          a {
            text-decoration: none;
            color: $MidnightBlue;
           
          }
          @include tablet-down {
            &:after {
              display: none;
            }
            a {
              
            }
          }
        }
      }
    }
  }
  &.fixed {
    position: fixed;
    z-index: 99;
    .full-container {
      background-color: $sky-light1;
      position: fixed;
      top: 107px;
      height: 74px;
      @include tablet {
        top: 92px;
      }
      .tab-heading {
        li {
          &.active {
            color: $MidnightBlue;
            background: $white2;
            &:after {
              width: 100%;
              content: '';
              left:0;
              right: 0;
              bottom:0;
              position: absolute;
              height: 3px;
              background: $MidnightBlue;
            }
            a {
              text-decoration: none;
              color: $MidnightBlue;
          
            }
            @include tablet-down {
              border-color: $MidnightBlue;
              a {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}




