.accordion-ibm {
  .container {
    > h3 {
      color: $black;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
  img[data-align=right]{
    float: right;
    padding: 5px;
  }
  img[data-align=left]{
    float: left;
    padding: 5px;
  }
  img[data-align=center]{
    display: block;
    float: none;
    margin: 0 auto;
    padding: 5px;
  }
  .accordion-outer {
    .accordion-wrap {
      .accordion-item {
        margin-top: 5px;
        &:first-child {
          margin-top: 0;
        }
        &.active {
          .heading{
            h3 {
              &:before {
                background: url(/themes/thalesesecurity/templates/dist/images/arrow-down.svg) no-repeat;
                background-size: 100%;
                background-color: $grey14;
              }
            }
          }
        }
        .heading {
          h3 {
            position: relative;
            color: $black;
            font-weight: 300;
            background-color: $grey8;
            border: 1px solid $grey9;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            padding: 10px 15px 10px 40px;
            margin-bottom: 0;
            cursor: pointer;
            &:before {
              content: '';
              position: absolute;
              left: 10px;
              top: 0;
              bottom: 0;
              width: 18px;
              height: 18px;
              margin: auto;
              background: url(/themes/thalesesecurity/templates/dist/images/plus.svg) no-repeat;
              background-size: 100%;
              border-radius: 50%;
              background-color: $grey14;
            }
          }
        }
        .description {
          padding: 0 30px 20px;
          border: 1px solid $grey9;
          border-top: 0;
          display: none;
          @include tablet-down {
            padding: 0 20px 20px;
          }
          .content {
            padding-top: 20px;
            display: inline-block
          }
          p {
            strong {
              color: $black;
              font-weight: 400;
            }
          }
          application_name,
          use_case,
          use_type{
            display: none;
          }
        }
      }
    }
  }
}
