.resource-grid {
  background: $white2;
  margin-bottom: 2px;
  @include container-up {
    //padding: 68px 0 98px;
  }
  @include tablet-down {
    margin: 0;
  }

  .content-segment,
  .language-changer {
    background: $white;
    position: relative;
    border: 2px solid $white3;
    font-size: 15px;
    line-height: 49px;
    padding: 0 30px 0 18px;
    text-decoration: none;
    color: $light-bluewhile;
    min-width: 236px;
    cursor: pointer;
    font-weight: 300;
    margin: 0 10px;
    display: flex;
    align-items: center;
    @include desktop {
      padding: 10px 48px 10px 16px;
      display: inline-block;
      width: calc(33.33% - 5px);
      line-height: 23px;
      margin: 0 0 10px;
    }
    @include tablet {
      padding: 10px 48px 10px 16px;
      display: inline-block;
      width: calc(33.33% - 5px);
      line-height: 23px;
      margin: 0 0 10px;
    }
    @include tablet-down {
      padding: 10px 48px 10px 16px;
      display: block;
      line-height: 23px;
      margin: 0 0 10px;
    }

    a {
      display: block;
      text-decoration: none;
      font-size: 15px;
      color: $light-bluewhile;
      font-weight: 300;
    }

    &:after,
    &:before {
      content: "";
      right: 10px;
      position: absolute;
      width: 20px;
      height: 20px;
      text-align: center;
      color: $blue3;
      background: $white4;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transition: all ease-out 0.3s;
      transition: all ease-out 0.3s;
    }

    &:before {
      border-radius: 0;
      right: 16px;
      top: 50%;
      z-index: 9;
      background: transparent;
      border-bottom: 1px solid $light-bluewhile;
      border-left: 1px solid $light-bluewhile;
      transform: translateY(-50%) rotate(-45deg);
      margin-top: -1px;
      width: 8px;
      height: 8px;
    }

    &.active {
      @include tablet-down {
        margin-bottom: 10px;
      }

      &:before {
        transform: translateY(-50%) rotate(135deg);
        margin-top: 2px;
      }
    }

    ul {
      &.content-dropdown,
      &.language-dropdown {
        display: none;
        width: 100%;
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 9;
        text-align: left;
        padding: 0 0 15px;
        background: $white;
        border: 2px solid $white3;
        @include tablet-down {
          top: 42px;
        }
      }

      li {
        display: block;
        float: left;
        padding: 0;
        width: 100%;
        margin-bottom: 0;
        cursor: default;

        &:first-child {
          margin: 10px 0 0;
          background: $white;
        }

        &:last-child {
          margin-right: 0;

          &:before {
            display: none;
          }
        }

        a {
          font-size: 17px;
          letter-spacing: 0.025em;
          color: $light-bluewhile;
          opacity: 1;
          border: none;
          transition: all 0.4s ease;
          display: block;
          padding: 5px 15px;
          font-weight: bold;

          &:hover {
            color: $white;
            background: $blue13;
          }
        }
      }
    }
  }

  .filter-wrap {
    margin-bottom: 37px;
    position: relative;

    .top-row {
      display: flex;
      justify-content: space-between;
      @include desktop {
        display: block;
      }
      @include tablet {
        display: block;
      }
      @include tablet-down {
        display: block;
      }

      .dropdown {
        background: $white;
        position: relative;
        border: 2px solid $white3;
        font-size: 15px;
        line-height: 49px;
        padding: 0 135px 0 18px;
        text-decoration: none;
        color: $light-bluewhile;
        min-width: 236px;
        cursor: pointer;
        font-weight: 300;
        @include desktop {
          padding: 10px 48px 10px 16px;
          display: inline-block;
          width: calc(33.33% - 5px);
          line-height: 23px;
          margin: 0 0 10px;
        }
        @include tablet {
          padding: 10px 48px 10px 16px;
          display: inline-block;
          width: calc(33.33% - 5px);
          line-height: 23px;
          margin: 0 0 10px;
        }
        @include tablet-down {
          padding: 10px 48px 10px 16px;
          display: block;
          line-height: 23px;
          margin-bottom: 10px;
        }

        &:after,
        &:before {
          content: "";
          right: 10px;
          position: absolute;
          width: 20px;
          height: 20px;
          text-align: center;
          color: $blue3;
          background: $white4;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transition: all ease-out 0.3s;
          transition: all ease-out 0.3s;
        }

        &:before {
          border-radius: 0;
          right: 16px;
          top: 50%;
          z-index: 9;
          background: transparent;
          border-bottom: 1px solid $light-bluewhile;
          border-left: 1px solid $light-bluewhile;
          transform: translateY(-50%) rotate(-45deg);
          margin-top: -1px;
          width: 8px;
          height: 8px;
        }

        &.open {
          border-bottom: 0;
          margin-bottom: 2px;
          @include phablet-down {
            border-bottom: 2px solid $white3;
          }
          @include tablet-down {
            margin-bottom: 10px;
          }

          &:before {
            transform: translateY(-50%) rotate(135deg);
            margin-top: 2px;
          }
        }
      }

      .search-wrapper {
        width: calc(100% - 32.95%);
        display: flex;
        @include tablet {
          width: 100%;
        }
        @include tablet-down {
          width: 100%;
        }

        input {
          width: 100%;
          max-width: 531px;
          margin-right: 13px;
          border: 2px solid $white3;
          outline: none;
          font-size: 15px;
          outline: 0;
          color: $light-bluewhile;
          padding: 0 17px;
          @include tablet-down {
            padding: 10px 17px;
          }
        }

        button {
          min-width: 118px;
          @include phablet-down {
            min-width: 90px;
          }
        }
      }
    }

    .bottom-row {
      position: absolute;
      padding: 38px 30px 40px;
      width: 100%;
      top: 100%;
      z-index: 12;
      margin: 10px 0 0;
      background: $white;
      border: 2px solid $white3;
      display: none;
      @include tablet-down {
        padding: 35px 20px;
      }
      @include phablet-down {
        position: relative;
        padding: 20px;
      }

      .cross {
        cursor: pointer;
        font-weight: 100;
        font-size: 36px;
        position: absolute;
        right: 20px;
        top: 0;
        color: $light-bluewhile;
        z-index: 9;
        @include desktop-down {
          line-height: 1;
        }
        @include phablet-down {
          display: none;
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @include tablet-down {
          display: block;
        }

        li {
          position: relative;
          width: calc(25% - 37px);
          display: flex;
          align-items: center;
          margin-right: 47px;
          cursor: pointer;
          margin-bottom: 4px;
          @include desktop-down {
            width: calc(50% - 25px);
            margin-right: 50px;

            &:nth-child(even) {
              margin-right: 0;

              &:after {
                display: none;
              }
            }
          }
          @include tablet-down {
            width: 100%;
          }

          .img-wrapper {
            width: 52px;
            min-height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-box-pack: center;

            img {
              object-fit: contain;
            }
          }

          span {
            &:nth-child(2) {
              width: calc(100% - 51px);
              font-family: $super-bold-font !important;
              font-weight: bold;
              word-break: break-word;
              padding: 9px 0 9px 8px;
              margin-left: 8px;
              margin-right: -7px;
              font-size: 17px;
              color: $light-bluewhile;
              display: flex;
              align-items: center;
              position: relative;
              transition: all linear 0.2s;
              @include tablet-down {
                margin-right: 0;
              }
            }
          }

          &:after {
            content: "";
            position: absolute;
            right: -28px;
            height: calc(100% + 4px);
            width: 1px;
            top: 0;
            background: $white5;
            pointer-events: none;
            @include tablet-down {
              display: none;
            }
          }
          @include desktop-up {
            &:nth-child(4n+4) {
              margin-right: 0;
              width: calc(25% - 36px);

              &:after {
                display: none;
                @include desktop-down {
                  display: block;
                }
                @include tablet-down {
                  display: none;
                }
              }
            }
          }

          &.selected {
            span {
              &:nth-child(2) {
                background: $white3;
                display: inline-block;

                &:before {
                  content: "";
                  background: url("/themes/thalesesecurity/templates/dist/images/selected-mark.png") no-repeat 0 2px / 13px;
                  position: relative;
                  display: inline-block;
                  width: 18px;
                  height: 14px;
                }
              }
            }
          }

          &.increased {
            &:after {
              height: calc(100% + 8px + var(--tooltip-height, auto));
            }
          }
        }
      }
    }
  }

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    @include phablet-down {
      display: block;
    }

    .col-wrap {
      width: calc(33.33% - 8px);
      margin: 0 4px 8px;
      padding: 47px 22px 41px;
      text-align: center;
      display: block;
      position: relative;
      //(max-width 1024px)
      @media screen and (max-width: $desktop_mid_width) {
        width: calc(50% - 8px);
      }
      //(max-width 768px)
      @include tablet-down {
        width: calc(50% - 8px);
        padding: 20px;
      }
      @include phablet-down {
        width: calc(100% - 8px);
      }

      &:before,
      .cover {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transition: all ease-in-out 0.2s;
        background: $white;
      }

      .cover {
        background: transparent;
        z-index: 4;
      }

      .content-wrap {
        position: relative;
        z-index: 2;
      }

      &:hover {
        z-index: 4;

        &:before,
        .cover {
          //transform: scale(1.15);
          top: -22px;
          bottom: -22px;
          left: -24px;
          right: -24px;
          @media screen and (max-width: $desktop_mid_width) {
            top: -14px;
            bottom: -14px;
            left: -14px;
            right: -14px;
          }
        }

        &:before {
          border: 1px solid $white3;
        }
      }

      h4 {
        color: $light-bluewhile;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 16px;
        @include phone-down {
          font-size: 14px;
        }
      }

      .img-wrap {
        margin: 0 auto 19px;
        height: 188px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        img {
          max-height: 100%;
          object-fit: contain;
        }
      }

      h3 {
        margin-bottom: 12px;
        color: $light-bluewhile;
        font-weight: 300;
        font-size: 23px;
        letter-spacing: -0.5px;
        line-height: 1.2;
        @include tablet {
          font-size: 22px;
        }
        @include phablet {
          font-size: 20px;
        }
        @include phablet-down {
          font-size: 18px;
        }
      }
    }
  }

  .views-exposed-form {
    display: none;
  }
}