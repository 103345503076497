.content-detail {
  @include container-up {
    //padding: 145px 0 70px;
  }
  .content {

    h1 {
      color: $light-bluewhile;
      font-size: 45px;
      font-weight: 300;
      line-height: 1.2;
      padding-bottom: 10px;
    }
    .date {
      h3 {
        margin: 0 0 33px;
      }
    }
    .info {
      display: flex;
      align-items: center;
      align-content: center;
      margin: 0 0 57px;
      flex-wrap: wrap;
      @include phablet-down {

      }
      img {
        width: 60px;
        height: 60px;
        border: 2px solid #e6e6e6;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        margin: 0 8px -2px 0;
        @include phablet-down {
          margin: 0 8px 0 0;
        }
      }

      .name {
        display: block;
        border-bottom: 1px solid #a9a9a9;
        font-weight: 700;
        padding: 0 0 5px;
        margin: -14px 0 3px;
        font-size: 22px;
        line-height: 25px;
        color: #050735;
        @include phablet-down {
          text-align: left;
          margin: 10px 0 3px;
        }
      }

      a {
        float: left;
        clear: both;
        color: #050735;
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: -15px;
        margin-left: 68px;
        @include phablet-down {
          margin-left: 0;
          margin-top: 10px;
        }
      }

    }

    h3 {
      color: $light-bluewhile;
      font-size: 30px;
      font-weight: 100;
    }
    ul {
      list-style-type: none;
      padding: 65px 0 18px 57px;
      li {
        position: relative;
        color: $light-bluewhile;
        font-size: 22px;
        font-weight: 100;
        padding-bottom: 32px;
        padding-left: 22px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: $light-blue;
        }
      }
    }
    h4 {
      color: $light-bluewhile;
      font-size: 22px;
      line-height: 1.5;
      padding-bottom: 33px;
    }
    p {
      font-size: 22px;
      color: $light-bluewhile;
      margin-bottom: 33px;
      a {
        font-size: 22px;
        color: $light-blue;
        &:hover {
          color: $light-blue;
        }
      }
      i {
        font-size: 22px;
      }
    }
    @include container-down {
      h2 {
        font-size: 40px;
      }
      h3 {
        font-size: 28px;
      }

    }
    @include tablet-down {
      text-align: center;
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 24px;
      }

      ul {
        padding: 30px 0;
        li {
          padding-bottom: 30px;
          padding-left: 20px;
        }
      }
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 20px;
        a {
          font-size: 20px;
        }
        i {
          font-size: 20px;

        }
      }
    }
  }
}
