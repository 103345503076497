.page-links {
  border-top: 2px solid $light-sky;
  background: $white2;
  @include container-up {
    //padding: 71px 0 84px;
  }
  .column-wrapper {
    float: left;
    position: relative;
    width: 100%;
    .text-link {
      width: calc(33.3%);
      background: $white;
      padding: 45px 38px 40px;
      min-height: 408px;
      @include tablet {
        width: calc(40%);
      }
      @include tablet-down {
        width: calc(100%);
        min-height: 280px;
        padding: 20px 10px;
      }
      h3 {

        font-weight: 300;
        margin: 0 0 36px;
        word-break: break-word;
        line-height: 1.18;
        color: $blue4;
        font-size: 22px;
        text-align: center;
        @include tablet-down {
          font-size: 20px;
          margin: 0 0 20px;
        }
      }
      ul {
        list-style: none;
        text-align: left;
        padding-bottom: 26px;
        padding-left: 0;
        @include tablet-down {
          padding: 0 0 10px 0;
        }
        li {
          margin: 0 0 14px;
          font-weight: 100;
          line-height: 1.48;
          @include tablet-down {
            margin: 0 0 19px;
          }
          .active-link {
            &:after {
              top: 10px;
              @include phablet-down {
                top: 3px;
              }
              @include tablet {
                margin: 0 0 0 5px;
              }
            }
          }
        }
      }
      .btn-box {
        margin: 0 auto;
        text-align: center;
      }
    }
    .blog-box {
      width: calc(66.67%);
      padding-left: 4px;
      @include tablet {
        width: calc(60%);
      }
      @include tablet-down {
        width: calc(100%);
        padding-left: 0;
      }
      .card-blog {
        width: 100%;
        position: relative;
        > a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          cursor: pointer;
        }
        .bg-img {
          width: 100%;
          //height: 100%;
          min-height: 408px;
          position: relative;
          @include tablet-down {
            height: 350px;
          }
          &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            opacity: .6;
            transition: all 0.5s ease;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .content-box {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 0;
          width: 100%;
          padding: 28px 20px 0;
          text-align: center;
          z-index: 1;
          .icon-box {
            margin: 0 auto 30px;
            max-width: 90px;
            max-height: 91px;
            height: auto;
            @include tablet-down {
              margin: 10px auto 20px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .main-content {
            .author {
              display: block;
              line-height: 1.33;
              color: $white;
              font-weight: 100;
              font-size: 22px;
              padding-top: 5px;
              @include tablet-down {
                font-size: 16px;
              }
            }
            h3 {
              color: $white;
              font-weight: 300;
              font-size: 22px;
            }
          }
        }
        &:hover {
          .bg-img {
            transition: all 0.5s ease;
            &:before {
              opacity: .8;
              transition: all 0.5s ease;
            }
          }
        }
      }
    }
  }
}