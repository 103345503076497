.introduction-section{
  padding: 60px 0;
  @include tablet-down {
    padding: 40px 0;
  }
  strong {
    font-weight: 700 !important;
  }
  .full-row{
      .content{
       h2{
        color: $light-bluewhile;
        font-size: 27px;
        padding-bottom: 10px;
       }
       p{
        font-size: 16px;
        padding : 0 0 1em;
        color: $light-bluewhile;
       }
       @include phablet-down {
       text-align: center;
        h2{
          font-size: 24px;
          }
        }
      }
      .bigbtn {
        padding: 0 0 1em;
        margin-bottom: 10px;
        .btn-default {
          width: 100%;
          font-size: 32px !important;
          text-align: center;
          margin-top: 50px;
          line-height: 1.6;
          border-radius: 3px;
          padding: 3px 12px 8px;
          font-weight: 900 !important;
          color: $white !important;
        }        
        &:hover{
          opacity: 0.9;
        }
        @include desktop-down {
          .btn-default {
            font-weight: 500 !important;
            font-size: 24px !important;
          }
        }
        @include tablet-down {
          max-width: 400px;
          margin: 0 auto;
          font-weight: 500 !important;
          text-align: center;
          .btn-default {
            margin-top: 0;
            max-width: 280px;
            font-size: 20px !important;
          }
        }
      }
    }
  }
  

