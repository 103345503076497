.tab-with-text {
  @include container-up {
    //padding-bottom: 55px;
  }

  .column-wrapper {
    width: 100%;
    float: left;
    margin: 0 auto;
    font-size: 0;
    .item-list {
      width: 100%;
      margin-bottom: 20px;
      &.change {
        margin-bottom: 10px;
      }
      h3 {
        position: relative;
        padding-left: 26px;
        font-size: 18px;
        line-height: 1.21;
        color: $light-bluewhile;
        display: inline;
        cursor: pointer;
        letter-spacing: 0.66px;
        font-weight: 400;
        @include tablet-down {
          font-size: 16px;
          padding-left: 16px;
        }
        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          left: 0;
          top: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 6px solid $light-bluewhile;
          margin: 2px 0 0;
          transform: translate(0, 50%);
          transition: all ease 0.2s;
        }
        &:hover {
          text-decoration: underline;
        }
        &.active {
          margin: 0 0 16px;
          &:before {
            top: 0;
            border-bottom: none;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid $light-bluewhile;
            margin: 6px 0 0;
          }
        }
      }
      ul {
        list-style: none;
        padding-top: 12px;
        display: none;
        li {
          display: flex;
          width: 100%;
          list-style: none;
          position: relative;
          .tab-link {
            display: block;
            list-style: none;
            padding: 6px 5px 5px 13px;
            position: relative;
            color: $light-bluewhile;
            font-size: 16px;
            cursor: pointer;
            font-family: $font-families;
            font-weight: 100;
            max-width: 404px;
            width: 100%;
            @include tablet-down {
              font-size: 14px;
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 4px;
              background: #323678;
              opacity: 0;
              @include tablet-down {
                display: none;
              }
            }
            &:hover {
              background: $grey5;
              @include tablet-down {
                background: transparent;
                text-decoration: underline;
              }
              &:before {
                opacity: 1;
              }
            }
            &.current {
              background: $grey5;
              @include tablet-down {
                background: transparent;
              }
              &:before {
                opacity: 1;
              }
            }
          }
          .tab-content {
            width: 68%;
            display: none;
            &.current {
              display: block;
            }
            p + ul {
              //&.content-text
              margin: -25px 0 33px;
            }
            h4 {
              position: relative;
              line-height: 1.33;
              color: $light-bluewhile;
              font-size: 30px;
              font-weight: 300;
              margin-bottom: 14px;
              @include tablet-down {
                font-size: 20px;
                margin-bottom: 10px;
              }
            }
            p {
              font-weight: 100;
              color: $light-bluewhile;
              margin-bottom: 32px;
              line-height: 1.67;
              @include desktop-down {
                font-size: 18px;
              }
              @include tablet-down {
                line-height: 1.33;
              }
              a {
                font-size: 22px;
                word-wrap: break-word;
                color: $blue6;
                text-decoration: none;
                font-family: $font-families;
                font-weight: 300;
                @include tablet-down {
                  font-size: 18px;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
              &.foot-note {
                sup {
                  left: 0.3em;
                }
              }
            }
            ul {
              margin: 0 0 33px;
              list-style-type: disc;
              padding-left: 24px;
              display: block !important;
              @include tablet-down {
                margin-left: 8px;
              }
              li {
                font-size: 22px;
                margin: 0 0 6px;
                font-weight: 100;
                color: $light-bluewhile;
                @include tablet-down {
                  font-size: 18px;
                }
                a {
                  font-size: 22px;
                  word-wrap: break-word;
                  color: $blue6;
                  text-decoration: none;
                  font-family: $font-families;
                  font-weight: 300;
                  @include tablet-down {
                    font-size: 18px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }

              }

            }
            ol {
              list-style-type: decimal;
              margin: 0 0 33px;
              padding-left: 24px;
              li {
                font-size: 22px;
                margin: 0 0 6px;
                font-weight: 100;
                color: $light-bluewhile;
                @include tablet-down {
                  font-size: 18px;
                }
                a {
                  font-size: 22px;
                  word-wrap: break-word;
                  color: $blue6;
                  text-decoration: none;
                  font-family: $font-families;
                  font-weight: 300;
                  @include tablet-down {
                    font-size: 18px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }

              }
            }
            .indent {
              padding-left: 50px;
              @include container-down {
                padding-left: 25px;
              }
            }
          }
        }
      }
    }

    .col-two {
      &.menu-item {
        width: 32%;
        padding-top: 15px;
        @include tablet-down {
          width: 100%;
          padding-top: 0;
        }
        .accordion-item {
          position: relative;
          width: 100%;
          overflow: hidden;
          margin: 0 0 28px;
          @include desktop-down {
            margin: 0 0 20px;
          }
          @include tablet-down {
            background: $white-light;
            margin: 0 0 5px;
            padding: 8px 32px 8px;
          }
          &.active {
            margin: 0 0 16px;
            > h3 {
              color: $light-bluewhile;
              margin-bottom: 12px;
              &:before {
                top: 0;
                border-bottom: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid $light-bluewhile;
                margin: 6px 0 0;
              }
            }
          }
          > h3 {

            position: relative;
            padding-left: 26px;
            font-size: 18px;
            line-height: 1.21;
            color: $light-bluewhile;
            display: inline-block;
            cursor: pointer;
            letter-spacing: 0.66px;
            font-weight: 400;
            margin-bottom: 0;
            @include tablet-down {
              font-size: 16px;
              padding-left: 16px;
            }
            &:before {
              position: absolute;
              content: '';
              width: 0;
              height: 0;
              left: 0;
              top: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 6px solid $light-bluewhile;
              margin: 2px 0 0;
              transform: translate(0, 50%);
              transition: all ease 0.2s;
            }
            &:hover {
              text-decoration: underline;
            }
          }
          .description {
            display: none;
            width: 100%;
            border-bottom: 1px solid #e5e5e5;
            list-style: none;
            padding-left: 0;
            @include tablet-down {
              border: 0;
            }
            li {
              margin: 0;
            }
            li a {
              display: block;
              width: 100%;
              list-style: none;
              padding: 6px 5px 5px 13px;
              position: relative;
              color: $light-bluewhile;
              font-size: 16px;
              cursor: pointer;
              font-family: $font-families;
              font-weight: 100;
              margin: 0;
              @include tablet-down {
                font-size: 14px;
              }
              &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 4px;
                background: #323678;
                opacity: 0;
                @include tablet-down {
                  //display: none;
                }
              }
              &:hover {
                background: $grey5;
                text-transform: none;
                text-decoration: none;
                @include tablet-down {
                  //background: transparent;
                  //text-decoration: underline;
                }
                &:before {
                  opacity: 1;
                }
              }
              &.current {
                background: $grey5;
                @include tablet-down {
                  //background: transparent;
                }
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &.content-box {
        width: 68%;
        @include tablet-down {
          width: 100%;
        }
        .cover-text {
          padding-left: 25px;
          @include tablet-down {
            padding-left: 0;
            margin-top: 33px;
          }
          .tab-content {
            width: 100%;
            //display: none;
            &.current {
              display: inherit;
            }
            p + ul {
              margin: -25px 0 33px;
            }
            h3 {
              position: relative;
              line-height: 1.33;
              color: $light-bluewhile;
              font-size: 30px;
              font-weight: 300;
              margin-bottom: 14px;
              @include tablet-down {
                font-size: 20px;
                margin-bottom: 10px;
              }
            }
            p {
              font-weight: 100;
              color: $light-bluewhile;
              margin-bottom: 32px;
              line-height: 1.67;
              @include desktop-down {
                font-size: 18px;
              }
              @include tablet-down {
                line-height: 1.33;
              }
              a {
                font-size: 22px;
                word-wrap: break-word;
                color: $blue6;
                text-decoration: none;
                font-family: $font-families;
                font-weight: 300;
                @include tablet-down {
                  font-size: 18px;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
              &.foot-note {
                sup {
                  left: 0.3em;
                }
              }
            }
            ul {
              margin: 0 0 33px;
              list-style-type: disc;
              padding-left: 24px;
              @include tablet-down {
                margin-left: 8px;
              }
              li {
                font-size: 22px;
                margin: 0 0 6px;
                font-weight: 100;
                color: $light-bluewhile;
                @include tablet-down {
                  font-size: 18px;
                }
                a {
                  font-size: 22px;
                  word-wrap: break-word;
                  color: $blue6;
                  text-decoration: none;
                  font-family: $font-families;
                  font-weight: 300;
                  @include tablet-down {
                    font-size: 18px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }

              }

            }
            ol {
              list-style-type: decimal;
              margin: 0 0 33px;
              padding-left: 24px;
              li {
                font-size: 22px;
                margin: 0 0 6px;
                font-weight: 100;
                color: $light-bluewhile;
                @include tablet-down {
                  font-size: 18px;
                }
                a {
                  font-size: 22px;
                  word-wrap: break-word;
                  color: $blue6;
                  text-decoration: none;
                  font-family: $font-families;
                  font-weight: 300;
                  @include tablet-down {
                    font-size: 18px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }

              }
            }
            .indent {
              padding-left: 50px;
              @include container-down {
                padding-left: 25px;
              }
            }
          }
        }
      }
    }

  }
}

&.node-732 {
  .tab-with-text .col-two.content-box {
    .views-element-container {
      display: none;
    }
    h2{
      margin-bottom: 30px;
    }
  }
  .overview{
    display: none;
  }
}
