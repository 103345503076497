
.cload-container {
  width: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: none;

  strong {
    font-weight: 700 !important;
  }

  .cookie-loader {
    width: 400px;
    text-align: center;
    padding: 0;
    position: fixed;
    top: 30%;
    background-color: #dddddd;
    border-radius: 5px;
    @include phablet-down {
      width: 320px;
    }
  }

  .closebtn {
    width: 100%;
    height: 40px;
    display: inline-block;
    background: url(/themes/thalesesecurity/templates/dist/images/close-icon-v2.png) right 10px no-repeat #242a75;
    margin-bottom: 10px;
    border-radius: 5px 5px 0 0;
  }

  p {
    line-height: 1.6;
    padding: 0 0 1em;
    color: #3C3C3C;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .bigbtn {
    padding: 8px 15px 8px;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 3px;
    display: inline-block;
    border-radius: 3px;
    border: none;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #00bbdd;
    &:hover {
      background-color: #41D4EE;
    }
  }
}

&.toolbar-tray-open #sentinel-up-banner {
  margin-top: 105px;
  //border-top: 7px solid #b90b67;
  border-top: 7px solid $Midlightblue;
}


#sentinel-up-banner {

  background: url(/themes/thalesesecurity/templates/dist/images/banner-v3.jpg) no-repeat center center #000000;
  background-size: cover;
  padding: 30px 0 10px;
  margin-top: 71px;
  border-top: 7px solid $Midlightblue;
  margin-bottom: 38px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 0 10px;
  }

  strong {
    font-weight: 700 !important;
  }

  .projectname{
    display: none;
  }
  h1, p, span, label, input {
    // font-family: $font-familie-dinpro;
  }
  .content-wrapper {
    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    .col-wrap {
      .white-box {
        padding: 28px 0 20px;
        background: none;
        h1 {
          color: white;
          font-size: 32px;
          font-weight: 400;
          padding-bottom: 20px;

          @include media-breakpoint-down(sm) {
            font-size: 28px;
          }
        }
        p {
          line-height: 1.6;
          padding: 0;
          font-size: 16px;
          color: #fff;

          @include media-breakpoint-down(xs) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        .projectname{
          font-weight: 400;
        }
        .label-full-width {
          width: 100%;
          margin-top: 40px;
          color: #fff;
          @include media-breakpoint-down(xs) {
            margin-top: 10px;
          }
        }
        #bvcname {
          width: 100%;
          border: none;
          border-bottom: 1px solid #cccccc;
          border-radius: 0;
          font-weight: bold;
          font-size: 1.3em;
          padding: 0 0 7px 0;
          margin-bottom: 38px;
          background-color: transparent;
          color: white;
        }
        .getstarted {
          padding: 8px 15px 7px;
          text-transform: uppercase;
          margin-bottom: 3px;
          display: inline-block;
          border-radius: 3px;
          border: none;
          background-color: #00bbdd;
          font-weight: bold;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          &:hover {
            background-color: #41D4EE;
          }
        }
      }
    }
  }
  &.activatedbanner {
    margin-bottom: 0;
  }
}


.value-calc {
  padding: 11px 0 68px;
  background-color: #f5f5f5;
  display: none;
  @include phablet-down {
    padding: 30px;
  }
  strong {
    font-weight: 700 !important;
  }
  
  .calcbody {
    padding: 0 27px;
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    #ctitles {
      padding-top: 30px;
      position: relative;
      overflow: hidden;
      @include phablet-down {
        padding-top: 0;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 2.3em;
      margin: 20px 0 30px 0;
      line-height: 1em;
      color: black;
      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
    #calculator-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 500px;
      padding-bottom: 76px;

      @include media-breakpoint-down(md) {
        padding-bottom: 30px;
      }

      .white-block {
        border-bottom: solid 5px #00bbdd;
        background-color: #fff;
        padding: 24px 0 27px;
        .wrapper {
          @include media-breakpoint-down(sm) {
            padding: 0 25px;
          }
          .col-xs-offset-2 {
            margin-left: 24.3%;
            @include media-breakpoint-down(sm) {
              margin-left: 0;
            }
            h3 {
              line-height: 1.09;
            }
          }

          p {
            font-size: 0.9em;
          }
          #mainren {
            width: 60px;
          }
          .mainren {
            display: none;
            margin-top: -10px;
            p {
              font-weight: 400;
              margin-bottom: 1px;
              color: #3c3c3c;

              &:first-child {
                margin-bottom: 10px;
              }
            }
          }
          h3, h4 {
            text-align: left;
            margin: 0 0 20px;
            padding: 0;
            font-size: 1.4em;
            color: #242a75;
            font-weight: 400;
          }
          .dollar {
            font-weight: bold;
            display: inline-block;
            width: 3.5%;
            color: #3C3C3C;
            font-size: 15px;
          }
          input {
            border-radius: 3px;
            border: solid 1px #cccccc;
            width: 93.5%;
            padding: 6px 10px 8px;
            font-weight: bold;
            font-size: 14px;
            color: #555555;
          }
          .percent {
            margin-left: 17px;
            font-weight: 600;

            @include media-breakpoint-down(xs) {
              margin-left: 0;
              padding-left: 11px;
            }
          }

          #growth {
            width: 60px;
          }
          #lgen {
            width: 60px;
          }

          .border-btn {
            border: solid 1px #cccccc;
            border-radius: 3px;
            padding: 8px 10px 7px;
            font-weight: bold;
            text-align: center;
            @include media-breakpoint-up(lg) {
              margin-bottom: 26px;
            }
            &:hover {
              border-color: #00bbdd;
              cursor: pointer;
            }
            &.activebbtn {
              background-color: #cccccc;
              color: white;
            }
          }
          .btn-outer {
            @include media-breakpoint-up(lg) {
              max-width: 24.5%;
            }
          }
        }
      }
    }
    
  }
  .meganbtn {
    line-height: 1.6;
    color: #3C3C3C;
    font-size: 16px;
    font-weight: 400;
    width: auto;
    padding: 18px 30px 17px;
    margin: 0;
    display: inline-block;
    border-radius: 3px;
    background-color: #00bbdd;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    @include media-breakpoint-up(lg) {
      margin-left: -29px;
      width: 97%;
    }

    @include media-breakpoint-down(md) {
      padding: 5px 30px;
    }
    &:hover {
      cursor: pointer;
      background-color: #41D4EE;
    }
    span {
      font-weight: bold;
      font-size: 1.4em;
      color: $white;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }
  #form {
    display: none;
    margin: 0 15px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 260;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0;

    @include media-breakpoint-down(sm) {
      overflow: scroll;
      padding-bottom: 6%;
    }
    .middlepart {
      position: relative;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      width: 40%;
      background-color: #fff;
      @include media-breakpoint-down(md) {
        width: 56%;
      }
      @include media-breakpoint-down(sm) {
        top: 56%;
        margin-bottom: 20px;
        width: 90%;
      }
    }
    h2 {
      font-size: 27px;
      font-weight: 400;
      margin: 0;
      color: #fff;
      width: 89%;
      display: inline-block;
      vertical-align: top;
      padding: 5px 0 0 26px;
      position: relative;
    }
    .nbluebg {
      position: relative;
      background-color: #242a75;
      padding: 9px 0 17px;
      h2 {
        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }
      }
    }
    .closefbtn {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      cursor: pointer;
      width: 10%;
      display: inline-block;
      background: url(/themes/thalesesecurity/templates/dist/images/close-icon-v2.png) center center no-repeat;
      @include media-breakpoint-down(xs) {
        width: 12%;
      }
    }
    .form-box {
      width: 100%;
      padding: 19px 25px 15px;
      clear: left;
      background-color: white;
    }
    form{
      overflow: hidden;
    }
    .form-group {
      margin-bottom: 0;
      position: relative;
      float: left;
      width: 50%;
      padding: 5px 20px 5px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      &.download-btn{
        width: 100%;
      }
      label{
        font-size: 16px;
        color: #253746;
        &:before {
          content: '*';
          display: inline;
          font-size: inherit;
          color: red;
        }
      }
      input {
        width: 100%;
        font-weight: 400;
        color: #0e0500;
        margin-left: 0;
        padding: 4px 6px;
        border-radius: 3px;
        border: solid 1px #bbbbbb;
        outline: none;
        @include media-breakpoint-down(xs) {
          // margin-bottom: 12px;
        }

        &.gem-button-lg {
          border: none;
          padding: 4px 20px 6px;
          text-transform: uppercase;
          background-color: #00bbdd;
          border-radius: 3px;
          position: relative;
          color: #FFF;
          width: 30%;
          margin-top: 17px;
          font-size: 18px;
          font-weight: 700;
          cursor: pointer;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
      }
      input::placeholder {
        color: #757575;
        font-weight: 500;
      }
      .dirty-value {
        border: 2px dashed red !important;
      }
      select {
        width: 100%;
        margin-left: 0;
        padding: 4px 6px;
        border-radius: 3px;
        border: solid 1px #bbbbbb;
        height: 36px;
      }
      #PTF_business_value_calculator, #business_value_calculator {
        display: none;
      }
      .submit {
        margin-top: 5px;
      }
      &.privacy {
        padding: 12px 17px 4px;
        width: 100%;
        p {
          font-size: 14px;
          @include media-breakpoint-down(sm) {
            padding-bottom: 13px;
          }
          a {
            color: $Midlightblue;
          }
        }
      }
    }
    .hiddenbvcfields {
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
  #calculator-part2{
    display: none; 
    #ctitles {
      padding-top: 30px;
      position: relative;
      overflow: hidden;
      h2{
        font-weight: bold;
        font-size: 2.3em;
        margin: 20px 0 30px 0;
        line-height: 1em;
      }
    } 
    .gethelp {
      text-align: center;
      padding-top: 34px;
      .bigbtn{
        font-size: 21px;
        padding: 5px 15px 10px;
        text-transform: uppercase;
        background-color: #00bbdd;
        color: white;
        text-decoration: none;      
        margin-bottom: 3px;
        display: inline-block;
        border-radius: 3px;
        font-weight: bold;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
    .results-block {
      padding: 30px 0 0;
      background-color: white;
    }
    h5 {
      font-size: 23px;
      margin-bottom: 30px;
      line-height: 1.4em;
      font-weight: bold;
      color: #242a75;
    }
    p{
      font-weight: 500;
    }
    .col-sm-offset-6 {
      margin-left: 50%;
    }
    .big-white-block {
      background-color: #fff;
      p {
        margin: 10px 0 15px;
        padding: 0;
        font-size: 0.9em;
      }
    }
    .lightblue {
      background-color: #00bbdd;
      @media (max-width: 768px){
        flex: 100%;
        padding-left: 35px !important;
        padding-right: 35px !important;
        max-width: 100%;
      }
    }
    .text-indent {
      padding-left: 85px;
      @media (max-width: 768px){
        padding: 10px 35px 1px;
        margin-top: 0px;
      }
    }
    .big-white-block {
      margin-bottom: 50px;
    }
    .yearsmob {
      display: none;
      @media (max-width: 768px){
        display: inline;
        width: 40%;
        float: left;
      }
    }
    .lightblue p {
      color: white;
    }
    .smain, .scomp{
      display: none;
    }
    .sentinelup-cta {
      padding: 30px 70px 10px;
      background: url(/themes/thalesesecurity/templates/dist/images/banner-v3.jpg) center center no-repeat;
      background-size: cover;
      p{
        color: #fff;
      }
    }
    .downloadbtn{
      text-transform: uppercase;
      padding: 14px 30px 19px 30px;
      margin: 15px 0 3px;
      display: inline-block;
      border-radius: 3px;
      font-size: 21px;
      font-weight: bold;
      background-repeat: no-repeat;
      background-color: #00bbdd;
      color: white;
      background-position: 10px 10px;
      background-image: url(/themes/thalesesecurity/templates/dist/images/pdf-icon.png);
      &:hover{
        text-decoration: none;
        background-color: #41D4EE;
      }
    }
    @media (max-width: 768px){
      .yearnums {
        display: none;
      }
    }

  }
}
