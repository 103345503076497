.col-three-resources{
  background: $light-grey;
  @include container-up{
    //padding: 67px 0 91px;
  }
  h2{
    text-align: center;
    color: $light-bluewhile;
    margin: 0 0 58px;
    font-weight: 300;
  }
.row-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px 0 -3px;
  @media screen and (max-width: 1200px) {
    margin: 0;
  }
  @include tablet-down {
    display: block;
  }
  .col-wrap{
    width: calc(33.33% - 8px);
    margin: 0 4px 8px;
    padding: 13px 24px 41px;
    text-align: center;
    display: block;
    position: relative;

    //(max-width 1024px)
    @media screen and (max-width: $desktop_mid_width) {
      width: calc(50% - 8px);
    }

    //(max-width 768px)
    @include tablet-down {
      width: calc(100% - 8px);
      margin:0 auto 15px;
      max-width: 350px;
      padding-bottom: 20px;
    }

    &:before,.cover{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      transition: all ease-in-out 0.2s;
      background: $white;
    }
    .cover{
      background: transparent;
      z-index: 4;
    }
    .content-wrap{
      position: relative;
      z-index: 2;
    }
      &:hover{
        z-index: 4;
        .cover,&:before{
          //transform: scale(1.15);
          top: -22px;
          bottom: -22px;
          left: -24px;
          right: -24px;
          @media screen and (max-width: $desktop_mid_width) {
            top: -14px;
            bottom: -14px;
            left: -14px;
            right: -14px;
          }
        }
        &:before{
          border: 1px solid $white3;
        }
      }

      h4{
        color: $light-bluewhile;
        margin-bottom: 7px;
        text-transform: uppercase;
        font-size: 16px;
        @include phone-down {
          font-size: 14px;
        }
      }
      .img-wrap{
        margin: 0 auto 19px;
        height: 188px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        img{
          max-height: 100%;
          object-fit: contain;
        }
      }
      h3{
        margin-bottom: 6px;
        color: $light-bluewhile;
        font-weight: 300;
        font-size: 23px;
        letter-spacing: -0.5px;
        line-height: 1.25;
        @include tablet {
          font-size: 22px;
        }
        @include phablet {
          font-size: 20px;
        }
        @include phablet-down {
          font-size: 18px;
        }
      }
    }

  }
}
