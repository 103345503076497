.feature-slider {
  background: #FFFFFF;
  padding: 60px 0;
  opacity: 0;

  @include tablet-down {
    padding: 40px 0;
  }

  .item {
    padding: 25px 70px;
    display: flex;
    margin: 0 8px;

    @include desktop-down {
      padding: 0;
      width: 100%;
      margin: 0;
      justify-content: initial;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: column;
    }

    // @include tablet-down {
    //   padding: 20px 0;
    // }
    &.img-with-slider {
      .img-wrapper {
        width: 250px;
        display: flex;
        align-items: center;
        padding-left: 0px;
        justify-content: center;

        @include container-down {
          width: 170px;
        }

        @include desktop-down {
          width: 100%;
          order: 1;
          display: block;
        }
      }

      .content-wrapper {
        width: calc(100% - 250px);

        @include container-down {
          width: calc(100% - 170px);
        }

        @include desktop-down {
          width: 100%;
        }
      }

    }
  }

  .col-wrap {
    @include desktop-down {
      // padding: 0 20px 20px ;
      padding: 10px 20px;

      flex-direction: column-reverse;
      order: 2;
    }

    .img-wrap {
      max-height: 226px;
      max-width: 226px;
      display: inline-block;

      @include desktop-down {
        margin: 0 auto;
      }

      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  .content {
    padding: 0 30px 0 0;
    text-align: left;

    @include desktop-down {
      padding: 0;
    }

    @include tablet-down {
      padding-bottom: 30px;
    }

    h2 {
      margin-bottom: 20px;
      font-weight: 600;
      color: #253746;
    }

    h3 {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 24px;
      color: #253746;
    }

    p {
      line-height: 1.44;
      font-weight: 100;
      color: #253746;
      font-size: 22px;
      letter-spacing: -0.03em;
      margin-bottom: 0;
      display: inline-block;
    }

    img {
      display: inline-block;
      max-height: 180px;
      max-width: 150px;
      object-fit: contain;
    }
  }

  .slick-arrow {
    outline: 0;
    position: absolute;
  }

  .slick-prev {
    background: none;
    float: right;
    width: 7px;
    height: 14px;
    z-index: 2;
    display: block;
    font-size: 0;
    left: 0;
    position: absolute;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    top: 48%;
    transform: translate(0, -50%);

    &:before {
      content: '';
      position: absolute;
      background: url(/themes/thalesesecurity/templates/assets/images/leftside-arrow.png) no-repeat;
      background-size: 100%;
      width: 7px;
      height: 14px;
      top: 50%;
      left: 10px;
      transform: rotate(0deg);
    }
  }

  .slick-next {
    background: none;
    float: right;
    width: 7px;
    height: 14px;
    z-index: 1;
    display: block;
    right: 0;
    font-size: 0;
    position: absolute;
    padding: 0;
    top: 48%;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;

    &:before {
      content: '';
      position: absolute;
      background: url(/themes/thalesesecurity/templates/assets/images/leftside-arrow.png) no-repeat;
      background-size: 100%;
      width: 7px;
      height: 14px;
      top: 50%;
      right: 10px;
      transform: rotate(180deg);
    }

  }

  .slick-dots {
    //min-width: 70px;
    padding: 0;
    margin: 20px auto;
    display: inline-block;
    position: absolute;
    //transform: translateX(-50%);
    //left: 50%;
    text-align: center;
    list-style-type: none;
    cursor: pointer;
    //bottom: 30px;
    bottom: -50px;
    width: 100%;
    @include desktop-down {
      bottom: -70px;
    }
    @include tablet-down {
      bottom: -60px;
    }
    @include phablet-down {
      bottom: -50px;
    }
    li {
      height: 12px;
      width: 12px;
      background: #ddd;
      list-style-type: none;
      display: inline-block;
      border-radius: 50%;
      margin: 0 4px;

      button {
        font-size: 0;
      }

      &.slick-active {
        background: linear-gradient(to bottom, #5dbdf4 0%, #2b86bc 100%)
      }
    }
  }
}

.feature-reverse {

  .col-wrap {
    flex-direction: column-reverse;
  }

  .content-wrapper {
    order: 2;
  }

  .img-wrapper {
    order: 1;
    padding-left: 0;
    padding-right: 20px;

    @include desktop-down {
      padding-right: 0;
    }
  }

  .content {
    padding: 0 0 0 40px;

    @include desktop-down {
      padding: 0;
    }
  }
}

.bg-light-grey {
  background: #F3F4F4 !important;
}

.bg-white {
  background: #FFFFFF !important;
}

.bg-dark-blue {
  background: #1A2856 !important;
}

.bg-light-blue {
  background: #E6EFF4 !important;
}
