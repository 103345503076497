.video-quote-slider {
  background: linear-gradient(to bottom, #276296 0%, #448ccb 100%);
  @include container-up{
    //padding: 50px 0;
  }
  .column-wrapper {
    width: 100%;
    position: relative;
    @include container-up {
      max-width: 1000px;
      margin: 0 auto;
    }
    .slider {
      opacity: 0;
    }
    .cover-video-with-info {
      width: 100%;
      outline: none;
      display: flex;
      align-items: center;
      @include container-down {
        padding: 0 40px;
      }
      @include tablet-down {
        flex-direction: column;
      }
      .auto-info {
        width: 50%;
        position: relative;
        @include container-up {
          padding-right: 15px;
          padding-left: 15px;
        }
        @include tablet-down {
          order: 2;
          width: 100%;
          margin-top: 20px;
        }
        h2 {
          line-height: 1.0;
          font-weight: bold;
          font-family: $font-families;
          color: $white;
          margin: 0 25px 20px;
          @include container-up {
            margin: 0 40px 20px;
          }
          @include phablet-down {
            margin: 0 0 15px 20px;
          }
          @include phone-down {
            font-size: 20px;
          }
          &:before {
            content: '“';
            left: 0;
            position: absolute;
            font-size: 32px;
            line-height: 1.0;
            @include container-up {
              left: 30px;
            }
          }
          &:after {
            content: '”';
            padding-left: 10px;
            position: absolute;
            font-size: 32px;
            line-height: 1.0;
          }
          @include desktop-up {
            font-size: 32px;
          }
        }
        p {
          margin: 0 0 15px 26px;
          font-family: $font-families;
          color: $white;
          display: block;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.0;
          @include container-up {
            margin: 0 0 20px 40px;
          }
          @include phablet-down {
            margin: 0 0 15px 20px;
            font-size: 16px;
          }
          span {
            font-family: $font-families;
            color: $white;
            display: block;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.0;
            @include phablet-down {
              font-size: 16px;
            }
          }
        }
        .log-icon {
          margin-left: 27px;
          max-height: 35px;
          @include container-up {
            margin-left: 40px;
          }
          @include phablet-down {
            margin: 0 0 0 20px;
          }
        }
      }
      .auto-video {
        width: 50%;
        position: relative;
        @include container-up {
          padding-right: 15px;
          padding-left: 15px;
        }
        @include tablet-down {
          order: 1;
          width: 100%;
        }
        .cover-video {
          position: relative;
          overflow: hidden;
          height: 250px;
          @include phablet-down {
            height: 220px;
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }

      }
    }

    .slick-arrow {
      outline: 0;
      position: absolute;
      background: $white-light;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $dark-blue;
      z-index: 2;
      display: block;
      font-size: 0;
      line-height: 0;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      border: none;
      outline: none;
      @include container-down {
        width: 30px;
        height: 30px;
      }
      &:before {
        font-family: 'icomoon' !important;
        opacity: 1;
        content: "\e908";
        font-size: 23px;
        color: $dark-blue;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        @include container-down {
          font-size: 21px;
        }
      }
      &.slick-disabled {
        cursor: auto;
        opacity: 0.6;
      }
      &.slick-prev {
        top: 50%;
        left: -50px;
        @include container-down {
          left: 0;
        }
        &:before {
          content: "\e909";
        }
      }
      &.slick-next {
        z-index: 1;
        top: 50%;
        right: -50px;
        @include container-down {
          right: 0;
        }
        &:before {
          content: "\e908";
        }
      }
    }
  }
}